<template>
    <div class="toolbar-kiosk">
        <ul class="toolbar-kiosk-items">
            <li class="toolbar-kiosk-item" v-if="features.kioskExit" @click="onResetUser">{{$translate(api.language, 'kiosk.exit')}}</li>
            <li class="toolbar-kiosk-item" v-if="api.bot.privacyUrl !== undefined" @click="onShowPrivacy">{{$translate(api.language, 'kiosk.privacy')}}</li>
            <li class="toolbar-kiosk-item" v-if="!imgLang && features.multibot">
                <BotLang :features="features" :icons="false" class="kiosk" :aria-label="$translate(api.language, 'tooltip.language')" :content="$translate(api.language, 'tooltip.language')" v-tippy="{ placement : 'top',  appendTo: container }" @onChangeBot="onChangeBot" />
            </li>
        </ul>
        <div class="toolbar-kiosk-langs" v-if="imgLang">
            <BotLang :features="features" :icons="true" class="rounded" :aria-label="$translate(api.language, 'tooltip.language')" :content="$translate(api.language, 'tooltip.language')" v-tippy="{ placement : 'left',  appendTo: container }" @onChangeBot="onChangeBot" />
        </div>
    </div>
</template>

<script>

import BotLang from './BotLang.vue';

export default {
    name: "KioskMessageTool",
    components: {
        BotLang
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            imgLang: true
        }
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            if (!this.features.kiosk)
                return ()=> rootShadow.querySelector('div');
            else
                return ()=> this.$el;
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onResetUser(event) {
            event.stopPropagation();
            this.$emit("onResetUser");
        },
        onShowPrivacy() {
            this.$emit("onShowPrivacy");
        },
        onChangeBot(bot) {
            this.$emit('onChangeBot', bot);
        },
    }

}
</script>

<style lang="less">
.toolbar-kiosk {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    ul.toolbar-kiosk-items {
        display: flex;
        justify-content: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: var(--bg-other-color);
        box-shadow: var(--box-shadow);
        backdrop-filter: var(--backdrop-filter);
        border-radius: var(--border-radius);
        width: fit-content;

        li.toolbar-kiosk-item {
            position: relative;
            display: block;
            min-width: 64px;
            padding: 4px 16px 4px 16px;
            border: 1px solid var(--border-other-color);
            font-size: var(--font-10);
            line-height: calc(var(--font-10) + 3px);
            color: var(--fg-other-color);
            white-space: nowrap;
            cursor: pointer;
        }
        li:first-child {
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
            //border-right-width: 0;
        }
        li:last-child {
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
            //border-left-width: 0;
        }
        li:not(:first-child):not(:last-child){
            border-left-width: 0;
            border-right-width: 0;
        }
    }

    .toolbar-kiosk-langs {
        position: absolute;
        right: 15px;
        top: -64px;
    }
}
</style>
