<template>
    <div class="container-message-manager-component">

        <div class="container-message-manager">

            <template v-if="features.stt && features.https && !features.dictatePreviewMessage">
                <div v-if="!showKeyboard" class="toolbar-message-preview">
                    <span ref="preview" v-if="recognizeVoice && textInput !== ''" v-html="textInput"></span>
                    <span v-else> </span>
                </div>
            </template>

            <div class="toolbar-message-manager" :class="{'has-keyboard': showKeyboard}">

                <Menu :features="features"
                      :audioVoice="audioVoice"
                      @onEnableAudio="onEnableAudio"
                      @onDisableAudio="onDisableAudio"
                      @onShowMenu="onShowMenu"
                      @onHideMenu="onHideMenu"
                      @onShowInfo="onShowInfo"
                      @onShowSetting="onShowSetting"
                      @onToggleMode="onToggleMode"
                      @onEnableLiveChat="onEnableLiveChat"
                      @onDisableLiveChat="onDisableLiveChat"
                />

                <div v-if="features.stt && features.https" class="toolbar-item">

                    <template v-if="!showKeyboard">
                        <template v-if="!features.status.speaking">
                            <ButtonIcon v-if="recognizeVoice" name="mic" :content="$translate(api.language, 'tooltip.microphone.disable')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus rounded-big running" @click="onDisableMic"/>
                            <ButtonIcon v-else name="mic_off" :content="$translate(api.language, 'tooltip.microphone.enable')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus rounded-big" @click="onEnableMic"/>
                        </template>
                        <ButtonIcon v-else name="speaker" :content="$translate(api.language, 'tooltip.speaker.disable')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus rounded-big running" @click="onStopSpeaking"/>
                    </template>
                    <template v-else>
                        <template v-if="features.device.desktop && features.status.fullscreen && features.showLastMessage">
                            <ButtonIcon v-if="!features.status.speaking" name="mic_off_small" :content="$translate(api.language, 'tooltip.microphone.enable')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus rounded-big" @click="onDisableKeyboard"/>
                            <ButtonIcon v-else name="speaker" :content="$translate(api.language, 'tooltip.speaker.stop')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus running rounded-big" @click="onStopSpeaking"/>
                        </template>
                        <template v-else>
                            <ButtonIcon v-if="!features.status.speaking" name="mic_off_small" :content="$translate(api.language, 'tooltip.microphone.enable')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus" @click="onDisableKeyboard"/>
                            <ButtonIcon v-else name="speaker" :content="$translate(api.language, 'tooltip.speaker.stop')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus running" @click="onStopSpeaking"/>
                        </template>
                    </template>
                </div>

                <div v-if="!showKeyboard" class="toolbar-item">
                    <ButtonIcon name="keyboard" :content="$translate(api.language, 'tooltip.keyboard.enable')" v-tippy="{ placement : 'top',  appendTo: container }" class="rounded focus" @click="onEnableKeyboard"/>
                </div>

                <template v-if="showKeyboard">
                    <div class="toolbar-item keyboard-container">
                        <div v-if="!(features.device.desktop && features.status.fullscreen && features.showLastMessage)" class="toolbar-message-keyboard">
                            <div class="message-text-box">
                                <input type="text" v-model="textInput" ref="userInput" class="message-input" :placeholder="$translate(api.language, 'placeholder.typeHere')"
                                     autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                                     tabIndex="0" maxlength="1000"
                                     @input="handleType" @keydown.up.exact="keyboardUp" @keyup="keyboardEnter">
                            </div>

                            <ButtonIcon v-visible="textInput.length > 0" name="send" class="margin-right" :content="$translate(api.language, 'tooltip.send')" v-tippy="{ placement : 'top',  appendTo: container }" @click.prevent="keyboardSend" />
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <template v-if="features.device.desktop && features.status.fullscreen && features.showLastMessage">
            <div class="toolbar-item-inline">
                <div v-if="showKeyboard" class="toolbar-message-keyboard">
                    <div class="message-text-box">
                        <input type="text" v-model="textInput" ref="userInput" class="message-input" :placeholder="$translate(api.language, 'placeholder.typeHere')"
                               autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                               tabIndex="0" maxlength="1000"
                               @input="handleType" @keydown.up.exact="keyboardUp" @keyup="keyboardEnter">
                    </div>

                    <ButtonIcon v-visible="textInput.length > 0" name="send" class="margin-right" :content="$translate(api.language, 'tooltip.send')" v-tippy="{ placement : 'top',  appendTo: container }" @click.prevent="keyboardSend" />
                </div>
                <div v-else class="toolbar-message-keyboard-empty">&nbsp;</div>
            </div>
        </template>
    </div>
</template>

<script>

    const StorageKeys = {
        INPUT: '_algho_input'
    };

    import {mapMutations} from 'vuex'
    import InputMode from "@/classes/InputMode";
    import ButtonIcon from './ButtonIcon';
    import Menu from './Menu';

    export default {
        name: 'MessageManager',
        components: {
            ButtonIcon,
            Menu,
        },
        props: {
            features: {
                type: Object,
                required: true
            },
            audioVoice: {
                type: Boolean,
                required: true
            },
            recognizeVoice: {
                type: Boolean,
                required: true,
            },
            recognized: {
                type: Object,
                required: true
            },
            messages: {
                type: Array,
                required: true
            },
            playVoice: {
                type: Boolean,
                required: true,
            },
            playCount: {
                type: Number,
                required: true,
            },
            waiting: {
                type: Boolean,
                required: true
            },
            commandVoice: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                textInput: '',
                prevInput: '',
                showKeyboard: false,
                toggleKeyboard: true,
            }
        },
        computed: {
            myself() {
                return this.$store.state.myself;
            },
            container() {
                const rootShadow = this.$root.$options.customElement.shadowRoot;
                return ()=> rootShadow.querySelector('div');
            },
            hasAudio() {
                return this.features.tts;
            },
            lastMessage() {
                const filtered = this.messages.filter((message) => {return !message.myself});
                return filtered.length > 0 ? filtered[filtered.length-1] : null;
            },
        },
        watch: {
            'showKeyboard': {
                handler(newVal) {
                    if (this.hasAudio)
                        window.localStorage.setItem(StorageKeys.INPUT, (newVal ? 'keyboard': 'microphone'));
                },
                deep: true
            },
            'waiting': {
                handler(newVal) {
                    if (newVal) {
                        this.toggleKeyboard = false;
                    }
                },
                deep: true
            },
            'recognized': {
                handler(newVal) {
                    this.textInput = newVal.text;

                    if (this.features.dictatePreviewMessage)
                        this.$emit("onPreviewContent", newVal);

                    if (this.$refs.preview !== undefined)
                        this.$refs.preview.scrollTop = 9999999;

                    if (newVal.final) {
                        this.prevInput = this.textInput;
                        const self = this;
                        setTimeout(() => {
                            self.sendMessage(InputMode.VOICE);
                        }, 1000);
                    }

                },
                deep: true
            },
            'commandVoice': {
                handler(newVal) {
                    if (newVal) {
                        this.showKeyboard = false;
                    }
                },
                deep: true
            },
        },
        beforeCreate() {
            this.api = this.$root.$children[0].$refs.api;
        },
        mounted() {

            if (this.features.settings.input) {
                const input = window.localStorage.getItem(StorageKeys.INPUT) || 'microphone';
                this.showKeyboard = (input === 'keyboard') || !this.hasAudio;
            }

        },
        methods: {
            ...mapMutations([
                'newMessage'
            ]),
            onKeyboardChange(input) {
                this.textInput = input;
            },
            onKeyboardKeyPress(button) {
                this.$log.debug("button", button);

                if (button === "{ent}") {
                    this.keyboardSend();
                }

            },
            toText(input) {
                return input.replace(/<[^>]+?>/g,'');
            },
            sendMessage(inputMode) {

                this.textInput = this.toText(this.textInput);

                // match characters that are different of spaces, tabs, line breaks...
                const matchNotEmpty = /[^\s]+/i
                // match characters that are between line spaces, tabs, line breaks...
                const contentMatch = this.textInput.match(/^\s*((.|\n)+?)\s*$/i)
                if (this.textInput && matchNotEmpty.test(this.textInput) && contentMatch) {
                    this.prevInput = this.textInput;
                    this.textInput = '';
                    this.toggleKeyboard = false;
                    this.$emit("onSendText", { text: contentMatch[1], inputMode});
                }
            },
            keyboardEnter(event) {
                //fix iOS
                //https://github.com/vuejs/vuejs.org/issues/394
                if (event.key === 'Enter') {
                    this.keyboardSend();
                }
            },
            keyboardSend() {
                this.sendMessage(InputMode.KEYBOARD);
            },
            keyboardUp() {
                this.textInput = this.prevInput;
            },
            onEnableKeyboard() {
                this.showKeyboard = true;
                this.toggleKeyboard = true;
                this.$nextTick(() => {
                    if (this.$refs.userInput !== undefined)
                        this.$refs.userInput.focus();
                });
                this.$emit("onDisableMic");
            },
            onDisableKeyboard() {
                this.showKeyboard = false;
                this.toggleKeyboard = false;
                this.$emit("onEnableMic");
            },
            onToggleKeyboard() {
                if (this.lastMessage == null || this.lastMessage.answerType !== 'FORM')
                    this.toggleKeyboard = !this.toggleKeyboard;
            },
            onEnableMic() {
                this.$emit("onEnableMic");
            },
            onDisableMic() {
                this.$emit("onDisableMic");
            },
            onEnableAudio() {
                this.$emit("onEnableAudio");
            },
            onDisableAudio() {
                this.$emit("onDisableAudio");
            },
            onStopSpeaking() {
                this.$emit("onStopSpeaking");
            },
            onShowMenu() {
                this.$emit("onShowMenu");
            },
            onHideMenu(){
                this.$emit("onHideMenu");
            },
            onShowInfo() {
                this.$emit("onShowInfo");
            },
            onShowSetting() {
                this.$emit("onShowSetting");
            },
            onToggleMode(mode) {
                this.$emit("onToggleMode", mode);
            },
            onEnableLiveChat() {
                this.$emit("onEnableLiveChat");
            },
            onDisableLiveChat() {
                this.$emit("onDisableLiveChat");
            },
            onToggleBaloon() {
                this.features.hideLastMessage = !this.features.hideLastMessage;
            },
            handleType(e) {
                this.$emit("onType", e);
            }
        }
    }
</script>

<style lang="less">
    .chat-body {
        .container-message-manager-component {
            width: 100%;
            margin-top: 10px;
        }

        .container-message-manager {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            padding: 0 10px 0 10px;

            .toolbar-message-manager {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 12px;

                &.has-keyboard {
                    .toolbar-item {
                        margin-right: 6px;
                    }
                }

                .toolbar-item {
                    position: relative;
                    flex: 0;
                    margin-right: 14px;

                    &:not(:first-child):not(:last-child) {
                        flex-grow: 0 !important;
                    }

                    &:first-child {
                        margin-left: 0 !important;
                    }

                    &:last-child {
                        margin-right: 0 !important;
                    }

                    &.keyboard-container {
                        flex: auto;
                    }
                }
            }

            .toolbar-message-preview {
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                margin-bottom: 10px;
                text-shadow: var(--text-shadow);

                span {
                    height: 18px;
                    font-size: var(--font-12);
                    color: var(--primary-color-text);
                    overflow: hidden;
                    padding: 3px 15px 3px 15px;
                }
            }
        }

        .toolbar-item-inline {
            position: relative;
            padding: 16px;
            pointer-events: none;
        }

        .toolbar-item, .toolbar-item-inline {
            vertical-align: middle;

            .toolbar-message-keyboard-empty {
                height: 40px;
                pointer-events: none;
            }

            .toolbar-message-keyboard {
                pointer-events: all;
                display: flex;
                flex: auto !important;
                background-color: var(--bg-button-color);
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);
                border-radius: 28px;
                height: 40px;
                width: 100%;

                &:focus-within {
                    //outline: 2px solid var(--focus-color-input);
                }

                .message-text-box {
                    padding: 0 5px 0 15px;
                    flex: 1;
                    overflow: hidden;
                }

                .message-input {
                    background: transparent;
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    font-size: var(--font-12);
                    color: var(--fg-color-input);
                }

                .message-input::placeholder {
                    color: var(--border-color-input);
                    //opacity: 0.4;
                }

                .message-input:focus {
                    outline: none;
                }
            }
        }
    }

    //MODE STYLE OVERRIDE

    .dhi.fullscreen.last-chat.desktop {
        .chat-body {
            .container-message-manager-component {
                .container-message-manager {
                    .toolbar-message-manager {
                        .toolbar-item {
                            flex: 1;
                            margin: 0;

                            &:first-child {
                                text-align: left;
                            }

                            &:last-child {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .dhi.fullscreen {
        .container-message-manager {
            .toolbar-message-preview {
                span {
                    height: 20px;
                }
            }
        }
    }

</style>
