<template>
    <div ref="containerMessageDisplay"
         class="container-message-display scrollable scrollable-y" :class="{'last-message' : showLastMessage}" @scroll="updateScrollState">
        <div v-if="loading" class="loader-history">
            <div class="message-loading"></div>
        </div>

        <template v-if="!showLastMessage">
            <div v-for="(message, index) in messages" :key="message.guid" class="message-container">

                <DateHeader v-if="showDate(message, index)" :message="message"
                            :timestamp-config="timestampConfig"
                />

                <transition name="fade" appear>
                    <MyMessage v-if="message.myself" :message="message"
                               :profile-picture-config="profilePictureConfig"
                               :timestamp-config="timestampConfig"
                               :show-partecipant="showPartecipant(message, index)"/>
                </transition>

                <transition name="fade" appear>
                    <OtherMessage v-if="!message.myself" :message="message"
                                  :features="features"
                                  :profile-picture-config="profilePictureConfig"
                                  :timestamp-config="timestampConfig"
                                  :show-partecipant="showPartecipant(message, index)"
                                  :showVote="showVote"
                                  @onSendCommand="onSendCommand"
                                  @onScrollBottom="onScrollBottom"
                                  @onToggleVote="onToggleVote"
                                  @onShowCard="onShowCard"
                                  @onShowCards="onShowCards"
                                  @onOpenUrl="onOpenUrl"
                                  @onPlayMessage="onPlayMessage"
                    />
                </transition>

            </div>
        </template>

        <template v-else-if="showLastMessage && latestMessage != null && features.groupLastMessage">

            <div v-for="(message) in messages" :key="message.guid" class="message-container-grouped">

                <transition name="fade" appear>
                    <MyMessage v-if="(features.kiosk || features.dictatePreviewMessage) && message.myself && !message.history && message.groupId === latestMessage.groupId" :message="message"
                               :profile-picture-config="profilePictureConfig"
                               :timestamp-config="timestampConfig"
                               :show-partecipant="false"/>
                </transition>

                <transition name="fade" appear>
                    <OtherMessage v-if="!message.myself && !message.history && message.groupId === latestMessage.groupId" :message="message"
                                  :features="features"
                                  :profile-picture-config="profilePictureConfig"
                                  :timestamp-config="timestampConfig"
                                  :show-partecipant="false"
                                  :showVote="showVote"
                                  :groupClose="true"
                                  @onSendCommand="onSendCommand"
                                  @onScrollBottom="onScrollBottom"
                                  @onToggleVote="onToggleVote"
                                  @onShowCard="onShowCard"
                                  @onShowCards="onShowCards"
                                  @onOpenUrl="onOpenUrl"
                                  @onPlayMessage="onPlayMessage"
                                  @onMessageClose="onMessageClose"
                                  @onGroupClose="onGroupClose"
                    />
                </transition>
            </div>
        </template>

        <template v-else-if="showLastMessage && latestMessage != null && !features.groupLastMessage && !latestMessage.history">
            <transition name="fade" appear>
                <div :key="latestMessage.guid" class="message-container-last">
                    <OtherMessage v-if="!latestMessage.myself" :message="latestMessage"
                                  :features="features"
                                  :profile-picture-config="profilePictureConfig"
                                  :timestamp-config="timestampConfig"
                                  :show-partecipant="false"
                                  :showVote="showVote"
                                  @onSendCommand="onSendCommand"
                                  @onScrollBottom="onScrollBottom"
                                  @onToggleVote="onToggleVote"
                                  @onShowCard="onShowCard"
                                  @onShowCards="onShowCards"
                                  @onOpenUrl="onOpenUrl"
                                  @onPlayMessage="onPlayMessage"
                                  @onMessageClose="onMessageClose"
                    />
                </div>
            </transition>
        </template>

        <Narration :features="features" @onNarrate="onNarrate" />
        <Emotion v-if="!features.kiosk && features.has.webcam" :features="features" @onEmotion="onEmotion" @onShowEmotionPanel="onShowEmotionPanel" @onShowPrivacyPanel="onShowPrivacyPanel" />

        <Vote v-if="showVote" :message="voteMessage" @onVote="onVote"/>

        <LoadingMessage v-if="!features.kiosk && !features.livechat.activated"
            :features="features"
            :profile-picture-config="profilePictureConfig"
            :show-partecipant="!showLastMessage"
            :waiting="waiting"/>

        <LiveChat v-if="features.livechat.ready && features.has.livechat" :features="features" @onReceiveMessages="onReceiveMessages" />
        <WebCam v-if="!features.kiosk && features.has.webcam" :features="features" />

    </div>
</template>

<script>
    import Vue from "vue";
    import {mapGetters, mapMutations} from 'vuex';
    import MyMessage from './MyMessage.vue';
    import OtherMessage from './OtherMessage.vue';
    import LoadingMessage from './LoadingMessage.vue';
    import DateHeader from './DateHeader.vue';
    import Narration from './others/Narration.vue';
    import Vote from './others/Vote.vue';
    import Emotion from './others/Emotion.vue';
    import LiveChat from "./others/LiveChat.vue";
    import WebCam from "./others/WebCam.vue";

    export default {
        name: 'MessageDisplay',
        components:{
            MyMessage,
            OtherMessage,
            LoadingMessage,
            DateHeader,
            Narration,
            Emotion,
            Vote,
            LiveChat,
            WebCam,
        },
        lastDate: null,
        lastPartecipant: null,
        props: {
            features: {
                type: Object,
                required: true
            },
            loadMoreMessages: {
                type: Function,
                required: false,
                default: null
            },
            scrollBottom: {
                type: Object,
                required: true
            },
            waiting: {
                type: Boolean,
                required: true,
            },
            profilePictureConfig: {
                type: Object,
                required: true
            },
            timestampConfig: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                updateScroll: true,
                lastMessage: null,
                loading: false,
                showVote: false,
                voteMessage: null
            }
        },
        computed: {
            ...mapGetters([
                'getParticipantById',
                'messages',
                'myself'
            ]),
            showLastMessage() {
              return this.features !== undefined && this.features.showLastMessage;
            },
            latestMessage() {
                if (this.messages.length > 0)
                    return this.messages[this.messages.length - 1];
                else
                    return null;
            },
        },
        created() {
            Vue.$common.scrollToBottom = this.onScrollBottom;
        },
        mounted() {
            this.goToBottom();
            this.$refs.containerMessageDisplay.dispatchEvent(new CustomEvent('scroll'));
        },
        updated() {
            if (this.messages.length && !this.messageCompare(this.messages[this.messages.length - 1], this.lastMessage)) {

                if(this.updateScroll || (this.scrollBottom.messageSent && this.messages[this.messages.length - 1].participantId == this.myself.id) || (this.scrollBottom.messageReceived && this.messages[this.messages.length - 1].participantId != this.myself.id)){
                    this.goToBottom();
                    if (this.messages.length) {
                        this.lastMessage = this.messages[this.messages.length - 1]
                    }
                }
            }
        },
        watch: {
            waiting: {
                handler() {
                    this.goToBottomDelayed(100);
                },
                deep: true
            },
            'scrollBottom.forceScroll': {
                handler() {
                    this.goToBottom();
                },
                deep: true
            },
            'features.showLastMessage': {
                handler() {
                    this.goToBottomDelayed(100);
                },
                deep: true
            }
        },
        methods: {
            ...mapMutations([
                'setMessages',
            ]),
            /**
             * This function compare two messages without looking at the uploaded propertie.
             * This function has been implemented to prevent chat scrolling down after changing the message from 'uploaded = false' to 'uploaded = true'.
             * @param {Object} message1 the first message object
             * @param {Object} message2 the second message object
             * @return {Boolean} true if the messages are equal and false if they are different
             */
            messageCompare(message1, message2){
                /**
                 * if one of the messages are null, you can safely compare the messages with '==='
                 */
                if(!message2 || !message1){
                    return message1 === message2
                }
                /**
                 * compare the immutable properties of a message
                 */
                let participant_equal = message1.participantId == message2.participantId;
                let content_equal = message1.content == message2.content;
                let timestamp_equal = message1.timestamp.valueOf() === message2.timestamp.valueOf();

                return  participant_equal && content_equal && timestamp_equal
            },
            updateScrollState({target: {scrollTop, clientHeight, scrollHeight}}) {
                this.updateScroll = scrollTop + clientHeight >= scrollHeight;

                if (!this.showLastMessage && !this.loading && typeof this.loadMoreMessages === 'function' && scrollTop < 20) {
                    this.loading = true;

                    const heightBefore = this.getScrollHeight();
                    this.loadMoreMessages((messages) => {

                        if (messages.length > 0) {
                            const self = this;
                            setTimeout(function () {
                                const heightAfter = self.getScrollHeight();
                                self.goToOffset(heightAfter - heightBefore);
                            }, 0);
                        }

                        this.loading = false;
                    });
                }
            },
            goToBottomDelayed(delay) {
                const self = this;
                setTimeout(() => {
                    self.goToBottom();
                }, delay);
            },
            goToBottom() {
                let scrollDiv = this.$refs.containerMessageDisplay;
                if (scrollDiv)
                    scrollDiv.scrollTop = scrollDiv.scrollHeight;

                this.updateScroll = false;
            },
            goToOffset(scrollTop) {
                let scrollDiv = this.$refs.containerMessageDisplay;
                if (scrollDiv)
                    scrollDiv.scrollTop = scrollTop;

                this.updateScroll = false;
            },
            getScrollHeight() {
                let scrollDiv = this.$refs.containerMessageDisplay;
                if (scrollDiv)
                    return scrollDiv.scrollHeight;
                else
                    return -1;
            },
            onSendCommand(value){
                this.$emit('onSendCommand', value)
            },
            onScrollBottom() {
                const self = this;
                setTimeout(() => {
                    self.goToBottom();
                }, 0);
            },
            onMessageClose(message) {
                message.history = true;
            },
            onGroupClose(message) {
                this.messages.map(function(item) {
                    if (item.groupId === message.groupId)
                        item.history = true;
                });
            },
            onToggleVote() {
                this.showVote = !this.showVote;
                this.voteMessage = this.messages[this.messages.length - 1];
                this.onScrollBottom();
            },
            onVote(value) {
                this.showVote = false;
                this.$emit('onVote', value);
            },
            onNarrate(value) {
                this.$emit('onNarrate', value);
            },
            onEmotion(value) {
                this.$emit('onEmotion', value);
            },
            onShowEmotionPanel() {
                this.$emit('onShowEmotionPanel');
            },
            onShowPrivacyPanel(anchor) {
                this.$emit('onShowPrivacyPanel', anchor);
            },
            onShowCard(option) {
                this.$emit("onShowCard", option);
            },
            onShowCards(message) {
                this.$emit("onShowCards", message);
            },
            onOpenUrl(url) {
                this.$emit("onOpenUrl", url);
            },
            onPlayMessage(message) {
                this.$emit("onPlayMessage", message);
            },
            onReceiveMessages(messages) {
                this.$emit("onReceiveMessages", messages);
            },
            showDate(message, index) {
                if (index === 0 || message.forceShowDate === true)
                    this.lastDate = null;

                let show = false;
                if (this.lastDate !== message.timestamp.toFormat('yyyyMMdd'))
                    show = true;

                this.lastDate = message.timestamp.toFormat('yyyyMMdd');
                return show;
            },
            showPartecipant(message, index) {
                if (index === 0)
                    this.lastPartecipant = null;

                let show = false;
                if (this.lastPartecipant !== message.participantId)
                    show = true;

                this.lastPartecipant = message.participantId;
                return show;
            }
        }
    }
</script>

<style lang="less">
    .chat-container .container-message-display {
        //position: relative; //aaaa
        flex: 1;
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        //padding-bottom: 10px; //aaa
        //width: 100%; //aaaa
        //height: 100%; /aaaa

        /************** Safari 10.1+ ********************/
        @media not all and (min-resolution:.001dpcm)
        { @supports (-webkit-appearance:none) {

            .message-container{
                width: 100%;

                /* non funziona su nuovo safari */
                /* display:-webkit-box !important; */
            }

        }}

        .message-image{
            padding: 6px 10px;
            border-radius: var(--border-radius);
            margin: 5px 0 5px 0;
            //max-width: 70%;
            width: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .message-image-display{
            width: 100%;
            border-radius: 5px;
            cursor:pointer;
            transition: 0.3s ease;
        }

        .message-image-display:hover{
            opacity: 0.8;
        }

        .my-message > .message-timestamp {
            text-align: right;
        }

        /* .my-message {
            justify-content: flex-end;
            padding-right: 15px;
            align-items: flex-end;
        } */

        .message-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
        }

        .message-container-grouped {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;


            .other-message {
                margin-bottom: 6px;
            }

            .has-thumb {
                margin-left: 0 !important;
            }

            .message-timestamp {
                display: none;
            }
        }

        .message-container-last {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;


            .message-text {
                border-radius: var(--border-radius-small) var(--border-radius) var(--border-radius) var(--border-radius);

                &.multi {
                    min-width: unset;
                }
            }

            .has-thumb {
                margin-left: 0;
            }

            .message-timestamp {
                display: none;
            }
        }

        .message-username {
            font-size: var(--font-10);
            font-weight: 600;
        }

        .icon-sent {
            padding-left: 5px;
            color: rgb(129, 127, 127);
        }

        .message-loading {
            height: 8px;
            width: 8px;
            border: 1px solid rgb(187, 183, 183);
            border-left-color: rgb(59, 59, 59);
            border-radius: 50%;
            margin-left: 5px;
            display: inline-block;
            animation: spin 1.3s ease infinite;
        }

        .loader-history .message-loading {
            width: 16px;
            height: 16px;
            margin: 5px 0 0 0;
        }

        .img-loading{
            height: 20px;
            width: 20px;
            border: 3px solid #ffffff00;
            border-left-color: #847f7f;
            border-top-color: #847f7f;
            border-radius: 50%;
            margin-left: 5px;
            display: inline-block;
            -webkit-animation: spin 1.0s ease infinite;
            animation: spin 1.0s ease infinite;
            position: absolute;
        }

        .img-overlay{
            opacity: 0.4;
        }

        .message-username-image{
            margin: 10px 10px 0px 10px;
            font-size: var(--font-12);
            font-weight: 600;
        }

    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }


</style>
