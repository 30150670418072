import * as Three from 'three'

Three.ShaderChunk.tonemapping_pars_fragment = Three.ShaderChunk.tonemapping_pars_fragment.replace(
'vec3 CustomToneMapping( vec3 color ) { return color; }',
`
    #define GAMMA_FACTOR 1
    float inWhitePoint = 2.0;
    vec3 GAMMA = vec3( 1.0 / float(GAMMA_FACTOR) );

    vec3 CustomToneMapping( vec3 color ) {

        color = color * toneMappingExposure;

        vec4 vh = vec4( color, inWhitePoint );
        vec4 va = ( 1.425 * vh ) + 0.05;
        vec4 vf = ( ( vh * va + 0.004 ) / ( ( vh * ( va + 0.55 ) + 0.0491 ) ) ) - 0.0821;
        color = vf.rgb / vf.www;
        color = pow( max( color, vec3( 0.0 ) ), GAMMA );

        return color;
    }
    `
);
