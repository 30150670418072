<template>
    <div class="chat-panel">
        <div class="chat-panel-container">
            <slot v-if="powered" name="powered">
                <div class="chat-panel-powered" v-html="poweredByMessage"></div>
            </slot>
            <div class="chat-panel-window scrollable scrollable-y" :class="{'chat-panel-window-border': border}">
                <slot name="body"></slot>
            </div>
            <div class="chat-panel-close">
                <slot name="close">
                    <ButtonIcon v-if="!features.kiosk" name="close" class="circle" :aria-label="$translate(api.language, 'tooltip.close')" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'top', appendTo: container }" @click="onClose"/>
                    <ButtonIcon v-else name="close" size="14px" class="rounded focus rounded-small" :aria-label="$translate(api.language, 'tooltip.close')" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'left', appendTo: container }" @click="onClose"/>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

import ButtonIcon from "@/components/chat/ButtonIcon.vue";

export default {
    name: "Panel",
    components: {
        ButtonIcon
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        powered: {
            type: Boolean,
            required: false,
            default: true
        },
        border: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {

        }
    },
    computed: {
        container() {
            if (!this.features.kiosk) {
                const rootShadow = this.$root.$options.customElement.shadowRoot;
                return () => rootShadow.querySelector('div');
            } else {
                return () => this.$el;
            }
        },
        poweredByMessage() {
            return this.api.bot.poweredBy || null;
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

    .chat-panel {
        width: 100%;
        height: 100%;

        .chat-panel-container {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: var(--border-radius-frame);

            .chat-panel-window {
                position: absolute;
                overflow: hidden;
                color: var(--fg-color);
                text-align: left;
                font-size: var(--font-10);

                top: 0;
                left: 0;
                right: 0;
                bottom: 78px;

                &.chat-panel-window-border {
                    top: 56px;
                    left: 15px;
                    right: 15px;
                }

                h3 {
                    font-size: var(--font-12);
                    line-height: calc(var(--font-12) + 3px);
                    margin: 0;
                    font-weight: 600;
                }

                ul {
                    padding-left: 20px;
                }

                hr {
                    height: 0px;
                    border: 1px solid #0000001D;
                    margin: 16px 2px 16px 2px;
                    border-radius: 2px;
                }

            }

            .chat-panel-powered {
                width: 100%;
                margin-top: 22px;

                text-align: center;
                font-size: var(--font-10);
                line-height: calc(var(--font-10) + 3px);
                color: var(--primary-color-text);

                a {
                    color: var(--primary-color-text);
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                p {
                    margin: 0;
                }
            }

            .chat-panel-close {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 18px;
                text-align: center;
            }

        }
    }

    .kiosk {

        .chat-panel {
            position: fixed !important;
            border: unset !important;
            border-radius: unset !important;
            z-index: calc(var(--z-index) + 1);

            .chat-panel-container {
                border-radius: unset;

                .chat-panel-window {
                    bottom: 0;

                    &.chat-panel-window-border {
                        top: 15px;
                        left: 15px;
                        right: 15px;
                        bottom: 54px;
                    }
                }

                .chat-panel-powered {
                    position: absolute;
                    bottom: 20px;

                    a {
                        pointer-events: none;
                    }
                }
            }
            .chat-panel-close {
                position: absolute;
                top: 22px;
                right: 22px;
                left: unset;
                bottom: unset;
                text-align: unset;

                svg {
                    backdrop-filter: unset;
                    background-color: var(--primary-color);
                }
            }

        }
    }

</style>
