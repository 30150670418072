<template>
    <Panel :features="features" @onClose="onClose">
        <div slot="body">
            <div ref="html" class="expired-content">{{ $translate(api.language, 'bot.expired') }}</div>
        </div>
        <div slot="close"></div>
    </Panel>
</template>

<script>
import Panel from "./Panel.vue";

export default {
    name: "ExpiredPanel",
    components: {
        Panel
    },
    props: {
        features: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            html: null
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.expired-content {
    margin-top: 40%;
    color: var(--fg-color);
    text-align: center;
    font-size: var(--font-14);
}

</style>
