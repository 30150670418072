<template>
    <div v-if="config != null" class="message-multimedia margin-common-content-multimedia">
        <form v-if="canPay" class="form-paypal" :action="config.paypalAction" method="POST">
            <input type="hidden" name="cmd" :value="payment.cmd">
            <input type="hidden" name="upload" :value="payment.upload">
            <input type="hidden" name="business" :value="payment.business">
            <input type="hidden" name="return" :value="payment.return">
            <input type="hidden" name="cancel_return" :value="payment.cancel_return">
            <input type="hidden" name="notify_url" :value="config.paypalNotifyPage">
            <input type="hidden" name="custom" :value="payment.custom">
            <input type="hidden" name="currency_code" :value="payment.currency_code">
            <input v-for="(item, index) in payment.items" type="hidden" :key="'item_name_' + index" :name="'item_name_' + (index+1).toString()" :value="item.name">
            <input v-for="(item, index) in payment.items" type="hidden" :key="'amount_' + index" :name="'amount_' + (index+1).toString()" :value="item.amount">
            <input v-for="(item, index) in payment.items" type="hidden" :key="'quantity_' + index" :name="'quantity_' + (index+1).toString()" :value="item.quantity">
            <input class="paypal-button" type="image" :src="icon">
        </form>
    </div>
</template>

<script>
export default {
    name: "MediaPayPal",
    components: {

    },
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    computed: {
        dataOrder() {
            return this.message.media;
        }
    },
    data() {
        return {
            canPay: false,
            payment: {
                cmd: '_cart',
                upload: '1',
                business: '',
                return: '',
                cancel_return: '',
                custom: '',
                currency_code: '',
                items: []
            },
            icon: require("../../../assets/img/paypal.svg"),
            config: null,
        };
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        this.loadConfig();
    },
    mounted() {
        if (this.dataOrder.orderStatus !== undefined) {
            this.initOrder();
        } else {
            this.api.getOrderId(this.dataOrder.orderId).then((data)=> {
                if (data != null) {
                    this.message.media = data;
                    this.initOrder();
                }
            });
        }
    },
    methods: {
        loadConfig() {
            const getRuntimeConfig = async () => {
                const configUrl = this.features.instance.baseUrl + '/configs/paypal.json' + '?_='+new Date().getTime();
                const runtimeConfig = await fetch(configUrl);
                return await runtimeConfig.json()
            }
            getRuntimeConfig().then((config) => {
                this.config = config;
            });
        },
        checkOrder() {
            // Check the status of order
            if (this.dataOrder.orderStatus !== undefined && (this.dataOrder.orderStatus === 'created' || this.dataOrder.orderStatus === 'updated')) {
                // Check payment data
                if (this.dataOrder.productList !== undefined && this.dataOrder.productList.length > 0) {
                    return true;
                }
            }
            return false;
        },
        initOrder() {
            if (this.checkOrder()) {
                const mainUrl = document.location.href;

                if (this.dataOrder.paymentMethod !== undefined && this.dataOrder.paymentMethod[0].info !== undefined && this.dataOrder.paymentMethod[0].info.mail !== undefined)
                    this.payment.business = this.dataOrder.paymentMethod[0].info.mail;

                if (this.dataOrder.data !== undefined && this.dataOrder.data.currency !== undefined)
                    this.payment.currency_code = this.dataOrder.data.currency;

                this.payment.return = mainUrl + (mainUrl.indexOf("?") > -1 ? ('&alghoPaymentSuccess=' + this.dataOrder.orderId) : ('?alghoPaymentSuccess=' + this.dataOrder.orderId));
                this.payment.cancel_return = mainUrl + (mainUrl.indexOf("?") > -1 ? ('&alghoPaymentError=' + this.dataOrder.orderId) : ('?alghoPaymentError=' + this.dataOrder.orderId));

                this.payment.custom = this.dataOrder.botId + '|' + this.dataOrder.orderId;

                for (const product of this.dataOrder.productList) {
                    this.payment.items.push({
                        name: product.optionText,
                        amount: product.price,
                        quantity: product.quantity
                    });
                }

                this.canPay = true;
            } else {
                this.canPay = false;
            }

        }
    }

}
</script>

<style lang="less">

 .form-paypal {

     width: 100%;
     text-align: center;

     .paypal-button {
         height: 24px;
         width: 100%;
         padding: 4px 0 4px 0;
         background-color: #ffc535;
         border-radius: var(--border-radius);
         box-shadow: var(--box-shadow);
         backdrop-filter: var(--backdrop-filter);

         &:hover {
             background-color: #ffc535E0;
         }
     }
 }

</style>
