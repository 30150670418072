<template>
    <div v-if="hasWelcomeVideo" class="welcome-video">
        <iframe v-if="!hasDirectLink" class="video" :src="parseLink"
                sandbox="allow-same-origin allow-scripts"
                allowfullscreen="true"
                allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen" title="Video"/>

        <video v-else class="video" autoplay="autoplay" loop="loop" disablepictureinpicture controlsList="nofullscreen nodownload noremoteplayback noplaybackrate" title="Video" :src="parseLink"></video>
    </div>
</template>

<script>
import VideoLink from "../classes/VideoLink";

export default {
    name: "KioskWelcomeVideo",
    props: {

    },
    data() {
        return {

        }
    },
    computed: {
        welcomeVideo() {
            return this.api.bot.welcomeVideo;
        },
        hasWelcomeVideo() {
            return (this.welcomeVideo || '').trim().length > 0;
        },
        hasDirectLink() {
            return (this.welcomeVideo || '').toLowerCase().indexOf(".mp4") !== -1;
        },
        parseLink: function() {
            return VideoLink(this.welcomeVideo, false, true, true, true);
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {

    }
}
</script>

<style lang="less">
 .welcome-video {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;

     iframe.video {
         background-color: #000000;
         width: 100%;
         height: 100%;
         margin: 0;
         padding: 0;
         border: 0;
         pointer-events: none;
     }

     video.video {
         background-color: #000000;
         width: 100%;
         height: 100%;
         margin: 0;
         padding: 0;
         border: 0;
         pointer-events: none;
     }
 }
</style>
