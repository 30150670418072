import {
    PerspectiveCamera,
    Quaternion,
    Vector3
} from 'three';
//import * as Three from "three";

/**
 * peppers ghost effect based on http://www.instructables.com/id/Reflective-Prism/?ALLSTEPS
 */

class PeppersGhostEffect {

    constructor( renderer ) {

        const scope = this;

        scope.cameraDistance = 15;
        scope.cameraYOffset = 0;
        scope.reflectFromAbove = false;

        // Internals
        let _halfWidth, _width, _halfHeight, _height;

        const _cameraF = new PerspectiveCamera(); //front
        const _cameraB = new PerspectiveCamera(); //back
        const _cameraL = new PerspectiveCamera(); //left
        const _cameraR = new PerspectiveCamera(); //right

        const _position = new Vector3();
        const _quaternion = new Quaternion();
        const _scale = new Vector3();

        // Initialization
        renderer.autoClear = false;

        this.setSize = function ( width, height ) {

            _halfWidth = width / 2;
            _halfHeight = height / 2;
            if ( width < height ) {

                _width = width / 2;
                _height = width / 2;

            } else {

                _width = height / 2;
                _height = height / 2;

            }

            renderer.setSize( width, height);

        };

        this.render = function ( scene, camera ) {

            scene.updateMatrixWorld();

            if ( camera.parent === null ) camera.updateMatrixWorld();

            camera.matrixWorld.decompose( _position, _quaternion, _scale );
            let position = scene.position.clone();
            position.y = scope.cameraYOffset;

            // front
            _cameraF.position.copy( _position );
            _cameraF.quaternion.copy( _quaternion );
            _cameraF.translateZ( scope.cameraDistance );
            _cameraF.translateY( position.y );
            _cameraF.lookAt( position );

            // back
            _cameraB.position.copy( _position );
            _cameraB.quaternion.copy( _quaternion );
            _cameraB.translateZ( - ( scope.cameraDistance ) );
            _cameraB.translateY( position.y );
            _cameraB.lookAt( position );
            _cameraB.rotation.z += 180 * ( Math.PI / 180 );

            // left
            _cameraL.position.copy( _position );
            _cameraL.quaternion.copy( _quaternion );
            _cameraL.translateX( ( scope.cameraDistance ) );
            _cameraL.translateY( position.y );
            _cameraL.lookAt( position );
            _cameraL.rotation.x += 270 * ( Math.PI / 180 );

            // right
            _cameraR.position.copy( _position );
            _cameraR.quaternion.copy( _quaternion );
            _cameraR.translateX( - scope.cameraDistance );
            _cameraR.translateY( position.y );
            _cameraR.lookAt( position );
            _cameraR.rotation.x += 270 * ( Math.PI / 180 );

            //scene.add(new Three.CameraHelper( _cameraF ));
            //scene.add(new Three.CameraHelper( _cameraB ));
            //scene.add(new Three.CameraHelper( _cameraL ));
            //scene.add(new Three.CameraHelper( _cameraR ));

            renderer.clear();
            renderer.setScissorTest( true );

            renderer.setScissor( _halfWidth - ( _width / 2 ), _halfHeight, _width, _height );
            renderer.setViewport( _halfWidth - ( _width / 2 ), _halfHeight, _width, _height );

            if ( scope.reflectFromAbove ) {

                renderer.render( scene, _cameraB );

            } else {

                renderer.render( scene, _cameraF );

            }

            renderer.setScissor( _halfWidth - ( _width / 2 ), _halfHeight - _height, _width, _height );
            renderer.setViewport( _halfWidth - ( _width / 2 ), _halfHeight - _height, _width, _height );

            if ( scope.reflectFromAbove ) {
                renderer.render( scene, _cameraF );

            } else {

                renderer.render( scene, _cameraB );

            }

            renderer.setScissor( _halfWidth - _width, _halfHeight - (_height / 2), _width, _height );
            renderer.setViewport( _halfWidth - _width, _halfHeight - (_height / 2), _width, _height );

            if ( scope.reflectFromAbove ) {

                renderer.render( scene, _cameraR );

            } else {

                renderer.render( scene, _cameraL );

            }

            renderer.setScissor(_halfWidth, _halfHeight - (_height / 2), _width, _height );
            renderer.setViewport(_halfWidth, _halfHeight - (_height / 2), _width, _height );

            if ( scope.reflectFromAbove ) {

                renderer.render( scene, _cameraL );

            } else {

                renderer.render( scene, _cameraR );

            }

            renderer.setScissorTest( false );

        };

    }

}

export { PeppersGhostEffect };
