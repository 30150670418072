<template>
    <div v-if="show" class="message-multimedia margin-common-content-multimedia">

        <Modal class="modal-image" :fullscreen="!features.kiosk && fullscreen" @close="fullscreen = false">
            <img v-if="!features.kiosk" slot="body" class="image image-radius shadow-common" :src="message.media" alt="">

            <KioskMedia v-if="features.kiosk && !fullscreen" slot="body" @onToggle="toggle" @onClose="close">
                <img slot="body" class="image" :src="message.media" alt="">
            </KioskMedia>
        </Modal>

        <div v-if="!features.kiosk" class="media-action">
            <ButtonIcon name="fullscreen" size="16px" class="action-small" @click="toggle"/>
        </div>

        <Panel :features="features" :border="false" v-if="features.kiosk && fullscreen" @onClose="fullscreen = false">
            <div slot="body" class="modal-body">
                <img class="image shadow-common" :src="message.media" alt="">
            </div>
            <div slot="powered"></div>
        </Panel>

    </div>


</template>

<script>
import ButtonIcon from '../ButtonIcon';
import Modal from '../../Modal';
import Panel from "../../panels/Panel";
import KioskMedia from "./KioskMedia";

export default {
    name: "MediaImage",
    components: {
        ButtonIcon,
        Modal,
        Panel,
        KioskMedia
    },
    data: () => ({
        show: true,
        fullscreen: false,
    }),
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        toggle () {
            this.fullscreen = !this.fullscreen
        },
        close: function () {
            this.show = false;
        },
    }
}
</script>

<style lang="less">

.message-multimedia {
    .image {
        width: 100%;
    }

    .image-radius {
        border-radius: var(--border-radius);
    }

    .modal-body .image {
        border: unset !important;
        border-radius: unset !important;
        box-shadow: unset !important;
        width: 100%;
        object-fit: contain;
    }

    .modal-image {

    }
}

</style>
