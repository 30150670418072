import { render, staticRenderFns } from "./KioskBanner.vue?vue&type=template&id=5ab9bb29&"
import script from "./KioskBanner.vue?vue&type=script&lang=js&"
export * from "./KioskBanner.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./KioskBanner.vue?vue&type=style&index=0&lang=less&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports