<template>
    <div class="animated-icon-play-container">

        <svg class="animated-icon" xmlns="http://www.w3.org/2000/svg" style="margin: auto; display: block;" width="100px" height="100px" viewBox="0 0 80 80">
            <circle cx="40" cy="40" r="20" fill="none" stroke="currentColor" stroke-width="3" stroke-dasharray="35,70" stroke-linecap="round">
                <animate attributeName="r" repeatCount="indefinite" dur="2s" values="18;36" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-0.5s"></animate>
                <animate attributeName="opacity" repeatCount="indefinite" dur="2s" values="0.7;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-0.5s"></animate>
            </circle>
            <circle cx="40" cy="40" r="20" fill="none" stroke="currentColor" stroke-width="3">
                <animate attributeName="r" repeatCount="indefinite" dur="2s" values="6;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline"></animate>
                <animate attributeName="opacity" repeatCount="indefinite" dur="2s" values="0.9;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline"></animate>
            </circle>
        </svg>

        <ButtonIcon name="play_circle" size="40px" class="animated-button" aria-label="Start" @click="onClick"/>

    </div>
</template>

<script>

import ButtonIcon from "@/components/chat/ButtonIcon.vue";

export default {
    name: "IconPlay",
    components: {
        ButtonIcon,
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    }
}
</script>

<style lang="less">

.animated-icon-play-container {
    position: relative;
    margin: 50px;

    .animated-icon {
        position: absolute;
        pointer-events: none;
        width: 100%;
        top: -30px;
        color: var(--primary-color);
        filter: drop-shadow(0px 0px 6px var(--primary-color));
    }

    .animated-button {
        color: var(--primary-color);
        filter: drop-shadow(0px 0px 6px var(--primary-color));
    }


}

</style>
