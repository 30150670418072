<template>
    <div v-if="message.suggestedOptions && message.suggestedOptions.length > 0" class="message-suggested">
        <ul class="suggested-list">
            <li v-for="(option, index) in message.suggestedOptions" :key="index" class="suggested-item" @click="onSuggestedClicked(option)">
                <span class="suggested-item-text">{{format(option)}}</span>
            </li>
        </ul>
    </div>
</template>

<script>

import InputMode from "@/classes/InputMode";
import {DateTime} from "luxon";

export default {
    name: "Suggested",
    props: {
        message: {
            type: Object,
            required: true
        },
    },
    methods: {
        fixDate(date) {
            if (date.indexOf('/') !== -1) {
                const split = date.split('/');
                return split[2] + '-' + split[1] + '-' + split[0];
            } else {
                return date;
            }
        },
        format(option) {

            let part = null;

            //todo: sistemare, mancano altri campi?
            switch (this.message.fieldType) {
                case 'DateFieldType':
                case 'BookingDateFieldType':
                case 'BirthDateFieldType':
                    return DateTime.fromISO(option.optionText).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' });

                case 'DateHourFieldType':
                    return DateTime.fromISO(option.optionText.replace(' ', 'T')).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false });

                case 'DateRangeFieldType':
                    part = option.optionText.split(' - ');
                    //algho torna date invertite in italiano
                    return DateTime.fromISO(this.fixDate(part[0])).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' }) +
                        ' - ' +
                        DateTime.fromISO(this.fixDate(part[1])).toLocaleString({ year: 'numeric', month: '2-digit', day: '2-digit' });

                case 'CFFieldType':
                case 'VatFieldType':
                    return option.optionText.toUpperCase();

                case 'EmailFieldType':
                    return option.optionText.toLowerCase();
            }

            return option.optionText;
        },
        onSuggestedClicked: function (option) {
            const newOption = JSON.parse(JSON.stringify(option));
            newOption.optionText = this.format(newOption);
            const value = Object.assign({ inputMode: InputMode.CLICK }, newOption);
            this.$emit("onSendCommand", value);
        }
    }
}

</script>

<style lang="less">
.message-suggested {

    margin-top: 15px;

    .suggested-list {
        list-style-type: none;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 0;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        backdrop-filter: var(--backdrop-filter);

        .suggested-item:first-child {
            border-top-left-radius: var(--border-radius);
            border-top-right-radius: var(--border-radius);
        }

        .suggested-item:last-child {
            border-bottom: 0;
            border-bottom-left-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
        }

        .suggested-item {
            position: relative;
            display: block;
            padding: 8px 12px 9px 12px;
            background-color: var(--bg-other-color);
            border-bottom: 1px solid var(--border-other-color);
            font-size: var(--font-12);
            line-height: calc(var(--font-12) + 3px);
            cursor: pointer;
        }

        .suggested-item-text {
            color: var(--fg-other-color);

            &:hover {
                color: var(--primary-color-text) !important;
            }
        }
    }
}

</style>
