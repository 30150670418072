<template>
    <div v-if="message.enabled" class="message-form">
        <DatePicker
            :locale="$locale(this)"
            class="calendar calendar-no-date"
            v-model="value"
            mode="time"
            is-range
            is24hr
        />

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns'
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import DatePicker from "@/components/calendar/components/DatePicker";

//todo: non gestiti valori ammissibili sul calendario

export default {
    name: "FieldTimeRange",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        DatePicker
    },
    data() {
        return {
            value: {
                start: null,
                end: null,
            },
        };
    },
    computed: {
        disabled() {
            return (this.value.start === null || this.value.end === null);
        },
    },
    methods: {
        onInputClicked: function () {
            const value = {
                inputMode: InputMode.CLICK,
                optionValue: format(this.value.start, "HH:mm") + " - " + format(this.value.end, "HH:mm"),
                optionText: format(this.value.start, "HH:mm") + " - " + format(this.value.end, "HH:mm"),
            };
            this.$emit("onSendCommand", value);

        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand", value);
        },
    }
}
</script>

<style>

</style>
