<template>
    <div v-if="kioskData.countdown" class="session">
        <div class="container-session">
            <ul class="message-session-content">
                <li class="message-session-title" v-html="disengageMessage('kiosk.disengageMessage')"></li>
                <li class="message-session-items">
                    <div class="message-session-item w-100" @click="onClick">
                        <svg viewBox="0 0 36 36" class="circular-session">
                            <path class="circle-off"
                                  stroke-dasharray="100, 100'"
                                  d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path class="circle-on"
                                  :stroke-dasharray="(countDown / initialCountDown * 100) + ', 100'"
                                  d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="23" class="percentage">{{countDown}}</text>
                        </svg>
                        {{$translate(api.language, 'kiosk.resumeMessage')}}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: "KioskSession",
    components: {
    },
    props: {
        talking: {
            type: Boolean,
            required: true
        },
        kioskData: {
            type: Object,
            required: true
        },
        session: {
            type: Number,
            required: true
        },
        standby: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            timer: null,
            timerResume: null,
            countDown: null,
            initialCountDown: 10,
        }
    },
    watch: {
        'kioskData.face': {
            handler(newVal) {
                if (newVal > 0) {
                    if (!this.standby) {
                        this.onClick();
                    } else {
                        const self = this;
                        setTimeout(()=> {
                            self.resetSession();
                        }, 3000);
                        //this.resetSession();
                    }
                }

            },
            deep: true,
        },
        'kioskData.click': {
            handler(newVal) {
                if (newVal > 0) {
                    if (!this.standby) {
                        this.onClick();
                    } else {
                        this.resetSession();
                    }
                }

            },
            deep: true,
        },
        talking: {
            handler() {
                this.resetTimer();
            },
            deep: true,
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        this.initGestureClick();
    },
    destroyed() {
        document.removeEventListener("click", this.gestureClick, {once : false});
    },
    methods: {
        initGestureClick() {
            document.addEventListener("click", this.gestureClick, {once : false});
        },
        gestureClick() {
            if (!this.kioskData.countdown)
                this.kioskData.click++;
        },
        disengageMessage(key) {
            if ((this.api.bot.disengageMessage || '').length > 0)
                return this.api.bot.disengageMessage;
            else
                return this.$translate(this.api.language, key)
        },
        resetTimer() {
            if (this.timer != null)
                clearTimeout(this.timer);

            this.timer = setTimeout(this.standbySession, this.session * 1000);
        },
        standbySession() {
            if (this.talking) {
                this.resetTimer();
            } else {
                this.kioskData.countdown = true;
                this.countDown = this.initialCountDown;
                this.timerResume = setInterval(this.onCountDown, 1000);
            }
        },
        resetSession() {
            this.resetTimer();
            this.$log.info('Kiosk resume standby');
            this.$emit("onResetSession");
        },
        onCountDown() {
            if (this.countDown === 0) {
                if (this.timerResume != null)
                    clearInterval(this.timerResume);

                this.kioskData.countdown = false;
                this.$log.info('Kiosk session timeout, standby');
                this.$emit("onStanbySession");
            } else {
                this.countDown--;
            }
        },
        onClick() {
            this.kioskData.countdown = false;

            if (this.timerResume != null) {
                clearInterval(this.timerResume);
                this.timerResume = null;
            }

            this.resetTimer();
        }
    }
}
</script>

<style lang="less">

.session {
    width: auto;
    height: auto;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .container-session {
        position: absolute;
        width: auto;
        height: auto;
        margin: 0;
        left: calc((100% - 486px) / 2);
        right: calc((100% - 486px) / 2);
        top: 50%;
        transform: translateY(-50%);

        .message-session-content {
            display: flex;
            flex-direction: column;
            list-style-type: none;

            padding: 0;
            margin: 10px 20px;

            color: var(--fg-other-color);
            background-color: var(--bg-other-color);
            box-shadow: var(--box-shadow);
            border-radius: var(--border-radius);
            font-size: var(--font-12);
            line-height: calc(var(--font-12) + 3px);

            text-align: center;

            .message-session-title {
                padding: 9px 12px 8px 12px;
                border-bottom: 1px solid var(--border-other-color);

                p {
                    margin: 0;
                }
            }

            .message-session-items {
                display: flex;
                flex-direction: row;
                border-bottom: 0;

                .message-session-item {
                    position: relative;
                    color: var(--fg-other-color);
                    border-right: 1px solid var(--border-other-color);
                    width: 50%;
                    padding: 8px 12px 9px 12px;
                    cursor: pointer;

                    &.w-100 {
                        width: 100%;
                        border-right: unset;
                    }

                    &:last-child {
                        border-right: 0;
                    }

                    &:hover {
                        color: var(--primary-color-text);
                    }
                }
            }

            .circular-session {
                position: absolute;
                display: inline-block;
                bottom: 3px;
                right: 3px;
                width: 26px;
                height: 26px;

                .circle-off {
                    stroke: var(--border-other-color);
                    fill: none;
                    stroke-width: 3;
                    stroke-linecap: round;
                }

                .circle-on {
                    stroke: var(--primary-color);
                    fill: none;
                    stroke-width: 3;
                    stroke-linecap: round;
                }

                .percentage {
                    fill: var(--fg-other-color);
                    font-size: 14px;
                    text-anchor: middle;
                }
            }

        }
    }
}

</style>
