<template>
    <svg
        class="chat-icon"
        :width="width"
        :height="height"
        :viewBox="viewBox"
        v-on="$listeners"
    >
        <path :d="path" />
    </svg>
</template>

<script>
const _defSize = '14px';
const _defViewBox = '0 0 24 24';

const icons = {
    'icon-vote-off': {
        viewBox: '0 0 24 24',
        pathOld: 'M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z',
        path: 'M12,12.6c-0.7-0.8-1.8-0.8-1.9-0.8H9.3l0-1.3C9.2,9.8,8.9,9.2,8.4,8.8C8,8.5,7.4,8.3,6.7,8.4c-0.5,0.1-0.9,0.5-1,1' +
            'c-0.1,0.3-0.4,2-0.4,2.2c-0.2,0.9-0.6,1-1.1,1.2L4,12.8c0,0-0.1,0-0.1,0.1H2.5c-1,0-1.8,0.8-1.8,1.7v3.2c0,0.9,0.8,1.7,1.8,1.7h1.3' +
            'c0.1,0.1,0.2,0.2,0.3,0.3C4.5,19.9,4.9,20,5.4,20l3.9,0.3c0.7,0,1.3-0.3,1.7-0.8c0.2-0.3,0.4-0.6,0.4-0.9l1-4.2' +
            'C12.6,13.5,12.3,12.9,12,12.6z M2.3,17.7l0-3.2c0,0,0.1-0.1,0.2-0.1h0.8v3.3H2.5C2.4,17.7,2.3,17.7,2.3,17.7z M10.9,14l-1,4.3' +
            'c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.4,0.2l-3.9-0.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.5v-3.7' +
            'c0.6-0.2,1.6-0.7,2-2.2c0-0.1,0.3-1.3,0.4-1.9c0.1,0,0.2,0,0.2,0.1c0.2,0.1,0.2,0.4,0.2,0.5v1.8c0,0.6,0.4,1,1,1h1.4' +
            'c0.2,0,0.5,0.1,0.7,0.2C10.9,13.7,10.9,13.9,10.9,14z M21.5,4.7h-1.3c-0.1-0.1-0.2-0.2-0.3-0.3C19.5,4.1,19,3.9,18.6,4l-3.9-0.3' +
            'c-0.7,0-1.3,0.3-1.7,0.8c-0.2,0.3-0.4,0.6-0.4,0.9l-1,4.3c-0.1,0.9,0.1,1.4,0.4,1.7c0.7,0.8,1.8,0.8,1.9,0.8h0.8l0,1.3' +
            'c0.1,0.7,0.4,1.3,0.9,1.7c0.4,0.3,0.9,0.4,1.4,0.4c0.1,0,0.2,0,0.4,0c0.5-0.1,0.9-0.5,1-1c0.1-0.3,0.4-2,0.4-2.2' +
            'c0.2-0.9,0.6-1,1.1-1.2l0.2-0.1c0,0,0.1,0,0.1-0.1h1.4c1,0,1.8-0.8,1.8-1.7V6.3C23.3,5.4,22.5,4.7,21.5,4.7z M19.1,9.8' +
            'c-0.6,0.2-1.6,0.7-2,2.2c0,0.1-0.3,1.3-0.4,1.9c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.2-0.4-0.2-0.5v-1.8c0-0.6-0.4-1-1-1h-1.4' +
            'c-0.2,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.1-0.2-0.1-0.4l1-4.3c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.2,0.4-0.2l3.9,0.3' +
            'c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.5V9.8z M21.5,9.6h-0.8V6.3h0.8c0.1,0,0.2,0.1,0.2,0.1l0,3.2C21.7,9.5,21.6,9.6,21.5,9.6z'
    },
    'icon-vote-on': {
        viewBox: '0 0 24 24',
        pathOld: 'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z',
        path: 'M12,12.6c-0.7-0.8-1.8-0.8-1.9-0.8H9.3l0-1.3C9.2,9.8,8.9,9.2,8.4,8.8C8,8.5,7.4,8.3,6.7,8.4c-0.5,0.1-0.9,0.5-1,1' +
            'c-0.1,0.3-0.4,2-0.4,2.2c-0.2,0.9-0.6,1-1.1,1.2L4,12.8c0,0-0.1,0-0.1,0.1H2.5c-1,0-1.8,0.8-1.8,1.7v3.2c0,0.9,0.8,1.7,1.8,1.7h1.3' +
            'c0.1,0.1,0.2,0.2,0.3,0.3C4.5,19.9,4.9,20,5.4,20l3.9,0.3c0.7,0,1.3-0.3,1.7-0.8c0.2-0.3,0.4-0.6,0.4-0.9l1-4.2' +
            'C12.6,13.5,12.3,12.9,12,12.6z M21.5,4.7h-1.3c-0.1-0.1-0.2-0.2-0.3-0.3C19.5,4.1,19,3.9,18.6,4l-3.9-0.3c-0.7,0-1.3,0.3-1.7,0.8' +
            'c-0.2,0.3-0.4,0.6-0.4,0.9l-1,4.3c-0.1,0.9,0.1,1.4,0.4,1.7c0.7,0.8,1.8,0.8,1.9,0.8h0.8l0,1.3c0.1,0.7,0.4,1.3,0.9,1.7' +
            'c0.4,0.3,0.9,0.4,1.4,0.4c0.1,0,0.2,0,0.4,0c0.5-0.1,0.9-0.5,1-1c0.1-0.3,0.4-2,0.4-2.2c0.2-0.9,0.6-1,1.1-1.2l0.2-0.1' +
            'c0,0,0.1,0,0.1-0.1h1.4c1,0,1.8-0.8,1.8-1.7V6.3C23.3,5.4,22.5,4.7,21.5,4.7z'
    },
    'email': {
        viewBox: '0 0 24 24',
        pathOld: 'M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z',
        path: 'M12.07,19.95c-4.97,0-7.79-0.37-8.02-0.45c-1.13-0.42-1.74-1.01-2.19-2.13l0.01,0C1.59,16.52,1.5,13.46,1.5,12' +
            'c0-3.27,0.27-5.12,0.34-5.32c0.47-1.17,1.08-1.76,2.19-2.17c0.29-0.11,3.28-0.46,8.03-0.46c4.96,0,7.67,0.37,7.92,0.47' +
            'c1.1,0.41,1.71,1,2.16,2.12l-0.01,0c0.3,0.92,0.37,4.2,0.37,5.37c0,3.23-0.26,5.1-0.33,5.31c-0.47,1.18-1.08,1.78-2.2,2.18' +
            'C19.69,19.59,16.93,19.95,12.07,19.95z M4.63,17.58c0.47,0.06,2.97,0.37,7.44,0.37c4.45,0,6.87-0.31,7.31-0.37' +
            'c0.48-0.18,0.67-0.37,0.88-0.86c0.05-0.37,0.25-1.92,0.25-4.72c0-2.82-0.2-4.37-0.25-4.71c-0.21-0.49-0.4-0.68-0.89-0.87' +
            'c-0.44-0.06-2.84-0.37-7.3-0.37c-4.5,0-6.98,0.31-7.44,0.37C4.15,6.61,3.96,6.79,3.75,7.28C3.7,7.65,3.5,9.2,3.5,12' +
            'c0,2.81,0.2,4.37,0.25,4.71C3.96,17.2,4.15,17.39,4.63,17.58z M12.46,13.74l6-3.12c0.49-0.25,0.68-0.86,0.43-1.35' +
            'c-0.25-0.49-0.86-0.68-1.35-0.43L12,11.73L6.46,8.85C5.97,8.59,5.37,8.78,5.11,9.27c-0.25,0.49-0.06,1.09,0.43,1.35l6,3.12' +
            'c0.14,0.08,0.3,0.11,0.46,0.11S12.32,13.82,12.46,13.74z',

    },
    'phone': {
        viewBox: '0 0 24 24',
        pathOld: 'M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z',
        path: 'M16.64,21.99c-0.4,0-0.82-0.05-1.23-0.15c-3.23-0.79-5.98-2.38-8.41-4.86c-2.46-2.42-4.05-5.17-4.85-8.39' +
            'c-0.59-2.4,0.7-4.65,1.72-5.57c0.56-0.51,1.4-0.84,2.57-1c0.9-0.14,1.53,0.33,1.77,0.89l2.04,3.51c0.37,0.61,0.28,1.42-0.23,1.95' +
            'C9.6,8.75,8.62,9.6,8.23,10c0.48,1.23,1.8,2.69,2.36,3.24c0.04,0.03,0.07,0.06,0.1,0.09c0.49,0.51,2.04,1.95,3.3,2.43' +
            'c0.48-0.47,1.27-1.38,1.56-1.72c0.59-0.58,1.4-0.67,2.03-0.29l3.5,2.03c0.56,0.23,1.03,0.87,0.9,1.73c-0.07,0.48-0.25,1.79-1,2.61' +
            'C20.21,20.96,18.55,21.99,16.64,21.99z M6.54,4.04C5.64,4.19,5.32,4.41,5.22,4.5C4.79,4.89,3.67,6.39,4.09,8.12' +
            'c0.7,2.85,2.12,5.29,4.32,7.45c2.18,2.22,4.62,3.63,7.47,4.33c1.72,0.42,3.23-0.7,3.61-1.12c0.12-0.13,0.33-0.47,0.47-1.33' +
            'l-3.14-1.83c-0.34,0.4-1.08,1.24-1.5,1.63c-0.87,0.82-1.64,0.52-2.01,0.38c-1.77-0.68-3.58-2.43-3.99-2.84' +
            'c-0.02-0.01-0.04-0.03-0.05-0.05c-0.09-0.08-2.17-2.1-2.92-4.04c-0.15-0.4-0.45-1.17,0.37-2.04c0.38-0.41,1.19-1.12,1.63-1.5' +
            'L6.54,4.04z M16.56,15.47C16.57,15.48,16.57,15.48,16.56,15.47L16.56,15.47z',

    },
    'link': {
        viewBox: '0 0 24 24',
        pathOld: 'M17 7h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-6 8H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-2zm-3-4h8v2H8z',
        path: 'M7.76,21.24c-1.34,0-2.59-0.52-3.54-1.46c-0.94-0.94-1.46-2.2-1.46-3.54c0-1.34,0.52-2.59,1.46-3.54l2.83-2.83' +
            'c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41l-2.83,2.83c-0.56,0.56-0.87,1.32-0.87,2.12c0,0.8,0.31,1.56,0.87,2.12' +
            'c1.13,1.13,3.12,1.13,4.24,0l2.83-2.83c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41l-2.83,2.83C10.35,20.72,9.1,21.24,7.76,21.24z' +
            'M16.95,14.12l2.83-2.83c1.95-1.95,1.95-5.12,0-7.07c-1.95-1.95-5.12-1.95-7.07,0L9.88,7.05c-0.39,0.39-0.39,1.02,0,1.41' +
            'c0.39,0.39,1.02,0.39,1.41,0l2.83-2.83c1.17-1.17,3.07-1.17,4.24,0c1.17,1.17,1.17,3.07,0,4.24l-2.83,2.83' +
            'c-0.39,0.39-0.39,1.02,0,1.41c0.2,0.2,0.45,0.29,0.71,0.29S16.75,14.32,16.95,14.12z M10.59,14.83l4.24-4.24' +
            'c0.39-0.39,0.39-1.02,0-1.41s-1.02-0.39-1.41,0l-4.24,4.24c-0.39,0.39-0.39,1.02,0,1.41c0.2,0.2,0.45,0.29,0.71,0.29' +
            'S10.39,15.02,10.59,14.83z'
    },
    'location': {
        viewBox: '0 0 24 24',
        pathOld: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z',
        path: 'M11.99,23C11.99,23,11.99,23,11.99,23c-0.28,0-0.54-0.11-0.73-0.31c-0.32-0.34-7.82-8.33-7.82-13.68C3.44,4.59,7.28,1,12,1' +
            'c4.69,0,8.54,3.59,8.56,8c0.03,5.36-7.53,13.35-7.85,13.68C12.52,22.89,12.26,23,11.99,23z M12,3C8.38,3,5.44,5.7,5.44,9.01' +
            'c0,3.39,4.22,8.87,6.55,11.51c2.35-2.64,6.59-8.12,6.57-11.5C18.54,5.7,15.6,3,12,3z'
    },
    'settings': {
        viewBox: '0 0 24 24',
        pathOld: 'M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z',
        path: 'M12,23c-0.53,0-1.06-0.14-1.53-0.41L3.6,18.62c-0.94-0.55-1.53-1.56-1.53-2.65V8.03c0-1.09,0.59-2.11,1.53-2.65l6.87-3.97' +
            'c0.94-0.54,2.12-0.54,3.06,0l6.87,3.97c0.94,0.55,1.53,1.56,1.53,2.65v7.94c0,1.09-0.59,2.11-1.53,2.65l-6.87,3.97' +
            'C13.06,22.86,12.53,23,12,23z M12,3c-0.18,0-0.37,0.05-0.53,0.14L4.6,7.11C4.27,7.3,4.06,7.65,4.06,8.03v7.94' +
            'c0,0.38,0.2,0.73,0.53,0.92l6.87,3.97c0.33,0.19,0.74,0.19,1.06,0l6.87-3.97c0.33-0.19,0.53-0.54,0.53-0.92V8.03' +
            'c0-0.38-0.2-0.73-0.53-0.92l-6.87-3.97C12.37,3.05,12.18,3,12,3z M12,17.12c-2.83,0-5.12-2.3-5.12-5.12c0-2.83,2.3-5.12,5.12-5.12' +
            'c2.83,0,5.12,2.3,5.12,5.12C17.12,14.83,14.83,17.12,12,17.12z M12,8.88c-1.72,0-3.12,1.4-3.12,3.12s1.4,3.12,3.12,3.12' +
            's3.12-1.4,3.12-3.12S13.72,8.88,12,8.88z'
    },
    'showall': {
        viewBox: '0 0 24 24',
        pathOld: 'M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z',
        path: 'M8.22,11.01h-3.5c-1.52,0-2.75-1.23-2.75-2.75v-3.5c0-1.52,1.23-2.75,2.75-2.75h3.5c1.52,0,2.75,1.23,2.75,2.75v3.5' +
            'C10.97,9.78,9.74,11.01,8.22,11.01z M4.72,4.01c-0.41,0-0.75,0.34-0.75,0.75v3.5c0,0.41,0.34,0.75,0.75,0.75h3.5' +
            'c0.41,0,0.75-0.34,0.75-0.75v-3.5c0-0.41-0.34-0.75-0.75-0.75H4.72z M19.22,11.01h-3.5c-1.52,0-2.75-1.23-2.75-2.75v-3.5' +
            'c0-1.52,1.23-2.75,2.75-2.75h3.5c1.52,0,2.75,1.23,2.75,2.75v3.5C21.97,9.78,20.74,11.01,19.22,11.01z M15.72,4.01' +
            'c-0.41,0-0.75,0.34-0.75,0.75v3.5c0,0.41,0.34,0.75,0.75,0.75h3.5c0.41,0,0.75-0.34,0.75-0.75v-3.5c0-0.41-0.34-0.75-0.75-0.75' +
            'H15.72z M8.22,21.99h-3.5c-1.52,0-2.75-1.23-2.75-2.75v-3.5c0-1.52,1.23-2.75,2.75-2.75h3.5c1.52,0,2.75,1.23,2.75,2.75v3.5' +
            'C10.97,20.75,9.74,21.99,8.22,21.99z M4.72,14.99c-0.41,0-0.75,0.34-0.75,0.75v3.5c0,0.41,0.34,0.75,0.75,0.75h3.5' +
            'c0.41,0,0.75-0.34,0.75-0.75v-3.5c0-0.41-0.34-0.75-0.75-0.75H4.72z M21.03,16.49h-2.6v-2.5c0-0.55-0.45-1-1-1s-1,0.45-1,1v2.5' +
            'h-2.61c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.61v2.5c0,0.55,0.45,1,1,1s1-0.45,1-1v-2.5h2.6c0.55,0,1-0.45,1-1S21.58,16.49,21.03,16.49z'
    },
    'info_o': {
        viewBox: '0 0 24 24',
        pathOld: 'M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z',
        path: 'M12,23C5.93,23,1,18.07,1,12S5.93,1,12,1s11,4.93,11,11S18.07,23,12,23z M12,3c-4.96,0-9,4.04-9,9c0,4.96,4.04,9,9,9' +
            'c4.96,0,9-4.04,9-9C21,7.04,16.96,3,12,3z M13,16.74v-6c0-0.55-0.45-1-1-1s-1,0.45-1,1v6c0,0.55,0.45,1,1,1S13,17.3,13,16.74z' +
            'M13,7.39V7.26c0-0.55-0.45-1-1-1s-1,0.45-1,1v0.13c0,0.55,0.45,1,1,1S13,7.94,13,7.39z'
    },
    'gps': {
        viewBox: '0 0 24 24',
        pathOld: 'M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
        path: 'M22,11h-2.07C19.48,7.39,16.61,4.52,13,4.07V2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2.07C7.39,4.52,4.52,7.39,4.07,11H2' +
            'c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.07c0.45,3.61,3.32,6.48,6.93,6.93V22c0,0.55,0.45,1,1,1s1-0.45,1-1v-2.07' +
            'c3.61-0.45,6.48-3.32,6.93-6.93H22c0.55,0,1-0.45,1-1S22.55,11,22,11z M12,18c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6' +
            'S15.31,18,12,18z M15,12c0,1.66-1.34,3-3,3s-3-1.34-3-3s1.34-3,3-3S15,10.34,15,12z'

    },
    'gps_off': {
        viewBox: '0 0 24 24',
        pathOld: 'M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06c-1.13.12-2.19.46-3.16.97l1.5 1.5C10.16 5.19 11.06 5 12 5c3.87 0 7 3.13 7 7 0 .94-.19 1.84-.52 2.65l1.5 1.5c.5-.96.84-2.02.97-3.15H23v-2h-2.06zM3 4.27l2.04 2.04C3.97 7.62 3.25 9.23 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c1.77-.2 3.38-.91 4.69-1.98L19.73 21 21 19.73 4.27 3 3 4.27zm13.27 13.27C15.09 18.45 13.61 19 12 19c-3.87 0-7-3.13-7-7 0-1.61.55-3.09 1.46-4.27l9.81 9.81z',
        path: 'M22,11h-2.07C19.48,7.39,16.61,4.52,13,4.07V2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2.07C7.39,4.52,4.52,7.39,4.07,11H2' +
            'c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.07c0.45,3.61,3.32,6.48,6.93,6.93V22c0,0.55,0.45,1,1,1s1-0.45,1-1v-2.07' +
            'c3.61-0.45,6.48-3.32,6.93-6.93H22c0.55,0,1-0.45,1-1S22.55,11,22,11z M12,18c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6' +
            'S15.31,18,12,18z'

    },
    'select-file': {
        viewBox: '0 0 24 24',
        pathOld: 'M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z',
        path: 'M11.94,20.89c-4.24,0-6.64-0.29-7.27-0.38l0,0c-0.08-0.01-0.16-0.03-0.23-0.05c-1.07-0.39-1.66-0.96-2.09-2.02' +
            'C2.22,18.15,2,16.2,2,13.35c0-0.55,0.45-1,1-1s1,0.45,1,1c0,2.6,0.18,4.07,0.23,4.43c0.19,0.43,0.36,0.6,0.79,0.76' +
            'c0.71,0.09,2.97,0.35,6.92,0.35c4.21,0,6.58-0.29,7.04-0.35c0.43-0.17,0.6-0.33,0.79-0.76c0.07-0.51,0.24-2,0.24-4.44' +
            'c0-0.55,0.45-1,1-1s1,0.45,1,1c0,2.67-0.19,4.26-0.27,4.82l0,0c-0.01,0.09-0.03,0.18-0.07,0.26c-0.43,1.07-1.02,1.63-2.09,2.03' +
            'c-0.03,0.01-0.07,0.02-0.1,0.03C19.28,20.54,16.61,20.89,11.94,20.89z M15.54,6.48l-2.87-3.06c0,0-0.01,0-0.01-0.01' +
            'c-0.18-0.19-0.43-0.31-0.72-0.31s-0.53,0.12-0.72,0.31c0,0-0.01,0-0.01,0.01L8.35,6.48c-0.38,0.4-0.36,1.04,0.05,1.41' +
            'c0.4,0.38,1.04,0.36,1.41-0.05l1.14-1.21v8.93c0,0.55,0.45,1,1,1s1-0.45,1-1V6.63l1.14,1.21c0.2,0.21,0.46,0.32,0.73,0.32' +
            'c0.25,0,0.49-0.09,0.68-0.27C15.89,7.51,15.92,6.88,15.54,6.48z'

    },
    'webcam_o': {
        viewBox: '0 0 24 24',
        pathOld: 'M15 8v8H5V8h10m1-2H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4V7c0-.55-.45-1-1-1z',
        path: 'M17.23,16H6.77C3.59,16,1,13.41,1,10.23V9.77C1,6.59,3.59,4,6.77,4h10.47C20.41,4,23,6.59,23,9.77v0.47' +
            'C23,13.41,20.41,16,17.23,16z M6.77,6C4.69,6,3,7.69,3,9.77v0.47C3,12.31,4.69,14,6.77,14h10.47c2.08,0,3.77-1.69,3.77-3.77V9.77' +
            'C21,7.69,19.31,6,17.23,6H6.77z M12,13.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.93,13.5,12,13.5z M12,8.5' +
            'c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S12.83,8.5,12,8.5z M16,19c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1' +
            's0.45,1,1,1h6C15.55,20,16,19.55,16,19z'
    },
};

export default {
    name: 'Icon',
    props: ['name', 'size'],
    data() {
        return {
            set: this.$root.props.iconSet,
            width: _defSize,
            height: _defSize,
            viewBox: _defViewBox,
            path: ''
        };
    },
    mounted() {
        this.updateIcon();
    },
    watch: {
        name() {
            this.updateIcon();
        },
        size() {
            this.updateIcon();
        },
    },
    methods: {
        updateIcon() {
            const icon = icons[this.name];
            if (icon) {
                let size = this.size;
                if (size === undefined)
                    size = _defSize;

                this.width = icon.width || size;
                this.height = icon.height || size;
                this.viewBox = icon.viewBox;
                this.path = icon['path' + this.set];
            }
        },
    },
};
</script>

<style lang="less">

.chat-icon {

    display: inline-block;
    stroke: currentColor;
    stroke-width: 0;
    color: var(--fg-color);
    //margin-left: 5px;

    & path {
        fill: currentColor;
    }

    &.margin-left {
        margin-left: 10px;
    }

    &.margin-right {
        margin-right: 10px;
    }
}


</style>
