<template>
    <div class="message-multimedia margin-common-content-multimedia">
        <qrcode-vue :value="message.media" :size="300" level="H" class="qrcode" />
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';


export default {
    name: "MediaQrCode",
    components: {
        QrcodeVue
    },
    props: {
        message: {
            type: Object,
            required: true
        },
    },
}
</script>

<style lang="less">

    .qrcode {
        padding: 16px;
        margin: 0 15% 0 15%;
        background-color: white;
        border-radius: var(--border-radius);
        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }

</style>
