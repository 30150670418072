<template>
    <div class="message-date">
        {{date}}
    </div>
</template>

<script>
    import {DateTime} from "luxon";

    export default {
        name: 'DateHeader',
        components:{
        },
        props:{
            message: {
                type: Object,
                required: true
            },
            timestampConfig: {
                type: Object,
                required: true
            }
        },
        computed: {
            date() {
                const test = this.message.timestamp.toFormat('yyyyMMdd');

                const today = DateTime.local().toFormat('yyyyMMdd');
                const yesterday = DateTime.local().plus({ days: -1 });

                if (test === today)
                    return this.$translate(this.api.language, 'date.today');
                else if (test === yesterday)
                    return this.$translate(this.api.language, 'date.yesterday');
                else
                    return this.message.timestamp.setLocale(this.$locale(this)).toFormat('DDDD');
            }
        },
        beforeCreate() {
            this.api = this.$root.$children[0].$refs.api;
        },
        methods: {

        }
    }
</script>

<style lang="less">
.container-message-display {
    .message-date {
        padding: 20px;
        font-size: var(--font-10);
        color: var(--caption-color);
        text-transform: uppercase;
    }

}
</style>

