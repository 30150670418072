<template>
    <div class="chat-widget-old" ref="widget" @mouseenter="zoom(true)" @mouseleave="zoom(false)" @click="openUI">
        <div ref="tooltip" class="chat-widget-container-old" :content="features.tooltip" v-tippy="{ placement: 'auto',  appendTo: container, trigger: features.tooltip ? 'mouseenter focus' : 'manual', delay: [1000, 30000], distance: 15, theme: 'widget' }">

            <svg v-if="!hover" class="icon-widget-old" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <path fill="currentColor" d="M8.5,106.07c0,25.48,4.1,38.31,4.16,38.48,2.62,6.55,5.65,9.57,12,11.95a83.12,83.12,0,0,0,11.18,2l7.91,17,18.07-15.08c4.43.15,9.31.23,14.67.23,38,0,51.7-4.09,51.87-4.15,6.37-2.42,9.4-5.44,12-12,.06-.1,4.16-13,4.16-38.42s-4.1-38.32-4.13-38.43c-2.63-6.6-5.65-9.62-12-12-.17-.06-13.87-4.15-51.87-4.15-37.52,0-51.7,4.09-51.86,4.14-6.38,2.38-9.4,5.4-12,12C12.6,67.75,8.5,80.59,8.5,106.07Zm21.56-2.63a4,4,0,0,1,4-4H89.17a4,4,0,1,1,0,8H34.06A4,4,0,0,1,30.06,103.44Zm70.51,25.9H44.51a4,4,0,1,1,0-8h56.06a4,4,0,0,1,0,8Zm0-43.81H44.51a4,4,0,1,1,0-8h56.06a4,4,0,0,1,0,8Z"/>
                <path fill="currentColor" d="M189,34.23c-1.57-4-3.4-5.79-7.26-7.22-.09,0-8.66-2.51-31.33-2.51-23,0-31.24,2.48-31.35,2.51-3.83,1.44-5.66,3.27-7.25,7.25a64.46,64.46,0,0,0-2,12.52C124.56,48.1,130.31,50,130.39,50l.11,0c7.89,3,12.23,7.27,15.46,15.39l.19.56c.42,1.38,2.75,9.63,3.82,24.48h.45q4.85,0,8.86-.14l10.92,9.12L175,89.16A48.21,48.21,0,0,0,181.73,88c3.84-1.43,5.67-3.26,7.26-7.22,0-.1,2.51-7.85,2.51-23.25S189,34.32,189,34.23Z"/>
            </svg>


            <svg v-if="hover" class="icon-widget-old" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <path fill="currentColor" d="M76.5,59.5c33.13,0,47.46,3.27,49.4,3.76,3.87,1.49,5.25,2.89,6.91,7,.46,1.6,3.71,13.59,3.71,35.82s-3.23,34.14-3.71,35.81c-1.67,4.08-3,5.49-6.85,7a73.23,73.23,0,0,1-9.63,1.69,8,8,0,0,0-6.4,4.58l-3.52,7.57-10.08-8.42a8,8,0,0,0-5.13-1.85h-.27c-4.42.15-9.26.23-14.4.23-33.67,0-47.57-3.29-49.35-3.76-3.93-1.53-5.31-2.95-7-7.1-.42-1.44-3.69-13.28-3.69-35.71,0-21.83,3.14-33.84,3.69-35.8,1.69-4.14,3.08-5.54,7-7,1.72-.45,15.61-3.75,49.36-3.75m0-8c-38,0-51.7,4.09-51.87,4.15-6.35,2.38-9.37,5.4-12,12,0,.11-4.13,13-4.13,38.43s4.1,38.32,4.16,38.42c2.6,6.56,5.63,9.58,12,12,.17.06,13.85,4.15,51.87,4.15,5.36,0,10.24-.08,14.67-.23l18.07,15.08,7.91-17a83.12,83.12,0,0,0,11.18-2c6.35-2.38,9.38-5.4,12-11.95.06-.17,4.16-13,4.16-38.48s-4.1-38.32-4.16-38.48c-2.6-6.55-5.62-9.57-12-12-.16,0-14.34-4.14-51.86-4.14Z"/>
                <path fill="currentColor" d="M100.57,85.52H44.51a4,4,0,0,1,0-8h56.06a4,4,0,0,1,0,8Z"/>
                <path fill="currentColor" d="M89.17,107.44H34.06a4,4,0,0,1,0-8H89.17a4,4,0,1,1,0,8Z"/>
                <path fill="currentColor" d="M100.57,129.34H44.51a4,4,0,1,1,0-8h56.06a4,4,0,0,1,0,8Z"/>
                <path fill="currentColor" d="M150.4,32.5c19,0,27.47,1.8,28.92,2.14,1.19.46,1.47.68,2.1,2.23.34,1.21,2.08,8.14,2.08,20.61s-1.73,19.34-2.07,20.61c-.64,1.53-1,1.77-2.08,2.21a38.31,38.31,0,0,1-5.1.89l-15.4,1.14-1.59,0a112,112,0,0,0-3.45-18.71l-.09-.29-.19-.56-.13-.36c-4.07-10.21-10.08-16.17-20.1-19.92l-.08,0-.11,0-.05,0c-.2-.07-4.2-1.46-14.32-2.75.28-1.45.52-2.39.63-2.82.65-1.57,1-1.79,2.14-2.25s9.6-2.13,28.89-2.13m0-8c-23,0-31.24,2.48-31.35,2.51-3.83,1.44-5.66,3.27-7.25,7.25a64.46,64.46,0,0,0-2,12.52C124.56,48.1,130.31,50,130.39,50l.11,0c7.89,3,12.23,7.27,15.46,15.39l.19.56c.42,1.38,2.75,9.63,3.82,24.48h.45q4.85,0,8.86-.14L175,89.16A48.21,48.21,0,0,0,181.73,88c3.84-1.43,5.67-3.26,7.26-7.22,0-.1,2.51-7.85,2.51-23.25S189,34.32,189,34.23c-1.57-4-3.4-5.79-7.26-7.22-.09,0-8.66-2.51-31.33-2.51Z"/>
            </svg>

        </div>
    </div>
</template>

<script>

const StorageKeys = {
    WIDGET: '_algho_widget',
}

export default {
    name: 'WidgetOld',
    components: {
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            hover: false,
        }
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        if (!this.features.device.mobile) {
            window.addEventListener("unload", this.unload, {once: true});
        }
    },
    destroyed() {
        window.removeEventListener("unload", this.unload, {once: true});
    },
    mounted() {

        if (this.features.custom.widget.bottom !== '')
            this.$refs.widget.style.bottom = this.features.custom.widget.bottom;
        if (this.features.custom.widget.right !== '')
            this.$refs.widget.style.right = this.features.custom.widget.right;
        if (this.features.custom.widget.left !== '')
            this.$refs.widget.style.left = this.features.custom.widget.left;
        if (this.features.custom.widget.top !== '')
            this.$refs.widget.style.top = this.features.custom.widget.top;

        if (this.features.custom.widget.borderRadius !== '')
            this.$refs.widget.style.borderRadius = this.features.custom.widget.borderRadius;

        if (!this.features.device.mobile) {
            const self = this;
            if (sessionStorage.getItem(StorageKeys.WIDGET) === '1') {
                setTimeout(() => {
                    self.features.status.opened = true;
                }, 1600);
            } else {
                if (this.features.tooltip) {
                    setTimeout(() => {
                        self.$nextTick(() => {
                            if (self.$refs.tooltip !== undefined) {
                                //self.$refs.tooltip._tippy.show();
                                const event = new Event('mouseenter');
                                self.$refs.tooltip.dispatchEvent(event);
                            }
                        });
                    }, 1600);
                }
            }
        }
    },
    methods: {
        unload() {
            sessionStorage.setItem(StorageKeys.WIDGET, (this.features.status.opened) ? '1' : '0');
        },
        zoom(val) {
            this.hover = val;
        },
        openUI() {
            this.features.status.click = true;
            this.$emit("onShow");
            this.api.trackOpenNotch();
            if (!this.features.demo)
                this.api.trackOpenNotch();
        },
    },
}

</script>

<style lang="less">

.chat-widget-old {
    position: fixed;
    box-sizing: border-box;
    right: 35px;
    bottom: 35px;
    width: 60px;
    height: 60px;
    background-color: var(--bg-color-widget);
    border: 2px solid var(--fg-color-widget);
    box-shadow: var(--box-shadow);
    border-radius: 28px 0px;

    cursor: pointer;

    .chat-widget-container-old {
        position: relative;
        width: 100%;
        height: 100%;

        .icon-widget-old {
            pointer-events: none;
            position: absolute;
            width: 36px;
            height: 36px;
            left: 10px;
            top: 10px;
            color: var(--fg-color-widget);
        }

    }

}

.widget-theme.tippy-tooltip {
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);
    padding: 10px 12px 8px 12px;
}

</style>
