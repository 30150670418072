
const EmptySound = require("../assets/empty.mp3");

export default {

    install(Vue, appUrl) {

        let currentSound = null;
        let gainNode = null;
        const audioContext = new (window.webkitAudioContext || window.AudioContext || window.mozAudioContext)();

        function loadSound(filename, callback) {
            const sound = {audioBuffer: null, source: null, startCallback: null, stopCallback: null}

            const ajax = new XMLHttpRequest()
            ajax.open("GET", filename, true);
            ajax.responseType = "arraybuffer";
            ajax.onload = function() {
                //Safari to unlock oneded audio events
                audioContext.resume();
                audioContext.decodeAudioData(
                    ajax.response,
                    function(buffer) {
                        Vue.$log.info("Load audio file", filename, buffer.duration);
                        sound.audioBuffer = buffer;
                        callback();
                    },
                    function(error) {
                        Vue.$log.error("Error decoding audio data", filename, error.message);
                        callback();
                    }
                )
            }

            ajax.onerror = function(e) {
                Vue.$log.error("Error loading audio file", filename, e);
                callback();
            }

            ajax.send();

            return sound;
        }

        function playSound(sound, volume) {

            if(!sound.audioBuffer)
                return false;

            const source = audioContext.createBufferSource();
            sound.source = source;

            if(!source)
                return false;

            source.buffer = sound.audioBuffer;

            if(!source.start)
                source.start = source.noteOn;

            if(!source.stop)
                source.stop = source.noteOff;

            if(!source.start) {
                Vue.$log.error("No start method present in AudioSourceNode.");
                return false;
            }

            gainNode = audioContext.createGain();
            gainNode.gain.value = volume / 100.0;
            source.connect(gainNode);
            gainNode.connect(audioContext.destination);

            //source.connect(audioContext.destination);

            source.onended = function () {
                Vue.$log.debug("End audio");
                if (sound.stopCallback !== null)
                    sound.stopCallback();
            };

            source.start(0);

            currentSound = sound;
            Vue.$log.debug("Start audio");
            if (sound.startCallback !== null)
                sound.startCallback(sound.audioBuffer.duration);

            return true;
        }

        function stopSound() {
            if (currentSound != null) {

                if (currentSound.source != null) {
                    Vue.$log.debug("Stopped audio");
                    currentSound.source.stop(0);
                }
            }
        }

        function setVolume(volume) {
            if (gainNode != null)
                gainNode.gain.value = volume / 100.0;
        }

        function dispose() {
            stopSound();
            document.removeEventListener('click', playGesture, {once : true});
        }

        function playGesture() {
            const sound = loadSound(EmptySound, function () {
                playSound(sound, 1.0);
            });
        }
        //Safari to unlock all audio
        document.addEventListener('click', playGesture, {once : true});

        Vue.$sound = Vue.prototype.$sound = {

            play(sound, volume) {
                const baseUrl = new URL(appUrl).origin;
                const SendSound = baseUrl + "/sounds/send.mp3";
                const ReceiveSound = baseUrl + "/sounds/receive.mp3";
                const PullSound = baseUrl + "/sounds/pull.mp3";
                const MicInSound = baseUrl + "/sounds/micin.mp3";
                const MicOutSound = baseUrl + "/sounds/micout.mp3";

                let note = null;
                switch (sound) {
                    case "send":
                        note = SendSound;
                        break;
                    case "receive":
                        note = ReceiveSound;
                        break;
                    case "pull":
                        note = PullSound;
                        break;
                    case "micIn":
                        note = MicInSound;
                        break;
                    case "micOut":
                        note = MicOutSound;
                        break;
                }
                this.playFile(note, volume, null, null);
            },

            playFile(file, volume, startCallback, stopCallback) {
                if (file !== undefined && file !=null) {
                    const sound = loadSound(file, function () {
                        sound.startCallback = startCallback;
                        sound.stopCallback = stopCallback;

                        const ret = playSound(sound, volume);
                        if (!ret && stopCallback)
                            stopCallback();
                    });
                } else {
                    const sound = loadSound(EmptySound, function () {
                        sound.startCallback = startCallback;
                        sound.stopCallback = stopCallback;

                        const ret = playSound(sound, volume);
                        if (!ret && stopCallback)
                            stopCallback();
                    });
                }
            },
            stop() {
                stopSound();
            },
            setVolume(volume) {
                setVolume(volume);
            },
            dispose() {
                dispose();
            }

        }
    }

}
