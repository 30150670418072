<template>
    <div class="message-multimedia">
        <ul class="message-file-content margin-common-content-multimedia">
            <li class="message-file-items">
                <div class="message-file-item">
                    <svg class="file-image" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/>
                    </svg>

                    <div class="file-title" v-html="filename"></div>
                    <div class="file-description" v-html="hostname"></div>
                </div>
            </li>
            <li v-if="!features.kiosk" class="message-file-anchor" :content="link" v-tippy="{ placement : 'top',  appendTo: container }" @click="onLinkClicked">{{$translate(api.language, 'file.download')}}</li>
            <li v-else class="message-file-anchor" :content="link" v-tippy="{ placement : 'top',  appendTo: container }" @click="onLinkClicked">{{$translate(api.language, 'file.open')}}</li>
        </ul>
    </div>
</template>

<script>

export default {
    name: "MediaFile",
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        },
        isNotEmpty() {
            return this.message.media.length > 0;
        },
        isAttachment() {
            return this.message.media.indexOf('data:') === 0;
        },
        hostname() {
            if (this.isAttachment) {
                return this.$translate(this.api.language, 'file.attachment');
            } else if (this.isNotEmpty) {
                const parser = document.createElement('a');
                parser.href = this.message.media;
                return parser.hostname;
            } else {
                return '';
            }
        },
        link() {
            if (this.isAttachment) {
                return this.$translate(this.api.language, 'file.attachment');
            } else {
                return this.message.media;
            }

        },
        filename() {
            if (this.isAttachment) {
                if (this.isNotEmpty) {
                    const split = this.message.media.split(';')
                    const mime = split[0].substr(5).toLowerCase();
                    if (split.length === 3) {
                        return split[1].substr(5);
                    } else {
                        let file = 'file';
                        let ext = this.$mime.extension(mime);
                        return file + '.' + ext;
                    }
                } else {
                    return '';
                }
            } else if (this.isNotEmpty) {
                const pathname = new URL(this.message.media).pathname;
                const index = pathname.lastIndexOf('/');
                return (-1 !== index) ? pathname.substring(index + 1) : pathname;
            } else {
                return '';
            }
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        /*
        if (this.features.kiosk)
            this.onLinkClicked();*/
    },
    methods: {
        onLinkClicked() {
            if (this.isNotEmpty) {
                const url = this.message.media;
                if (!this.features.kiosk && url.indexOf('http') === 0) {
                    const self = this;
                    this.api.downloadFile(url).then((result) => {
                        if (result != null) {
                            self.doOpenFile(result);
                        } else {
                            self.doOpenFile({
                                content: self.message.media,
                                fileName: self.filename
                            });
                        }
                    });
                } else {
                    this.doOpenFile({
                        content: this.message.media,
                        fileName: this.filename
                    });
                }
            }
        },
        doOpenFile(data) {
            if (!this.features.kiosk) {
                const link = document.createElement("a");
                link.download = data.fileName;
                link.target = "_blank";
                link.href = data.content;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(data.content + '#view=fitH&zoom=50&scrollbar=1&toolbar=0&navpanes=0', '_blank');
            }
        }
    }
}
</script>

<style lang="less">

.message-file-content {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;

    background-color: var(--bg-color-card);
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);
    border-radius: var(--border-radius);
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);

    .message-file-anchor {
        text-align: center;
        padding: 8px 12px 9px 12px;
        color: var(--primary-color-text);
        cursor: pointer;
    }

    .message-file-items {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--border-other-color);


        .message-file-item {
            color: var(--fg-color);
            padding: 8px 12px 9px 12px;

            &:last-child {
                border-right: 0;
            }
            &:first-child {
                padding-right: 0;
            }
        }
    }

    .file-image {
        padding: 0 6px 0 0;
        float: left;
        color: var(--fg-color);
    }

    .file-title {
        display: inline;
        color: var(--fg-color);
        font-weight: 600;
        font-size: var(--font-10);
    }

    .file-description {
        margin-bottom: 6px;

        color: var(--fg-color);
        font-size: var(--font-9);
        white-space: break-spaces;
    }

}


</style>
