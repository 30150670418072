<template>
    <div class="virtual-keyboard">
        <div class="keyboard-input">
            <div class="keyboard-input-tools-left">
                <ButtonIcon :disabled="value.length === 0" name="trash" size="18px" :content="$translate(api.language, 'tooltip.clear')" v-tippy="{ placement : 'top',  appendTo: container }" class="keyboard-input-item" @click.prevent="onClear" />
            </div>
            <span ref="input" v-html="value" class="keyboard-blink"></span>

            <div class="keyboard-input-tools">
                <ButtonIcon v-if="gps !== ''" name="gps" size="18px" :content="$translate(api.language, 'location.activate')" v-tippy="{ placement : 'top',  appendTo: container }" class="keyboard-input-item" @click="onGps"/>

                <ButtonIcon v-if="recognizeVoice" name="mic" size="18px" :content="$translate(api.language, 'tooltip.microphone.disable')" v-tippy="{ placement : 'top',  appendTo: container }" class="keyboard-input-item active" @click="onDisableMic"/>
                <ButtonIcon v-else name="mic_off" size="18px" :content="$translate(api.language, 'tooltip.microphone.enable')" v-tippy="{ placement : 'top',  appendTo: container }" class="keyboard-input-item" @click="onEnableMic"/>

                <ButtonIcon v-if="send" :disabled="value.length === 0" name="send" size="18px" :content="$translate(api.language, 'tooltip.send')" v-tippy="{ placement : 'top',  appendTo: container }" class="keyboard-input-item active" @click.prevent="onKeyboardSend" />
            </div>
        </div>
        <div ref="keyboard" :class="keyboardClass"></div>

        <SttChrome v-if="hasChromeStt" :listenVoice="listenVoice"
                   @onStartRecognizeVoice="onStartRecognizeVoice"
                   @onStopRecognizeVoice="onStopRecognizeVoice"
                   @onRecognizeText="onRecognizeText"
        />
        <SttAlgho v-if="!hasChromeStt"
                  :listenVoice="listenVoice"
                  @onStartRecognizeVoice="onStartRecognizeVoice"
                  @onStopRecognizeVoice="onStopRecognizeVoice"
                  @onRecognizeText="onRecognizeText"
        />

    </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import ButtonIcon from './chat/ButtonIcon';
import SttChrome from '@/components/SttChrome.vue';
import SttAlgho from '@/components/SttAlgho.vue';

export default {
    name: "KioskKeyboard",
    components: {
        ButtonIcon,
        SttChrome,
        SttAlgho
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        keyboardClass: {
            default: "simple-keyboard",
            type: String
        },
        input: {
            type: String
        },
        gps: {
            default: "",
            type: String
        },
        password: {
            default: false,
            type: Boolean
        },
        send: {
            default: false,
            type: Boolean
        }

    },
    data: () => ({
        keyboard: null,
        listenVoice: false,
        recognizeVoice: false,
    }),
    computed: {
        container() {
            return ()=> this.$el;
        },
        hasChromeStt() {
            return 'webkitSpeechRecognition' in window && this.$device.isChrome && !this.$device.isIOS && navigator.userAgent.indexOf("Algho") === -1;
        },
        value() {
            if (!this.password)
                return this.input;
            else
                return '*'.repeat(this.input.length)
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        //https://www.madeintext.com/text-symbols/

        const layouts = {
            it: {
                'default': [
                    "\\ 1 2 3 4 5 6 7 8 9 0 ' ì {backspace}",
                    "q w e r t y u i o p è + @",
                    "{caps} a s d f g h j k l ò à ù {enter}",
                    "{shiftleft} < z x c v b n m , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "\\ 1 2 3 4 5 6 7 8 9 0 ' Ì {backspace}",
                    "Q W E R T Y U I O P È * @",
                    "{caps} A S D F G H J K L Ò À Ù {enter}",
                    "{shiftleft} < Z X C V B N M , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "| ! \" £ $ % & / ( ) = ? ^ {backspace}",
                    "Q W E R T Y U I O P é * @",
                    "{caps} A S D F G H J K L ç ° § {enter}",
                    "{shiftleft} > Z X C V B N M ; : _ {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "` « “ ‘ ¥ ~ ‹ ÷ ´ ` ≠ ¡ ˆ {backspace}",
                    "„ Ω € ® ™ æ ¨ œ ø π [ ] {h}",
                    "{caps} å ß ∂ ƒ ∞ ∆ ª º ¬ @ # ¶ {enter}",
                    "{shiftleft} ≤ ∑ † © √ ∫ ˜ µ … • – {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            en: {
                'default': [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                    "q w e r t y u i o p [ ] \\ @",
                    "{caps} a s d f g h j k l ; , {enter}",
                    "{shiftleft} z x c v b n m , . / {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                    "Q W E R T Y U I O P [ ] \\ @",
                    "{caps} A S D F G H J K L ; , {enter}",
                    "{shiftleft} Z X C V B N M , . / {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "~ ! @ # $ % ^ & * ( ) _ + {backspace}",
                    "Q W E R T Y U I O P { } | @",
                    "{caps} A S D F G H J K L : \" {enter}",
                    "{shiftleft} Z X C V B N M < > ? {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "~ ¡ ² ³ ¤ € ¼ ½ ¾ ‘ ’ ¥ × {backspace}",
                    "ä å é ® þ ü ú í ó ö « » ¬ {h}",
                    "{caps} á ß ð f g h j k ø ¶ ´ {enter}",
                    "{shiftleft} æ x © v b ñ µ ç > ¿ {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            fr: {
                'default': [
                    "² & é \" ' ( - è _ ç à ) = {backspace}",
                    "a z e r t y u i o p ^ $ @",
                    "{caps} q s d f g h j k l m ù * {enter}",
                    "{shiftleft} < w x c v b n , ; : ! {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "² & É \" ' ( - È _ Ç À ) = {backspace}",
                    "A Z E R T Y U I O P ^ $ @",
                    "{caps} Q S D F G H J K L M Ù * {enter}",
                    "{shiftleft} < W X C V B N , ; : ! {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "> 1 2 3 4 5 6 7 8 9 0 ° + {backspace}",
                    "A Z E R T Y U I O P ¨ £ @",
                    "{caps} Q S D F G H J K L M % μ {enter}",
                    "{shiftleft} > W X C V B N ? . / § {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "≤ » ~ # { [ | ` \\ ^ @ ] } {backspace}",
                    "´ Â € ® † Ú º î œ π ô ¤ {h}",
                    "{caps} ´ Ò ∂ ƒ ﬁ Ì Ï È ¬ µ Ù @ {enter}",
                    "{shiftleft} « ‹ ≈ © ◊ ß ~∞ … ÷ ¡ {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            es: {
                'default': [
                    "º 1 2 3 4 5 6 7 8 9 0 ' ¡ {backspace}",
                    "q w e r t y u i o p ` + @",
                    "{caps} a s d f g h j k l ñ ´ ç {enter}",
                    "{shiftleft} < z x c v b n m , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "º 1 2 3 4 5 6 7 8 9 0 ' ¡ {backspace}",
                    "Q W E R T Y U I O P ` + @",
                    "{caps} A S D F G H J K L Ñ ´ Ç {enter}",
                    "{shiftleft} < Z X C V B N M , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "ª ! \" · $ % & / ( ) = ? ¿ {backspace}",
                    "Q W E R T Y U I O P ^ * @",
                    "{caps} A S D F G H J K L Ñ ¨ Ç {enter}",
                    "{shiftleft} > Z X C V B N M ; : _ {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "\\ | @ # ¢ ∞ ¬ ÷ “ ” ≠ ´ ‚ {backspace}",
                    "œ æ € ® † ¥ {n} {n} ø π [ ] {h}",
                    "{caps} å ∫ ∂ ƒ  ™ ¶ § {n} ~ { } {enter}",
                    "{shiftleft} ≤ Ω ∑ © √ ß {n} µ „ … – {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            pt: {
                'default': [
                    "§ 1 2 3 4 5 6 7 8 9 0 ' + {backspace}",
                    "q w e r t y u i o p º ´ @",
                    "{caps} a s d f g h j k l ç ~ \\ {enter}",
                    "{shiftleft} < z x c v b n m , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "§ 1 2 3 4 5 6 7 8 9 0 ' + {backspace}",
                    "Q W E R T Y U I O P º ´ @",
                    "{caps} A S D F G H J K L Ç ~ \\ {enter}",
                    "{shiftleft} < Z X C V B N M , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "± ! \" # $ % & / ( ) = ? * {backspace}",
                    "Q W E R T Y U I O P ª ` @",
                    "{caps} A S D F G H J K L ç Ç ^ | {enter}",
                    "{shiftleft} > Z X C V B N M ; : _ {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "§  @ € £ ‰ ¶ ÷ [ ] ≠ § ± {backspace}",
                    "œ ∑ æ ® ™ ¥ † ı ø π ° ¨ {h}",
                    "{caps} å ß ∂ ƒ ˙ ˇ ¯ „ ‘ ¸ ˜ ‹ {enter}",
                    "{shiftleft} ≤ Ω « © √ ∫ ¬ µ “ … — {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            de: {
                'default': [
                    "^ 1 2 3 4 5 6 7 8 9 0 ß ´ {backspace}",
                    "q w e r t y u i o p ü + @",
                    "{caps} a s d f g h j k l ö ä # {enter}",
                    "{shiftleft} < z x c v b n m , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "^ 1 2 3 4 5 6 7 8 9 0 ß ´ {backspace}",
                    "Q W E R T Y U I O P Ü + @",
                    "{caps} A S D F G H J K L Ö Ä # {enter}",
                    "{shiftleft} < Z X C V B N M , . - {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "° ! \" § $ % & / ( ) = ? ` {backspace}",
                    "Q W E R T Y U I O P Ü * @",
                    "{caps} A S D F G H J K L Ö Ä ' {enter}",
                    "{shiftleft} > Z X C V B N M ; : _ {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "„ ¡ “ ¶ ¢ [ ] | { } ≠ ¿ ' {backspace}",
                    "« ∑ € ® † Ω ¨⁄ ø π • ± {h}",
                    "{caps} å ‚ ∂ ƒ © ª º ∆ @ œ æ ‘ {enter}",
                    "{shiftleft} ≤ ¥ ≈ ç √ ∫ ~ µ ∞ … – {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            ro: {
                'default': [
                    "Ș 1 2 3 4 5 6 7 8 9 0 _ = {backspace}",
                    "q w e r t y u i o p ă â @",
                    "{caps} a s d f g h j k l ; ' ț {enter}",
                    "{shiftleft} î z x c v b n m , . / {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "Ș 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                    "Q W E R T Y U I O P Ă Â @",
                    "{caps} A S D F G H J K L ; ' Ț {enter}",
                    "{shiftleft} Î Z X C V B N M , . / {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "Ș ! @ # $ % ^ & * ( ) _ + {backspace}",
                    "Q W E R T Y U I O P Ă Â @",
                    "{caps} A S D F G H J K L : \" Ț {enter}",
                    "{shiftleft} Î Z X C V B N M < > ? {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "` ¡ ™ £ ¢ ∞ § ¶ • ª º – ≠ {backspace}",
                    "œ ∑ ´ ´ † ` ¨ ˆ ˆ π [ ] {h}",
                    "{caps} å ß ∂ ƒ © ˙ ∆ ˚ ¬ … ‘ \\ {enter}",
                    "{shiftleft} ș Ω ≈ ç √ ∫ ˜ µ ≤ ≥ µ {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            ru: {
                'default': [
                    "ё 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                    "й ц у к е н г ш щ з х ъ @",
                    "{caps} ф ы в а п р о л д ж э \\ {enter}",
                    "{shiftleft} ] я ч с м и т ь б ю . {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "Ё 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                    "Й Ц У К Е Н Г Ш Щ З Х Ъ @",
                    "{caps} Ф Ы В А П Р О Л Д Ж Э \\ {enter}",
                    "{shiftleft} ] Я Ч С М И Т Ь Б Ю . {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "Ё ! \" № ; % : ? * ( ) _ + {backspace}",
                    "Й Ц У К Е Н Г Ш Щ З Х Ъ @",
                    "{caps} Ф Ы В А П Р О Л Д Ж Э / {enter}",
                    "{shiftleft} [ Я Ч С М И Т Ь Б Ю , {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "§ ! @ # $ © ^ & ₽ ( ) – ≠ {backspace}",
                    "ј џ ў ќ † њ ѓ ѕ ' ‘ “ « {h}",
                    "{caps} d z ћ ÷ … • ∆ љ l « є \\ {enter}",
                    "{shiftleft} ` ђ x c v і ƒ m ≤ ≥ ї {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
            cn: {
                'default': [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                    "q w e r t y u i o p [ ] \\ @",
                    "{caps} a s d f g h j k l ; , {enter}",
                    "{shiftleft} z x c v b n m , . / {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'caps': [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {backspace}",
                    "Q W E R T Y U I O P [ ] \\ @",
                    "{caps} A S D F G H J K L ; , {enter}",
                    "{shiftleft} Z X C V B N M , . / {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'shift': [
                    "~ ! @ # $ % ^ & * ( ) _ + {backspace}",
                    "Q W E R T Y U I O P { } | @",
                    "{caps} A S D F G H J K L : \" {enter}",
                    "{shiftleft} Z X C V B N M < > ? {shiftright}",
                    "{alt} {space} {alt}"
                ],
                'alt': [
                    "~ ¡ ² ³ ¤ € ¼ ½ ¾ ‘ ’ ¥ × {backspace}",
                    "ä å é ® þ ü ú í ó ö « » ¬ {h}",
                    "{caps} á ß ð f g h j k ø ¶ ´ {enter}",
                    "{shiftleft} æ x © v b ñ µ ç > ¿ {shiftright}",
                    "{alt} {space} {alt}"
                ],
            },
        }

        this.keyboard = new Keyboard(this.$refs.keyboard, { //this.keyboardClass, {
            debug: false,
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            mergeDisplay: true,
            useButtonTag: true,
            disableButtonHold: true,
            physicalKeyboardHighlight: true,
            physicalKeyboardHighlightPress: true,
            layoutName: "default",
            layout: layouts[this.api.language],
            display: {
                "{backspace}": "⌫",
                "{enter}": "↵",
                "{caps}": "⇪",
                "{shiftleft}": "⇧",
                "{shiftright}": "⇧",
                "{space}": "_____",
                "{alt}": "⌥",
                "{n}": " ",
                "{h}": " "
            }
        });
    },
    destroyed() {
        this.keyboard.destroy();
    },
    methods: {
        setInput(input) {
            this.keyboard.setInput(input);
            this.$emit("onChange", input);
        },
        onChange(input) {
            this.$emit("onChange", input);
        },

        onKeyPress(button) {
            this.$emit("onKeyPress", button);
            /**
             * If you want to handle the shift and caps lock buttons
             */
            //const layout = this.keyboard.options.layoutName;

            if (button === "{caps}") this.handleLayout("default", "caps");
            if (button === "{shift}" || button === "{shiftleft}" || button === "{shiftright}") this.handleLayout("default", "shift");
            if (button === "{alt}") this.handleLayout("default",  "alt");

            if (button === "{enter}")
                this.onKeyboardSend();

            if (button === "{backspace}") {
                this.setInput(this.input.slice(0, -1));
            }

        },
        handleLayout(current, next) {
            const currentLayout = this.keyboard.options.layoutName;
            const layout = currentLayout === current ? next : current;
            this.keyboard.setOptions({
                layoutName: layout
            });
        },
        onEnableMic() {
            this.listenVoice = true;
            this.features.status.command = false;
        },
        onDisableMic() {
            this.listenVoice = false;
            this.features.status.command = true;
        },
        onStartRecognizeVoice() {
            if (!this.$device.isAndroid)
                this.$sound.play('micIn', this.features.settings.volume);

            this.recognizeVoice = true;
            this.features.status.command = false;
            this.onRecognizeText({ text: '', final: false, send: false});
        },
        onStopRecognizeVoice() {
            if (!this.$device.isAndroid)
                this.$sound.play('micOut', this.features.settings.volume);

            this.listenVoice = false;
            this.recognizeVoice = false;
            this.features.status.command = true;
        },
        onRecognizeText(recognized) {
            this.features.status.command = false;
            this.setInput(recognized.text);
        },
        onGps() {
            this.setInput(this.gps);
        },
        onClear() {
            this.setInput('');
        },
        onKeyboardSend() {
            this.$emit('onKeyboardSend');
        }
    },
    watch: {
        'input': {
            handler(newVal) {
                this.keyboard.setInput(newVal);
            }
        }
    }
};
</script>

<style lang="less">

.virtual-keyboard {

    margin: 0 0 10px 0;
    padding: 10px;
    box-sizing: border-box;

    background-color: var(--bg-other-color);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);

    .keyboard-input {
        position: relative;
        height: fit-content;
        min-height: 30px;
        width: 100%;
        bottom: 0;
        text-align: center;
        color: var(--primary-color);
        font-size: var(--font-14);
    }

    .keyboard-input {
        line-height: 30px;
        background-color: var(--bg-keyboard-other-color);
        border-radius: var(--border-radius);
        text-align: left;
        padding: 0 72px 0 32px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .keyboard-input-tools {
        position: absolute;
        right: 6px;
        top: 0px;

        .keyboard-input-item {
            margin-left: 6px;
        }
    }

    .keyboard-input-tools-left {
        position: absolute;
        left: 6px;
        top: 0px;

        .keyboard-input-item {
            margin-right: 6px;
        }
    }

    .keyboard-input-item[disabled=disabled], .keyboard-input-item:disabled {
        opacity: 0.3;
    }

    .keyboard-blink:after {
        content: "_";
        color: var(--primary-color);
        text-decoration: blink;
        -webkit-animation-name: blink;
        -webkit-animation-duration: 0.6s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-direction: alternate;
    }

    @-webkit-keyframes blink {
        from {
            opacity: 0.0;
        }
        to {
            opacity: 1.0;
        }
    }
}

/**
 * simple-keyboard
 * Theme: hg-theme-default
 */
.hg-theme-default {
    //width: 100%;
    user-select: none;
    box-sizing: border-box;
    touch-action: manipulation;
    overflow: visible;

    .hg-button span {
        pointer-events: none;
    }

    .hg-row {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 5px;
        }

        .hg-button:not(:last-child) {
            margin-right: 5px;
        }

        .hg-button-container {
            margin-right: 5px;
        }

        & > div:last-child {
            margin-right: 0;
        }

        .hg-button-container {
            display: flex;
        }
    }

    .hg-button {
        display: inline-block;
        flex-grow: 1;
        height: 25px;
        padding: 5px;
        outline: 0;

        align-items: center;
        justify-content: center;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        font-size: 13px;
        font-weight: 400;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0);

        background: var(--bg-keyboard-color);
        color: var(--fg-button-color);
        filter: drop-shadow(var(--box-shadow));
        cursor: pointer;

        &.hg-standardBtn {
            width: 20px;
        }

        &.hg-activeButton {
            border: 1px solid var(--primary-color);
            background: var(--suggested-color);
        }
    }

    .hg-button.hg-functionBtn {
        filter: drop-shadow(var(--box-shadow));
        background: var(--bg-keyboard-other-color);
    }

    .hg-button.hg-button-space {
        background: var(--bg-keyboard-color);
        width: 79%;
    }
    .hg-button.hg-button-backspace {
        width: 30px;
    }

    .hg-button.hg-button-enter {
        width: 30px;
    }

    .hg-button.hg-button-caps {
        width: 20px;
    }

    .hg-button.hg-button-shiftleft,
    .hg-button.hg-button-shiftright {
        width: 20px;
    }

    .hg-button.hg-button-alt {
        width: 20px;
    }

    .hg-button.hg-selectedButton {
        background: rgba(5, 25, 70, 0.53);
        color: white;
    }

    .hg-button.hg-button-n {
        width: 20px;
        background: transparent;
        box-shadow: unset;
        border: 1px solid #00000030;
        pointer-events: none;
    }

    .hg-button.hg-button-h {
        width: 30px;
        background: transparent;
        box-shadow: unset;
        border: 0;
        pointer-events: none;
    }

}

</style>
