export default {

    icons: {
        qrCode: '<img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiICBzdGFuZGFsb25lPSJubyI/Pg0KPHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiINCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSINCiAgICB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+DQogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIzMDAiIGhlaWdodD0iNDIyIiBzdHJva2U9Im5vbmUiIGZpbGw9IiNGRkYiLz4NCjxnIHRyYW5zZm9ybT0ic2NhbGUoLjUsLjUpIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMiI+DQogIDxwYXRoIGlkPSJBIiBkPSJNMywzIGg4IHY4IGgtOCB2LTggeiBNNiw3IGgyIi8+DQogIDx1c2UgeGxpbms6aHJlZj0iI0EiIHg9IjE4IiB5PSIwIiAvPg0KICA8dXNlIHhsaW5rOmhyZWY9IiNBIiB4PSIwIiB5PSIxOCIgLz4NCiAgPHBhdGggZD0iTTE4LDMgaC0zIHY0IGgyIHY0IGgtMiB2MyIgLz4NCiAgPHBhdGggZD0iTTIsMTUgaDIgbTIsMCBoNCBtNiwwIGgyIG0yLDAgaDQgbTQsMCBoMiIvPg0KICA8cGF0aCBkPSJNNCwxNyBoMiBtMiwwIGg4IG02LDAgaDIgbTIsMCBoNCIgLz4NCiAgPHBhdGggZD0iTTE0LDE5IGgyIG0yLDAgaDIgbTYsMCBoMiIgLz4NCiAgPHBhdGggZD0iTTE2LDIxIGgyIG0yLDAgaDgiLz4NCiAgPHBhdGggZD0iTTE0LDIzIGgyIG0yLDAgaDIgbTQsMCBoMiBtMiwwIGgyIiAvPg0KICA8cGF0aCBkPSJNMTQsMjUgaDQgbTIsMCBoMiBtMiwgMCBoNCIgLz4NCiAgPHBhdGggZD0iTTE0LDI3IGgyIG02LDAgaDIgbTQsMCBoMiIgLz4NCiAgPHBhdGggZD0iTTE2LDI5IGg4IG0yLDAgaDIiLz4NCiAgPC9nPg0KPC9zdmc+DQo=">',
    },

    isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    },

    mergeDeep(target, source) {
        let output = Object.assign({}, target);
        if (this.isObject(target) && this.isObject(source)) {
            Object.keys(source).forEach(key => {
                if (this.isObject(source[key])) {
                    if (!(key in target))
                        Object.assign(output, {[key]: source[key]});
                    else
                        output[key] = this.mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(output, {[key]: source[key]});
                }
            });
        }
        return output;
    },

    isHttps() {
        return (location.protocol === 'https:' || location.protocol === 'file:' || location.hostname === 'localhost');
    }
};
