export default {

    install(Vue, options) {

        function log(type, text, args) {
            const formatted = (args || []).map((a) => JSON.stringify(a)).join(" ");
            const colorInfo = 'color: #009900';
            const colorDebug = 'background: #222; color: #ffff66';
            const colorError = 'color: #ff3300';
            const colorWarn = 'color: #ff6600';

            let full = true;
            if (!/(chrome|android)/i.test(navigator.userAgent) && /(safari|applewebkit)/i.test(navigator.userAgent)) {
                full = false;
            }

            if (type === 'debug' && typeof text === 'object') {
                if (options.mode === 'debug')
                    console.table(text);
            } else {
                if (type === 'info') {
                    if (options.mode === 'info' || options.mode === 'debug') {
                        if (full)
                            console.log(`[${options.name}] ${type} - %c${text}`, colorInfo, formatted);
                        else
                            console.log(`[${options.name}] ${type} - ${text}`, formatted);
                    }

                } else if (type === 'debug') {
                    if (options.mode === 'debug') {
                        if (full)
                            console.log(`[${options.name}] ${type} - %c${text}`, colorDebug, formatted);
                        else
                            console.log(`[${options.name}] ${type} - ${text}`, formatted);
                    }
                } else if (type === 'warn') {
                    if (options.mode === 'warn' || options.mode === 'info' || options.mode === 'debug') {
                        if (full)
                            console.log(`[${options.name}] ${type} - %c${text}`, colorWarn, formatted);
                        else
                            console.log(`[${options.name}] ${type} - ${text}`, formatted);
                    }
                } else if (type === 'error') {
                    if (full)
                        console.log(`[${options.name}] ${type} - %c${text}`, colorError, formatted);
                    else
                        console.log(`[${options.name}] ${type} - ${text}`, formatted);
                }
            }
        }

        function set_mode(mode) {
            options.mode = mode;
        }

        Vue.$log = Vue.prototype.$log = {
            setMode(mode) { set_mode(mode); },
            error(text, ...args) { log('error', text, args) },
            warn(text, ...args) { log('warn', text, args) },
            info(text, ...args) { log('info', text, args) },
            debug(text, ...args) { log('debug', text, args) },
            table(text, ...args) { log('debug', text, args) },
            log
        }
    }
}
