<template>
    <div v-if="false"></div>
</template>

<script>

export default {
    name: "SttChrome",
    props: {
        listenVoice: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            textInput: '',
            recognition: null,
            languageMap: {
                'it': 'it-IT',
                'en': 'en-US',
                'es': 'es-ES',
                'pt':'pt-PT',
                'fr': 'fr-FR',
                'de': 'de-DE',
                'ro': 'ro-RO',
                'zh': 'zh-CN',
                'ru': 'ru-RU',
            }
        }
    },
    watch: {
        listenVoice: {
            handler(newVal) {
                if (newVal)
                    this.startRecognizeVoice();
                else
                    this.stopRecognizeVoice();
            },
            deep: true
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        startRecognizeVoice() {

            if ('webkitSpeechRecognition' in window) {

                const self = this;

                this.recognition = new window.webkitSpeechRecognition();
                this.recognition.continuous = false;
                this.recognition.interimResults = true;
                this.recognition.lang = this.languageMap[this.api.language];
                this.recognition.start();
                this.$log.debug('Chrome recognition start');


                this.recognition.onstart = function () {
                    self.$log.debug('Chrome recognition started');
                    self.inputText = '';
                    self.$emit('onStartRecognizeVoice');
                };

                this.recognition.onerror = function (event) {
                    self.$log.error('Chrome recognition error', event.error);
                    self.recognition = null;
                    self.$emit('onStopRecognizeVoice');
                };

                this.recognition.onresult = (event) => {
                    let text = '';
                    let final = false;
                    let confidence = 0;

                    for (let i = event.resultIndex; i < event.results.length; ++i) {
                        text += event.results[i][0].transcript;
                        confidence = event.results[i][0].confidence;

                        if (event.results[i].isFinal) {
                            final = true;
                            break;
                        }
                    }

                    self.textInput = text;
                    self.$log.debug('Chrome recognized', text, confidence);

                    self.$emit('onRecognizeText', {text, confidence, final, send: false});

                }
                this.recognition.onend = () => {
                    self.$log.debug('Chrome recognition ended');
                    self.recognition = null;
                    self.$emit('onStopRecognizeVoice');
                }
            }
        },
        stopRecognizeVoice() {
            if ('webkitSpeechRecognition' in window) {
                if (this.recognition != null) {
                    this.recognition.abort();
                    this.recognition.stop();
                    this.recognition = null;
                    this.$emit('onRecognizeText', {text: '', confidence: 0, final: true, send: false});
                }
            }
        },
    }
}
</script>

<style>

</style>
