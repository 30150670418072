<template>
    <div class="chat-browser">
        <template v-if="!features.kiosk">
            <ButtonIcon v-if="urlClose" name="close" class="chat-browser-close rounded" @click="onClose"/>
            <iframe ref="iframe" class="chat-browser-iframe" src="about:blank" @load="onLoad" title=""></iframe>
        </template>
        <template v-else>
            <Panel :features="features" :border="false" @onClose="onClose">
                <div slot="powered"></div>
                <div slot="body" class="chat-browser-body">
                    <iframe ref="iframe" class="chat-browser-iframe" src="about:blank" @load="onLoad" title=""></iframe>
                </div>
            </Panel>
        </template>
    </div>
</template>

<script>

import ButtonIcon from './chat/ButtonIcon';
import Panel from './panels/Panel';



export default {
    name: "Browser",
    components: {
        ButtonIcon,
        Panel
    },
    props: {
        url: {
            type: String,
            required: true
        },
        urlClose: {
            type: Boolean,
            required: true
        },
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            contentWindow: null
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        this.navigate(this.url);
        document.body.style.overflow = 'hidden';
    },
    beforeDestroy() {
        document.title = this.features.backup.currentTitle;
        document.body.style.overflow = this.features.backup.bodyOverflow;
        this.features.browser.window = null;
        this.api.currentUrl = this.features.backup.currentUrl;
    },
    methods: {
        useHistory() {
            return this.$refs.iframe.contentWindow.location.href !== 'about:blank';
        },
        navigate(url) {
            this.$refs.iframe.contentWindow.location.replace(url);
            if (this.useHistory())
                history.replaceState({}, null, url);
        },
        onLoad() {
            let success = false;
            try {
                if (this.$refs.iframe.contentWindow.location.href)
                    success = true;

            } catch (e) {
                this.$log.warn('The interaction with the navigation is limited');
                this.$log.warn(e.message);
            }

            if (success) {
                const currentUrl = this.$refs.iframe.contentWindow.location.href;
                const currentTitle = this.$refs.iframe.contentWindow.document.title;

                this.$log.debug('Browser loaded url', currentUrl);
                this.api.currentUrl = currentUrl;
                this.contentWindow = this.$refs.iframe.contentWindow;
                this.features.browser.window = this.contentWindow;
                this.contentWindow.document.addEventListener('click', this.interceptClick);

                document.title = currentTitle;
                if (this.useHistory())
                    history.replaceState({}, null, currentUrl);

                this.$emit('onBrowserLoaded', currentUrl);
            }
        },
        interceptClick(e) {
            const self = this;
            const target = e.target;
            const a = target.closest('a');
            if (a != null) {
                const href = target.getAttribute('href');

                if (href != null && href.charAt(0) === "#") {
                    return;
                }

                const url = new URL(href, document.baseURI);

                if (url.host !== self.contentWindow.location.host) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    e.stopPropagation();
                    window.open(href, '_blank');
                    return;
                } else {
                    if (this.useHistory())
                        history.replaceState({}, null, href);
                }
            }
        },
        onClose() {
            this.$emit('onBrowserClosed');
        }
    }
}
</script>

<style lang="less">
.chat-browser {
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
    background-color: white;

    .chat-browser-body {
        width: 100%;
        height: 100%;
        padding: 0 15px 15px 15px;
        box-sizing: border-box;
    }

    .chat-browser-iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
    .chat-browser-close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
