<template>
    <ul class="message-multimedia-content">
        <li class="message-multimedia-body">
            <slot name="body"></slot>
        </li>
        <li class="message-multimedia-items">
            <div class="message-multimedia-item" @click="onClose">{{$translate(api.language, 'kiosk.multimedia.close')}}</div>
            <div class="message-multimedia-item" @click="onToggle">{{$translate(api.language, 'kiosk.multimedia.expand')}}</div>
        </li>
    </ul>
</template>

<script>

export default {
    name: "KioskMedia",
    components: {

    },
    props: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onToggle() {
            this.$emit("onToggle");
        },
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.message-multimedia {
    .message-multimedia-content {
        display: flex;
        flex-direction: column;
        list-style-type: none;

        padding: 0;
        margin: 0;

        color: var(--fg-other-color);
        background-color: var(--bg-other-color);
        box-shadow: var(--box-shadow);
        backdrop-filter: var(--backdrop-filter);
        border-radius: var(--border-radius);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: var(--font-12);
        line-height: calc(var(--font-12) + 3px);

        text-align: center;

        .message-multimedia-body {
            padding: 4px 4px 4px 4px;
            border-bottom: 1px solid var(--border-other-color);

            img, iframe, video {
                border-radius: unset;
                box-shadow: unset;
            }
        }

        .message-multimedia-items {
            display: flex;
            flex-direction: row;
            border-bottom: 0;

            .message-multimedia-item {
                position: relative;
                color: var(--fg-other-color);
                border-right: 1px solid var(--border-other-color);
                width: 50%;
                padding: 8px 12px 9px 12px;
                cursor: pointer;

                &.w-100 {
                    width: 100%;
                    border-right: unset;
                }

                &:last-child {
                    border-right: 0;
                }

                &:hover {
                    color: var(--primary-color-text);
                }
            }
        }
    }
}

</style>
