<template>
    <div v-if="message.enabled" class="message-form">

        <div v-if="!features.kiosk" class="form-field-location">

            <input ref="input" type="text" :class="{'input-geolocation': hasGeolocation}" v-model="value" :placeholder="placeholder" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" @keyup="onKeyboardSend">

            <div v-if="hasGeolocation" class="box-gps">
                <Icon v-if="!ready" name="gps_off" class="icon-gps" size="20px" :content="$translate(api.language, 'location.disabled')" v-tippy="{ placement : 'left',  appendTo: container }" @click="onReadLocation" />
                <Icon v-if="ready && !loading" name="gps" class="icon-gps" size="20px" :content="$translate(api.language, 'location.activate')" v-tippy="{ placement : 'left',  appendTo: container }" @click="onReadLocation" />
                <div v-if="loading" class="icon-gps-loading">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid">
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" opacity="0.3" >
                        </circle>
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" stroke-dasharray="10 50">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 10 10;360 10 10" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                </div>
            </div>
        </div>

        <div v-if="features.kiosk" class="keyboard-message-manager">
            <KioskKeyboard :features="features" @onChange="onKeyboardChange" @onKeyPress="onKeyboardKeyPress" :input="value" :gps="features.gps.location"/>
        </div>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import KioskKeyboard from "../../KioskKeyboard";
import InputMode from "@/classes/InputMode";
import Icon from "../Icon";
import Helpers from "../../../classes/Helpers";

export default {
    name: "FieldLocation",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        KioskKeyboard,
        Icon
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            value: '',
            loading: false,
            ready: false
        };
    },
    computed: {
        disabled() {
            return (this.value === '');
        },
        placeholder() {
            return '';
        },
        hasGeolocation() {
            return Helpers.isHttps() && navigator.geolocation;
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        const self = this;
        this.$nextTick(() => {
            if (this.$refs.input !== undefined)
                this.$refs.input.focus();
        });

        if (this.hasGeolocation) {
            if (navigator.permissions !== undefined) {
                //https://developer.mozilla.org/en-US/docs/Web/API/Navigator/permissions
                navigator.permissions.query({name: 'geolocation'}).then(function (result) {
                    self.$log.info('Geolocation state', result.state);
                    if (result.state === 'granted') {
                        self.ready = true;
                    } else if (result.state === 'prompt') {
                        self.ready = true;
                    } else if (result.state === 'denied') {
                        self.ready = false;
                    }
                    result.onchange = function () {
                        self.$log.info('Geolocation state', result.state);
                        self.ready = result.state !== 'denied';
                    }
                });
            } else {
                this.ready = true;
            }
        }

    },
    methods: {
        onInputClicked() {
            const value = {
                inputMode: InputMode.KEYBOARD,
                optionValue: this.value,
                optionText: this.value,
            };
            this.$emit("onSendCommand", value);
        },
        onSendCommand(value) {
            this.$emit("onSendCommand", value);
        },
        onKeyboardSend(event) {
            //fix iOS
            //https://github.com/vuejs/vuejs.org/issues/394
            if (event.key === 'Enter') {
                if (this.value.length > 0) {
                    this.onInputClicked();
                }
            }
        },
        onReadLocation() {
            if (this.ready) {
                const options = {
                    enableHighAccuracy: true,
                    timeout: 20000
                };
                this.loading = true;
                navigator.geolocation.getCurrentPosition(this.calculateGpsAddress, this.gpsError, options);
            } else {
                this.$log.error('Geolocation is not supported by this browser.');
            }
        },
         calculateGpsAddress(position) {
             const self = this;
             this.$log.info("Latitude: " + position.coords.latitude + ", Longitude: " + position.coords.longitude);

             if (position.coords.latitude === 0 && position.coords.longitude === 0) {
                 this.value = '';
                 this.loading = false;
                 return null;
             }

             this.api.getLocation(position.coords.latitude, position.coords.longitude).then((result) => {
                 self.value = result;
                 self.loading = false;
             });
         },
        gpsError(error) {
            this.loading = false;

            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.$log.error("User denied the request for Geolocation.");
                    this.ready = false;
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.$log.error("Location information is unavailable.");
                    break;
                case error.TIMEOUT:
                    this.$log.error("The request to get user location timed out.");
                    break;
                case error.UNKNOWN_ERROR:
                    this.$log.error("An unknown error occurred.");
                    break;
            }
        },
        onKeyboardChange(input) {
            this.value = input;
        },
        onKeyboardKeyPress(button) {
            this.$log.debug("button", button);

            if (button === "{ent}") {
                if (this.value.length > 0) {
                    this.onInputClicked();
                }
            }

        },

    }
}
</script>

<style lang="less">

.message-form {
    .form-field-location {
        position: relative;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        width: 100%;

        .input-geolocation {
            padding: 5px 30px 5px 10px;
        }

        & > input {
            & {
                color: var(--primary-color-text);
                width: 100%;
                height: 24px;
                padding: 5px 10px;
                border-radius: var(--border-radius);
                border: 1px solid var(--border-other-color);
                background-color: var(--bg-color-card);
                font-size: var(--font-12);
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);
            }

            &:focus {
                outline: 0;
            }

        }

        .box-gps {
            position: absolute;
            right: 8px;
            top: 8px;

            .icon-gps {
                color: var(--fg-button-color);
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }

        .icon-gps-loading {
            color: var(--primary-color);
        }


    }
}
</style>
