<template>
    <div v-if="online" class="algho" ref="app"
         :class="{
                'noselect': this.disableSelect || features.kiosk,
                'dhi': features.dhi,
                'mobile': $device.isMobileOnly,
                'desktop': !features.kiosk && !$device.isMobileOnly,
                'widget': features.widget,
                'fullscreen': features.status.fullscreen,
                'not-fullscreen': !features.status.fullscreen,
                'transparent': features.transparent && !features.status.fullscreen,
                'floating': features.floating && !features.status.fullscreen,
                'inline': features.inline && !features.status.fullscreen,
                'kiosk': features.kiosk && features.dhi,
                'standby': standby,
                'last-chat': features.showLastMessage,
                'full-chat': !features.showLastMessage,
                'browser-mode': url != null,
                'debug': this.debug
    }">

        <Api ref="api" :features="features" />

        <template v-if="loaded">

            <Browser ref="browser" v-if="url != null" :url="url" :urlClose="urlClose" :features="features" @onBrowserLoaded="onBrowserLoaded" @onBrowserOpen="onBrowserOpen" @onBrowserClosed="onBrowserClosed" />

            <Avatar ref="avatar" v-if="features.dhi" v-visible="features.status.opened || features.preview"
                    :features="features"
                    :playVoice="playVoice"
                    :playCount="playCount"
                    :listenVoice="listenVoice"
                    :audioVoice="audioVoice"
                    :recognizeVoice="recognizeVoice"
                    :recognized="recognized"
                    :receiveData="receiveData"
                    :allowFullscreen="allowFullscreen"
                    :messageRead="messageRead"
                    :empathy="empathy"
            />

            <template v-if="!features.preview">

                <SttChrome v-if="hasChromeStt"
                    :listenVoice="listenVoice"
                    @onStartRecognizeVoice="onStartRecognizeVoice"
                    @onStopRecognizeVoice="onStopRecognizeVoice"
                    @onRecognizeText="onRecognizeText"
                />

                <SttAlgho v-if="!hasChromeStt && !demo"
                    :listenVoice="listenVoice"
                    @onStartRecognizeVoice="onStartRecognizeVoice"
                    @onStopRecognizeVoice="onStopRecognizeVoice"
                    @onRecognizeText="onRecognizeText"
                />

                <SttChromeCommand v-if="hasChromeStt && features.command && features.status.opened && !features.device.mobile"
                                  :features="features"
                                  :enabled="features.status.command"
                                  @onRecognizeCommand="onRecognizeCommand"
                />

                <Command v-if="features.commandOffline && features.status.opened && !features.device.mobile"
                         :features="features"
                         :enabled="features.status.command"
                />

                <transition name="fade" appear>
                    <Widget v-if="features.widget && !widgetOld" v-show="!features.status.opened"
                                :features="features"
                                @onShow="onShow"
                    />
                    <WidgetOld v-if="features.widget && widgetOld" v-show="!features.status.opened"
                            :features="features"
                            @onShow="onShow"
                    />
                </transition>

                <KioskBanner v-if="features.kiosk" />

                <transition name="fade" appear>
                    <KioskWelcomeVideo v-if="features.kiosk && standby" />
                </transition>

                <KioskNotch v-if="features.kiosk"
                            :features="features"
                            :standby="standby"
                            :webcam="webcam"
                            @onShowSetting="onShowSetting"
                />

                <transition name="fade" appear>
                    <Chat v-if="!standby" ref="chat" v-show="!kioskData.countdown && features.status.opened"
                                :features="features"
                                :listenVoice="listenVoice"
                                :audioVoice="audioVoice"
                                :recognizeVoice="recognizeVoice"
                                :recognized="recognized"
                                :webcamData="webcamData"
                                :playVoice="playVoice"
                                :playCount="playCount"
                                :commandVoice="commandVoice"
                                @onClose="onClose"
                                @onToggleFullScreen="onToggleFullScreen"
                                @onStartVoice="onStartVoice"
                                @onStopVoice="onStopVoice"
                                @onCountVoice="onCountVoice"
                                @onEnableMic="onEnableMic"
                                @onDisableMic="onDisableMic"
                                @onEnableAudio="onEnableAudio"
                                @onDisableAudio="onDisableAudio"
                                @onStartRecognizeVoice="onStartRecognizeVoice"
                                @onStopRecognizeVoice="onStopRecognizeVoice"
                                @onSendData="onSendData"
                                @onReceiveData="onReceiveData"
                                @onMessageRead="onMessageRead"
                                @onTalking="onTalking"
                                @onBrowserOpen="onBrowserOpen"
                                @onBrowserClosed="onBrowserClosed"
                                @onToggleMode="onToggleMode"
                                @onRecognizeWebcamData="onRecognizeWebcamData"
                                @onStanbySession="onStanbySession"
                    />
                </transition>

                <KioskSession v-if="features.kiosk"
                              :kioskData="kioskData"
                              :talking="talking"
                              :session="totem.session"
                              :standby="standby"
                              @onResetSession="onResetSession"
                              @onStanbySession="onStanbySession" />

                <WebCam v-if="features.kiosk && features.has.webcam && !features.device.mobile" :features="features" />

            </template>

            <Stats v-if="features.stats" :features="features" :kioskData="kioskData" />

        </template>

    </div>
</template>

<script>

import {version, description} from '../package.json';

const appVersion = version;
const appRelease = description;

let appUrl = window.alghojs;
if (appUrl === undefined) {
    appUrl = document.currentScript.src;
    window.alghojs = appUrl;
}

import Vue from "vue";

import Api from '@/components/Api.vue';

import Chat from '@/components/Chat.vue';
import Widget from '@/components/Widget.vue';
import WidgetOld from '@/components/WidgetOld.vue';
import Avatar from '@/components/Avatar.vue';
import SttChrome from '@/components/SttChrome.vue';
import SttChromeCommand from '@/components/SttChromeCommand.vue';
import SttAlgho from '@/components/SttAlgho.vue';
import Stats from '@/components/Stats.vue';
import Browser from "@/components/Browser.vue";
import KioskSession from '@/components/KioskSession.vue';
import KioskWelcomeVideo from "@/components/KioskWelcomeVideo.vue";
import KioskNotch from "@/components/KioskNotch.vue";
import KioskBanner from "@/components/KioskBanner.vue";
import WebCam from "@/components/chat/others/WebCam.vue";
import Command from "@/components/chat/others/Command.vue";

import Device from 'mobile-device-detect';

Vue.$device = Vue.prototype.$device = Device;
Vue.$common = Vue.prototype.$common = {};

import Smartphone from "@/classes/Smartphone";
Vue.use(Smartphone);

import Logger from '@/classes/Logger';
Vue.use(Logger, {name: 'ALGHO', mode: 'debug'}); //none, info, debug

import Track from '@/classes/Track';
Vue.use(Track);

import Lang from '@/classes/Lang';
Vue.use(Lang);

import Mime from '@/classes/Mime';
Vue.use(Mime);

import Cookies from "@/classes/Cookies";
Vue.use(Cookies);

import Sound from "@/classes/Sound";
Vue.use(Sound, appUrl);

import VueTippy, { TippyComponent } from "vue-tippy/src/index-nostyles";

const Color = require("tinycolor2");

Vue.use(VueTippy, {
    directive: "tippy",
    appendTo: 'parent',
    flipDuration: 0,
    delay: [1000, 1500],
    arrow: false,
    animateFill: false,
    hideOnClick: true, // if you want
    onShow(instance) {
        setTimeout(() => {
            instance.hide();
        }, instance.props.delay[1]);

        if (instance.props.delay[1] > 1500)
            instance.props.delay[1] = 1500;
    },
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false
            }
        }
    }
});
Vue.component("tippy", TippyComponent);

Vue.directive('visible', function(el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
});

import Helpers from "./classes/Helpers";
import {DateTime, Interval} from "luxon";

const StorageKeys = {
    SETTINGS: '_algho_settings',
    AUTOMATION: '_algho_automation',
    MODE: '_algho_mode'
};
const EventTypes = {
    READY: 'alghoReady',
    LOADED: 'alghoLoaded',
    MESSAGE: 'alghoMessage',
    MICROPHONE: 'alghoMicrophone'
}

export default {
    name: 'AppAlgho',
    components: {
        Api,
        Avatar,
        Chat,
        Widget,
        WidgetOld,
        SttChrome,
        SttChromeCommand,
        SttAlgho,
        Stats,
        Browser,
        KioskWelcomeVideo,
        KioskSession,
        KioskNotch,
        KioskBanner,
        WebCam,
        Command
    },
    props: {
        id: {
            type: String,
            required: false
        },
        apiUrl: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        apiPath: {
            type: String,
            required: false,
            default: () => {
                return '/api'
            }
        },
        wsUrl: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        botId: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        language: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        token: {
            type: String,
            required: false,
            default: () => {
                return null
            }
        },
        context: {
            type: String,
            required: false,
            default: () => {
                return null
            }
        },
        authorization: {
            type: String,
            required: false,
            default: () => {
                return null
            }
        },
        cookieDomain: {
            type: String,
            required: false,
            default: () => {
                return null
            }
        },
        currentUrl: {
            type: String,
            required: false,
            default: () => {
                return location.href
            }
        },
        userId: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        userIdPrefix: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        questionId: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        questionRepeat: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        groupId: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        open: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        openDelay: {
            type: Number,
            required: false,
            default: () => {
                return 0
            }
        },
        audio: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        voice: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        mic: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        sound: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        command: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        commandOffline: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        autoMicrophone: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        dhi: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        dhiConfig: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        colorConfig: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        transparent: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        floating: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        inline: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        widget: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        widgetIcon: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        widgetBottom: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        widgetRight: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        widgetLeft: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        widgetTop: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        widgetBorderRadius: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        widgetOld: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        tooltip: {
            type: String,
            required: false,
            default: () => {
                return null
            }
        },
        fullscreen: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        allowFullscreen: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        botName: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        botLogo: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        botIcon: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        zIndex: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        themeStyle: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        theme: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        themeInvert: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        color: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        dark: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        bgImage: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        bgVideo: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        model3d: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        config3d: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        avatarLite: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        chat: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        kiosk: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        standbyHours: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        session: {
            type: Number,
            required: false,
            default: () => {
                return 60
            }
        },
        stats: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        browser: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        home: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        autoNarration: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        autoHideMessage: {
            type: Number,
            required: false,
            default: () => {
                return 0
            }
        },
        groupLastMessage: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        hideLastMessage: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        dictatePreviewMessage:{
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        hideMobileSuggestion:{
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        scrollDownSuggestion:{
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        showAllSuggestion:{
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        iframe: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        disableSelect: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        hideSettings: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        hideInfos: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        livechatMenu :{
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
        preview :{
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        demo: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        editor: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        debug: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        iconSet: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        livechatPlugin: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        webcamPlugin: {
            type: String,
            required: false,
            default: () => {
                return '/js/plugins/algho-webcam.min.js'
            }
        },
        commandPlugin: {
            type: String,
            required: false,
            default: () => {
                return '/js/plugins/algho-command.min.js'
            }
        },
        webcam: {
            //usato solo per avviare la webcam all'avvio
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
        backend: {
            type: String,
            required: false,
            default: () => {
                return 'webgl'
            }
        },
        renderer: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        postprocessing: {
          type: String,
          required: false,
          default: () => {
            return ''
          }
        },
        pixelRatio: {
            type: Number,
            required: false,
            default: () => {
                return window.devicePixelRatio
            }
        },
        hdr: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        lutCube: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
        lutIntensity: {
            type: Number,
            required: false,
            default: () => {
                return 1.0
            }
        },
        log: {
            type: String,
            required: false,
            default: () => {
                return 'none'
            }
        },

        gpsLocation: {
            type: String,
            required: false,
            default: () => {
                return ''
            }
        },
    },
    data() {
        return {
            online: true,
            baseUrl: null,
            appVer: appVersion,

            loaded: false,
            standby: false,
            alreadyStarted: false,

            url: null,
            urlClose: false,

            totem: {
                session: this.session,
                webcam: this.webcam,
                standbyHours: this.standbyHours
            },

            features: {
                instance: null,
                demo: this.demo,
                preview: this.preview && this.dhi,
                https: Helpers.isHttps(),
                stt: this.voice && this.mic,
                tts: this.voice,
                sound: this.sound,
                command: this.command,
                commandOffline: this.commandOffline,
                autoMicrophone: this.autoMicrophone,
                kiosk: this.kiosk,
                dhi: this.dhi,
                cta: false,
                multibot: false,
                privacy: false,
                dhiConfig: this.dhiConfig,
                chat: this.chat,
                autoplay: false, //chrome.exe --autoplay-policy=no-user-gesture-required
                lipsync: false,
                fullscreen: this.fullscreen,
                allowFullscreen: this.allowFullscreen,
                transparent: this.transparent,
                floating: this.floating,
                inline: this.inline,
                widget: this.widget,
                tooltip: this.tooltip,
                open: this.open,
                openDelay: this.openDelay,
                showLastMessage: this.dhi,
                groupLastMessage: this.groupLastMessage && !this.hideLastMessage,
                hideLastMessage: this.hideLastMessage,
                hideMobileSuggestion: this.hideMobileSuggestion,
                scrollDownSuggestion: this.scrollDownSuggestion,
                showAllSuggestion: this.showAllSuggestion,
                dictatePreviewMessage: this.dictatePreviewMessage,
                questionId: this.questionId,
                questionRepeat: this.questionRepeat,
                orderId: null,
                stats: this.stats,
                pixelRatio: this.pixelRatio,
                kioskExit: false,
                renderer: this.renderer,
                postprocessing: this.postprocessing,
                themeStyle: this.themeStyle,
                //player start
                //showStart: false,
                //others
                has: {
                    //funzionalità da usare
                    dhi: false,
                    text: false,
                    voice: false,
                    kiosk: false,
                    livechat: false,
                    banner: false,
                    webcam: false,
                    multibot: false,
                    cta: false,
                    privacy: false,
                    switch: false,
                    //hideMessage: this.hideLastMessage
                },
                device: {
                    desktop: !this.$device.isMobileOnly,
                    mobile: this.$device.isMobileOnly,
                    slowMobile: this.$smartphone.slowDevices(),
                    iosMobile: this.$smartphone.iosDevices(),
                    chromeStt: 'webkitSpeechRecognition' in window && this.$device.isChrome && !this.$device.isIOS,
                },
                themes: {
                    dark: {
                        "primary-color": "#31b3f4",
                        "primary-color-text": "#31b3f4",
                        "suggested-color": "#31b3f44d",

                        "fg-color": "#ffffff",

                        "fg-color-widget": "#ffffff",
                        "bg-color-widget": "#31b3f4",

                        "fg-color-bot": "#ffffff",
                        "fg-color-user": "#ffffff",

                        "bg-color-user": "#5e6c80",
                        "bg-color-bot": "#40444d",

                        "bg-color": "#40444d",
                        "bg-color-gradient": "#292b31",
                        "bg-color-overlay": "#00000000",

                        "bg-color-card": "#404752",

                        "fg-other-color": "#FFFFFF",
                        "bg-other-color": "#3e4550",

                        "border-other-color": "#525e6e",

                        "fg-color-input": "#FFFFFF",
                        "border-color-input": "#525e6e",
                        "focus-color-input": "#FFFFFFA0", //non usato

                        "border-color": "#6e7585",

                        "caption-color": "#74767c",
                        "timestamp-color-user": "#a2aab8",
                        "timestamp-color-bot": "#74767c",

                        "fg-button-color": "#FFFFFF",
                        "fg-button-color-active": "#31b3f4",
                        "fg-button-color-deactive": "#d93025",
                        "fg-button-color-hover": "#31b3f4",
                        "fg-button-color-disabled": "#70798f",

                        "bg-button-color": "#40444d",
                        "bg-button-color-hover": "#353840",

                        "bg-keyboard-color": "#FFFFFF26",
                        "bg-keyboard-other-color": "#FFFFFF0F",

                        "fg-button-color-transparent": "#FFFFFF",
                        "bg-button-color-transparent": "#40444D",

                        "fg-tooltip-color": "#FFFFFF",
                        "bg-tooltip-color": "#353840",

                        "scroll-color": "#ffffff",

                        "border-radius-frame": "24px",
                        "border-radius": "16px",
                        "border-radius-small": "4px",  //new aggiungere tema

                        "backdrop-filter": "unset"
                    },
                    light: {
                        "primary-color": "#31b3f4",
                        "primary-color-text": "#31b3f4",
                        "suggested-color": "#31b3f44d",

                        "fg-color": "#667180",

                        "fg-color-widget": "#ffffff",
                        "bg-color-widget": "#31b3f4",

                        "fg-color-bot": "#515A66",
                        "fg-color-user": "#FFFFFF",

                        "bg-color-user": "#5E6C80",
                        "bg-color-bot": "#fcfcfc",

                        "bg-color": "#FFFFFF",
                        "bg-color-gradient": "#f5f6f7",
                        "bg-color-overlay": "#00000000",

                        "bg-color-card": "#e9ecf0",

                        "fg-other-color": "#5e6f87",
                        "bg-other-color": "#E9ECF0",

                        "border-other-color": "#D8DDE6",

                        "fg-color-input": "#515a66",
                        "border-color-input": "#c7cedb",
                        "focus-color-input": "#FFFFFFA0", //non usato

                        "border-color": "#FFFFFF",
                        "caption-color": "#A3B5CC",
                        "timestamp-color-user": "#B3C1D3",
                        "timestamp-color-bot": "#A3B5CC",

                        "fg-button-color": "#5e6c80",
                        "fg-button-color-active": "#31b3f4",
                        "fg-button-color-deactive": "#efdcbf",
                        "fg-button-color-hover": "#31b3f4",
                        "fg-button-color-disabled": "#a4b1c1",

                        "bg-button-color": "#FFFFFF",
                        "bg-button-color-hover": "#E9ECF0",

                        "bg-keyboard-color": "#FFFFFFD9",
                        "bg-keyboard-other-color": "#0000000F",

                        "fg-button-color-transparent": "#404752",
                        "bg-button-color-transparent": "#FFFFFF",

                        "fg-tooltip-color": "#5e6c80",
                        "bg-tooltip-color": "#E9ECF0",

                        "scroll-color": "#D8DDE6",

                        "border-radius-frame": "24px",
                        "border-radius": "16px",
                        "border-radius-small": "4px",

                        "backdrop-filter": "unset"
                    },
                    icy: {
                        "primary-color": "#31b3f4",
                        "primary-color-text": "#31b3f4",
                        "suggested-color": "#31b3f44d",

                        "fg-color": "#ffffff",

                        "fg-color-widget": "#ffffff",
                        "bg-color-widget": "#31b3f4",

                        "fg-color-bot": "#ffffff",
                        "fg-color-user": "#ffffff",

                        "bg-color-user": "#8181811D",
                        "bg-color-bot": "#8181811D",

                        "bg-color": "#40444d",
                        "bg-color-gradient": "#292b31",
                        "bg-color-overlay": "#00000000",

                        "bg-color-card": "#8181811D",

                        "fg-other-color": "#FFFFFF",
                        "bg-other-color": "#8181811D",

                        "border-other-color": "#FFFFFF50",

                        "fg-color-input": "#FFFFFF",
                        "border-color-input": "#FFFFFF50",
                        "focus-color-input": "#FFFFFFA0", //non usato

                        "border-color": "#6e7585",

                        "caption-color": "#74767c",
                        "timestamp-color-user": "#ffffff",
                        "timestamp-color-bot": "#ffffff",

                        "fg-button-color": "#FFFFFF",
                        "fg-button-color-active": "#31b3f4",
                        "fg-button-color-deactive": "#d93025",
                        "fg-button-color-hover": "#31b3f4",
                        "fg-button-color-disabled": "#70798f",

                        "bg-button-color": "#8181811D",
                        "bg-button-color-hover": "#8181811D",

                        "bg-keyboard-color": "#FFFFFF1D",
                        "bg-keyboard-other-color": "#FFFFFF30",

                        "fg-button-color-transparent": "#FFFFFF",
                        "bg-button-color-transparent": "#8181811D",

                        "fg-tooltip-color": "#FFFFFF",
                        "bg-tooltip-color": "#353840",

                        "scroll-color": "#FFFFFF",

                        "border-radius-frame": "24px",
                        "border-radius": "16px",
                        "border-radius-small": "4px",  //new aggiungere tema

                        "backdrop-filter": "blur(16px)"
                    },
                },
                fonts: {
                    normal: {
                        "font-8": "8px",
                        "font-9": "9px",
                        "font-10": "10px",
                        "font-11": "11px",
                        "font-12": "12px",
                        "font-14": "14px",
                        "font-16": "16px",
                        "font-18": "18px",
                    } ,
                    large: {
                        "font-8": "9px",
                        "font-9": "10px",
                        "font-10": "12px",
                        "font-11": "13px",
                        "font-12": "14px",
                        "font-14": "16px",
                        "font-16": "17px",
                        "font-18": "20px",
                    }
                },
                //settings
                avatar: {
                    editor: false,
                    loading: false,
                    bgImage: this.bgImage,
                    bgVideo: this.bgVideo,
                    model3d: this.model3d,
                    config3d: this.config3d,
                    hdr: this.hdr,
                    lutCube: this.lutCube,
                    lutIntensity: this.lutIntensity,
                    gender: 'f',
                    currentConfig: {},
                    autoHideMessage: this.autoHideMessage,
                    lite: this.avatarLite,
                },
                status: {
                    hidden: false,
                    visible: true,
                    loaded: false,
                    loadedAvatar: false,
                    start: false,
                    click: false,
                    opened: this.open,
                    fullscreen: this.fullscreen,
                    speaking: false,
                    listening: false,
                    command: false,
                    expired: false,
                },
                agreements: {
                    disclosure: false,
                    marketing: false,
                    profiling: false,
                    sensitive: false,
                    service: false,
                },
                narration: {
                    auto: this.autoNarration,
                    message: null,
                },
                webcam: {
                    requested: false,
                    activated: this.webcam,
                    backend: this.backend
                },
                gps: {
                  location: this.gpsLocation
                },
                livechat: {
                    extended: !this.livechatMenu,
                    show: false,
                    ready: false,
                    open: false,
                    close: false,
                    requested: false,
                    waiting: false,
                    activated: false,
                    requestType: '',
                    sendMessage: null,
                },
                settings: {
                    //saved on localstorage
                    log: false,
                    developer: false,
                    editor: false,
                    sound: true,
                    input: this.kiosk ? false : true,
                    webcamDebug: false,
                    fontLarge: false,
                    volume: 100
                },
                extras: {
                    botName: this.botName,
                    botIcon: this.botIcon,
                    botLogo: this.botLogo
                },
                custom: {
                    widget: {
                        icon: this.widgetIcon,
                        bottom: this.widgetBottom,
                        right: this.widgetRight,
                        left: this.widgetLeft,
                        top: this.widgetTop,
                        borderRadius: this.widgetBorderRadius
                    }
                },
                backup: {
                    currentUrl: this.currentUrl,
                    currentTitle: document.title,
                    bodyOverflow: document.body.style.overflow
                },
                browser: {
                    enable: this.browser,
                    home: this.home,
                    window: null,
                    url: null,
                },
                hide: {
                    settings: this.hideSettings,
                    infos: this.hideInfos,
                },
                debug: {
                    emotionModel: 'inceptionResnet'
                },
                version: {
                    appVersion: appVersion,
                    appRelease: appRelease
                }
            },
            webcamData: {
                listen: [],
                speak: []
            },
            kioskData: {
                face: 0,
                click: 0,
                countdown: false,
                intervals: null
            },
            empathy: null,
            playVoice: false,
            playCount: 0,
            talking: false,

            listenVoice: false,
            audioVoice: this.audio && this.voice,
            recognizeVoice: false,
            recognized: {
                text: '',
                final: false
            },
            commandVoice: false,
            receiveData: true,
            messageRead: {}
        }
    },
    computed: {
        hasChromeStt() {
            return 'webkitSpeechRecognition' in window && this.$device.isChrome && !this.$device.isIOS && navigator.userAgent.indexOf("Algho") === -1;
        },
        ready() {
            return (this.dhi ? this.features.status.loaded && this.features.status.loadedAvatar : this.features.status.loaded);
        },
    },
    mounted() {
        this.api = this.$refs.api;
        // Important: first check iframe to prevent error on iframe
        // vue.runtime.esm.js:1897 DOMException: Blocked a frame with origin "https://..." from accessing a cross-origin frame.

        this.$log.info('VERSION', appVersion);
        this.$log.info('RELEASE', appRelease);

        if (this.iframe) {
            this.$log.info('STARTING ALGHO (IFRAME)');
            this.fireCustomEvent(EventTypes.LOADED);
        } else {
            //if (window.top.location.href === window.location.href) {
            if (window.frameElement == null) {
                this.$log.info('STARTING ALGHO');
                this.fireCustomEvent(EventTypes.LOADED);
            } else {
                this.$log.info('STARTING ALGHO (IFRAME MODE)');
                this.$log.warn('Use attribute iframe="true" to show inside iframe.');
                window.algho = window.top.algho;
                this.fireCustomEvent(EventTypes.LOADED);
                return;
            }
        }

        this.$log.debug('Current Script Url', appUrl);
        const baseUrl = new URL(appUrl);
        this.baseUrl = baseUrl.origin;

        this.initObserver();
        this.initWindowVisibility();
        this.initFonts();
        this.initExposeFunctions();
        this.initExposeCompatibilityFunctions();

        this.initSettings();
        this.initLogger();

        this.checkDeviceInfo();

        const self = this;
        this.checkAutoPlay(function(enabled) {
            self.features.autoplay = enabled;

            if (!self.features.preview) {
                self.initApp();
            } else {
                self.initPreview();
            }
        });

    },
    destroyed() {
        this.$log.info('DESTROY ALGHO');

        delete window.algho;
        delete window.sendMessageToAlgho;

        document.removeEventListener('visibilitychange', this.visibilityChange);
        document.removeEventListener('click', this.interceptClick, false);

        // remove the element from the DOM
        this.$el.parentNode.removeChild(this.$el);

        // remove the root shadow from the DOM
        this.$root.$options.customElement.remove();

    },

    watch: {
        ready: {
          handler(newVal) {
              if (newVal) {
                  if (!this.alreadyStarted) {
                      this.fireCustomEvent(EventTypes.READY);
                      this.alreadyStarted = true;
                  }
              }
          }
        },
        'features.status.opened': {
            handler(newVal) {
                if (newVal) {
                    this.$track.event('open');
                    if (this.features.status.fullscreen || this.features.device.mobile) {
                        document.body.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = this.features.backup.bodyOverflow;
                    }
                } else {
                    document.body.style.overflow = this.features.backup.bodyOverflow;
                }
            },
            immediate: true,
        },
        'features.status.fullscreen': {
            handler(newVal) {
                if (newVal)
                    document.body.style.overflow = 'hidden';
                else if (!this.features.device.mobile)
                    document.body.style.overflow = this.features.backup.bodyOverflow;
            },
            deep: true,
            immediate: true
        },
        'features.settings.developer': {
            handler() {
                this.initInspector();
            },
            deep: true,
            immediate: false
        },
        'features.settings.log': {
            handler() {
                this.initLogger();
            },
            deep: true,
            immediate: false
        },
    },
    methods: {
        initWindowVisibility() {
            document.addEventListener('visibilitychange', this.visibilityChange);
        },
        visibilityChange() {
            this.features.status.hidden = document.hidden;
        },
        initSettings() {
            const settings = window.localStorage.getItem(StorageKeys.SETTINGS);
            if (settings != null) {
                this.features.settings = Object.assign( {}, this.features.settings, JSON.parse(settings));
            }

            if (this.editor)
                this.features.settings.editor = true;
        },
        initLogger() {
            this.$log.setMode(this.features.settings.log ? 'debug' : this.log);
        },
        initInspector() {
            const self = this;
            this.features.instance.inspector = function() {
                if (self.features.settings.developer) {
                    return {
                        app: self,
                        chat: self.$refs.chat,
                        avatar: self.$refs.avatar,
                    }
                } else {
                    return {}
                }
            };
        },
        initBrowser() {

            if (this.features.kiosk) {
                const self = this;
                window.open = function (url) {
                    self.onBrowserOpen(url, true);
                };
            }

            if (this.features.browser.enable) {
                document.addEventListener('click', this.interceptClick, false);
            }
        },
        interceptClick(e) {
            const self = this;
            const target = e.target;
            const a = target.closest('a');

            if (a != null) {
                const href = a.getAttribute('href');
                if (href != null && href.charAt(0) === "#") {
                    return;
                }

                const url = new URL(href, document.baseURI);
                if (url.host === window.location.host) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    e.stopPropagation();
                    self.onBrowserOpen(url, false);
                }
            }
        },
        initStandbyRanges() {
            if (this.features.kiosk && this.totem.standbyHours !== '') {
                const ranges = this.totem.standbyHours.split(',');
                for (const range of ranges) {
                    const parts = range.split('-');
                    let start = DateTime.fromFormat(parts[0].replace('.', ':').trim(), "HH:mm");
                    let stop = DateTime.fromFormat(parts[1].replace('.', ':').trim(), "HH:mm");
                    if (start > stop)
                        start = start.minus({days: 1});

                    const interval = Interval.fromDateTimes(start, stop);
                    if (this.kioskData.intervals == null)
                        this.kioskData.intervals = [];

                    this.kioskData.intervals.push(interval);
                }
            }
        },
        initPreview() {

            this.initInspector();
            this.initBrowser();

            this.applyRootStyles({ "primary-color": "#8f9194"});

            this.loaded = true;
        },
        initApp(newBotId, newQuestionId, newQuestionRepeat, callback) {
            const self = this;

            let baseUrl = this.baseUrl;
            const url = new URL(baseUrl);
            let userIdPrefix = url.hostname.replace(/\./g,'_');

            if (this.apiUrl !== '')
                baseUrl = this.apiUrl;

            this.$log.info('API', baseUrl);

            if (this.userIdPrefix !== '')
                userIdPrefix = this.userIdPrefix;

            let wsUrl = null;
            if (this.wsUrl !== '')
                wsUrl = this.wsUrl;

            let botId = this.botId;
            let userId = this.userId;
            let groupId = this.groupId;
            let currentUrl = this.currentUrl;
            let token = this.token;
            let context = this.context;
            let authorization = this.authorization;
            let cookieDomain = this.cookieDomain;
            let apiPath = this.apiPath;

            if (cookieDomain == null) {
                cookieDomain = location.hostname;
            }

            const htmlUrl = new URL(location.href);

            //themeStyle
            if (htmlUrl.searchParams.get('themeStyle') != null) {
                this.features.themeStyle = htmlUrl.searchParams.get('themeStyle');
            }

            //custom querystring params html (botId defined in page)
            if (htmlUrl.searchParams.get('orderId') != null) {
                this.features.orderId = htmlUrl.searchParams.get('orderId');
            }
            //gps
            if (htmlUrl.searchParams.get('gpsLocation') != null) {
                this.features.gps.location = htmlUrl.searchParams.get('gpsLocation');
            }

            //editor
            if (htmlUrl.searchParams.get('avatarEditor') != null) {
                this.features.avatar.editor = htmlUrl.searchParams.get('avatarEditor') === '1';
            }

            //webcam
            if (htmlUrl.searchParams.get('webcam') != null) {
                this.features.webcam.activated = htmlUrl.searchParams.get('webcam') === '1';
            }

            //webcam debug
            if (htmlUrl.searchParams.get('webcamDebug') != null) {
                this.features.settings.webcamDebug = htmlUrl.searchParams.get('webcamDebug') === '1';
            }

            //google stt command
            if (htmlUrl.searchParams.get('command') != null) {
                this.features.command = htmlUrl.searchParams.get('command') === '1';
            }

            //vosk stt command
            if (htmlUrl.searchParams.get('commandOffline') != null) {
                this.features.commandOffline = htmlUrl.searchParams.get('commandOffline') === '1';
            }

            //automatic open mic
            if (htmlUrl.searchParams.get('autoMicrophone') != null) {
                this.features.autoMicrophone = htmlUrl.searchParams.get('autoMicrophone') === '1';
            }

            //avatar lite
            if (htmlUrl.searchParams.get('avatarLite') != null) {
                this.features.avatar.lite = htmlUrl.searchParams.get('avatarLite') === '1';
            }

            //kiosk only
            if (this.features.kiosk) {

                //session
                if (htmlUrl.searchParams.get('session') != null) {
                    this.totem.session = parseInt(htmlUrl.searchParams.get('session')) || this.session;
                }

                //standbyHours
                if (htmlUrl.searchParams.get('standbyHours') != null) {
                    this.totem.standbyHours = htmlUrl.searchParams.get('standbyHours');
                }

                //pixelRatio
                if (htmlUrl.searchParams.get('pixelRatio') != null) {
                    this.features.pixelRatio = parseFloat(htmlUrl.searchParams.get('pixelRatio')) || this.pixelRatio;
                }

                //exit
                if (htmlUrl.searchParams.get('exit') != null) {
                    this.features.kioskExit = htmlUrl.searchParams.get('exit') === '1';
                }

            }

            //custom querystring params html
            if (this.botId === '') {
                if (htmlUrl.searchParams.get('bot') != null)
                    botId = htmlUrl.searchParams.get('bot');

                if (htmlUrl.searchParams.get('groupId') != null)
                    groupId = htmlUrl.searchParams.get('groupId');

                if (htmlUrl.searchParams.get('alghoId') != null)
                    userId = htmlUrl.searchParams.get('alghoId');

                if (htmlUrl.searchParams.get('currentUrl') != null)
                    currentUrl = htmlUrl.searchParams.get('currentUrl');

                if (htmlUrl.searchParams.get('token') != null)
                    token = htmlUrl.searchParams.get('token');

                if (htmlUrl.searchParams.get('context') != null)
                    context = htmlUrl.searchParams.get('context');

                if (htmlUrl.searchParams.get('act') != null)
                    authorization = htmlUrl.searchParams.get('act');

                if (htmlUrl.searchParams.get('wsBasePath') != null)
                    wsUrl = htmlUrl.searchParams.get('wsBasePath');

                if (htmlUrl.searchParams.get('autostartQuestionId') != null) {
                    this.features.questionId = htmlUrl.searchParams.get('autostartQuestionId');
                    this.features.questionRepeat = false;
                }

                if (htmlUrl.searchParams.get('autostartEveryTimeQuestionId') != null) {
                    this.features.questionId = htmlUrl.searchParams.get('autostartEveryTimeQuestionId');
                    this.features.questionRepeat = true;
                }

                if (htmlUrl.searchParams.get('autoOpen') != null) {
                    const autoOpen = parseInt(htmlUrl.searchParams.get('autoOpen'));
                    this.features.status.opened = false;
                    if (autoOpen === 0)
                        this.features.status.opened = true;
                    else if (autoOpen > 0)
                        this.features.openDelay = autoOpen;
                }

                if (location.hash.length > 1) {
                    token = location.hash.substr(1);
                }
            }

            //custom querystring params js
            const jsUrl = new URL(appUrl);
            if (jsUrl.searchParams.get('bot') != null)
                botId = jsUrl.searchParams.get('bot');

            if (jsUrl.searchParams.get('groupId') != null)
                groupId = jsUrl.searchParams.get('groupId');

            if (jsUrl.searchParams.get('alghoId') != null)
                userId = jsUrl.searchParams.get('alghoId');

            if (jsUrl.searchParams.get('currentUrl') != null)
                currentUrl = jsUrl.searchParams.get('currentUrl');

            if (jsUrl.searchParams.get('token') != null)
                token = jsUrl.searchParams.get('token');

            if (jsUrl.searchParams.get('context') != null)
                context = jsUrl.searchParams.get('context');

            if (jsUrl.searchParams.get('act') != null)
                authorization = jsUrl.searchParams.get('act');

            if (jsUrl.searchParams.get('wsBasePath') != null)
                wsUrl = jsUrl.searchParams.get('wsBasePath');

            if (jsUrl.searchParams.get('autostartQuestionId') != null) {
                this.features.questionId = jsUrl.searchParams.get('autostartQuestionId');
                this.features.questionRepeat = false;
            }

            if (jsUrl.searchParams.get('autostartEveryTimeQuestionId') != null) {
                this.features.questionId = jsUrl.searchParams.get('autostartEveryTimeQuestionId');
                this.features.questionRepeat = true;
            }

            if (jsUrl.searchParams.get('autoOpen') != null) {
                const autoOpen = parseInt(jsUrl.searchParams.get('autoOpen'));
                this.features.status.opened = false;
                if (autoOpen === 0)
                    this.features.status.opened = true;
                else if (autoOpen > 0)
                    this.features.openDelay = autoOpen;
            }

            //test model emotion
            if (htmlUrl.searchParams.get('emotionModel') != null) {
                const model = htmlUrl.searchParams.get('emotionModel');
                if (model === 'inceptionResnet' || model === 'vgg19' || model === 'resnet50')
                    this.features.debug.emotionModel = model;
            }

            //new parameter passed
            if (newBotId !== undefined)
                botId = newBotId;

            if (newQuestionId !== undefined)
                this.features.questionId = newQuestionId || '';

            if (newQuestionRepeat !== undefined)
                this.features.questionRepeat = newQuestionRepeat || false;

            //text_embedded, voice_embedded, dhi_embedded, text_landscape, voice_landscape, dhi_landscape, kiosk, custom, unknown, social, voip
            let source = this.kiosk ? 'kiosk' : '';
            if (source === '') {
                let sourcePrefix = 'text';
                let sourceSuffix = this.widget ? 'embedded' : 'landscape';
                if (this.voice)
                    sourcePrefix = 'voice';
                if (this.dhi)
                    sourcePrefix = 'dhi';

                source = sourcePrefix + '_' + sourceSuffix;
            }

            //algho_chatbot_text, algho-vocal-assistant, algho-chatbot-3d, algho-chatbot-kiosk
            const sourceType = 'web';

            if (this.demo)
                botId = 'demo';

            if (botId.length > 0) {

                this.$log.info('BOTID', botId);
                this.$log.info('GROUPID', groupId);
                this.$log.info('USERID', userId);


                this.api.init(baseUrl, apiPath, botId, userId, groupId, currentUrl, token, context, authorization, userIdPrefix, wsUrl, cookieDomain, source, sourceType, this.demo);
                this.api.loadBotConfig().then((success) => {
                    if (success) {

                        if (this.language !== '') {
                            this.api.language = this.language;
                        }

                        //Check bot features to disable
                        const bot = this.api.bot;

                        //check bot conversation limit reached or license expired
                        if (
                            (bot.limitsExceded !== undefined && bot.limitsExceded) ||
                            (bot.licenseEnabled !== undefined && !bot.licenseEnabled)
                        ) {
                            this.features.status.expired = true;
                            this.$log.warn('CONVERSATIONS LIMIT REACHED OR LICENSE EXPIRED!');
                            if (this.features.widget || this.features.floating) {
                                this.$log.warn('DISABLED!');
                                return;
                            }
                        }

                        if (this.colorConfig) {
                            if (bot.lookStyleId === '0') {
                                this.$log.info('Init dark theme from config');
                                this.initRootStyles(this.features.themes.dark, bot.headerColor, bot.pageBackground);
                            } else if (bot.lookStyleId === '1') {
                                this.$log.info('Init light theme from config');
                                this.initRootStyles(this.features.themes.light, bot.headerColor, bot.pageBackground);
                            } else if (bot.lookStyleId === '2') {
                                this.$log.info('Init icy theme from config');
                                this.initRootStyles(this.features.themes.icy, bot.headerColor, bot.pageBackground);
                            }
                        } else {
                            this.$log.info('Init theme from settings');
                            this.initRootStyles();
                        }

                        if (this.color !== '')
                            this.initRootStylesAutoColor();

                        this.features.avatar.gender = bot.gender;

                        if (!this.features.status.opened && this.features.openDelay > 0) {
                            this.$log.info('Open delayed');
                            setTimeout(() => {
                                this.$log.info('Auto Open');
                                self.onShow();
                            }, (this.features.openDelay * 1000) + 1600);
                        }

                        //switch mode check
                        if (this.features.has.switch) {
                            const mode = window.sessionStorage.getItem(StorageKeys.MODE);
                            if (mode != null)
                                this.onToggleMode(mode);
                        } else {
                            window.sessionStorage.removeItem(StorageKeys.MODE);
                        }

                        if (this.features.kiosk) {
                            this.standby = true;
                            this.features.status.command = false;
                        }

                        this.loaded = true;

                        this.initInspector();
                        this.initBrowser();
                        this.initStandbyRanges();

                        if (callback !== undefined && typeof callback === 'function')
                            callback();

                    } else {
                        this.$log.warn('BOT NOT FOUND');
                    }

                });

            } else {
                this.$log.warn('BOTID NOT DEFINED');
            }
        },
        initFonts() {
            function setAttributes(elem, attrs) {
                attrs.forEach(attr => {
                    elem.setAttribute(attr.name, attr.value)
                })
            }

            const fontImport = document.createElement('link')
            setAttributes(fontImport, [
                {
                    name: 'href',
                    value: this.baseUrl + '/fonts/poppins.css'
                    //'https://fonts.googleapis.com/css?family=Poppins'
                },
                { name: 'rel', value: 'stylesheet' },
                { name: 'type', value: 'text/css' }
            ])
            document.head.appendChild(fontImport)
        },

        initObserver() {
            const self = this;
            const parent = document;
            const root = this.$root.$options.customElement;

            // Select the node that will be observed for mutations

            const obs = new MutationObserver(mutations => {

                const isSameOrParent = function(el, check) {
                    let test = el;
                    if (test === check)
                        return true;

                    while (test.parentNode) {
                        test = test.parentNode;
                        if (test === check)
                            return true;
                    }
                    return false;
                }

                for (const mutation of mutations) {
                    for (const el of mutation.removedNodes) {
                        if (isSameOrParent(root, el)) {
                            obs.disconnect();
                            self.destroyApp();
                        }
                    }
                }
            });

            obs.observe(parent, {
                childList: true,
                subtree: true
            });

        },
        destroyApp() {
            this.online = false;
            this.$sound.dispose();
            // destroy the vue listeners, etc
            this.$destroy();
        },

        getScrollBarWidth() {
            let scrollBarWidth = 0;
            // Add temporary box to wrapper
            const scrollbox = document.createElement('div');
            // Make box scrollable
            scrollbox.style.overflow = 'scroll';
            // Append box to document
            document.body.appendChild(scrollbox);
            // Measure inner width of box
            scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
            // Remove box
            document.body.removeChild(scrollbox);
            return scrollBarWidth;
        },

        applyRootStyles(styles) {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            const rootStyles = rootShadow.styleSheets[0].cssRules[0].style;

            Object.keys(styles).map((key) => {
                rootStyles.setProperty('--' + key, styles[key]);
            });

            /*
            var self = this;
            this.$nextTick(function () {
                const rootStyles = self.$refs.app.style;
                Object.keys(styles).map((key) => {
                    rootStyles.setProperty('--' + key, styles[key]);
                });
            });
            */

        },

        initRootStyles(newTheme, primaryColor, backgroundOverlay) {

            let defaultTheme = newTheme;
            //theme styles
            const themeStyle = this.features.themeStyle.toLowerCase();

            if (primaryColor !== undefined && backgroundOverlay !== undefined) {
                switch (themeStyle) {
                    case 'dark':
                        this.$log.info('Use theme style dark');
                        defaultTheme = this.features.themes.dark;
                        break;
                    case 'light':
                        this.$log.info('Use theme style light');
                        defaultTheme = this.features.themes.light;
                        break;
                    case 'icy':
                        this.$log.info('Use theme style icy');
                        defaultTheme = this.features.themes.icy;
                        break;
                    default:
                        this.$log.info('Use override colors');
                        defaultTheme["primary-color"] = primaryColor;
                        defaultTheme["primary-color-text"] = primaryColor;
                        defaultTheme["bg-color-widget"] = primaryColor;
                        defaultTheme["fg-button-color-active"] = primaryColor;
                        defaultTheme["fg-button-color-hover"] = primaryColor;
                        defaultTheme["bg-color-overlay"] = backgroundOverlay;
                        break;
                }
            } else if (newTheme === undefined) {
                switch (themeStyle) {
                    case 'dark':
                        this.$log.info('Use theme style dark');
                        defaultTheme = this.features.themes.dark;
                        break;
                    case 'light':
                        this.$log.info('Use theme style light');
                        defaultTheme = this.features.themes.light;
                        break;
                    case 'icy':
                        this.$log.info('Use theme style icy');
                        defaultTheme = this.features.themes.icy;
                        break;
                    default:
                        this.$log.info('Use default theme style dark');
                        defaultTheme = this.features.themes.dark;
                        break;
                }
            }

            let theme = {};
            //custom theme
            if (this.theme !== '') {
                this.$log.info('Apply custom theme preferences');
                const lines = this.theme.split(';');
                lines.map((line) => {
                    const m = line.split(':');
                    if (m.length === 2)
                        theme[m[0].trim()] = m[1].trim();
                });
            }

            theme = Object.assign({}, defaultTheme, theme);

            if (this.themeInvert) {
                this.$log.info('Invert theme');
                Object.keys(theme).map((key) => {
                    if (key.indexOf('-color') !== -1) {
                        const color = Color(theme[key]).toHsl();
                        color.l = 1 - color.l;
                        theme[key] = Color(color).toHex8String();
                    }
                });
            }

            this.applyRootStyles(theme);

            //other styles
            let others = {
                "font-family": "Poppins, sans-serif",
                "box-shadow": "0px 3px 6px #00000029",
                "text-shadow": "0px 3px 6px #00000029",
                "z-index": this.zIndex === '' ? "0" : this.zIndex,
                "scroll-size": this.getScrollBarWidth().toString() + 'px',
                "box-margin" : "36px"
            };

            if (this.features.settings.fontLarge)
                others = Object.assign(others, this.features.fonts.large);
            else
                others = Object.assign(others, this.features.fonts.normal);

            this.applyRootStyles(others);

            //calendar styles
            const calendar = {
                "primary-color-100": Color(theme['primary-color']).lighten(30).toString(),
                "primary-color-200": Color(theme['primary-color']).lighten(25).toString(),
                "primary-color-300": Color(theme['primary-color']).lighten(20).toString(),
                "primary-color-400": Color(theme['primary-color']).lighten(15).toString(),
                "primary-color-500": Color(theme['primary-color']).lighten(10).toString(),
                "primary-color-600": Color(theme['primary-color']).toString(),
                "primary-color-700": Color(theme['primary-color']).darken(10).toString(),
                "primary-color-800": Color(theme['primary-color']).darken(15).toString(),
                "primary-color-900": Color(theme['primary-color']).darken(20).toString(),
            };
            this.applyRootStyles(calendar);

        },

        initRootStylesAutoColor(dark, primaryColor) {

            const newColor = primaryColor !== undefined ? primaryColor : this.color;
            const newDark = dark !== undefined ? dark : this.dark;

            if (newColor !== '') {

                const mix = newDark ? '#444444' : '#AAAAAA';
                const bw =["#000", "#FFF"];
                const gray =[Color("#000").lighten(30).toString(), Color("#FFF").darken(30).toString()];

                let bgColor = Color.mix(mix, newColor, 10).toString('hex');

                const isDark = Color(bgColor).isDark();
                const bgColorGrad = Color(bgColor).darken(10).toString();

                const botColor = Color(bgColor).lighten(10).toString();
                const userColor = Color(bgColorGrad).darken(10).toString();
                const bwColor = Color.mostReadable(bgColor, bw).toString();

                const temp = {
                    "primary-color": Color(newColor).toString(),
                    "primary-color-text": isDark ? Color(newColor).lighten(30).toString() : Color(newColor).darken(30).toString(),
                    "suggested-color": Color(newColor).spin(180).toString(),
                    "bg-color-widget": Color(newColor).toString(),
                    "fg-color-widget": Color.mostReadable(Color(newColor).monochromatic(), bw).toString(),

                    "bg-color": Color(bgColor).toString(),
                    "bg-color-gradient": Color(bgColorGrad).toString(),

                    "fg-color": Color.mostReadable(bgColor, bw).toString(),

                    "fg-color-bot": Color.mostReadable(botColor, bw).toString(),
                    "fg-color-user": Color.mostReadable(userColor, bw).toString(),

                    "bg-color-user": userColor,
                    "bg-color-bot": botColor,

                    "bg-color-card": Color(bgColor).lighten(isDark ? -2 : 2).toString(),

                    "fg-other-color": Color.mostReadable(Color(bgColor).darken(15).monochromatic(), bw).toString(),
                    "bg-other-color": Color(bgColor).darken(15).toString(),

                    "border-other-color": Color(bgColorGrad).darken(isDark ? -10 : 10).toString(),

                    "fg-color-input": Color(bwColor).toString(),
                    "border-color-input": Color(bgColorGrad).darken(isDark ? -10 : 10).toString(),
                    "focus-color-input": Color(bwColor).setAlpha(0.75).toString(),

                    "fg-button-color": isDark ? '#FFFFFF' : '#000000',
                    "bg-button-color": '#FFFFFF99',

                    "fg-button-color-active": Color(newColor).toString(),
                    "fg-button-color-deactive": Color(newColor).spin(90).toString(),
                    "fg-button-color-disabled": Color(bgColorGrad).darken(isDark ? -15 : 15).toString(),

                    "fg-button-color-hover": Color(newColor).toString(),
                    "bg-button-color-hover": '#FFFFFF80',

                    "bg-keyboard-color":  isDark ? '#FFFFFF26' : '#FFFFFFD9',
                    "bg-keyboard-other-color": isDark ? '#FFFFFF0F' : '#0000000F',

                    "fg-button-color-transparent": isDark ? '#FFFFFF' : '#404752',
                    "bg-button-color-transparent": isDark ? '#40444D' : '#FFFFFF',

                    "fg-tooltip-color": isDark ? '#FFFFFF' : '#000000',
                    "bg-tooltip-color": '#FFFFFF80',

                    "border-color": Color(bgColor).lighten(20).toString(),
                    "caption-color": Color.mostReadable(bgColor, gray).toString(),
                    "timestamp-color-user": Color.mostReadable(bgColor, gray).toString(),
                    "timestamp-color-bot": Color.mostReadable(bgColor, gray).toString(),
                    "scroll-color": isDark ? Color(bgColor).lighten(15).toString() : Color(bgColor).darken(15).toString(),
                }
                this.applyRootStyles(temp);

                //calendar styles
                const calendar = {
                    "primary-color-100": Color(newColor).lighten(30).toString(),
                    "primary-color-200": Color(newColor).lighten(25).toString(),
                    "primary-color-300": Color(newColor).lighten(20).toString(),
                    "primary-color-400": Color(newColor).lighten(15).toString(),
                    "primary-color-500": Color(newColor).lighten(10).toString(),
                    "primary-color-600": Color(newColor).toString(),
                    "primary-color-700": Color(newColor).darken(10).toString(),
                    "primary-color-800": Color(newColor).darken(15).toString(),
                    "primary-color-900": Color(newColor).darken(20).toString(),
                };
                this.applyRootStyles(calendar);
            }
        },

        fireCustomEvent(type, data) {
            this.$log.debug('Fire custom event', type, data);
            const event = new CustomEvent(type, {'detail': JSON.parse(JSON.stringify(data || null))}); //{ 'bubbles': true });
            this.$root.$options.customElement.dispatchEvent(event);
        },

        checkDeviceInfo() {
            this.$log.debug('DEVICE INFO');
            this.$log.debug(this.$device.deviceDetect());
            this.$log.debug(this.$device);
        },

        checkAutoPlay(callback) {
            this.$log.info('CHECK SOUND AUTOPLAY');

            try {
                const emptySound = 'data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==';
                const audio = document.createElement('audio');
                audio.setAttribute('src', emptySound);
                const playPromise = audio.play();

                if (!/(chrome|android)/i.test(navigator.userAgent) && /(safari|applewebkit)/i.test(navigator.userAgent)) {
                    this.$log.info('FIX SAFARI/APPLEWEBKIT');
                    audio.src = emptySound; //fix safari (audioplay always)
                }

                if (playPromise !== undefined) {
                    const self = this;
                    playPromise.then(function () {
                        self.$log.debug('AUTOPLAY IS ACTIVE');
                        callback(true);
                    }).catch(function () {
                        self.$log.debug('AUTOPLAY NOT ACTIVE, NEED GESTURE');
                        callback(false);
                    });
                } else {
                    callback(false);
                }
            } catch (e) {
                this.$log.error(e);
                callback(false);
            }
        },

        initExposeFunctions() {

            const self = this;

            this.features.instance = {
                baseUrl: this.baseUrl,
                appVer: this.appVer,
                plugins: {
                    livechat: {
                        tag: 'algho-livechat',
                        src: this.livechatPlugin,
                        loaded: false,
                    },
                    webcam: {
                        tag: 'algho-webcam',
                        src: this.webcamPlugin,
                        loaded: false,
                    },
                    command: {
                        tag: 'algho-command',
                        src: this.commandPlugin,
                        loaded: false,
                    }
                },
                dispose() {
                    self.destroyApp();
                },
                open: function () {
                    setTimeout(() => {
                        self.features.status.opened = true;
                    }, 100);
                },
                close: function () {
                    setTimeout(() => {
                        self.features.status.opened = false;
                    }, 100);
                },
                mute() {
                    self.audioVoice = false;
                },
                unMute() {
                    self.audioVoice = true;
                },
                enableVoice() {
                    self.features.tts = true;
                    self.features.stt = true;
                    self.audioVoice = true;
                },
                disableVoice() {
                    self.features.tts = false;
                    self.features.stt = false;
                    self.audioVoice = false;
                },
                sendBotMessage: function (text, mute, emotion) {
                    if (self.$refs.chat !== undefined)
                        self.$refs.chat.sendBotMessage(text, mute, emotion);
                },
                sendUserMessage: function (text) {
                    if (self.$refs.chat !== undefined)
                        self.$refs.chat.sendUserMessage(text);
                },
                sendUserStartMessage: function (questionId) {
                    if (self.$refs.chat !== undefined)
                        self.$refs.chat.sendUserStartMessage(questionId || '');
                },
                /*
                sendWebcamData: function (data) {
                    self.onRecognizeWebcamData(data);
                },*/
                setUserId: function (userId) {
                    if (self.$refs.chat !== undefined)
                        self.$refs.chat.resetUserId(userId);
                },
                setBotId: function (botId, questionId, questionRepeat, callback) {
                    if (self.$refs.chat === undefined)
                        self.initApp(botId, questionId, questionRepeat, callback);
                    else
                        self.$refs.chat.resetBotId(botId, '', questionId, questionRepeat, callback);
                },
                setToken: function (token) {
                    self.api.setToken(token);
                },
                setContext: function (context) {
                    self.api.setContext(context);
                },
                initTrack: function (callback) {
                    self.$track.init(callback);
                },
                setLookStyleColor(lookStyleId, headerColor, pageBackground) {
                    if (lookStyleId === '0')
                        self.initRootStyles(self.features.themes.dark, headerColor, pageBackground);
                    else if (lookStyleId === '1')
                        self.initRootStyles(self.features.themes.light, headerColor, pageBackground);
                    else if (lookStyleId === '2')
                        self.initRootStyles(self.features.themes.icy, headerColor, pageBackground);
                },
                setAutoColor(dark, primaryColor) {
                    if (dark)
                        self.initRootStyles(self.features.themes.dark);
                    else
                        self.initRootStyles(self.features.themes.light);

                    self.initRootStylesAutoColor(dark, primaryColor);
                },
                setThemeColor(theme) {

                    let newTheme = theme;
                    if (typeof theme === 'string') {
                        newTheme = {};
                        const lines = theme.split(';');
                        lines.map((line) => {
                            const m = line.split(':');
                            if (m.length === 2)
                                newTheme[m[0].trim()] = m[1].trim();
                        });
                    }

                    self.initRootStyles(newTheme);
                },

                resetAll: function (reload) {
                    if (reload === undefined)
                        reload = true;

                    self.api.resetAll();

                    if (reload)
                        window.location.reload(true);
                },

                inspector: function() {return {}},
            };

            Object.defineProperty(this.$root.$options.customElement, 'algho', {
                value: this.features.instance
            });

            window.algho = this.features.instance;
        },
         initExposeCompatibilityFunctions() {
             const self = this;
             window.sendMessageToAlgho = function (cmd, param) {
                 if (cmd === 'open-notch')
                     self.features.status.opened = true;
                 else if (cmd === 'close-notch')
                     self.features.status.opened = false;
                 else if (cmd === 'show-bot-message') {
                     self.features.status.opened = true;
                     if (self.$refs.chat !== undefined)
                        self.$refs.chat.sendBotMessage(param);
                 }
                 else if (cmd === 'show-user-message') {
                     self.features.status.opened = true;
                     if (self.$refs.chat !== undefined)
                        self.$refs.chat.sendUserMessage(param);
                 }
                 else if (cmd === 'open-mic')
                     self.listenVoice = self.features.stt;
                 else if (cmd === 'set-token')
                     self.api.setToken(param);
                 else if (cmd === 'set-application-context')
                     self.api.setContext(param);
             };
         },

        onShow() {
            this.features.status.opened = true;
        },
        onClose() {
            this.features.status.opened = false;
        },
        onShowSetting() {
          this.$refs.chat.onShowSetting();
        },
        onToggleMode(mode) {
            if (mode === 'dhi') {
                this.features.showLastMessage = true;
                this.features.floating = true;
                this.features.transparent = true;
                this.features.fullscreen = false;
                this.features.dhi = true;
            } else if (mode === 'txt') {
                this.features.showLastMessage = false;
                this.features.floating = false;
                this.features.transparent = false;
                this.features.dhi = false;
                this.features.lipsync = false;
            }
            window.sessionStorage.setItem(StorageKeys.MODE, mode);
        },
        onToggleChatWindow() {
            this.features.status.opened = !this.features.status.opened;
        },
        onToggleFullScreen() {
            this.features.status.fullscreen = !this.features.status.fullscreen;
        },
        onStartVoice() {
            this.playVoice = true;
        },
        onStopVoice(playCount) {
            this.playCount = playCount;
            this.playVoice = false;
        },
        onCountVoice(playCount) {
            this.playCount = playCount;
        },
        onEnableMic() {
            this.listenVoice = true;
            this.features.status.command = false;

            const eventData = { status: true };
            this.fireCustomEvent(EventTypes.MICROPHONE, eventData);
        },
        onDisableMic() {
            this.listenVoice = false;
            this.features.status.command = true;

            const eventData = { status: false };
            this.fireCustomEvent(EventTypes.MICROPHONE, eventData);
        },
        onEnableAudio() {
            this.audioVoice = true;
            this.features.status.command = false;
        },
        onDisableAudio() {
            this.audioVoice = false;
            this.features.status.command = true;
        },
        onStartRecognizeVoice() {
            if (!this.$device.isAndroid)
                this.$sound.play('micIn', this.features.settings.volume);

            this.recognizeVoice = true;
            this.features.status.command = false;
            this.onRecognizeText({ text: '', final: false, send: false});
        },
        onStopRecognizeVoice() {
            if (!this.$device.isAndroid)
                this.$sound.play('micOut', this.features.settings.volume);

            this.listenVoice = false;
            this.recognizeVoice = false;
            this.features.status.command = true;

            const eventData = { status: false };
            this.fireCustomEvent(EventTypes.MICROPHONE, eventData);
        },
        onRecognizeText(recognized) {
            this.recognized = recognized;
        },
        onRecognizeCommand() {
            this.commandVoice = false;
            this.listenVoice = true;
            this.features.status.command = false;
            this.$nextTick(() => {
                this.commandVoice = true;
            });

            const eventData = { status: true };
            this.fireCustomEvent(EventTypes.MICROPHONE, eventData);
        },
        onSendData() {
            this.receiveData = false;
            this.$track.event('chat');
        },
        onReceiveData() {
            this.receiveData = true;
        },
        onMessageRead(message) {

            const eventData = {
                content: message.content,
                isForm: message.isForm || false,
                fieldType: message.fieldType || null,
                fieldName: message.fieldName || null,
                fieldId: message.fieldId || null,
                mediaType: message.mediaType || null,
                media: message.media || null,
                suggestions: (message.suggestions || []).map((item) => {
                    return {
                        id: item.optionId,
                        text: item.optionText,

                }}),
                payload: message.payload || null
            }
            this.fireCustomEvent(EventTypes.MESSAGE, eventData);
            this.messageRead = message;
        },
        onSendCommand(value) {
            const message = this.$refs.chat.createUserMessage(value.optionText);
            this.$refs.chat.onSendData({message, value});
        },
        onTalking(value) {
          this.talking = value;
          this.features.status.command = !value;
        },
        onRecognizeWebcamData(data) {

            if (data != null) {

                if (this.isStandByTime()) {
                    this.kioskData.face = -1;
                } else {
                    if (data.face)
                        this.kioskData.face++;
                    else
                        this.kioskData.face = 0;
                }

                if (data.emotions != null && data.angles != null) {
                    //console.log(data);
                    //test empatia
                    this.empathy = data.emotions;

                    const mapEmotion = {
                        angry: 'ANGRY',
                        disgusted: 'DISGUSTED',
                        fearful: 'SCARED',
                        happy: 'HAPPY',
                        neutral: 'NEUTRAL',
                        sad: 'SAD',
                        surprised: 'AMAZED',
                    };

                    const mapAngles = {
                        pitch: 'PITCH',
                        roll: 'ROLL',
                        yaw: 'YAW',
                    };

                    const now = Date.now();
                    const old = now - 30000; //30 seconds

                    const webcamdata = {
                        timestamp: now,
                        emotions: {},
                        angles: {}
                    };

                    Object.keys(data.emotions).map(function (item) {
                        const key = mapEmotion[item];
                        webcamdata.emotions[key] = Math.trunc(data.emotions[item] * 100) / 100;
                    });

                    Object.keys(data.angles).map(function (item) {
                        const key = mapAngles[item];
                        webcamdata.angles[key] = data.angles[item];
                    });

                    if (this.talking) {
                        this.webcamData.listen.push(webcamdata);
                    } else {
                        this.webcamData.speak.push(webcamdata);
                        this.webcamData.speak = this.webcamData.speak.filter(function (item) {
                            return item.timestamp >= old;
                        });
                    }
                }
            }
        },

        onResetSession() {
            this.api.resetUserId(null);
            this.standby = false;
            this.features.status.command = true;
        },
        onStanbySession() {
            this.standby = true;
            this.features.status.command = false;
        },
        isStandByTime() {
            let ret = false;

            if (this.features.kiosk && this.kioskData.intervals !== null) {
                for (const interval of this.kioskData.intervals) {
                    if (!ret) {
                        ret = interval.contains(DateTime.now());
                    }
                }
            }
            return ret;
        },

        onBrowserOpen(url, showClose) {

            document.body.style.position = 'fixed';
            document.body.style.left = '-10000px';

            if (this.$refs.browser === undefined) {
                this.url = url;
                this.urlClose = showClose;
            } else {
                this.$refs.browser.navigate(url);
            }
        },

        onBrowserLoaded(url) {
            this.$log.info('Browser load', url);
            this.features.browser.url = url;

            if (this.features.status.fullscreen)
                this.features.status.fullscreen = false;
        },
        onBrowserClosed() {
            document.body.style.position = 'static';
            document.body.style.left = 'unset';
            this.$log.info('Browser closed');

            this.url = null;
            this.features.browser.url = null;
        },
    }
}
</script>

<style lang="less">

@import "./less/Theme.less";

.algho {
    position: relative;
    display: block;
    font-family: var(--font-family);
    font-weight: 400;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: var(--z-index);

    input {
        font-family: 'Poppins', sans-serif;
    }

    &.widget {
        position: fixed;
        top: -100%;
        left: -100%;
    }

    /* Remove outline */
    *:focus {
        outline: none;
    }

    &.noselect {
        *:not(input) { //fix iOS
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
            -webkit-tap-highlight-color: transparent; /* for removing the highlight */
        }
    }

}
</style>


