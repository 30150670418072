<template>
    <div v-if="message.enabled" class="message-form">
        <DatePicker
            v-if="!hours"
            :locale="$locale(this)"
            class="calendar calendar-no-date"
            v-model="value"
            mode="time"
            is24hr
        />

        <div v-if="hours" class="calendar-time">
            <ul class="time-list scrollable scrollable-primary scrollable-y">
                <li v-for="(item, index) in hours" :key="index" class="time-item" :class="{'selected': time===item}" @click="onTimeClicked(item)">
                    <span class="time-text">{{ pad(item.hours) }}:{{ pad(item.minutes) }}</span>
                </li>
            </ul>
        </div>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns'
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import DatePicker from "@/components/calendar/components/DatePicker";


export default {
    name: "FieldTime",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        DatePicker
    },
    data() {
        return {
            value: null,
            time: null,
        };
    },
    computed: {
        disabled() {
            if (this.hours != null) {
                return (this.value === null || this.time === null);
            } else {
                return (this.value === null);
            }
        },
        optionType() {
            return this.options.length > 0 ? 'AdmissibleValue' : null;
        },
        hours() {
            return this.options.length > 0 ? this.options.map(function (item) {
                const time = new Date('1900/01/01 ' + item.optionText);
                return {
                    hours: time.getHours(),
                    minutes: time.getMinutes()
                }
            }) : null;
        }
    },
    methods: {
        pad: function (n) {
            return (n < 10) ? ('0' + n) : n;
        },
        onTimeClicked: function(time) {
            this.value = new Date('1900/01/01');
            this.time = time;
        },
        onInputClicked: function () {
            const newValue = new Date(this.value);
            if (this.hours != null && this.time != null) {
                newValue.setHours(this.time.hours, this.time.minutes,0);
            }
            const value = {
                inputMode: InputMode.CLICK,
                optionType: this.optionType,
                optionValue: format(newValue, "HH:mm"),
                optionText: format(newValue, "HH:mm"),
            };
            this.$emit("onSendCommand", value);
        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand", value);
        },
    }
}
</script>

<style>

</style>
