var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"message-multimedia margin-common-content-multimedia"},[_c('Modal',{staticClass:"modal-video",attrs:{"fullscreen":!_vm.features.kiosk && _vm.fullscreen},on:{"close":function($event){_vm.fullscreen = false}}},[(!_vm.features.kiosk && !_vm.hasDirectLink)?_c('iframe',{staticClass:"video video-radius shadow-common",attrs:{"slot":"body","src":_vm.parseLink,"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen","sandbox":_vm.disableSandbox ? '' :
                (_vm.features.kiosk ?
                'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation' :
                'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-popups'),"allowfullscreen":"","title":"Video"},slot:"body"}):_vm._e(),(!_vm.features.kiosk && _vm.hasDirectLink)?_c('video',{staticClass:"video video-radius shadow-common",attrs:{"slot":"body","controls":"controls","title":"Video","src":_vm.parseLink},slot:"body"}):_vm._e(),(_vm.features.kiosk && !_vm.fullscreen)?_c('KioskMedia',{attrs:{"slot":"body"},on:{"onToggle":_vm.toggle,"onClose":_vm.close},slot:"body"},[(!_vm.hasDirectLink)?_c('iframe',{staticClass:"video",attrs:{"slot":"body","src":_vm.parseLink,"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen","sandbox":_vm.disableSandbox ? '' :
                (_vm.features.kiosk ?
                'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation' :
                'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-popups'),"allowfullscreen":"","title":"Video"},slot:"body"}):_vm._e(),(_vm.hasDirectLink)?_c('video',{staticClass:"video",attrs:{"slot":"body","controls":"controls","disablepictureinpicture":"","controlsList":"nofullscreen nodownload noremoteplayback noplaybackrate","title":"Video","src":_vm.parseLink},on:{"click":_vm.onPlayback},slot:"body"}):_vm._e()]):_vm._e()],1),(_vm.features.kiosk && _vm.fullscreen)?_c('Panel',{attrs:{"features":_vm.features,"border":false},on:{"onClose":function($event){_vm.fullscreen = false}}},[_c('div',{staticClass:"modal-body",attrs:{"slot":"body"},slot:"body"},[(!_vm.hasDirectLink)?_c('iframe',{staticClass:"video shadow-common",attrs:{"src":_vm.parseLink,"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen","sandbox":_vm.disableSandbox ? '' :
                (_vm.features.kiosk ?
                'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation' :
                'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-popups'),"allowfullscreen":"","title":"Video"}}):_vm._e(),(_vm.hasDirectLink)?_c('video',{staticClass:"video shadow-common",attrs:{"slot":"body","controls":"controls","disablepictureinpicture":"","controlsList":"nofullscreen nodownload noremoteplayback noplaybackrate","title":"Video","src":_vm.parseLink},on:{"click":_vm.onPlayback},slot:"body"}):_vm._e()]),_c('div',{attrs:{"slot":"powered"},slot:"powered"})]):_vm._e(),(!_vm.features.kiosk)?_c('div',{staticClass:"media-action"},[_c('ButtonIcon',{staticClass:"action-small",attrs:{"name":"fullscreen","size":"16px"},on:{"click":_vm.toggle}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }