<template>
  <div v-show="ready && features.status.visible" class="chat" :class="{'last-message': features.showLastMessage, 'hide-chat': !features.chat}" >

      <ExpiredPanel v-if="features.status.expired" class="chat-container" :features="features" @onClose="onClose" />

      <div v-if="!features.status.expired" class="chat-container" :class="{ 'show-menu': menu }">

          <transition :name="features.dhi || features.inline ? 'fade': 'slide-up'" appear>
              <div v-show="ready && !panelVisible" class="chat-body">
                  <Header v-if="displayHeader"
                          :features="features"
                          @onClose="onClose"
                          @onToggleFullScreen="onToggleFullScreen"
                          @onChangeBot="onChangeBot"
                          @onEnableLiveChat="onEnableLiveChat"
                          @onDisableLiveChat="onDisableLiveChat"
                  />

                  <KioskMessageManager v-if="features.kiosk"
                                  :features="features"
                                  :listenVoice="listenVoice"
                                  :audioVoice="audioVoice"
                                  :recognizeVoice="recognizeVoice"
                                  :recognized="recognized"
                                  :messages="messages"
                                  :playVoice="playVoice"
                                  :playCount="playCount"
                                  :waiting="waiting"
                                  :commandVoice="commandVoice"
                                  @onSendText="onSendText"
                                  @onEnableMic="onEnableMic"
                                  @onDisableMic="onDisableMic"
                                  @onEnableAudio="onEnableAudio"
                                  @onDisableAudio="onDisableAudio"
                                  @onStopSpeaking="onStopSpeaking"
                                  @onPreviewContent="onPreviewContent"

                  />

                  <MessageDisplay
                                  :features="features"
                                  :load-more-messages="loadMoreMessages"
                                  :scroll-bottom="scrollBottom"
                                  :waiting="waiting"
                                  :profile-picture-config="profilePictureConfig"
                                  :timestamp-config="timestampConfig"
                                  @onSendCommand="onSendCommand"
                                  @onVote="onVote"
                                  @onNarrate="onNarrate"
                                  @onEmotion="onEmotion"
                                  @onShowEmotionPanel="onShowEmotionPanel"
                                  @onShowPrivacyPanel="onShowPrivacyPanel"
                                  @onShowCard="onShowCard"
                                  @onShowCards="onShowCards"
                                  @onOpenUrl="onOpenUrl"
                                  @onPlayMessage="onPlayMessage"
                                  @onReceiveMessages="onReceiveMessages"
                  />

                  <MessageManager v-if="!features.kiosk"
                                  :features="features"
                                  :listenVoice="listenVoice"
                                  :audioVoice="audioVoice"
                                  :recognizeVoice="recognizeVoice"
                                  :recognized="recognized"
                                  :messages="messages"
                                  :playVoice="playVoice"
                                  :playCount="playCount"
                                  :waiting="waiting"
                                  :commandVoice="commandVoice"
                                  @onSendText="onSendText"
                                  @onType="onType"
                                  @onEnableMic="onEnableMic"
                                  @onDisableMic="onDisableMic"
                                  @onEnableAudio="onEnableAudio"
                                  @onDisableAudio="onDisableAudio"
                                  @onStopSpeaking="onStopSpeaking"
                                  @onShowMenu="onShowMenu"
                                  @onHideMenu="onHideMenu"
                                  @onShowInfo="onShowInfo"
                                  @onShowSetting="onShowSetting"
                                  @onShowPrivacy="onShowPrivacy"
                                  @onToggleMode="onToggleMode"
                                  @onEnableLiveChat="onEnableLiveChat"
                                  @onDisableLiveChat="onDisableLiveChat"
                                  @onPreviewContent="onPreviewContent"

                  />

                  <KioskMessageTool v-if="features.kiosk" :features="features"
                                    @onResetUser="onResetUser"
                                    @onShowPrivacy="onShowPrivacy"
                                    @onChangeBot="onChangeBot"
                  />
              </div>
          </transition>

          <transition name="slide-down" >
              <PlayerStart v-if="ready && panels.start && !panels.setting" ref="playerStart" :features="features"
                           @onPlayStart="onPlayStart"
                           @onShowSetting="onShowSetting"
                           @onClose="onClose"
              />

              <InfoPanel v-if="panels.info" :features="features" @onClose="onCloseInfo" />
              <EmotionPanel v-if="panels.emotion" :features="features" @onClose="onCloseEmotion" />
              <PrivacyPanel v-if="panels.privacy" :features="features" :anchor="panels.privacyAnchor" @onClose="onClosePrivacy" />
              <SettingPanel v-if="panels.setting" :features="features" @onClose="onCloseSetting" />
              <CardPanel v-if="panels.card" :option="panels.card" :features="features" @onClose="onCloseCard" />
              <CardsPanel v-if="panels.cards"
                          :message="panels.cards"
                          :features="features"
                          @onClose="onCloseCards"
                          @onSendCommand="onSendCommand"
                          @onShowCard="onShowCard"
                          @onShowCards="onShowCards"
              />
          </transition>
      </div>
  </div>
</template>

<script>

const StorageKeys = {
    AUTOMATION: '_algho_automation',
    NARRATION: '_algho_narration',
};

import {DateTime} from "luxon";
import InputMode from "@/classes/InputMode";

import {mapMutations} from 'vuex'
import store from './store'

import Header from './chat/Header.vue'
import MessageDisplay from './chat/MessageDisplay.vue'
import MessageManager from './chat/MessageManager.vue'
import KioskMessageManager from './chat/KioskMessageManager.vue'

import KioskMessageTool from "./chat/KioskMessageTool.vue";
import PlayerStart from "./PlayerStart.vue";
import InfoPanel from "./panels/InfoPanel.vue";
import EmotionPanel from "./panels/EmotionPanel.vue";
import PrivacyPanel from "./panels/PrivacyPanel.vue";
import SettingPanel from "./panels/SettingPanel.vue";
import CardPanel from "./panels/CardPanel.vue";
import CardsPanel from "./panels/CardsPanel.vue";
import ExpiredPanel from "./panels/ExpiredPanel.vue";


export default {
    name: "Chat",
    components: {
        Header,
        MessageDisplay,
        MessageManager,
        KioskMessageManager,
        KioskMessageTool,
        PlayerStart,
        InfoPanel,
        EmotionPanel,
        PrivacyPanel,
        SettingPanel,
        CardPanel,
        CardsPanel,
        ExpiredPanel
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        listenVoice: {
            type: Boolean,
            required: true
        },
        audioVoice: {
            type: Boolean,
            required: true
        },
        recognizeVoice: {
            type: Boolean,
            required: true,
        },
        recognized: {
            type: Object,
            required: true
        },
        webcamData: {
            type: Object,
            required: true
        },
        playVoice: {
            type: Boolean,
            required: true,
        },
        playCount: {
            type: Number,
            required: true,
        },
        commandVoice: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            callback: null,
            doStarted: false,
            alreadyStartedQa: false,
            offset: 0,
            step: 10,
            conversationTimeout: 900,
            messageRead: null,
            toRead: [],
            toAction: [],
            speakMessagesTimer: null,
            skipToRead: false,
            toLoad: [],
            messages: [],
            messagesLoaded: -1,
            lastHistoryOffset: 0,
            enableEngagement: false,
            seleniumStarted: false,
            privacy: false,
            talking: false,
            menu: false,
            panels: {
                start: false,
                info: false,
                setting: false,
                emotion: false,
                privacy: false,
                privacyAnchor: '',
                card: null,
                cards: null
            },
            engagement: {
                timer: null,
                timerDelay: null,
                firstDelay: null,
                counter: null
            },
            participants: [
                {
                    name: this.api.bot.name,
                    id: 1,
                    profilePicture: this.api.bot.avatarImage
                },
                {
                    name: 'Operatore',
                    id: 2,
                    profilePicture: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                }
            ],
            myself: {
                name: 'Utente',
                id: 3,
                profilePicture: ''
            },
            chatTitle: "...",
            scrollBottom: {
                messageSent: true,
                messageReceived: false,
                forceScroll: false
            },
            waiting: false,
            displayHeader: true,
            profilePictureConfig: {
                others: this.api.bot.avatarImage !== '',
                myself: false,
                styles: {
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%'
                }
            },
            timestampConfig: {
                format: 'HH:mm',
                relative: false
            },
            previewMessage: null
        }
    },
    watch: {
        participants() {
            this.setParticipants(this.participants);
        },
        myself() {
            this.setMyself(this.myself);
        },
        messages() {
            this.setMessages(this.messages);
        },
        chatTitle() {
            this.setChatTitle(this.chatTitle);
        },
        showStart: {
            handler(newVal) {
                this.features.status.start = newVal;
            }
        },
        ready: {
            handler(newVal) {
                //console.log('Chat ready changed: ', newVal);
                if (newVal) {
                    this.scrollDown();
                    if (!this.doStarted)
                        this.doStart();
                }
            },
            deep: true
        },
        clicked: {
            handler(newVal) {
                //console.log('Chat click changed: ', newVal);
                if (newVal) {
                    this.doStartPlayMessages();
                }
            },
            deep: true
        },
        closedStart: {
            handler(newVal) {
                //console.log('Closed start changed: ', newVal);
                if (newVal) {
                    this.doStartPlayMessages();
                }
            },
            deep: true
        },
        recognizeVoice: {
            handler(newVal) {
                //console.log('recognizeVoice changed: ', newVal);
                if (newVal)
                    this.skipAudio();
            },
            deep: true
        },
        audioVoice: {
            handler(newVal) {
                //console.log('audioVoice changed: ', newVal);
                if (!newVal)
                    this.skipAudio();
            },
            deep: true
        },
        listenVoice: {
            handler(newVal) {
                //console.log('listenVoice changed: ', newVal);
                this.features.status.listening = newVal;
            },
            deep: true
        },
        talking: {
            handler(newVal) {
                this.$emit('onTalking', newVal);
                if (!newVal && this.features.webcam.activated) {
                    this.onSendWebcamData();
                }
            },
            deep: true
        },
        'features.status.hidden': {
            handler(newVal) {
                //console.log('hidden changed: ', newVal);
                if (newVal) {
                    this.skipAudio();
                }
            },
            deep: true
        },
        'features.status.opened': {
            handler(newVal) {
                //console.log('opened changed: ', newVal);
                if (!newVal) {
                    this.skipAudio();
                }
            },
            deep: true
        },
        'features.browser.url': {
            handler(newVal) {
                //console.log('browser url changed: ', newVal);
                if (newVal != null) {
                    this.resetEngagement();
                    this.checkRunSeleniumActions();
                    this.checkRunNarrations();
                }
            },
        },
        'features.status.speaking': {
            handler() {
                //console.log('Speaking changed: ', newVal);
                this.delayEngagement();
            },
        },
        'features.status.listening': {
            handler() {
                //console.log('Listening changed: ', newVal);
                this.delayEngagement();
            },
        },
    },
    beforeCreate() {
        this.$store = store();
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        const self = this;
        this.setParticipants(this.participants);
        this.setMyself(this.myself);
        this.setChatTitle(this.chatTitle);
        this.setMessages(this.messages);

        this.initBotConfig();
        this.loadHistory(() => {
            if (self.features.status.click)
                self.doStart();
        });
    },
    mounted() {
        this.initGestureClick();
        this.checkRunSeleniumActions();

        if (this.canOpenBrowserHome)
            this.$emit('onBrowserOpen', this.features.browser.home, false);
    },
    destroyed() {
        document.removeEventListener("click", this.gestureClick, {once : true});
    },
    computed: {
        panelVisible() {
            return this.panels.start
                || this.panels.info
                || this.panels.setting
                || this.panels.emotion
                || this.panels.privacy
                || this.panels.card
                || this.panels.cards;
        },
        ready() {
            //determina se la chat è pronta per partire
            return (this.features.dhi ? this.features.status.loaded && this.features.status.loadedAvatar : this.features.status.loaded) && this.features.status.opened;
        },
        clicked() {
            return this.features.status.click;
        },
        closedStart() {
            return !this.panels.start;
        },
        canPlayMessages() {
            return !this.needClick && this.closedStart;
        },
        needClick() {
            if (this.features.autoplay && !this.features.chat)
                return false;
            else
                return (this.audioVoice && !this.clicked && !this.features.autoplay) || (this.privacy && !this.features.kiosk);
        },
        isQaSingle() {
            return this.features.questionId !== '' && !this.features.questionRepeat && this.messagesLoaded === 0;
        },
        isQaRepeat() {
            return this.features.questionId !== '' && this.features.questionRepeat && this.messagesLoaded >= 0;
        },
        isWelcome() {
            return this.messagesLoaded === 0;
        },
        isWelcomeBack() {
            return this.messagesLoaded > 0;
        },
        canDoStartQa() {
            return (this.isQaSingle || this.isQaRepeat) && !this.alreadyStartedQa;
        },
        canDoStart() {
            return !this.canDoStartQa && (this.isWelcome || this.isWelcomeBack);
        },
        lastMessage() {
            const filtered = this.messages.filter((message) => {return !message.myself});
            return filtered.length > 0 ? filtered[filtered.length-1] : null;
        },
        browser() {
            return this.features.browser.enable && this.features.browser.window != null ? this.features.browser.window : window;
        },
        browserOpened() {
            return this.features.browser.enable && this.browser !== window;
        },
        canOpenBrowserHome() {
            return this.features.browser.enable && this.browser === window && this.features.browser.home != '';
        },
        canOpenBrowser() {
            return this.features.browser.enable;
        }
    },
    methods: {
        ...mapMutations([
            'setParticipants',
            'setMyself',
            'setMessages',
            'setChatTitle',
            'newMessage',
        ]),

        createBotMessage(text, mute, emotion) {
            let message = {
                content: text || '',
                myself: false,
                participantId: 1,
                timestamp: DateTime.local().toISO(),
                uploaded: true,
                enabled: false,
                hidden: false,
                viewed: false,
                first: true,
                last: true,
                multi: false,
                history: false,
                type: 'text',
                noAudio: mute || false,
                emotion: emotion || 'neutral'
            };

            return message;
        },
        createUserMessage(text) {
            let message = {
                content: (text || '') !== '' ? text : '&nbsp;',
                myself: true,
                participantId: this.myself.id,
                timestamp: DateTime.local().toISO(),
                uploaded: false,
                enabled: false,
                hidden: false,
                viewed: false,
                history: false,
                mediaType: 'text',
                partial: false
            };

            return message;
        },
        skipMic() {
            this.$emit("onDisableMic");
            this.skipToRead = false;
        },
        resumeAudio() {
            this.skipToRead = false;
        },
        skipAudio() {
            this.skipToRead = true;
            this.$sound.stop();
        },
        playAudio() {
            const self = this;
            if (this.messageRead.speakOnly === undefined) {
                this.messages.push(this.messageRead);
                this.scrollDown();
            }

            const startEvent = (duration) => {
                self.messageRead.duration = duration;
                self.$emit("onStartVoice");
            };

            const stopEvent = () => {

                self.features.status.speaking = false;
                self.$emit("onStopVoice", this.toRead.length);
                self.messageRead.enabled = true;
                self.scrollDown();

                //self.waiting = false;
                self.startPlayMessages();
            }

            this.$sound.playFile(this.messageRead.audio, this.features.settings.volume, startEvent, stopEvent);
            this.features.status.speaking = true;

        },
        doStartPlayMessages() {
            if (!this.panels.start) {
                if (this.callback != null)
                    this.callback(this);
                else
                    this.appendStartPlayMessages();
            }
        },
        appendStartPlayMessages() {
            //prevent call startPlayMessages multiple times as same time
            const self = this;

            if (this.speakMessagesTimer !== null) {
                clearTimeout(this.speakMessagesTimer);
                this.speakMessagesTimer = null;
            }

            this.speakMessagesTimer = setTimeout(()=> {
                self.startPlayMessages();
                self.speakMessagesTimer = null;
            }, 100);
        },
        startPlayMessages() {
            const self = this;

            this.waiting = this.toRead.length > 1;

            if (this.toRead.length > 0) {

                this.$emit("onCountVoice", this.toRead.length);

                this.messageRead = this.toRead.shift();
                this.toAction.push(this.messageRead);

                if (this.messageRead.content == null) {
                    this.startPlayMessages();
                    return;
                }

                this.$emit('onMessageRead', this.messageRead);

                this.audio = null;

                this.talking = true;

                if (this.audioVoice && !this.skipToRead && this.messageRead.noAudio !== true) {
                    //audio enabled
                    if (this.messageRead.audio === undefined) {
                        this.$log.debug('Create audio for message ...');

                        if (this.messageRead.tts || null != null) {
                            this.api.getTTS(this.messageRead.tts, this.audioVoice, this.features.lipsync).then((result) => {
                                if (result != null) {
                                    this.messageRead.audio = result.audio;
                                    this.messageRead.lipsync = result.lipsync;
                                }
                                this.playAudio();
                            });
                        } else {
                            this.api.getMp3(this.messageRead.content, this.audioVoice, this.features.lipsync).then((result) => {
                                if (result != null) {
                                    this.messageRead.audio = result.audio;
                                    this.messageRead.lipsync = result.lipsync;
                                }
                                this.playAudio();
                            });
                        }
                    } else {
                        this.playAudio();
                    }

                } else {
                    //audio disabled
                    if (this.messageRead.speakOnly === undefined) {
                        this.messages.push(this.messageRead);
                        this.messageRead.enabled = true;
                        this.scrollDown();
                    }

                    if (this.skipToRead) {
                        this.startPlayMessages();
                    } else {
                        //apply delay
                        const delay = this.messageDelay(this.messageRead.content);
                        setTimeout(() => {
                            self.$emit("onCountVoice", self.toRead.length);
                            self.startPlayMessages();
                        }, delay);
                    }
                }
            } else {
                this.talking = false;
                this.checkAction();
            }
        },
        messageDelay(text) {
            const DEFAULT = 1000;

            const IMMEDIATE = 10;
            const FAST = 600;
            const MEDIUM = 1000;
            const SLOW = 2200;
            const DYNAMIC = 0;

            let delay = DEFAULT;

            if(this.api.delayMode !== undefined) {
                switch (this.api.delayMode) {
                    case 'IMMEDIATE':
                        delay = IMMEDIATE;
                        break;
                    case 'FAST':
                        delay = FAST;
                        break;
                    case 'MEDIUM':
                        delay = MEDIUM;
                        break;
                    case 'SLOW':
                        delay = SLOW;
                        break;
                    case 'DYNAMIC':
                        delay = DYNAMIC;
                        break;
                }

                if (delay === DYNAMIC) {
                    let plain = text.replace(/<\/?[^>]+(>|$)/g, '');
                    delay = (plain.length / 13) > 0 ? (Math.round(plain.length / 13) + 1) * 1000 : 1000;
                }
            }

            return delay;
        },
        speakMessages(messages) {

            const filtered = messages.filter((message) => {return message.noAudio !== true});
            const noAudio = filtered.length  === 0;
            if (this.needClick && (!noAudio || this.callback != null)) {
                if (!this.features.kiosk) {
                    this.panels.start = true;
                } else {
                    this.onPlayStart();
                }
            }

            if (messages.length > 0) {
                this.resumeAudio();
                this.$log.info('Append messages to speech queue...');
                for (let i in messages) {
                    this.toRead.push(messages[i]);
                }
                if (this.canPlayMessages || noAudio) {
                    this.appendStartPlayMessages();
                }
            }

            if (this.canPlayMessages && this.callback != null) {
                this.callback(this);
            }
        },

        onEnableMic() {
            this.$emit("onEnableMic");
            this.skipAudio();
        },
        onDisableMic() {
            this.$emit("onDisableMic");
            this.resumeAudio();
        },
        onEnableAudio() {
            this.$emit("onEnableAudio");
            this.resumeAudio();
        },
        onDisableAudio() {
            this.$emit("onDisableAudio");
            this.skipAudio();
        },
        onStopSpeaking() {
            this.$sound.stop();
        },
        onShowMenu() {
            //this.$emit("onShowMenu");
            this.menu = true;
        },
        onHideMenu() {
            //this.$emit("onHideMenu");
            this.menu = false;
        },
        onShowInfo() {
            this.panels.info = true;
        },
        onCloseInfo() {
            this.panels.info = false;
        },
        onCloseEmotion() {
            this.panels.emotion = false;
        },
        onShowPrivacy() {
            this.panels.privacy = true;
        },
        onClosePrivacy() {
            this.panels.privacy = false;
        },
        onShowSetting() {
            this.panels.setting = true;
        },
        onCloseSetting() {
            this.panels.setting = false;
            //scroll down for font size change
            this.scrollDown();
        },
        onCloseCard() {
            this.panels.card = null;
        },
        onCloseCards() {
            this.panels.cards = null;
        },

        onType: function () {
            //console.log(event);
            //here you can set any behavior
        },

        initGestureClick() {

            if (this.features.autoplay && !this.features.chat)
                this.features.status.click = true;

            document.addEventListener("click", this.gestureClick, {once : true});
        },

        gestureClick() {
            let visible = this.$refs.playerStart !== undefined;
            if (visible)
                visible = this.$refs.playerStart.$el.clientHeight !== 0;

            if (!visible) {
                this.$log.info('Gesture click');
                this.features.status.click = true;
            }
        },

        checkAction() {
            if (!this.checkFrontEndMessage())
                this.checkCommand();

            this.checkBrowserAutomation();
            this.toAction = [];
        },

        checkFrontEndMessage() {
            const self = this;
            let res = false;

            this.toAction.map(function(message) {
                if (message.frontEndMessage === 'STOP_CONVERSATION') {
                    //da form
                    res = true;
                    setTimeout(()=> {
                        self.onResetUser();
                    }, 1000);
                } else if (message.frontEndMessage === 'REQUEST_OPERATOR') {
                    //da quibble
                    res = true;
                    self.features.livechat.requestType = 'REQUEST_OPERATOR';
                    self.features.livechat.requested = true;
                } else if (message.frontEndMessage === 'LIVE_CHAT') {
                    //da operatore o resume
                    res = true;
                    self.features.livechat.requestType = 'LIVE_CHAT';
                    self.features.livechat.requested = true;
                } else if (message.frontEndMessage === 'NARRATION') {
                    res = true;
                    self.runNarrations();
                }

            });
            return res;
        },

        checkCommand() {
            const self = this;

            this.toAction.map(function(message) {
                if (message.command === 'STOP_CONVERSATION') {
                    setTimeout(()=> {
                        self.onResetUser();
                    }, 1000);
                } else if (message.command === 'REQUEST_OPERATOR') {
                    setTimeout(()=> {
                        self.features.livechat.requestType = 'REQUEST_OPERATOR';
                        self.features.livechat.requested = true;
                    }, 1000);
                } else if (message.command === 'NARRATION') {
                    self.runNarrations();
                }

            });
        },

        checkBrowserAutomation() {

            const self = this;

            const script = {
                timestamp: DateTime.local().toISO(),
                actions: []
            }

            this.toAction.map(function(message) {

                let canReadUrl = false;
                try {
                    canReadUrl = self.browser.location.href !== undefined;
                } catch (e) {
                    self.$log.warn('Cannot read iframe url from blocked cross-origin frame');
                    canReadUrl = false;
                }

                //check browser automation A (link)
                if (canReadUrl) {
                    if (message.mediaType === 'link' && message.media !== '' && self.browser.location.href !== message.media) {
                        const url = new URL(message.media);
                        if (url.host === window.location.host && url.hash !== '#ba=false') {
                            script.actions.push({
                                type: 'openUrl',
                                xpath: undefined,
                                value: message.media,
                                executed: false
                            });
                        }
                    }
                }

                //check browser automation B (selenium)
                if (message.payload !== undefined) {
                    let actions = null;
                    try {
                        actions = JSON.parse(message.payload);
                    } catch (e) {
                        self.$log.warn('Payload is string', message.payload);
                    }
                    if (actions != null && actions.tests !== undefined) {
                        var tests = actions.tests;
                        var test = tests[0];

                        if (test.commands !== undefined) {
                            var commands = test.commands;

                            for (let i in commands) {
                                let commandObj = commands[i];

                                let type = undefined;
                                if (commandObj.command !== undefined) {
                                    type = commandObj.command;
                                }

                                let value = undefined;
                                if (commandObj.value !== undefined) {
                                    value = commandObj.value;
                                }

                                let target = undefined;
                                if (commandObj.target !== undefined) {
                                    target = commandObj.target;
                                }

                                let xpath = undefined;
                                if (commandObj.targets !== undefined) {
                                    let targets = commandObj.targets;

                                    for (let j in targets) {
                                        let item = targets[j];

                                        for (let k in item) {
                                            let string = item[k];

                                            let marker = 'xpath=';
                                            let marker2 = 'id='
                                            if (typeof string === 'string' && string.indexOf(marker) != -1 && string.indexOf(marker2) != -1) {
                                                xpath = string.substring(string.indexOf(marker), string.length).replace(marker, '');
                                            }
                                        }
                                    }

                                    if (xpath === undefined) {
                                        for (let j in targets) {
                                            let item = targets[j];

                                            for (let k in item) {
                                                let string = item[k];

                                                let marker = 'xpath=';
                                                if (typeof string === 'string' && string.indexOf(marker) != -1) {
                                                    xpath = string.substring(string.indexOf(marker), string.length).replace(marker, '');
                                                    break;
                                                }
                                            }
                                        }
                                    }

                                    if (xpath === undefined && type === 'select' && targets.length === 0 && target !== undefined && target.length > 0) {
                                        if (target.indexOf('=') >= 0) {
                                            var splitArray = target.split('=');
                                            xpath = '//*[@' + splitArray[0] + '="' + splitArray[1] + '"]';
                                        }
                                    }
                                }

                                if (type !== undefined && xpath !== undefined)
                                    script.actions.push({type: type, xpath: xpath, value: value, executed: false});
                                else if (type === 'openUrl') //not selenium compliance (targrt)
                                    script.actions.push({type: 'openUrl', xpath: undefined, value: value, executed: false});
                                else if (type === 'executeScript') //not selenium compliance (target)
                                    script.actions.push({type: type, xpath: undefined, value: value, executed: false});
                            }
                        }
                    }
                }

            });

            if (script.actions.length > 0) {
                this.$log.info('Start browser automation', script.actions);
                setTimeout(()=> {
                    window.sessionStorage.setItem(StorageKeys.AUTOMATION, JSON.stringify(script));
                    self.runSeleniumActions(script);
                }, 1000);
            }

        },

        checkRunSeleniumActions() {
            if (window.sessionStorage.getItem(StorageKeys.AUTOMATION) != null) {
                const script = JSON.parse(window.sessionStorage.getItem(StorageKeys.AUTOMATION));

                const now = DateTime.local();
                const test = DateTime.fromISO(script.timestamp);
                const elapsed = now.diff(test, "seconds").seconds;
                if (elapsed <= 10) {
                    this.runSeleniumActions(script);
                    this.$log.info('Open after browser automation');
                    this.features.status.opened = true;
                } else {
                    window.sessionStorage.removeItem(StorageKeys.AUTOMATION);
                }
            }
        },
        runSeleniumActions(script) {
            const self = this;
            let timeout = 0;

            if (script.actions.length > 0) {
                let stop = false;
                this.$log.info('Run Selenium actions', script.actions);

                const event = new Event('change', { 'bubbles': true });

                for (let i = 0; i < script.actions.length && !stop; i++) {
                    const action = script.actions[i];

                    if (!action.executed) {
                        this.seleniumStarted = true;
                        // CLICK action
                        if (action.type === 'click') {

                            (function (xpath, timeout) {
                                setTimeout(function () {
                                    self.$log.info('Selenium CLICK', xpath);
                                    action.executed = true;
                                    script.timestamp = DateTime.local().toISO();
                                    window.sessionStorage.setItem(StorageKeys.AUTOMATION, JSON.stringify(script));

                                    const element = self.browser.document.evaluate(xpath, self.browser.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

                                    if (element !== undefined && element !== null) {
                                        if (element.tagName === 'INPUT') {
                                            element.focus();
                                            element.click();
                                        } else
                                            element.click();
                                    }
                                }, timeout);
                            })(action.xpath, timeout);
                        }
                        // TYPE action
                        else if (action.type === 'type') {

                            (function (xpath, value, timeout) {
                                setTimeout(function () {
                                    self.$log.info('Selenium TYPE', xpath);
                                    action.executed = true;
                                    script.timestamp = DateTime.local().toISO();
                                    window.sessionStorage.setItem(StorageKeys.AUTOMATION, JSON.stringify(script));

                                    const element = self.browser.document.evaluate(xpath, self.browser.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

                                    if (element !== undefined && element !== null) {
                                        element.value = value;
                                        element.dispatchEvent(event);
                                    }
                                }, timeout);
                            })(action.xpath, action.value, timeout);
                        }
                        // SEND-KEYS action
                        else if (action.type === 'sendKeys') {

                            (function (xpath, value, timeout) {
                                setTimeout(function () {
                                    self.$log.info('Selenium SEND-KEYS', xpath);
                                    action.executed = true;
                                    script.timestamp = DateTime.local().toISO();
                                    window.sessionStorage.setItem(StorageKeys.AUTOMATION, JSON.stringify(script));

                                    const element = self.browser.document.evaluate(xpath, self.browser.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

                                    if (element !== undefined && element !== null) {
                                        if (element.closest('form') !== undefined && element.closest('form') !== null) {
                                            element.closest('form').submit();
                                        }
                                    }
                                }, timeout);
                            })(action.xpath, action.value, timeout);
                        }
                        // SELECT action
                        else if(action.type === 'select') {

                            (function(xpath, value, timeout) {
                                setTimeout(function() {
                                    self.$log.info('Selenium SELECT', xpath, value);

                                    const element = self.browser.document.evaluate( xpath, self.browser.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null ).singleNodeValue;

                                    if(element !== undefined && element !== null) {

                                        if(value.indexOf('label=') >= 0) {
                                            const labelValue = value.replace('label=', '');

                                            for(let k = 0; k < element.options.length; k++) {
                                                const item = element.options[k];
                                                if(item.label == labelValue) {
                                                    element.value = item.value;
                                                    element.dispatchEvent(event);
                                                    break;
                                                }
                                            }
                                        }
                                        else {
                                            element.value = value;
                                            element.dispatchEvent(event);

                                        }
                                    }
                                }, timeout);
                            })(action.xpath, action.value, timeout);
                        }
                        // OPEN action
                        else if (action.type === 'openUrl') {
                            (function (xpath, value, timeout) {
                                setTimeout(function () {
                                    self.$log.info('Custom Selenium OPEN-URL', value);
                                    action.executed = true;
                                    script.timestamp = DateTime.local().toISO();
                                    window.sessionStorage.setItem(StorageKeys.AUTOMATION, JSON.stringify(script));
                                    if (value !== undefined && value !== null && value !== '') {
                                        if (self.canOpenBrowser) {
                                            self.$emit('onBrowserOpen', value, false);
                                        } else {
                                            self.browser.location.href = value;
                                        }
                                    }
                                }, timeout);
                            })(action.xpath, action.value, timeout);

                            if (action.value !== undefined && action.value !== null && action.value !== '') {
                                stop = true;
                            }
                        }

                        // EXECUTE-SCRIPT action
                        else if (action.type === 'executeScript') {

                            (function (xpath, value, timeout) {
                                setTimeout(function () {
                                    self.$log.info('Custom Selenium EXECUTE-SCRIPT', value);
                                    action.executed = true;
                                    script.timestamp = DateTime.local().toISO();
                                    window.sessionStorage.setItem(StorageKeys.AUTOMATION, JSON.stringify(script));

                                    try {
                                        self.browser.eval(value);
                                    } catch (e) {
                                        self.$log.error('Error', e);
                                    }
                                }, timeout);
                            })(action.xpath, action.value, timeout);
                        }

                        timeout += 500;
                    }
                }
            }
        },

        checkRunNarrations() {
            const bot = this.api.bot;
            let rules = [];

            if(bot.narrationRules !== undefined) {
                rules = bot.narrationRules;
            }

            if(bot.narration !== undefined && bot.narration === 1) {

                this.$log.info('Check narration');

                for(let rule of rules) {
                    const regexMatch = this.browser.location.href.match(rule.ruleKey);
                    if(regexMatch != null) {
                        this.$log.info('Narration url match for rule key', rules);

                        const element = this.browser.document.evaluate(rule.ruleValue, this.browser.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null ).singleNodeValue;

                        if(element !== undefined && element != null) {
                            this.$log.info('Narration element found');
                            const text = element.textContent;
                            const html = element.innerHTML;

                            const lastNarrationUrl = window.sessionStorage.getItem(StorageKeys.NARRATION) || '';
                            if (this.browser.location.href !== lastNarrationUrl) {

                                this.$log.info('Narration text', text, html);

                                if (text != null && text.length > 0) {
                                    const message = {
                                        content: html,
                                        myself: false,
                                        participantId: 1,
                                        timestamp: DateTime.local(),
                                        uploaded: true,
                                        enabled: false,
                                        hidden: false,
                                        viewed: true,
                                        first: true,
                                        last: true,
                                        multi: false,
                                        history: false,
                                        emotion: 'neutral'
                                    };
                                    if (this.browserOpened && this.features.narration.auto) {
                                        this.onPlayMessage(message);
                                    } else {
                                        this.features.narration.message = [message];
                                    }
                                }
                            } else {
                                this.$log.info('Narration temporary disabled', lastNarrationUrl);
                                this.features.narration.message = null;
                            }

                        }

                        break;
                    }
                }
            }
        },

        runNarrations() {
            const bot = this.api.bot;
            let rules = [];

            if (bot.narrationRules !== undefined) {
                rules = bot.narrationRules;
            }

            this.$log.info('Check narration');

            let found = false;

            for (let rule of rules) {
                const regexMatch = this.browser.location.href.match(rule.ruleKey);
                if (regexMatch != null) {
                    this.$log.info('Narration url match for rule key', rules);

                    const element = this.browser.document.evaluate(rule.ruleValue, this.browser.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

                    if (element !== undefined && element != null) {
                        this.$log.info('Narration element found');
                        const text = element.textContent;
                        const html = element.innerHTML;

                        this.$log.info('Narration text', text, html);

                        if (text != null && text.length > 0) {
                            found = true;
                            const message = {
                                content: html,
                                myself: false,
                                participantId: 1,
                                timestamp: DateTime.local(),
                                uploaded: true,
                                enabled: false,
                                hidden: false,
                                viewed: true,
                                first: true,
                                last: true,
                                multi: false,
                                history: false,
                                emotion: 'neutral'
                            };

                            this.onPlayMessage(message);
                        }

                    }

                    break;
                }
            }

            if (!found) {
                const html = this.api.bot.narrationNotAvailableMessage;
                const message = {
                    content: html,
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    first: true,
                    last: true,
                    multi: false,
                    history: false,
                    tts: 'narrationNotAvailable',
                    emotion: 'neutral'
                };

                this.onPlayMessage(message);
            }
        },

        loadHistory(callback) {

            if (this.features.orderId != null) {
                //disable history if redirect with payment
                this.features.status.loaded = true;
                return;
            }

            this.$log.info('Load history...');
            this.api.getHistory(this.offset, this.step).then((data) => {

                this.features.status.loaded = true;
                this.messagesLoaded = data.messages.length;

                this.lastHistoryOffset = data.offset;
                this.offset += data.offset;

                this.messages.unshift(...data.messages);
                this.scrollDown();

                if (this.api.conversationId != null)
                    this.resetEngagement();

                this.features.livechat.ready = true;

                if (callback !== undefined && typeof callback === 'function')
                    callback();

            });
        },

        resetHistory() {
            this.$log.info('Reset history');
            this.offset = 0;
            this.lastHistoryOffset = 0;
            this.messagesLoaded = 0;
            this.messages = [];
        },

        resetUserId(userId) {
            const self = this;
            if (userId != null)
                this.$log.info('Change userId');
            else
                this.$log.info('Reset userId');

            if (this.panels.settings)
                this.panels.settings = false;

            if (this.panels.privacy)
                this.panels.privacy = false;

            this.api.resetUserId(userId);

            this.api.loadBotConfig().then(() => {
                self.initBotConfig();
                self.resetHistory();
                    if (userId != null) {
                        self.loadHistory();
                    }
                self.doStart();
            });
        },

        resetBotId(botId, lang, questionId, questionRepeat, callback) {
            if (botId !== this.api.botId) {
                const self = this;
                this.skipAudio();
                this.$log.info('Change botId', botId);
                this.features.questionId = '';
                this.features.questionRepeat = false;

                this.resetHistory();
                this.api.resetBotId(botId, lang);

                this.api.loadBotConfig().then((success) => {
                    if (success) {
                        self.features.status.visible = false;
                        self.features.status.visible = true;
                        self.features.questionId = questionId || '';
                        self.features.questionRepeat = questionRepeat || false;
                        self.initBotConfig();
                        self.loadHistory(()=> {
                            self.doStart();
                            if (callback !== undefined && typeof callback === 'function') {
                                callback();
                            }
                        });
                    }
                });
            } else {
                this.features.questionId = questionId || '';
                this.features.questionRepeat = questionRepeat || false;
                this.doRestart();
                if (callback !== undefined && typeof callback === 'function') {
                    callback();
                }
            }
        },

        initBotConfig() {
            const bot = this.api.bot;
            this.chatTitle = bot.name;

            //Check agreements
            this.features.agreements.service = bot.activeAgreements.service;
            this.features.agreements.marketing = bot.activeAgreements.marketing;
            this.features.agreements.profiling = bot.activeAgreements.profiling;
            this.features.agreements.sensitive = bot.activeAgreements.sensitive;
            this.features.agreements.disclosure = bot.activeAgreements.disclosure;

            if (!this.doStarted && !this.features.kiosk) {
                this.privacy = this.api.hasPrivacyMessage();
                if (this.features.chat)
                    this.panels.start = this.privacy;
            }

            this.initEngagement();

        },

        initEngagement() {

            this.clearEngagementTimer();

            this.$log.info("Init Engagement");
            this.engagement.counter = 0;
            this.engagement.firstDelay = parseInt(this.api.bot.engagementTime || '-1');
            this.engagement.timerDelay = this.engagement.firstDelay;
            this.$log.info("Set engagement timer", this.engagement.timerDelay);
        },

        clearEngagementTimer() {
            if(this.engagement.timer != null) {
                clearTimeout(this.engagement.timer);
                this.engagement.timer = null;
            }
        },

        resetEngagement() {
            const self = this;
            this.$log.info("Reset engagement timer");
            this.engagement.counter = 0;
            this.engagement.timerDelay = this.engagement.firstDelay;

            this.clearEngagementTimer();

            if (this.api.conversationId != null) {
                if (this.enableEngagement && this.engagement.timerDelay > 0) {
                    this.engagement.timer = setTimeout(function () {
                        self.getEngagement();
                    }, this.engagement.timerDelay * 1000);

                    this.$log.info("Set engagement timer", this.engagement.timerDelay);

                }
            }
        },

        delayEngagement() {
            const self = this;
            this.clearEngagementTimer();

            if (this.api.conversationId != null) {

                if (this.lastMessage != null && this.engagement.timerDelay > 0) {
                    this.engagement.timer = setTimeout(function () {
                        self.getEngagement();
                    }, this.engagement.timerDelay * 1000);

                    this.$log.info("Delay engagement timer", this.engagement.timerDelay);

                }
            }
        },

        updateEngagement(nextTimer) {
            const self = this;

            this.clearEngagementTimer();

            if (this.api.conversationId != null) {

                if (nextTimer !== undefined) {
                    let start = false;

                    if (nextTimer == null || nextTimer === 0) {
                        // Start next engagement with default delay
                        this.engagement.timerDelay = this.engagement.firstDelay;
                        this.engagement.counter++;
                        start = true;
                    } else if (nextTimer === -1) {
                        // No next engagement
                        this.engagement.counter = -1;
                    } else if (nextTimer > 0) {
                        // Start next engagement
                        this.engagement.timerDelay = nextTimer;
                        if (this.engagement.timerDelay < 0)
                            this.engagement.timerDelay = 3;

                        this.engagement.counter++;
                        start = true;
                    }

                    if (start) {
                        this.$log.debug("Update engagement", this.engagement.timerDelay);
                        this.engagement.timer = setTimeout(function () {
                            self.getEngagement();
                        }, this.engagement.timerDelay * 1000);
                    } else {
                        this.$log.debug("Paused engagement");
                    }
                }
            }
        },

        getEngagement() {
            if (this.api.conversationId != null) {
                if (!this.features.status.opened) {
                    this.$log.debug('Clear engagement: chat closed');
                    this.clearEngagementTimer();
                } else if (this.features.status.speaking || this.features.status.listening || this.features.livechat.waiting || this.features.livechat.activated) {
                    this.$log.debug('Delay engagement');
                    this.delayEngagement();
                } else {
                    if (this.lastMessage != null && this.lastMessage.answerType !== 'FORM') {
                        if (this.engagement.counter !== -1) {
                            this.$log.debug('Call engagement');
                            this.api.getEngagement(this.engagement.counter, this.audioVoice, this.webcamData.speak, this.features.lipsync).then((data) => {
                                this.$log.info('Next engagement time', data.nextTimer);
                                this.webcamData.speak = [];
                                this.updateEngagement(data.nextTimer);

                                if (data.messages.length > 0) {
                                    const messages = data.messages;
                                    this.offset = this.offset + messages.length;
                                    this.speakMessages(messages);
                                }
                            });
                        }
                    }
                }
            }
        },

        setPrivacy() {
            this.api.setPrivacyMessage();
        },

        scrollDown() {
            const self = this;
          setTimeout(() => {
              self.scrollBottom.forceScroll = !self.scrollBottom.forceScroll;

          }, 0);
        },
        loadMoreMessages(resolve) {

            if (this.lastHistoryOffset > 0) {

                if ((this.features.status.opened && !this.features.demo && this.offset > 0)) {

                    // if (this.features.sound && this.features.settings.sound && !this.skipToRead)
                    //    this.$sound.play('pull', this.features.settings.volume);

                    //load history messages
                    this.api.getHistory(this.offset, this.step, false).then((data) => {
                        this.$log.info('Load more history');

                        this.lastHistoryOffset = data.offset;
                        this.offset += data.offset;
                        this.toLoad = data.messages;
                        this.messages.unshift(...data.messages);
                        this.toLoad = [];

                        resolve(this.toLoad);
                    });
                } else {
                    resolve(this.toLoad);
                }
            } else {
                resolve(this.toLoad);
            }

        },

        onPlayStart() {
            this.panels.start = false;
            this.features.status.click = true;
            this.privacy = false;
            this.setPrivacy();
        },

        onClose() {
            //this.visible = false;
            this.$emit('onClose');
        },
        onToggleMode(mode) {
            this.$emit("onToggleMode", mode);
        },
        onToggleFullScreen() {
            this.$emit('onToggleFullScreen');
        },
        onEnableLiveChat() {
            this.features.livechat.open = true;
        },
        onDisableLiveChat() {
            this.features.livechat.close = true;
        },
        onChangeBot(bot) {
            this.resetBotId(bot.botId, bot.lang);
        },
        onPreviewContent(content) {

            if (content.text !== '') {
                if (this.previewMessage === null) {
                    this.previewMessage = this.createUserMessage(content.text);
                    this.previewMessage.partial = !content.final;

                    if (this.lastMessage !== null)
                        this.previewMessage.groupId = this.lastMessage.groupId;

                    this.newMessage(this.previewMessage);
                    this.messages.push(this.previewMessage);
                } else {
                    this.previewMessage.content = content.text;
                    this.previewMessage.partial = !content.final;
                }
            }

            this.scrollDown();
        },
        onSendText: function({text, inputMode}) {
            const message = this.createUserMessage(text);
            const value = {
                inputMode: inputMode,
                optionValue: message.content,
                optionText: message.content,
            };

            this.onSendData({message, value});
        },
        onSendCommand: function(value) {
            const message = this.createUserMessage(value.optionText);
            this.onSendData({message, value});
        },
        onSendData({message, value}) {

            if (this.previewMessage !== null) {
                message = this.previewMessage;
                this.previewMessage = null;
            } else {
                this.newMessage(message);
                this.messages.push(message);
            }
            if (this.lastMessage != null)
                this.lastMessage.enabled = false;

            this.scrollDown();

            if (this.features.sound && this.features.settings.sound && !this.skipToRead)
                this.$sound.play('send', this.features.settings.volume);

            this.$log.info('Send message');

            this.skipAudio();
            this.skipMic();

            this.$emit('onSendData');
            this.waiting = true;
            this.features.narration.message = null;
            this.features.webcam.requested = false;
            this.features.livechat.requested = false;

            if (!this.features.livechat.activated) {
                this.api.sendMessage(value, this.audioVoice, this.webcamData.speak, this.features.lipsync).then((result) => {
                    this.webcamData.speak = [];
                    this.waiting = false;
                    this.$emit('onReceiveData');

                    this.$log.info('Receive response');

                    this.offset++;

                    message.uploaded = true;

                    if (result.length > 0)
                        message.groupId = result[0].groupId;

                    if (!this.audioVoice && this.features.sound && this.features.settings.sound && !this.skipToRead)
                        this.$sound.play('receive', this.features.settings.volume);

                    this.speakMessages(result);
                });
            } else {
                this.features.livechat.sendMessage(value);
            }

            this.enableEngagement = true;
            this.resetEngagement();
        },
        onSendWebcamData() {
            if (this.api.conversationId != null) {
                const self = this;
                setTimeout(() => {
                    self.$log.info('Send emotions', self.webcamData.listen);
                    self.api.sendWebcamData(self.webcamData.listen).then(() => {
                        self.webcamData.listen = [];
                    });
                }, 2000);
            }
        },
        onVote(value) {
            this.$log.info('Vote message');

            this.waiting = !value.success;

            if (this.features.sound && this.features.settings.sound && !this.skipToRead)
                this.$sound.play('send', this.features.settings.volume);

            this.api.voteMessage(value, this.audioVoice, this.features.lipsync).then((result) => {
                this.$log.info('Receive vote response');
                this.waiting = false;
                this.$emit('onReceiveData');

                if (this.features.sound && this.features.settings.sound && !this.skipToRead)
                    this.$sound.play('receive', this.features.settings.volume);

                let messages = [];
                messages = messages.concat(result);

                //if (value.success)

                if (messages.length > 0) {
                    this.offset++;
                    this.speakMessages(messages);
                }

                this.resetEngagement();
            });
        },
        onNarrate(value) {
            this.features.status.click = true;
            if (value) {
                this.speakMessages(this.features.narration.message);
                this.resetEngagement();
                window.sessionStorage.removeItem(StorageKeys.NARRATION);
            } else {
                window.sessionStorage.setItem(StorageKeys.NARRATION, this.browser.location.href);
          }
          this.features.narration.message = null;
        },
        onEmotion(value) {
            this.features.webcam.requested = false;
            this.features.webcam.activated = value;
        },
        onShowEmotionPanel() {
            this.panels.emotion = true;
        },
        onShowPrivacyPanel(anchor) {
            this.panels.privacyAnchor = anchor;
            this.panels.privacy = true;
        },
        onShowCard(option) {
            this.panels.card = option;
        },
        onShowCards(message) {
            this.panels.cards = message;
        },
        onOpenUrl(url) {
            if (this.browserOpened) {
                this.$emit('onBrowserOpen', url, false);
            }
            else if (this.canOpenBrowser) {
                this.$emit('onBrowserOpen', url, false);
            } else {
                window.open(url, '_self');
            }
        },
        onPlayMessage(message) {
            this.skipAudio();
            if (message.content != null && message.content.length > 0)
                this.speakMessages([message]);
        },
        onReceiveMessages(messages) {
            this.skipAudio();
            this.speakMessages(messages);
        },
        onResetUser() {
            this.skipAudio();
            if (!this.features.kiosk)
                this.resetUserId(null);
            else
                this.$emit("onStanbySession");
        },
        doStart() {

            this.doStarted = true;

            if (this.features.livechat.activated) {
                this.$log.info('Livechat resumed, Do Start disabled');
                return;
            }

            if (this.features.orderId != null) {
                this.$log.info('Check paypal order, Do Start disabled');
                this.api.getOrderMessage(this.features.orderId).then((messages)=> {
                    if (messages != null) {
                        this.speakMessages(messages);
                    }
                });
                return;
            }

            let messages = [];
            this.$log.info('Do Start');

            this.checkRunNarrations();

            if (this.canDoStart) {

                if (this.isWelcome) {
                    this.$log.info('Get welcome message');
                    messages = messages.concat(this.api.getWelcomeMessage(this.privacy, this.features.widget));
                } else if (this.isWelcomeBack) {
                    let showWelcomeBack = !this.seleniumStarted || this.features.narration.message === null;

                    if (this.messages.length > 0) {
                        const lastMessage = this.messages[this.messages.length - 1];

                        if (!lastMessage.myself && (lastMessage.isForm || (lastMessage.suggestions || []).length > 0)) {

                            if (showWelcomeBack) {
                                const now = DateTime.local();
                                const elapsed = now.diff(lastMessage.timestamp, "seconds").seconds;
                                if (elapsed <= this.conversationTimeout) {
                                    this.$log.info('Enable last message or form');
                                    lastMessage.history = false;
                                    lastMessage.enabled = true;
                                    lastMessage.noAudio = true;
                                    this.scrollDown();
                                    showWelcomeBack = false;
                                } else {
                                    showWelcomeBack = true;
                                }
                            }
                        }
                    }

                    if (showWelcomeBack) {
                        this.$log.info('Get welcome back message');
                        messages = messages.concat(this.api.getWelcomeBackMessage(this.privacy, this.features.widget));
                    }
                }
            }

            if (this.canDoStartQa) {

                this.alreadyStartedQa = true;

                this.callback = (self) => {
                    self.callback = null;

                    this.$log.info('Get autostart message', self.features.questionId);
                    this.sendAutostartMessage(self.features.questionId, messages);
                };

            }

            this.speakMessages(messages);

        },
        doRestart() {
            this.$log.info('Do Restart');
            const messages = [];

            const self = this;
            if (self.features.questionId !== '') {
                this.callback = (self) => {
                    self.callback = null;

                    this.$log.info('Get autostart message', self.features.questionId);
                    this.sendAutostartMessage(self.features.questionId, messages);
                };
                this.speakMessages(messages);
            }
        },

        sendBotMessage(text, mute, emotion) {
            //external exposed function
            this.doStarted = true;
            const message = this.createBotMessage(text, mute, emotion);
            this.speakMessages([message]);
        },
        sendUserMessage(text) {
            //external exposed function
            this.doStarted = true;
            this.onSendText({text, inputMode: InputMode.KEYBOARD});
        },
        sendUserStartMessage(questionId) {
            //external exposed function
            if (questionId !== '') {
                this.doStarted = true;
                this.sendAutostartMessage(questionId, []);
            }
        },
        sendAutostartMessage(questionId, messages) {
            //external/internal exposed function
            const self = this;

            const value = {
                inputMode: InputMode.CLICK,
                optionId: questionId,
                optionType: 'NextQuestion',
                optionValue: '',
                optionText: '',
            };

            this.skipAudio();
            this.$emit('onSendData');
            this.waiting = true;
            this.features.narration.message = null;
            this.features.webcam.requested = false;
            this.features.livechat.requested = false;

            this.api.sendMessage(value, this.audioVoice, this.webcamData.speak, this.features.lipsync).then((result) => {
                self.webcamData.speak = [];
                self.waiting = false;
                self.$emit('onReceiveData');
                self.offset++;
                if (!self.audioVoice && self.features.sound && self.features.settings.sound)
                    self.$sound.play('receive', this.features.settings.volume);


                /*
                //REMOVED
                if (self.messages.length > 0) {
                    const lastMessage = self.messages[self.messages.length-1];
                    if (!lastMessage.myself && lastMessage.isForm) {

                        const now = DateTime.local();
                        const elapsed = now.diff(lastMessage.timestamp, "seconds").seconds;
                        if (elapsed <= self.conversationTimeout) {
                            self.$log.info('Remove history last message if is form');
                            lastMessage.hidden = true;
                        }
                    }
                }
                 */

                messages = messages.concat(result);

                if (messages.length > 0)
                    self.speakMessages(messages);
            });
        },
    }
}
</script>

<style lang="less">

  .chat {
      position: relative;
      box-sizing: border-box;
      height: 100%;
      box-shadow: var(--box-shadow);
      border: 2px solid var(--border-color);
      border-radius: var(--border-radius-frame);
      background: transparent linear-gradient(153deg, var(--bg-color) 0%, var(--bg-color-gradient) 100%) 0% 0% no-repeat padding-box;

      &.hide-chat {
          opacity: 0 !important;
      }

      .chat-container {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: flex-end;
          border-radius: var(--border-radius-frame);
          overflow: hidden; //importante
          background-color: var(--bg-color-overlay);

          .chat-body {
              display: flex;
              flex-flow: column;
              width: 100%;
              height: 100%;
          }

          &.show-menu {
              .container-message-display {
                  opacity: 0.3;
                  transition: opacity 0.3s;
              }
          }

          .container-message-display {
              opacity: 1.0;
              transition: opacity 0.3s;
          }

      }
  }

  /* INLINE */
  .inline:not(.dhi) {
      .chat {
          border: 0;
          border-radius: 0;
          box-shadow: none;
      }
  }

  /* MOBILE */
  .mobile:not(.inline) {
      .chat {
          position: fixed;
          width: auto;
          height: auto;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          padding: 0;
          margin: auto;

          border: 0;
          border-radius: 0;
          box-shadow: none;

          .chat-container {
              border-radius: unset;
          }
      }
  }

</style>
