<template>
    <ul class="message-interrupt-content">
        <li class="message-interrupt-title" v-html="title"></li>
        <li class="message-interrupt-items">
            <div class="message-interrupt-item" @click="onInterrupt(true)">{{$translate(api.language, 'form.yes')}}</div>
            <div class="message-interrupt-item" @click="onInterrupt(false)">{{$translate(api.language, 'form.no')}}</div>
        </li>
    </ul>
</template>

<script>

export default {
    name: "Interrupt",
    components: {

    },
    props: {
        message: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            hoverUp: false,
            hoverDown: false,
        };
    },
    computed: {
        title() {
            return this.$translate(this.api.language, 'form.title');
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onInterrupt(value) {
            if (value) {
                this.$emit("onAbortClicked");
            }
            this.$emit('onInterrupt');
        }
    }
}
</script>

<style lang="less">

.message-interrupt-content {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    padding: 0;
    margin: 0;

    color: var(--fg-other-color);
    background-color: var(--bg-other-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);
    border-radius: var(--border-radius);
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);

    text-align: center;

    .message-interrupt-title {
        padding: 9px 12px 8px 12px;
        border-bottom: 1px solid var(--border-other-color);

        p {
            margin: 0;
        }
    }

    .message-interrupt-items {
        display: flex;
        flex-direction: row;
        border-bottom: 0;

        .message-interrupt-item {
            color: var(--fg-other-color);
            border-right: 1px solid var(--border-other-color);
            width: 50%;
            padding: 8px 12px 9px 12px;
            cursor: pointer;

            &:last-child {
                border-right: 0;
            }

            &:hover {
                color: var(--primary-color-text);
            }
        }
    }

}

</style>
