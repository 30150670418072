<template>
    <div class="chat-widget" ref="widget" @mouseenter="zoom(true)" @mouseleave="zoom(false)" @click="openUI">
        <div ref="tooltip" class="chat-widget-container" :content="features.tooltip" v-tippy="{ placement: 'auto',  appendTo: container, trigger: features.tooltip ? 'mouseenter focus' : 'manual', delay: [1000, 30000], distance: 15, theme: 'widget' }">

            <svg class="animate-widget" xmlns="http://www.w3.org/2000/svg" style="margin: auto; display: block; shape-rendering: auto;" width="140px" height="140px" viewBox="0 0 140 140" preserveAspectRatio="xMidYMid">
               <circle v-show="!features.dhi || (features.dhi && features.status.loadedAvatar)" cx="70" cy="70" r="35" fill="currentColor" stroke="none">
                   <animate id="op" attributeName="r" repeatCount="1" dur="1.4s" values="30;50;40;46;30" keyTimes="0;0.25;0.5;0.65;1" begin="1s;op.end+15s"></animate>
                   <animate id="op" attributeName="opacity" repeatCount="1" dur="1.4s" values="1;0.3;0.6;0.3;1" keyTimes="0;0.25;0.5;0.65;1" begin="1s;op.end+15s"></animate>
               </circle>

                <circle cx="70" cy="70" :r="radius" fill="currentColor" stroke="none" stroke-width="0">
                </circle>
            </svg>

            <svg v-if="features.custom.widget.icon === ''" class="icon-widget" viewBox="0 0 256 256">
                <path fill="currentColor"
                      d="m 64.305832,131.76221 c 0,17.836 2.87,26.817 2.912,26.936 1.834,4.585 3.955,6.699 8.4,8.365 a 58.184,58.184 0 0 0 7.826,1.4 l 5.537,11.9 12.648998,-10.556 c 3.101,0.105 6.517,0.161 10.269,0.161 26.6,0 36.19,-2.863 36.309,-2.905 4.459,-1.694 6.58,-3.808 8.4,-8.4 0.042,-0.07 2.912,-9.1 2.912,-26.894 0,-17.794 -2.87,-26.824 -2.891,-26.901 -1.841,-4.62 -3.955,-6.733997 -8.4,-8.399997 -0.119,-0.042 -9.709,-2.905 -36.309,-2.905 -26.263998,0 -36.189998,2.863 -36.301998,2.898 -4.466,1.666 -6.58,3.779997 -8.4,8.399997 -0.042,0.077 -2.912,9.065 -2.912,26.901 z m 15.092,-1.841 a 2.8,2.8 0 0 1 2.8,-2.8 h 38.576998 a 2.8,2.8 0 1 1 0,5.6 H 82.197832 a 2.8,2.8 0 0 1 -2.8,-2.8 z m 49.356998,18.13 H 89.512832 a 2.8,2.8 0 1 1 0,-5.6 h 39.241998 a 2.8,2.8 0 0 1 0,5.6 z m 0,-30.667 H 89.512832 a 2.8,2.8 0 1 1 0,-5.6 h 39.241998 a 2.8,2.8 0 0 1 0,5.6 z m 61.901,-35.909997 c -1.099,-2.8 -2.38,-4.053 -5.082,-5.054 -0.063,0 -6.062,-1.757 -21.931,-1.757 -16.1,0 -21.868,1.736 -21.945,1.757 -2.681,1.008 -3.962,2.289 -5.075,5.075 a 45.122,45.122 0 0 0 -1.4,8.764 c 10.325,0.924 14.35,2.254 14.406,2.254 h 0.077 c 5.523,2.1 8.561,5.089 10.822,10.772997 l 0.133,0.392 c 0.294,0.966 1.925,6.741 2.674,17.136 h 0.315 q 3.395,0 6.202,-0.098 l 7.644,6.384 3.36,-7.175 a 33.747,33.747 0 0 0 4.711,-0.812 c 2.688,-1.001 3.969,-2.282 5.082,-5.054 0,-0.07 1.757,-5.495 1.757,-16.274997 0,-10.78 -1.75,-16.247 -1.75,-16.31 z">
                </path>
            </svg>
            <img v-else :src="features.custom.widget.icon" class="icon-widget" alt="">

            <svg class="animate-widget" xmlns="http://www.w3.org/2000/svg" style="margin: auto; display: block; shape-rendering: auto;" width="140px" height="140px" viewBox="0 0 140 140" preserveAspectRatio="xMidYMid">
                <circle cx="70" cy="70" r="33" fill="none" stroke="#FFFFFF" stroke-opacity="0.3" stroke-width="4">
                </circle>

                <circle v-if="features.dhi && !features.status.loadedAvatar" class="animate-filter" cx="70" cy="70" fill="none" stroke="currentColor" stroke-opacity="1" stroke-width="4" stroke-linecap="round" r="40" stroke-dasharray="90 180">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="2.5s" values="0 70 70;360 70 70" keyTimes="0;1"></animateTransform>
                </circle>
            </svg>
        </div>
    </div>
</template>

<script>

const StorageKeys = {
    WIDGET: '_algho_widget',
}

export default {
    name: 'Widget',
    components: {
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            hover: false,
            radius: '35',
        }
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        if (!this.features.device.mobile) {
            window.addEventListener("unload", this.unload, {once: true});
        }
    },
    destroyed() {
        window.removeEventListener("unload", this.unload, {once: true});
    },
    mounted() {
        const self = this;

        if (this.features.custom.widget.bottom !== '')
            this.$refs.widget.style.bottom = this.features.custom.widget.bottom;
        if (this.features.custom.widget.right !== '')
            this.$refs.widget.style.right = this.features.custom.widget.right;
        if (this.features.custom.widget.left !== '')
            this.$refs.widget.style.left = this.features.custom.widget.left;
        if (this.features.custom.widget.top !== '')
            this.$refs.widget.style.top = this.features.custom.widget.top;

        if (!this.features.device.mobile) {
            if (sessionStorage.getItem(StorageKeys.WIDGET) === '1') {
                setTimeout(() => {
                    self.features.status.opened = true;
                }, 1600);
            } else {
                if (this.features.tooltip) {
                    setTimeout(() => {
                        self.$nextTick(() => {
                            if (self.$refs.tooltip !== undefined) {
                                //self.$refs.tooltip._tippy.show();
                                const event = new Event('mouseenter');
                                self.$refs.tooltip.dispatchEvent(event);
                            }
                        });
                    }, 1600);
                }
            }
        }

    },
    methods: {
        unload() {
            sessionStorage.setItem(StorageKeys.WIDGET, (this.features.status.opened) ? '1' : '0');
        },
        zoom(val) {
            this.hover = val;
            this.radius = val ? '38': '35';
        },
        openUI() {
            this.features.status.click = true;
            this.$emit("onShow");
            if (!this.features.demo)
                this.api.trackOpenNotch();
        },
    },
}

</script>

<style lang="less">

.chat-widget {
    position: fixed;

    right: 35px;
    bottom: 35px;
    width: 70px;
    height: 70px;

    cursor: pointer;

    &:hover {
        .chat-widget-container {
            .icon-widget {
                width: 76px;
                height: 76px;
                top: -3px;
                left: -3px;
            }
        }
    }

    .chat-widget-container {
        position: relative;
        width: 100%;
        height: 100%;

        .animate-widget {
            pointer-events: none;
            position: absolute;
            top: -35px;
            left: -35px;
            color: var(--bg-color-widget);
            filter: drop-shadow(0px 0px 10px #0000003D);
            -webkit-filter: drop-shadow(0px 0px 6px #0000003D);
        }

        .animate-filter {
            filter: drop-shadow(0px 0px 6px var(--bg-color-widget));
        }

        .icon-widget {
            pointer-events: none;
            position: absolute;
            width: 70px;
            height: 70px;
            left: 0;
            top: 0;
            border-radius: 50%;
            color: var(--fg-color-widget);
        }

    }

}

.widget-theme.tippy-tooltip {
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);
    padding: 10px 12px 8px 12px;
}

</style>
