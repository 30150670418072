<template>
    <div v-if="isVisible" class="message-suggestion margin-common-content-multimedia">

        <ul v-if="$device.isMobileOnly && !showCorrelated" class="suggestion-list">
            <li class="suggestion-item" @click="showCorrelatedSuggestion">
                <span class="suggestion-item-other">{{$translate(api.language, 'suggestion.title')}}</span>
            </li>
        </ul>

        <ul v-if="!$device.isMobileOnly || showCorrelated" class="suggestion-list">
            <template v-for="(option, index) in suggestions">
                <li v-if="showAll || index < maxSuggestions" :key="index" class="suggestion-item" @click="onInputClicked(option)">
                    <span class="suggestion-item-text">{{option.optionText}}</span>
                </li>
            </template>
            <li v-if="!showAll && suggestions.length > maxSuggestions" class="suggestion-item" @click="onToggle">
                <span class="suggestion-item-other">{{$translate(api.language, 'suggestion.others')}}</span>
            </li>
        </ul>
    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import InputMode from "@/classes/InputMode";

export default {
    name: "Suggestion",
    mixins: [CommandMixin],
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            maxSuggestions: 3,
            showCorrelated: !this.features.hideMobileSuggestion,
            showAll: this.features.showAllSuggestion
        };
    },
    computed: {
      isVisible() {
          return this.message.enabled && this.suggestions && this.suggestions.length > 0 && !this.features.livechat.waiting;
      }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        showCorrelatedSuggestion() {
            this.showCorrelated = true;
            this.$emit('onScrollBottom');
        },
        onToggle() {
            this.showAll = true;
            if (this.features.scrollDownSuggestion)
                this.$emit('onScrollBottom');
        },
        onInputClicked: function (option) {
            const value = {
                inputMode: InputMode.CLICK,
                optionId: option.optionId,
                optionType: option.optionType,
                optionValue: option.optionText,
                optionText: option.optionText,
                command: option.command || null,
            };
            this.$emit("onSendCommand", value);
        }
    }
}
</script>

<style lang="less">

.message-suggestion {

    display: block;
    width: auto;

}

.suggestion-list {
    list-style-type: none;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);

    .suggestion-item:first-child {
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
    }

    .suggestion-item:last-child {
        border-bottom: 0;
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }

    .suggestion-item {
        position: relative;
        display: block;
        padding: 8px 12px 9px 12px;
        background-color: var(--bg-other-color);
        border-bottom: 1px solid var(--border-other-color);
        font-size: var(--font-12);
        line-height: calc(var(--font-12) + 3px);
        cursor: pointer;
    }

    .suggestion-item-text {
        color: var(--fg-other-color);

        &:hover {
            color: var(--primary-color-text) !important;
        }
    }

    .suggestion-item-other {
        color: var(--primary-color-text);
    }
}

.kiosk {
    .suggestion-list {
        margin: 0 40px;
    }
}

</style>
