<template>
    <div class="container-livechat">
        <template v-if="!plugin.loaded">
            <ul v-if="connection == null && features.livechat.requested" class="message-livechat-content">
                <li class="message-livechat-title" v-html="operatorQuestionMessage"></li>
                <li class="message-livechat-items">
                    <div class="message-livechat-item" @click="doActivate">{{$translate(api.language, 'livechat.yes')}}</div>
                    <div class="message-livechat-item" @click="doCancel">{{$translate(api.language, 'livechat.no')}}</div>
                </li>
            </ul>

            <template v-else>
                <ul v-if="showNoOperators" class="message-livechat-content">
                    <li class="message-livechat-title" v-html="operatorUnavalaibleMessage"></li>
                    <li class="message-livechat-items">
                        <div class="message-livechat-item w-100" @click="showNoOperators = false">{{$translate(api.language, 'livechat.close')}}</div>
                    </li>
                </ul>

                <ul v-if="connection != null && timer.waitOperator != null" class="message-livechat-content">
                    <li class="message-livechat-title" v-html="$translate(api.language, 'livechat.wait')"></li>
                    <li class="message-livechat-items">
                        <div class="message-livechat-item w-100" @click="doClose">
                            <svg viewBox="0 0 36 36" class="circular-chart">
                                <path class="circle-off"
                                      stroke-dasharray="100, 100'"
                                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <path class="circle-on"
                                      :stroke-dasharray="(contDown/60*100) + ', 100'"
                                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                                <text x="18" y="23" class="percentage">{{contDown}}</text>
                            </svg>
                            {{$translate(api.language, 'livechat.cancel')}}
                        </div>
                    </li>
                </ul>
            </template>
        </template>

        <div v-show="plugin.loaded" ref="plugin">
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import pluginComponent from "../../../classes/PluginComponent.js";

const StorageKeys = {
    LIVECHAT: '_algho_livechat',
}

export default {
    name: "LiveChat",
    components: {

    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data: function() {
        return {
            connection: null,
            showNoOperators: false,
            initialCountDown: 60,
            contDown: null,
            timer: {
                waitOperator: null,
                noOperator: null,
                fakeMessage: null
            }
        }
    },
    watch: {
        'features.livechat.open': {
            handler(newVal) {
                if (newVal) {
                    this.features.livechat.open = false;
                    this.features.livechat.close = false;
                    this.features.livechat.requestType = '';
                    this.doActivate();
                }
            }
        },
        'features.livechat.close': {
            handler(newVal) {
                if (newVal) {
                    this.features.livechat.open = false;
                    this.features.livechat.close = false;
                    this.features.livechat.requestType = '';
                    this.doDeactivate();
                }
            }
        },
        'features.livechat.requested': {
            handler(newVal) {
                if (newVal) {
                    if (this.plugin.loaded) {
                        this.features.livechat.requested = false;
                        this.doActivate();
                    }
                }
            }
        },
        'plugin.loaded': {
            handler(newVal) {
                if (newVal) {
                    this.doPluginLoaded();
                }
            }
        },
    },
    computed: {
        plugin() {
            return this.features.instance.plugins.livechat;
        },
        operatorUnavalaibleMessage() {
            return this.api.bot.operatorUnavalaibleMessage;
        },
        operatorQuestionMessage() {
            let message = this.api.bot.requestOperatorInMessage || '';
            if (message !== '')
                return message;
            else
                return this.features.livechat.requestType === 'REQUEST_OPERATOR' ? this.$translate(this.api.language, 'livechat.question') : this.$translate(this.api.language, 'livechat.request');
        },
        rejectOperatorMessage() {
            return this.api.bot.rejectToCallWithOperatorMessage || '';
        },
        muted() {
            return !this.features.status.click;
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        window.addEventListener("unload", this.unload, {once : true});
    },
    mounted() {
        //test livechat plugin
        if (this.plugin.src !== '')
            pluginComponent(this.plugin, this.$refs.plugin, {}, this.features.instance);

        this.features.livechat.sendMessage = this.sendMessage;

        if (sessionStorage.getItem(StorageKeys.LIVECHAT) === '1') {
            if (this.plugin.loaded) {
                this.doActivate();
            } else {
                this.api.getLivechatStillAlive().then((success) => {
                    if (success) {
                        this.doActivate();
                    }
                });
            }
        }
    },
    beforeDestroy() {
        if (!this.plugin.loaded) {
            this.doClose();
        }
    },
    destroyed() {
        window.removeEventListener("unload", this.unload, {once : true});
        this.$log.debug('Livechat destroyed');
    },
    methods: {
        unload() {
            sessionStorage.setItem(StorageKeys.LIVECHAT, (this.features.livechat.activated) ? '1' : '0');
        },
        doPluginLoaded() {
            this.$log.debug('Livechat plugin loaded and ready');
            this.plugin.receive(this.onMessage);
            this.plugin.activated(this.onActivated);
        },
        doActivate() {
            if (!this.plugin.loaded) {
                if (this.connection == null) {
                    const url = this.api.getLiveChatUrl('/livechat');
                    this.$log.debug("Livechat opening", url);
                    this.connection = new WebSocket(url)

                    this.connection.onopen = this.onOpen;
                    this.connection.onclose = this.onClose;
                    this.connection.onerror = this.onError;
                    this.connection.onmessage = this.onMessage;
                }
            } else {
                this.plugin.open();
                Vue.$common.scrollToBottom();
                this.features.livechat.waiting = true;
            }
        },
        doDeactivate() {
            if (!this.plugin.loaded) {
                this.onCancel();
                this.playMessages(this.api.getOperatorDisconnectMessage(this.muted));
            } else {
                this.plugin.close();
                this.features.livechat.waiting = false;
            }
        },
        sendMessage(value) {
            if (!this.plugin.loaded) {
                const data = {
                    conversationId: this.api.conversationId,
                    currentUrl: this.api.currentUrl,
                    question_text: value.optionValue
                };
                this.sendData(JSON.stringify(data));
            } else {
                this.plugin.send(value.optionValue);
            }
        },
        onActivated(newVal) {
            this.features.livechat.activated = newVal;
            if (newVal)
                this.features.livechat.waiting = false;

            if (this.plugin.loaded) {
                if (this.features.livechat.activated)
                    this.playMessages(this.api.getOperatorConnectMessage(this.muted));
                else
                    this.playMessages(this.api.getOperatorDisconnectMessage(this.muted));
            }
        },
        onMessage(event) {
            if (!this.plugin.loaded) {
                const data = JSON.parse(event.data);

                if (data.conversationId !== undefined) {
                    this.api.conversationId = data.conversationId;
                    this.$log.debug("Livechat conversationId", data.conversationId);
                }

                if (data.responseType !== undefined) {
                    this.hideTimerWaitOperator();

                    if (data.responseType === 'KO') {
                        if (data.messageCode === 'NO_OPERATORS_AVALAIBLE') {
                            this.setShowNotOperators(true);
                            this.doClose();
                        }
                    } else {
                        if (data.value.answerList !== undefined) {
                            this.setShowNotOperators(false);

                            this.features.livechat.activated = true;
                            this.features.status.opened = true;
                            this.playMessages(this.api.getLivechatMessages(data, this.muted));
                            //se arriva il messaggio annulla quello programmato si è in contatto con un operatore
                            this.clearTimer('fakeMessage');
                        }
                    }
                } else if (data.command !== undefined) {
                    if (data.command === 'OPEN_SOCKET') {
                        // Close the wait , in contact with operator
                        this.hideTimerWaitOperator();
                        this.features.livechat.activated = true;
                        this.features.livechat.waiting = false;
                        const self = this;
                        this.setTimer('fakeMessage', function () {
                            //quando si termina un form non c'è il messaggio in contatto con operatore
                            self.playMessages(this.api.getOperatorConnectMessage(self.muted));
                        }, 1500);

                    }
                } else if (data.type !== undefined) {
                    if (data.type === 'PING') {
                        this.pong();
                    }
                }
            } else {
                const message = event;
                this.features.status.opened = true;
                this.playMessages(this.api.getStandardMessage(message, this.muted));
            }

        },

        doCancel() {
            this.setShowNotOperators(false);
            this.features.livechat.requested = false;
            this.api.sendLivechatReject();
            if (this.rejectOperatorMessage !== '')
                this.playMessages(this.api.getStandardMessage(this.rejectOperatorMessage, this.muted));
        },
        showTimerWaitOperator() {
            this.hideTimerWaitOperator();
            this.timer.waitOperator = setInterval(this.onCountDown, 1000);
            Vue.$common.scrollToBottom();
        },
        hideTimerWaitOperator() {
            if (this.timer.waitOperator != null) {
                clearInterval(this.timer.waitOperator);
                this.timer.waitOperator = null;
                this.contDown = null;
            }
        },
        clearTimer(name) {
            if (this.timer[name] != null) {
                clearTimeout(this.timer[name]);
                this.timer[name] = null;
            }
        },
        setTimer(name, callback, time) {
            this.clearTimer(name);
            this.timer[name] = setTimeout(()=> {
                this.timer[name] = null;
                callback();
            }, time);
        },
        setShowNotOperators(visible) {
            this.showNoOperators = visible;
            if (visible) {
                const self = this;
                this.setTimer('noOperator', function () {
                    self.showNoOperators = false;
                }, 10000);
            } else {
                this.clearTimer('noOperator');
            }
            Vue.$common.scrollToBottom();
        },
        onCountDown() {
            if (this.contDown === 0) {
                this.hideTimerWaitOperator();
                this.setShowNotOperators(true);
                this.doClose();
            } else {
                this.contDown--;
            }
        },
        doClose() {
            if (this.connection != null) {
                if (this.connection.readyState === this.connection.OPEN) {
                    this.connection.close();
                }
            }
        },
        onOpen(event) {
            this.features.livechat.requested = false;
            this.features.livechat.waiting = true;
            this.contDown = this.initialCountDown;
            this.setShowNotOperators(false);
            this.showTimerWaitOperator();
            this.$log.debug('Livechat connected', event);
        },
        onClose() {
            this.features.livechat.waiting = false;
            this.features.livechat.activated = false;
            this.connection = null;
            this.hideTimerWaitOperator();
            this.$log.debug('Livechat closed');
        },
        onError(error) {
            this.$log.debug('Livechat error', error);
        },
        pong() {
            const data = JSON.stringify({type: 'PONG'});
            this.sendData(data);
        },
        sendData(data) {
            if (this.connection != null) {
                if (this.connection.readyState === this.connection.OPEN) {
                    this.connection.send(data);
                }
            }
        },
        onCancel() {
            this.doCancel();
            const data = JSON.stringify({type: 'CANCEL_OPERATOR_REQUEST_BY_USER'});
            this.sendData(data);
            this.hideTimerWaitOperator();
            this.doClose();
        },

        playMessages(messages) {
            this.$emit('onReceiveMessages', messages);
            this.$log.debug("Livechat message", messages);
        },

    }
}
</script>

<style lang="less">
.container-livechat {
    .message-livechat-content {
        display: flex;
        flex-direction: column;
        list-style-type: none;

        padding: 0;
        margin: 10px 20px;

        color: var(--fg-other-color);
        background-color: var(--bg-other-color);
        box-shadow: var(--box-shadow);
        backdrop-filter: var(--backdrop-filter);
        border-radius: var(--border-radius);
        font-size: var(--font-12);
        line-height: calc(var(--font-12) + 3px);

        text-align: center;

        .message-livechat-title {
            padding: 9px 12px 8px 12px;
            border-bottom: 1px solid var(--border-other-color);

            p {
                margin: 0;
            }
        }

        .message-livechat-items {
            display: flex;
            flex-direction: row;
            border-bottom: 0;

            .message-livechat-item {
                position: relative;
                color: var(--fg-other-color);
                border-right: 1px solid var(--border-other-color);
                width: 50%;
                padding: 8px 12px 9px 12px;
                cursor: pointer;

                &.w-100 {
                    width: 100%;
                    border-right: unset;
                }

                &:last-child {
                    border-right: 0;
                }

                &:hover {
                    color: var(--primary-color-text);
                }
            }
        }

        .circular-chart {
            position: absolute;
            display: inline-block;
            bottom: 3px;
            right: 3px;
            width: 26px;
            height: 26px;

            .circle-off {
                stroke: var(--border-other-color);
                fill: none;
                stroke-width: 3;
                stroke-linecap: round;
            }

            .circle-on {
                stroke: var(--primary-color);
                fill: none;
                stroke-width: 3;
                stroke-linecap: round;
            }

            .percentage {
                fill: var(--fg-other-color);
                font-size: 14px;
                text-anchor: middle;
            }
        }

    }
}

</style>
