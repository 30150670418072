<template>
    <div v-if="message.enabled" class="message-form">
        <DatePicker
            :locale="$locale(this)"
            class="calendar"
            v-model="value"
            :attributes="attr"
            :available-dates="dates"
            mode="date"
        />

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns'
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import DatePicker from "@/components/calendar/components/DatePicker";


export default {
    name: "FieldDate",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        DatePicker
    },
    data() {
        return {
            value: null,
        };
    },
    computed: {
        disabled() {
            if (!this.multiple) {
                return (this.value === null);
            } else {
                return (this.value === null || this.value.length == 0);
            }
        },
        optionType() {
            return this.options.length > 0 ? 'AdmissibleValue' : null;
        },
        dates() {
            return this.options.map(function (item) {
                return {
                    start: new Date(item.optionValue),
                    end: new Date(item.optionValue)
                };
            })
        },
        attr() {
            const dates = [];
            this.suggested.map(function (item) {
                dates.push(new Date(item.optionValue));
            });
            return [
                {
                    dot: true,
                    dates: dates,
                    popover: {
                        label: 'Suggested',
                        visibility: 'hover'
                    }
                },

            ];
        }
    },
    methods: {
        onInputClicked: function () {
            const value = {
                inputMode: InputMode.CLICK,
                optionType: this.optionType,
                optionValue: format(this.value, "yyyy-MM-dd"),
                optionText: format(this.value, "dd/MM/yyyy"),
            };
            this.$emit("onSendCommand", value);
        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand", value);
        },
    }
}
</script>

<style>

</style>
