import Vue from 'vue'
import Vuex from 'vuex'
import {DateTime} from "luxon";

Vue.use(Vuex);

export default () => {
    return new Vuex.Store({
        state: {
            messages: [],
            myself: {},
            participants: [],
            chatTitle: '',
        },
        mutations: {
            newMessage: (state, message) => {
                message.myself = message.participantId === state.myself.id;
                state.messages = [...state.messages, message];
            },
            setParticipants: (state, participants) => {
                state.participants = participants;
            },
            setMyself: (state, myself) => {
                state.myself = myself;
            },
            setMessages: (state, messages) => {
                let id = 0;

                const groupId = Date.now().toString();

                state.messages = messages.map(message => {

                    message.id = id++;
                    if (message.guid === undefined)
                        message.guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                            return v.toString(16);
                        });

                    if (message.groupId === undefined)
                        message.groupId = groupId;

                    if (message.groupType === undefined)
                        message.groupType = '';

                    if (message.timestamp === undefined)
                        message.timestamp = DateTime.local();

                    message.timestamp = DateTime.fromISO(message.timestamp);

                    if (!("myself" in message))
                        message.myself = message.participantId === state.myself.id;
                    return message
                });
            },
            setChatTitle: (state, title) => {
                state.chatTitle = title;
            },
        },
        actions: {},
        getters: {
            getParticipantById: (state) => (id) => {
                let curr_participant;
                state.participants.forEach(participant => {
                    if (participant.id === id) {
                        curr_participant = participant;
                    }
                });

                return curr_participant;
            },
            messages: (state) => {
                return state.messages;
            },
            myself: (state) => {
                return state.myself;
            }
        }
    })
}
