var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"message-multimedia margin-common-content-multimedia"},[_c('Modal',{staticClass:"modal-slideshow",attrs:{"fullscreen":!_vm.features.kiosk && _vm.fullscreen},on:{"close":function($event){_vm.fullscreen = false}}},[(!_vm.features.kiosk)?_c('vueper-slides',{staticClass:"slideshow slideshow-radius no-shadow",attrs:{"slot":"body","fixed-height":true,"bullets-outside":true,"slide-content-outside":"bottom"},on:{"ready":function($event){return _vm.onChangeSlide($event)},"slide":function($event){return _vm.onChangeSlide($event)}},slot:"body",scopedSlots:_vm._u([{key:"bullets",fn:function(ref){
var bulletIndexes = ref.bulletIndexes;
var goToSlide = ref.goToSlide;
var currentSlide = ref.currentSlide;
return _vm._l((bulletIndexes),function(slideIndex,i){return _c('span',{key:i,staticClass:"slideshow-bullet",class:{ 'active': currentSlide === slideIndex },on:{"click":function($event){return goToSlide(slideIndex)}}},[_vm._v(" "+_vm._s(slideIndex + 1)+" ")])})}}],null,false,1902939834)},_vm._l((_vm.parsedSlides),function(item,index){return _c('vueper-slide',{key:index,attrs:{"title":index.toString(),"image":item.image,"video":item.video,"content":item.content},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('i',{staticClass:"icon icon-loader spinning",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("Loading...")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"vueperslide__content-wrapper",staticStyle:{"flex-direction":"row"}},[_c('div',{staticClass:"slideshow-content",domProps:{"innerHTML":_vm._s(item.content)}})])]},proxy:true}],null,true)})}),1):_vm._e(),(_vm.features.kiosk && !_vm.fullscreen)?_c('KioskMedia',{attrs:{"slot":"body"},on:{"onToggle":_vm.toggle,"onClose":_vm.close},slot:"body"},[_c('vueper-slides',{staticClass:"slideshow",attrs:{"slot":"body","fixed-height":true,"bullets-outside":true,"slide-content-outside":"bottom"},on:{"ready":function($event){return _vm.onChangeSlide($event)},"slide":function($event){return _vm.onChangeSlide($event)}},slot:"body",scopedSlots:_vm._u([{key:"bullets",fn:function(ref){
var bulletIndexes = ref.bulletIndexes;
var goToSlide = ref.goToSlide;
var currentSlide = ref.currentSlide;
return _vm._l((bulletIndexes),function(slideIndex,i){return _c('span',{key:i,staticClass:"slideshow-bullet",class:{ 'active': currentSlide === slideIndex },on:{"click":function($event){return goToSlide(slideIndex)}}},[_vm._v(" "+_vm._s(slideIndex + 1)+" ")])})}}],null,false,1902939834)},_vm._l((_vm.parsedSlides),function(item,index){return _c('vueper-slide',{key:index,attrs:{"title":index.toString(),"image":item.image,"video":item.video,"content":item.content},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('i',{staticClass:"icon icon-loader spinning",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("Loading...")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"vueperslide__content-wrapper",staticStyle:{"flex-direction":"row"}},[_c('div',{staticClass:"slideshow-content",domProps:{"innerHTML":_vm._s(item.content)}})])]},proxy:true}],null,true)})}),1)],1):_vm._e()],1),(!_vm.features.kiosk)?_c('div',{staticClass:"media-action"},[_c('ButtonIcon',{staticClass:"action-small",attrs:{"name":"fullscreen","size":"16px"},on:{"click":_vm.toggle}})],1):_vm._e(),(_vm.features.kiosk && _vm.fullscreen)?_c('Panel',{attrs:{"features":_vm.features,"border":false},on:{"onClose":function($event){_vm.fullscreen = false}}},[_c('div',{staticClass:"modal-body not-scrollable",attrs:{"slot":"body"},slot:"body"},[_c('vueper-slides',{staticClass:"slideshow no-shadow",attrs:{"slot":"body","fixed-height":true,"bullets-outside":true,"slide-content-outside":"bottom"},on:{"ready":function($event){return _vm.onChangeSlide($event)},"slide":function($event){return _vm.onChangeSlide($event)}},slot:"body",scopedSlots:_vm._u([{key:"bullets",fn:function(ref){
var bulletIndexes = ref.bulletIndexes;
var goToSlide = ref.goToSlide;
var currentSlide = ref.currentSlide;
return _vm._l((bulletIndexes),function(slideIndex,i){return _c('span',{key:i,staticClass:"slideshow-bullet",class:{ 'active': currentSlide === slideIndex },on:{"click":function($event){return goToSlide(slideIndex)}}},[_vm._v(" "+_vm._s(slideIndex + 1)+" ")])})}}],null,false,1902939834)},_vm._l((_vm.parsedSlides),function(item,index){return _c('vueper-slide',{key:index,attrs:{"title":index.toString(),"image":item.image,"video":item.video,"content":item.content},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('i',{staticClass:"icon icon-loader spinning",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("Loading...")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"vueperslide__content-wrapper",staticStyle:{"flex-direction":"row"}},[_c('div',{staticClass:"slideshow-content",domProps:{"innerHTML":_vm._s(item.content)}})])]},proxy:true}],null,true)})}),1)],1),_c('div',{attrs:{"slot":"powered"},slot:"powered"})]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }