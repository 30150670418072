<template>
    <div></div>
</template>

<script>

import forge from "node-forge/dist/forge.min.js";
import {DateTime} from "luxon";
import axios from "axios";
import Helpers from "../classes/Helpers";
import Vue from "vue";

const Timeout = 10000;
const StorageKeys = {
    BOT: '_algho_bot',
    WELCOME: '_algho_welcome',
    WELCOMEBACK: '_algho_welcome_back',
}

const CookieKeys = {
    USERID: '_algho_user_id',
    PRIVACYID: '_algho_policy_id',
    PRIVACYWEBCAMID: '_algho_policy_em_id',
    GROUPID: '_algho_group_id'
}

const BotCacheTime = 60 * 5;

export default {
    name: "Api",
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            demo: false,
            baseUrl: '',
            apiPath: '',

            bot: null,
            botId: null,
            userId: null,
            userIdPrefix: 'Algho',
            groupId: null,
            conversationId: null,
            language: null,
            token: null,
            authorization: null,
            context: null,
            currentUrl: null,
            wsUrl: null,
            cookieDomain: null,
            cookieSecure: window.location.protocol === 'https:',

            source: 'unknown',
            sourceType: '',


            history: false,
            delayMode: null,
            vote: null,

            conversationTimeout: 900,

        }
    },

    methods: {
        init(baseUrl, apiPath, botId, userId, groupId, currentUrl, token, context, authorization, userIdPrefix, wsUrl, cookieDomain, source, sourceType, demo) {
            this.baseUrl = baseUrl;
            this.apiPath = apiPath;
            this.botId = botId;
            this.userId = userId;
            this.groupId = groupId;
            this.currentUrl = currentUrl;
            this.token = token;
            this.context = context;
            this.authorization = authorization;
            this.userIdPrefix = userIdPrefix;
            this.wsUrl = wsUrl;
            this.cookieDomain = cookieDomain;
            this.source = source;
            this.sourceType = sourceType;
            this.demo = demo;

            if (this.demo) {
                this.apiPath = '/fakeapi';
                this.botId = 'demo';
                this.userId = 'demo';
            }

            if (!this.features.kiosk) {
                let lang = navigator.language || navigator.userLanguage;
                if (lang.indexOf("-") >= -1)
                    lang = lang.substr(0, 2);

                this.language = lang;
            } else {
                this.language = '';
            }

            this.setPreferedBotFromCookie();
        },
        setToken(token) {
            this.token = token;
        },

        setContext(context) {
            this.context = context;
        },

        setConversationId(conversationId) {
            this.conversationId = conversationId;
        },

        getBot() {
            return this.bot;
        },

        applyBotConfig() {

            //fix relative path
            if (this.bot.privacyUrl !== undefined) {
                if (this.bot.privacyUrl.indexOf('/') === 0)
                    this.bot.privacyUrl = this.baseUrl + this.bot.privacyUrl;
            }

            //apply
            this.botId = this.bot.botId;
            this.language = this.bot.lang;

            this.$log.info('SET LANGUAGE', this.language);

            this.delayMode = this.bot.paragraphDelay;
            this.$log.info('SET PARAGRAPH DELAY', this.paragraphDelay);

            this.voting = this.bot.voting;
            this.$log.info('SET VOTING', this.voting);

            //Check agreements
            this.features.agreements.service = this.bot.activeAgreements.service;
            this.features.agreements.marketing = this.bot.activeAgreements.marketing;
            this.features.agreements.profiling = this.bot.activeAgreements.profiling;
            this.features.agreements.sensitive = this.bot.activeAgreements.sensitive;
            this.features.agreements.disclosure =this.bot.activeAgreements.disclosure;

            //Check features
            this.features.has.text = this.bot.features.textAssistant;
            this.features.has.voice = this.bot.features.vocalAssistant;
            this.features.has.dhi = this.bot.features.digitalHumanInterface;
            this.features.has.kiosk = this.bot.features.kiosk;
            this.features.has.livechat = this.bot.features.livechat;

            this.features.has.banner = this.bot.features.kioskBanner;
            this.features.has.webcam = (!this.features.kiosk && this.bot.features.emotionalAnalysis && this.bot.emotionalAnalysis) || this.features.webcam.activated; //per demo giornalisti

            this.features.has.multibot = this.bot.botGroup.bots.length > 0;
            this.features.has.cta = Object.keys(this.bot.callToAction).filter(function (key) { return key !== 'operator'}).length > 0;
            this.features.has.privacy = this.bot.privacyUrl !== undefined;

            if (!(this.features.has.voice || this.features.has.kiosk)) {
                this.$log.info('Voice interface not enabled');
                this.features.stt = false;
                this.features.tts = false;
            }

            if (!(this.features.has.dhi || this.features.has.kiosk)) {
                this.$log.info('DHI interface not enabled');
                this.features.dhi = false;
            }

            if (this.features.kiosk) {
                this.$log.info('Enable Kiosk i-frame navigation');
                this.features.browser.enable = true;
            }

            if (!this.features.has.livechat) {
                this.$log.info('Livechat not enabled');
            }

            //multibot
            if (this.features.has.multibot) {
                this.$log.info('Bot has multibot');
                this.features.multibot = true;
            }

            //cta & privacy & others
            this.features.cta = this.features.has.cta;
            this.features.privacy = this.features.has.privacy;
            this.features.livechat.show = this.features.has.livechat && this.bot.callToAction.operator;

            //switch mode
            this.features.has.switch = this.features.widget && this.features.has.dhi && this.features.has.voice;

            //logo
            if (this.features.extras.botLogo === '')
                this.features.extras.botLogo = this.bot.iconImage || ''; //far rimettere su algho api

            //kiosk
            /*
            if (window.kiosk) {
                const cmd = {
                    it: ["ok", "ehi", "ciao"],
                    en: ["ok", "hey", "hello", "hi"],
                    fr: ["ok", "hé", "bonjour"],
                    es: ["ok", "oye", "hola"],
                    pt: ["ok", "ei", "ola"],
                    de: ["ok", "hey", "hallo"],
                    ro: ["ok"],
                    zh: ["ok"],
                    ru: ["ok", "priviet"],
                }

                window.kiosk.initApp(this.bot.lang, this.bot.name, cmd[this.bot.lang].join(' '));
            }
            */
        },

        hasWelcomeMessage() {
            return this.bot.welcomeMessage !== undefined
                && this.bot.welcomeMessage !== '';
        },

        hasWelcomeBackMessage() {
            return this.bot.welcomeBackMessage !== undefined
                && this.bot.welcomeBackMessage !== '';
        },

        getWelcomeMessage(privacy, widget) {
            const ret = [];
            const noAudio = widget && sessionStorage.getItem(StorageKeys.WELCOME) === '1';
            if (!privacy)
                sessionStorage.setItem(StorageKeys.WELCOME, '1');

            const groupId = Date.now().toString();

            if (this.hasWelcomeMessage()) {

                const message = {
                    groupId: groupId,
                    content: this.bot.welcomeMessage,
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    noAudio: noAudio,
                    suggestions: null,
                    showTime: true,
                    first: true,
                    last: true,
                    multi: false,
                    history: false,
                    tts: 'welcomeMessage',
                    emotion: 'happy'
                }
                ret.push(message);
            } else {
                const message = {
                    groupId: groupId,
                    content: this.$translate(this.language, 'bot.welcome'),
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    noAudio: noAudio,
                    suggestions: null,
                    showTime: true,
                    first: true,
                    last: true,
                    multi: false,
                    history: false,
                    tts: null,
                    emotion: 'happy'
                }
                ret.push(message);
            }

            if (this.features.kiosk && this.bot.privacyUrl !== undefined) {
                const message = {
                    groupId: groupId,
                    content: '<p><span alias="">' + this.$translate(this.language, 'kiosk.privacyMessage') + '</span></p>',
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    noAudio: false,
                    suggestions: null,
                    showTime: true,
                    first: false,
                    last: true,
                    multi: false,
                    history: false,
                    tts: null,
                    emotion: 'happy'
                }
                ret.push(message);
            }

            if (ret.length > 0) {
                ret[0].showTime = true;
                ret[0].first = true;
                ret[0].last = false;
                ret[ret.length - 1].last = true;
                ret[ret.length - 1].suggestions = this.bot.options;

                if (ret.length > 1) {
                    for (let i in ret) {
                        ret[i].multi = true;
                    }
                }
            }

            return ret;
        },

        getWelcomeBackMessage(privacy, widget) {
            const ret = [];
            const noAudio = widget && sessionStorage.getItem(StorageKeys.WELCOMEBACK) === '1';
            if (!privacy)
                sessionStorage.setItem(StorageKeys.WELCOMEBACK, '1');

            if (this.hasWelcomeBackMessage()) {
                const message = {
                    content: this.bot.welcomeBackMessage,
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    noAudio: noAudio,
                    showTime: true,
                    first: true,
                    last: true,
                    multi: false,
                    history: false,
                    tts: 'welcomeBackMessage',
                    emotion: 'happy'
                }
                ret.push(message);
            }
            return ret;
        },

        getOperatorConnectMessage(muted) {
            const ret = [];
            if (this.bot.operatorConnectMessage !== undefined) {
                const message = {
                    content: this.bot.operatorConnectMessage,
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    //noAudio: true
                    showTime: true,
                    first: true,
                    last: true,
                    multi: false,
                    history: false,
                    noAudio: muted,
                    tts: 'operatorConnectMessage',
                    emotion: 'happy'
                }
                ret.push(message);
            }
            return ret;
        },

        getOperatorDisconnectMessage(muted) {
            const ret = [];
            if (this.bot.operatorDisconnectMessage !== undefined) {
                const message = {
                    content: this.bot.operatorDisconnectMessage,
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    //noAudio: true
                    showTime: true,
                    first: true,
                    last: true,
                    multi: false,
                    history: false,
                    noAudio: muted,
                    tts: 'operatorDisconnectMessage',
                    emotion: 'happy'
                }
                ret.push(message);
            }
            return ret;
        },

        getStandardMessage(text, muted) {
            const ret = [];
            const message = {
                content: text,
                myself: false,
                participantId: 1,
                timestamp: DateTime.local(),
                uploaded: true,
                enabled: false,
                hidden: false,
                viewed: true,
                //noAudio: true
                showTime: true,
                first: true,
                last: true,
                multi: false,
                history: false,
                noAudio: muted,
                emotion: 'happy'
            }
            ret.push(message);

            return ret;
        },

        getPrivacyMessage() {
            let ret = null;
            const oldPrivacyDisclaimeId = this.getPrivacyBotCookie(this.botId);

            if (this.bot.privacyMessage !== undefined && this.bot.privacyMessage !== '' &&
                this.bot.privacyDisclaimeId !== undefined && this.bot.privacyDisclaimeId != null) {

                const privacyDisclaimeId = this.bot.privacyDisclaimeId.toString();
                let content = this.bot.privacyMessage;

                if ((oldPrivacyDisclaimeId == null) || (oldPrivacyDisclaimeId != null && oldPrivacyDisclaimeId !== privacyDisclaimeId)) {

                    if (oldPrivacyDisclaimeId != null)
                        content = this.bot.privacyUpdateMessage;

                    ret = content.replace("/privacy", this.baseUrl + "/privacy");
                }
            }
            return ret;
        },
        hasPrivacyMessage() {
            let ret = false;
            const oldPrivacyDisclaimeId = this.getPrivacyBotCookie(this.botId);

            if (this.bot.privacyMessage !== undefined && this.bot.privacyMessage !== '' &&
                this.bot.privacyDisclaimeId !== undefined && this.bot.privacyDisclaimeId != null) {

                const privacyDisclaimeId = this.bot.privacyDisclaimeId.toString();

                if ((oldPrivacyDisclaimeId == null) || (oldPrivacyDisclaimeId != null && oldPrivacyDisclaimeId !== privacyDisclaimeId)) {

                    ret = true;
                }
            }
            return ret;
        },
        setPrivacyMessage() {
            const oldPrivacyDisclaimeId = this.getPrivacyBotCookie(this.botId);

            if (this.bot.privacyMessage !== undefined && this.bot.privacyMessage !== '' &&
                this.bot.privacyDisclaimeId !== undefined && this.bot.privacyDisclaimeId != null) {

                const privacyDisclaimeId = this.bot.privacyDisclaimeId.toString();

                if ((oldPrivacyDisclaimeId == null) || (oldPrivacyDisclaimeId != null && oldPrivacyDisclaimeId !== privacyDisclaimeId)) {
                    this.setPrivacyBotCookie(this.botId, privacyDisclaimeId);
                    this.$cookies.remove(CookieKeys.PRIVACYWEBCAMID, null, this.cookieDomain);
                }
            }
        },

        setPrivacyBotCookie(botId, privacyId) {
            let privacyIds = this.$cookies.get(CookieKeys.PRIVACYID) || {};
            if (typeof privacyIds === 'string')
                privacyIds = {};

            privacyIds[botId] = privacyId;
            this.$cookies.set(CookieKeys.PRIVACYID, privacyIds, '1y', null, this.cookieDomain, this.cookieSecure);

            sessionStorage.setItem(StorageKeys.WELCOME, '1');
            sessionStorage.setItem(StorageKeys.WELCOMEBACK, '1');
        },

        getPrivacyBotCookie(botId) {
            const privacyIds = this.$cookies.get(CookieKeys.PRIVACYID) || {};
            if (typeof privacyIds === 'string')
                return privacyIds;
            else
                return privacyIds[botId] || null;
        },

        setPrivacyWebcam() {
            let privacyIds = this.$cookies.get(CookieKeys.PRIVACYWEBCAMID) || {};
            if (typeof privacyIds === 'string')
                privacyIds = {};

            privacyIds[this.botId] = this.bot.privacyDisclaimeId.toString();
            this.$cookies.set(CookieKeys.PRIVACYWEBCAMID, privacyIds, '1y', null, this.cookieDomain, this.cookieSecure);

            const url = this.baseUrl + this.apiPath + '/privacy/agreement/EMOTIONAL';
            const config = {
                timeout: Timeout,
                params: {
                    bot_id: this.botId,
                    user_id: this.userId,
                    accepted: true
                },
            };

            return axios
                .post(url, null, config)
                .then(response => {
                    const json = response.data;
                    return (json.responseType === 'OK');
                }).catch((error) => {
                    this.$log.error('Set Privacy Webcam', error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return false
                });
        },

        getPrivacyWebcam() {
            const privacyIds = this.$cookies.get(CookieKeys.PRIVACYWEBCAMID) || {};
            if (typeof privacyIds === 'string')
                return privacyIds;
            else
                return privacyIds[this.botId] || null;
        },

        setPreferedBotFromCookie() {
            const bot = this.$cookies.get(CookieKeys.GROUPID) || {};
            if (bot.groupId !== undefined && bot.groupId === this.groupId) {
                this.botId = bot.botId;
                this.language = bot.lang;
                this.$log.info('SET BOT FROM COOKIE GROUPID', this.botId, this.language);
            } else {
                this.$cookies.remove(CookieKeys.GROUPID, null, this.cookieDomain);
                this.$log.info('BOT REMOVED FROM COOKIE GROUPID');
            }
        },

        resetAll() {

            this.$log.info('RESET ALL COOKIE AND STORAGE SETTINGv FOR ALGHO');

            const self = this;
            Object.keys(CookieKeys).map(function (key) {
                const cookie = CookieKeys[key];
                if (cookie.indexOf('_algho_') === 0)
                    self.$cookies.remove(cookie, null, self.cookieDomain);
            });

            Object.keys(sessionStorage).map(function (key) {
                if (key.indexOf('_algho_') === 0)
                    sessionStorage.removeItem(key);
            });

            Object.keys(localStorage).map(function (key) {
                if (key.indexOf('_algho_') === 0)
                    localStorage.removeItem(key);
            });
        },

        resetUserId(userId) {
            this.conversationId = null;
            this.userId = userId;
            this.$cookies.remove(CookieKeys.USERID, null, this.cookieDomain);
            this.$cookies.remove(CookieKeys.PRIVACYID, null, this.cookieDomain);
            this.$cookies.remove(CookieKeys.PRIVACYWEBCAMID, null, this.cookieDomain);
            sessionStorage.removeItem(StorageKeys.BOT);
            sessionStorage.removeItem(StorageKeys.WELCOME);
            sessionStorage.removeItem(StorageKeys.WELCOMEBACK);
            this.checkUserId();
        },

        resetBotId(botId, language) {
            this.conversationId = null;
            this.botId = botId;
            this.language = language;
            sessionStorage.removeItem(StorageKeys.BOT);
            sessionStorage.removeItem(StorageKeys.WELCOME);
            sessionStorage.removeItem(StorageKeys.WELCOMEBACK);
            this.checkUserId();
        },

        checkUserId() {
            if (this.userId == null || this.userId.length === 0) {
                if (!this.features.kiosk)
                    this.userId = this.$cookies.get(CookieKeys.USERID);

                if (this.userId === null || this.userId.length === 0) {

                    const ts = Date.now();
                    const rnd = Math.floor((Math.random() * 10000) + 1);
                    const preUserId = this.userIdPrefix + "_" + ts + "_" + rnd;

                    // generate a random key and IV
                    var iv = forge.random.getBytesSync(16);
                    // Note: a key size of 16 bytes will use AES-128, 24 => AES-192, 32 => AES-256
                    var salt = forge.random.getBytesSync(256);
                    var key = forge.pkcs5.pbkdf2('password', salt, 12, 32);

                    // encrypt some bytes using CBC mode
                    // (other modes include: ECB, CFB, OFB, CTR, and GCM)
                    // Note: CBC and ECB modes use PKCS#7 padding as default
                    var cipher = forge.cipher.createCipher('AES-CBC', key);
                    cipher.start({iv: iv});
                    cipher.update(forge.util.createBuffer(preUserId));
                    cipher.finish();
                    var encrypted = cipher.output;
                    // get encrypted hex
                    this.userId = encrypted.toHex();

                    if (!this.features.kiosk)
                        this.$cookies.set(CookieKeys.USERID, this.userId, '1y', null, this.cookieDomain, this.cookieSecure);
                }
                this.$log.debug('USERID', this.userId);
            }
        },

        getMp3(text, speak, lipsync) {
            const url = this.baseUrl + this.apiPath + '/general_tts/create';

            const config = {
                timeout: Timeout,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: {
                    tts: speak ? "MULTIPLE" : "TEXT_MULTIPLE",
                    lipSync: lipsync && speak,
                }
            };

            //todo: ripulire html emoji
            const params = new URLSearchParams();
            params.append('botId', this.botId);
            params.append('input', text);
            params.append('language', this.language);
            params.append('gender', this.bot.gender);
            params.append('voiceId', this.bot.voiceId);

            return axios
                .post(url, params, config)
                .then(response => {
                    const json = response.data;
                    if (json.success) {
                        return {
                            audio: this.baseUrl + "/" + json.output,
                            lipsync: json.lipSyncData
                        };
                    } else {
                        return null;
                    }
                }).catch((error) => {
                    this.$log.error("Get mp3", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return null;
                });
        },

        getTTS(type, speak, lipsync) {
            const url = this.baseUrl + this.apiPath + '/bot/' + this.botId + '/tts';

            const config = {
                timeout: Timeout,
                params: {
                    type: type,
                    tts: speak ? "MULTIPLE" : "TEXT_MULTIPLE",
                    lipSync: lipsync && speak ? "true" : "false",
                }
            };

            //todo: ripulire html emoji

            return axios
                .get(url, config)
                .then(response => {
                    const json = response.data;
                    return json.success ? {
                        audio: this.baseUrl + "/" + json.output,
                        lipsync: json.lipSyncData
                    } : null;
                }).catch((error) => {
                    this.$log.error("Get TTS", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return null;
                });
        },

        voteMessage(data, speak, lipsync) {

            const url = this.baseUrl + this.apiPath + '/qa/' + (data.success ? 'upvote' : 'downvote');
            const config = {
                timeout: Timeout,
                headers: {
                    alghoToken: this.token,
                },
                params: {
                    tts: speak ? "MULTIPLE" : "TEXT_MULTIPLE",
                    lipSync: lipsync && speak ? "true" : "false",
                    lip_sync: lipsync && speak ? "true" : "false", //for downvote fix
                    question_text: data.questionText,
                    answer_id: data.answerId,
                    conversation_id: this.conversationId,
                    source: this.source
                },
            };

            if (this.authorization != null)
                config.headers.Authorization = 'Bearer ' + this.authorization;

            return axios
                .post(url, null, config)
                .then(response => {
                    let parsed = [];
                    if (!data.success) {
                        const conversationId = this.parseConversationId(response);
                        parsed = this.parseMessage(response, this.baseUrl);

                        if (conversationId != null)
                            this.conversationId = conversationId;
                    }

                    return parsed;
                }).catch((error) => {
                    this.$log.error('Vote message', error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return [{
                        content: this.$translate(this.language, 'bot.error'),
                        myself: false,
                        participantId: 1,
                        timestamp: DateTime.local(),
                        uploaded: true,
                        enabled: false,
                        hidden: false,
                        viewed: true,
                        first: true,
                        last: true,
                        multi: false,
                    }];
                });

        },

        loadPreview(link) {
            const url = this.baseUrl + '/../preview/';
            const config = {
                timeout: Timeout,
                params: {
                    url: link
                }
            };

            return axios
                .get(url, config)
                .then(response => {
                    response.data.loaded = true;
                    if (response.data.image !== undefined && response.data.image != null && response.data.image.length > 0)
                        response.data.image = response.data.image[0];
                    else
                        response.data.image = '';

                    return response.data;
                }).catch((error) => {
                    this.$log.error('Load preview', error);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return null;
                });

        },

        downloadFile(link) {
            const url = this.baseUrl + this.apiPath + '/file/file_download';
            const config = {
                timeout: Timeout,
                params: {
                    url: link
                }
            };

            return axios
                .get(url, config)
                .then(response => {
                    if (response.data.status === 200)
                        return response.data;
                    else
                        return null;
                }).catch((error) => {
                    this.$log.error('Load preview', error);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return null;
                });

        },

        loadBotConfig() {

            this.checkUserId();

            let bot = JSON.parse(sessionStorage.getItem(StorageKeys.BOT));

            //check bot cache expire
            if (bot != null) {
                if (bot.botId !== this.botId || (bot.botGroup !== undefined && bot.botGroup.groupId !== undefined && bot.botGroup.groupId !== this.groupId)) {
                    this.$log.info('Bot or group changed, removed from sessionStorage');
                    bot = null;
                    sessionStorage.removeItem(StorageKeys.BOT);
                } else {
                    const now = DateTime.local();
                    const test = DateTime.fromISO(bot.timestamp);
                    const elapsed = now.diff(test, "seconds").seconds;
                    if (elapsed > BotCacheTime) {
                        this.$log.info('Bot cache expired, removed from sessionStorage');
                        bot = null;
                        sessionStorage.removeItem(StorageKeys.BOT);
                    }
                }
            }

            if (bot === null) {

                const url = this.baseUrl + this.apiPath + '/bot/' + this.botId;
                const config = {
                    timeout: Timeout,
                    params: {
                        user_id: this.userId,
                        '3d_scenario': 'fe' //todo: cambiare in seguito
                    },
                };

                if (this.groupId !== '') {
                    const groupId = {
                        groupId: this.groupId,
                        botId: this.botId,
                        lang: this.language
                    };
                    config.params.bot_group_id = this.groupId;
                    config.params.lang = this.language;
                    this.$cookies.set(CookieKeys.GROUPID, groupId, '1y', null, this.cookieDomain, this.cookieSecure);
                }

                return axios
                    .get(url, config)
                    .then(response => {
                        this.bot = this.parseBot(response);
                        this.applyBotConfig();
                        sessionStorage.setItem(StorageKeys.BOT, JSON.stringify(this.bot, null, 2));
                        return true;
                    }).catch((error) => {
                        this.$log.error("Get bot", error.message);
                        /*
                        //todo: leggere errore da json e data
                        {
                          "responseType" : "KO",
                          "messageCode" : "INTERNAL_SERVER_ERROR",
                          "serverTime" : "2021-03-27 22:13:07"
                        }
                         */
                        return false;
                    });

            } else {
                this.bot = bot;
                this.applyBotConfig();
                return Promise.resolve(true);
            }


        },

        getHistory(offset, step) {

            this.checkUserId();

            if (!this.bot.noHistory) {

                const url = this.baseUrl + this.apiPath + '/conversate_item/list';
                const config = {
                    timeout: Timeout,
                    params: {
                        bot_id: this.botId,
                        user_id: this.userId,
                        offset: offset,
                        step: step,
                        sort: 'conversation_item_id+DESC'
                    },
                };

                return axios
                    .get(url, config)
                    .then(response => {

                        let conversationId = null;
                        if (offset === 0)
                            conversationId = this.parseConversationIdFromHistory(response);

                        const parsed = this.parseHistory(response);

                        if (conversationId != null)
                            this.conversationId = conversationId;

                        return parsed;

                    }).catch((error) => {
                        this.$log.error("Get history", error.message);
                        /*
                        //todo: leggere errore da json e data
                        {
                          "responseType" : "KO",
                          "messageCode" : "INTERNAL_SERVER_ERROR",
                          "serverTime" : "2021-03-27 22:13:07"
                        }
                         */
                        return {
                            messages: [],
                            offset: 0
                        };
                    });
            } else {
                this.$log.info('History disabled');
                return Promise.resolve({
                    messages: [],
                    offset: 0
                });
            }
        },

        getEngagement(engagementCounter, speak, webcamData, lipsync) {

            if (this.conversationId != null) {

                const url = this.baseUrl + this.apiPath + '/engagement/conversation/' + this.conversationId;
                const config = {
                    timeout: Timeout,
                    headers: {
                        alghoToken: this.token,
                    },
                    params: {
                        tts: speak ? "MULTIPLE" : "TEXT_MULTIPLE",
                        lipSync: lipsync && speak ? "true" : "false",
                        next_engagement: engagementCounter,
                        current_url: this.currentUrl,
                        applicationContext: this.context
                    },
                };

                if (this.authorization != null)
                    config.headers.Authorization = 'Bearer ' + this.authorization;

                const data = this.features.webcam.activated ? this.webcamDataToFilters(webcamData) : [];

                return axios
                    .post(url, data, config)
                    .then(response => {
                        return this.parseEngagement(response, this.baseUrl, this.conversationId);
                    }).catch((error) => {
                        this.$log.error("Get engagement", error.message);
                        /*
                        //todo: leggere errore da json e data
                        {
                          "responseType" : "KO",
                          "messageCode" : "INTERNAL_SERVER_ERROR",
                          "serverTime" : "2021-03-27 22:13:07"
                        }
                         */
                        return {
                            messages: [],
                            nextTimer: null
                        };
                    });
            } else {
                this.$log.info('Engagement disabled');
                return Promise.resolve({
                    messages: [],
                    nextTimer: null
                });
            }
        },

        async uploadFile(file) {

            const fileToBase64 = async (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = (e) => reject(e)
                });

            const base64 = await fileToBase64(file);

            const url = this.baseUrl + this.apiPath + '/file/file_upload';

            const config = {
                timeout: Timeout
            }
            const data = {
                sourceType: 'FORM',
                extension: file.name.split('.').pop(),
                conversationId: this.conversationId,
                payload: base64.split(',')[1]
            }

            return axios
                .post(url, data, config)
                .then(response => {
                    return response.data.value + '?name=' + file.name;
                }).catch((error) => {
                    this.$log.error("Upload file", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return null;
                });
        },

        sendMessage(obj, speak, webcamData, lipsync) {

            if (!this.bot.hasConversationItems) {
                this.$log.info('First message: bot cache removed from sessionStorage');
                sessionStorage.removeItem(StorageKeys.BOT);
                this.bot.hasConversationItems = true;
            }

            this.checkUserId();

            const url = this.baseUrl + this.apiPath + '/conversate_item/send_message';
            const config = {
                headers: {
                    alghoToken: this.token,
                },
                params: {
                    tts: speak ? "MULTIPLE" : "TEXT_MULTIPLE",
                    lipSync: lipsync && speak ? "true" : "false",
                },
            };

            if (this.authorization != null)
                config.headers.Authorization = 'Bearer ' + this.authorization;

            const data = {
                userId: this.userId,
                botId: this.botId,
                conversationId: this.conversationId,
                currentUrl: this.currentUrl,
                questionId: -1,
                command: null,
                callType: this.authorization == null ? 'standard' : 'sandbox',
                inputMode: null,
                source: this.source,
                sourceType: this.sourceType,
                disableFieldHistory: this.features.kiosk,

                applicationContext: this.context,
                wsBasePath: this.wsUrl
            };

            if (typeof obj === 'string') {
                //from text
                data.inputMode = 'keyboard';
                data.question_text = obj;
            } else if (obj.optionType === undefined) {
                //from form component
                if (obj.optionObject !== undefined)
                    data.question_object = obj.optionObject;
                data.inputMode = obj.inputMode;
                data.question_text = obj.optionValue;

            } else {
                //from suggestion
                data.inputMode = obj.inputMode;
                data.optionType = obj.optionType;
                data.questionId = obj.optionId;
                data.question_text = obj.optionValue;
                data.command = obj.command;
            }

            let isStaging = this.baseUrl.indexOf('http://localhost:8080') !== -1 ||
                this.baseUrl.indexOf('https://staging-fe.alghoncloud.com') !== -1;

            if (isStaging) {
                if (this.features.webcam.activated) {
                    data.filters = this.webcamDataToFilters(webcamData);
                }
            } else {
                if (webcamData.length > 0) {
                    data.filters = this.webcamDataToFilters(webcamData);
                }
            }

            return axios
                .post(url, data, config)
                .then(response => {
                    const conversationId = this.parseConversationId(response);
                    const parsed = this.parseMessage(response, this.baseUrl);

                    //fix missing userQuestionText
                    parsed.map(function (item) {
                        if (item.userQuestionText === undefined)
                            item.userQuestionText = data.question_text;
                    });

                    if (conversationId != null)
                        this.conversationId = conversationId;

                    return parsed;
                }).catch((error) => {
                    this.$log.error("Send message", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return [{
                        content: this.$translate(this.language, 'bot.error'),
                        myself: false,
                        participantId: 1,
                        timestamp: DateTime.local(),
                        uploaded: true,
                        enabled: false,
                        hidden: false,
                        viewed: true,
                        first: true,
                        last: true,
                        multi: false,
                    }];
                });
        },

        trackOpenNotch() {

            this.checkUserId();

            const url = this.baseUrl + this.apiPath + '/bot/open_notch/' + this.botId;
            const config = {
                timeout: Timeout,
                params: {
                    user_id: this.userId,
                    source_type: this.source,
                    operation_type: 'opening',
                    source: this.currentUrl
                },
            };

            return axios
                .post(url, null, config)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    return true;
                }).catch((error) => {
                    this.$log.error("Track open notch", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return false;
                });
        },

        webcamDataToFilters(webcamData) {

            const emotionsList = webcamData.map(function (item) {
                return {
                    timestamp: item.timestamp,
                    emotions: item.emotions
                }
            });

            const attentionList = webcamData.map(function (item) {
                return {
                    timestamp: item.timestamp,
                    attentionParams: item.angles
                }
            });

            return [{
                "@type": "VIDEO_EMOTION",
                "emotionsList": emotionsList
            }, {
                "@type": "VIDEO_ATTENTION",
                "attentionList": attentionList
            }];
        },
        sendWebcamData(webcamData) {

            this.checkUserId();

            //const url = this.baseUrl + this.apiPath + '/emotions/answer_filter';
            const url = this.baseUrl + this.apiPath + '/user_question_filter/answer_filter';

            const config = {
                timeout: Timeout,
                headers: {
                    alghoToken: this.token,
                },
                params: {
                    bot_id: this.botId,
                    conversation_id: this.conversationId,
                    user_id: this.userId
                }
            };

            if (this.authorization != null)
                config.headers.Authorization = 'Bearer ' + this.authorization;

            const data = this.webcamDataToFilters(webcamData);

            return axios
                .post(url, data, config)
                .then(response => {
                    if (response.data.responseType === 'OK') {
                        return true;
                    } else {
                        this.$log.log("Error send emotion", response.data.message);
                        return false;
                    }
                }).catch((error) => {
                    this.$log.error("Send emotion", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return false;
                });
        },

        getLiveChatUrl(wsPath) {
            const conversationId = (this.conversationId != null) ? this.conversationId : -1;
            return this.baseUrl.replace('http', 'ws') + wsPath + '/' + this.botId + '/' + this.userId + '/' + conversationId.toString() + '?currentUrl=' + encodeURIComponent(this.currentUrl);
        },

        getLivechatMessages(data, muted) {
            let messages = this.parseMessage({data: data}, this.baseUrl, true, muted);
            //se scatta un form aggiungo prima il messaggio si è in contatto con il chatbot
            if (data.value !== undefined && data.value.frontEndMessage === "FORM_START")
                messages = this.getOperatorDisconnectMessage(muted).concat(messages);

            return messages;
        },

        sendLivechatReject() {
            this.checkUserId();

            const url = this.baseUrl + this.apiPath + '/operators/' + this.conversationId + '/' + this.botId + '/reject';
            const config = {
                timeout: Timeout
            };

            return axios
                .put(url, null, config)
                .then(response => {
                    const json = response.data;
                    return json.responseType === 'OK';

                }).catch((error) => {
                    this.$log.error("Send Livechat Reject", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return false;
                });
        },

        getLivechatStillAlive() {
            this.checkUserId();

            const url = this.baseUrl + this.apiPath + '/bot/' + this.botId + '/' + this.userId + '/live_chat_still_alive';
            const config = {
                timeout: Timeout
            };

            return axios
                .get(url, config)
                .then(response => {
                    const json = response.data;
                    if (json.responseType === 'OK')
                        return json.value;
                    else
                        return false;
                }).catch((error) => {
                    this.$log.error("Get Livechat Still Alive", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return false;
                });
        },

        getOrderMessage(orderId) {
            const url = this.baseUrl + this.apiPath + '/order/' + orderId;

            const config = {
                timeout: Timeout,
                params: {
                    extended: 'true',
                }
            };

            return axios
                .get(url, config)
                .then(response => {
                    const json = response.data;
                    if (json.responseType === 'OK') {
                        if (this.userId !== json.value.userId) {
                            //userid switch
                            this.userId = json.value.userId;
                        }
                        const message = {
                            content: json.value.orderMessage,
                            myself: false,
                            participantId: 1,
                            timestamp: DateTime.local(),
                            uploaded: true,
                            enabled: false,
                            hidden: false,
                            viewed: true,
                            first: true,
                            last: true,
                            multi: false,
                            //noAudio: true,
                            mediaType: 'paypal',
                            media: json.value,
                        };

                        return [message];
                    } else {
                        return null;
                    }
                }).catch((error) => {
                    this.$log.error("Get Order Message", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return null;
                });
        },
        getOrderId(orderId) {
            const url = this.baseUrl + this.apiPath + '/order/' + orderId;

            const config = {
                timeout: Timeout,
                params: {
                    extended: 'true',
                }
            };

            return axios
                .get(url, config)
                .then(response => {
                    const json = response.data;
                    if (json.responseType === 'OK') {
                        return json.value;
                    } else {
                        return null;
                    }
                }).catch((error) => {
                    this.$log.error("Get Order Id", error.message);
                    /*
                    //todo: leggere errore da json e data
                    {
                      "responseType" : "KO",
                      "messageCode" : "INTERNAL_SERVER_ERROR",
                      "serverTime" : "2021-03-27 22:13:07"
                    }
                     */
                    return null;
                });
        },

        printPdf(file) {
            const formData = new FormData();
            formData.append('file', file);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            return axios.post('http://localhost:9000/api/print', formData, config).then(response => {
                this.$log.error("Print send");
                return response.data;
            }).catch((error) => {
                this.$log.error("Print error", error.message);
                return null;
            });
        },

        getLocation(latitude, longitude) {

            const url = this.baseUrl + this.apiPath + '/conversate_item/geo_location/';
            const config = {
                timeout: Timeout,
                params: {
                    lat: latitude,
                    lng: longitude
                }
            };

            return axios
                .get(url, config)
                .then(response => {
                    const json = response.data;
                    if (json.responseType === 'OK') {
                        return json.value;
                    }

                }).catch((error) => {
                    this.$log.error("Get Location", error.message);
                    return '';
                });
        },

        parseBot(response) {
            const json = response.data;

            if (json.responseType === "OK") {

                //fix privacy disclaimer id
                if (json.value.privacyDisclaimeId === undefined)
                    json.value.privacyDisclaimeId = 0;

                //fix agreements
                if (json.value.activeAgreements === undefined)
                    json.value.activeAgreements = {
                        disclosure: false,
                        marketing: false,
                        profiling: false,
                        sensitive: false,
                        service: false,
                    };

                //fix lang
                let lang = json.value.lang;
                if (lang.length > 2)
                    json.value.lang = lang.substr(0, 2);

                //fix avatar image
                if (json.value.avatarImage === undefined || json.value.avatarImage === null)
                    json.value.avatarImage = '';

                //fix empty botGroup
                if (json.value.botGroup === undefined || json.value.botGroup.bots === undefined) {
                    json.value.botGroup = {
                        bots: []
                    }
                }

                json.value.botGroup.bots = json.value.botGroup.bots.filter(function (item) {
                    return item.lang !== json.value.lang;
                });

                //fix call to action
                json.value.callToAction = json.value.callToAction || {};
                Object.keys(json.value.callToAction).map(function (key) {
                    if (json.value.callToAction[key] === '')
                        delete json.value.callToAction[key];
                });
                if (json.value.callToAction.operator === undefined)
                    json.value.callToAction.operator = false;

                json.value.bannerList = json.value.banner ? (json.value.bannerList || []) : [];

                if (this.features.kiosk)
                    json.value.noHistory = true;

                json.value.timestamp = DateTime.local();
                return json.value;
            } else {
                return null;
            }
        },

        parseConversationId(response) {
            const json = response.data;

            if (json.responseType === "OK") {
                return json.value.conversationId;
            } else {
                return null;
            }
        },

        parseConversationIdFromHistory(response) {
            const json = response.data;

            if (json.responseType === "OK") {
                if (json.value.length > 0) {

                    const lastMessage = json.value[0];

                    const now = DateTime.local();
                    const timestamp = DateTime.fromISO(lastMessage.date.replace(' ', 'T'));

                    const elapsed = now.diff(timestamp, "seconds").seconds;
                    if (elapsed <= this.conversationTimeout) {
                        const conversationId = lastMessage.conversationId;
                        Vue.$log.info('Recovered conversationId from history last message (' + elapsed.toFixed(0) + ' seconds)', conversationId);
                        return conversationId;
                    } else {
                        Vue.$log.info('Expired conversationId from history last message (' + elapsed.toFixed(0) + ' seconds)');
                        return null;
                    }
                }
                return null;
            } else {
                return null;
            }
        },

        parseHistory(response) {
            const self = this;

            const ret = [];

            const json = response.data;

            if (json.responseType === "OK") {

                (json.value || []).map(function (item) {

                    if ((item.botMessageText || '') !== '') {

                        const botMessage = {

                            groupId: DateTime.fromISO(item.date.replace(' ', 'T')).ts.toString(),
                            groupType: item.botMessageType,
                            conversationId: item.conversationId,
                            content: item.botMessageText || '',

                            questionType: item.questionType,
                            answerType: item.answerType || item.botMessageType,
                            mediaType: item.mediaType,
                            media: item.media || '',

                            options: item.options,

                            myself: false,
                            participantId: 1,
                            timestamp: DateTime.fromISO(item.date.replace(' ', 'T')),

                            uploaded: true,
                            enabled: false,
                            hidden: false,
                            viewed: true,

                            showTime: true,
                            first: true,
                            last: true,
                            multi: false,

                            history: true,
                        }

                        //smart document access
                        if (botMessage.mediaType === 'smart_document_access')
                            botMessage.mediaType = 'link';

                        //check media file
                        if (botMessage.mediaType === 'file' && botMessage.media.length === 0) {
                            delete botMessage.mediaType;
                            delete botMessage.media;
                        }

                        //check slideshow
                        if (item.questionType === 'SLIDESHOW' && item.payload !== undefined) {
                            botMessage.content = self.$translate(self.language, 'slideshow.title');
                            botMessage.mediaType = 'slideshow';
                            botMessage.slides = JSON.parse(item.payload).slideshow;
                        }

                        botMessage.suggestions = self.extractNextQuestionOptions(item.options);

                        //check form
                        botMessage.isForm = (item.fieldType !== undefined);
                        if (botMessage.isForm) {
                            botMessage.fieldType = item.fieldType.type;

                            botMessage.scrollable = false; //non ha informazioni
                            botMessage.fullscreen = false;

                            botMessage.options = self.extractAdmissibleOptions(item.options);
                            botMessage.suggestedOptions = self.extractSuggestedOptions(item.options);
                            botMessage.skipOption = self.extractSkipOption(item.options);
                            botMessage.abortOption = self.extractAbortOptions(item.options);
                            botMessage.admissibleType = self.extractAdmissibleType(item.options);

                            botMessage.hasOptions = botMessage.options.length > 0;

                            botMessage.value = null;
                            botMessage.values = self.preselectAdmissibleOptions(botMessage.options);

                        }

                        //console.log(botMessage);

                        ret.unshift(botMessage);
                    }

                    if ((item.userQuestion || '') !== '') {

                        let userQuestion = item.userQuestion || '&nbsp;';
                        let mediaType = 'text';

                        if (item.botMessageType === 'FORM') {
                            if (userQuestion.indexOf(' ') === -1 && userQuestion.indexOf('http') === 0) {
                                const ext = userQuestion.split('.').pop().toLowerCase();
                                let name = self.$translate(self.language, 'chat.file');

                                if (item.userQuestion.indexOf('?name=') !== -1)
                                    name = item.userQuestion.split('?name=').pop();

                                if (ext === 'jpeg' || ext === 'jpg' || ext === 'png')
                                    userQuestion = '<img src="' + item.userQuestion + '">';
                                else
                                    userQuestion = '<a href="' + item.userQuestion + '" target="_blank">' + name + '</a>';
                            } else if (userQuestion === "Green pass") {
                                userQuestion = Helpers.icons.qrCode;
                            }
                        }

                        const userMessage = {
                            history: true,
                            content: userQuestion,
                            myself: true,
                            participantId: 3,
                            timestamp: DateTime.fromISO(item.date.replace(' ', 'T')),
                            answerType: item.answerType,
                            mediaType: mediaType,

                            uploaded: true,
                            enabled: false,
                            hidden: false,
                            viewed: true,
                        }

                        ret.unshift(userMessage);

                    }

                });

            } else if (json.messageCode === 'CONVERSATION_ITEM_NOT_FOUND') {
                Vue.$log.warn('No conversation items found');
            } else {
                Vue.$log.error("Api error", json);
            }

            if (ret.length > 0) {
                ret[0].forceShowDate = true;
            }


            return {
                messages: ret,
                offset: (json.value || []).length
            };

        },

        parseEngagement(response, baseUrl, conversationId) {

            let nextTimer = -1;

            const json = response.data;

            if (json.responseType === 'OK') {

                if (json.messageCode === 'OK' && json.value !== undefined) {
                    nextTimer = parseInt(json.value.nextEngagementTime);

                    json.conversationId = conversationId;
                    if ((json.value.answerText || '') !== '') {
                        json.value.answerList = [json.value];
                    }
                }

            } else {
                Vue.$log.error('Api error', json);
            }

            return {
                messages: this.parseMessage(response, baseUrl),
                nextTimer: nextTimer
            };

        },

        parseMessage(response, baseUrl, livechat, muted) {
            const self = this;

            const ret = [];
            const json = response.data;

            if (livechat === undefined)
                livechat = false;

            if (muted === undefined)
                muted = false;

            if (json.responseType === "OK") {
                const value = json.value;

                if (value.questionType !== 'SLIDESHOW') {
                    //STANDARD RESPONSE
                    const groupId = Date.now().toString();

                    (value.answerList || []).map(function (item) {

                        const botMessage = {

                            key: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                                const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                                return v.toString(16);
                            }),
                            groupId: groupId,
                            groupType: value.answerType,
                            conversationId: json.conversationId,
                            content: item.answerText || '',
                            userQuestionText: value.userQuestionText, //ci sarà in futuro
                            questionId: value.questionId || null,
                            answerType: item.answerType,
                            questionType: value.questionType || 'STANDARD',
                            answerId: item.answerId || null,
                            frontEndMessage: value.frontEndMessage || null,

                            //media
                            mediaType: null,
                            media: null,
                            payload: null,

                            //- form
                            fieldType: null,
                            maxValuesToSelect: null,
                            multiple: null,
                            scrollable: null,
                            fullscreen: null,
                            options: null,
                            suggestions: null,
                            suggestedOptions: null,
                            skipOption: null,
                            abortOption: null,
                            admissibleType: null,
                            hasOptions: null,

                            command: item.command || null,
                            //--
                            userQuestion: value.question,

                            myself: false,
                            participantId: (!livechat) ? 1 : 2,
                            timestamp: DateTime.fromISO(json.serverTime.replace(' ', 'T')),

                            uploaded: true,
                            enabled: false,
                            hidden: false,
                            viewed: false,
                            voted: null,

                            showTime: false,
                            first: false,
                            last: false,
                            multi: false,

                            history: false,
                            noAudio: muted

                        }

                        //fix inizio e fine form per baloon
                        if (botMessage.frontEndMessage === 'FORM_START' && botMessage.groupType === 'QA') {
                            botMessage.groupType = 'FORM';
                        }

                        if (botMessage.frontEndMessage === 'FORM_END' && botMessage.groupType === 'FORM') {
                            botMessage.groupType = 'QA';
                        }

                        //fix inizio e fine form per engagement
                        if (botMessage.frontEndMessage === 'FORM_START' && botMessage.answerType === 'QA') {
                            botMessage.answerType = 'FORM';
                        }

                        if (botMessage.frontEndMessage === 'FORM_END' && botMessage.answerType === 'FORM') {
                            botMessage.answerType = 'QA';
                        }

                        //check tts separated paragraph
                        if (item.ttsAnswerList != undefined) {
                            /*
                    remove empty like
                    {
                        audioUrl: ""
                        htmlText: "<br>"
                        plainText: ""
                        ssmlText: "<speak></speak>"
                        lipSyncData: ...
                    }
                     */

                            const ttsAnswerList = item.ttsAnswerList.filter((tts) => {
                                return tts.plainText.length > 0;
                            });

                            for (let i = 0; i < ttsAnswerList.length; i++) {
                                const tts = ttsAnswerList[i];
                                if (i != ttsAnswerList.length - 1) {
                                    //duplicate message with only text (no media, no form, no suggestion, ecc...)
                                    const newBotMessage = Object.assign({}, botMessage);
                                    newBotMessage.content = tts.htmlText;
                                    if (tts.audioUrl && tts.audioUrl.length > 0)
                                        newBotMessage.audio = baseUrl + '/' + tts.audioUrl;

                                    if (tts.lipSyncData) {
                                        //fix wrong format audio/mp3 montemagno
                                        if (tts.lipSyncData.result)
                                            newBotMessage.lipsync = tts.lipSyncData.result;
                                        else
                                            newBotMessage.lipsync = tts.lipSyncData;
                                    }

                                    ret.push(newBotMessage);
                                } else {
                                    //last message contains all
                                    botMessage.content = tts.htmlText;
                                    if (tts.audioUrl && tts.audioUrl.length > 0)
                                        botMessage.audio = baseUrl + '/' + tts.audioUrl;

                                    if (tts.lipSyncData) {
                                        //fix wrong format audio/mp3 montemagno
                                        if (tts.lipSyncData.result)
                                            botMessage.lipsync = tts.lipSyncData.result;
                                        else
                                            botMessage.lipsync = tts.lipSyncData;
                                    }
                                }
                            }
                        }

                        //media
                        botMessage.mediaType = item.mediaType;
                        botMessage.media = item.media || '';

                        //smart document access
                        if (botMessage.mediaType === 'smart_document_access')
                            botMessage.mediaType = 'link';

                        //emotion mirror
                        const emotionMirror = {
                            fearful: 'fearful',
                            happy: 'happy',
                            sad: 'sad',
                            angry: 'sad',
                            disgusted: 'sad',
                            surprised: 'surprised',
                            neutral: 'neutral'
                        };
                        //emotion
                        botMessage.emotion = null;
                        switch (value.questionEmotion || value.answerEmotion || null) {
                            case 'ANGRY':
                                botMessage.emotion = emotionMirror['angry'];
                                break;
                            case 'DISGUSTED':
                                botMessage.emotion = emotionMirror['disgusted'];
                                break;
                            case 'SCARED':
                                botMessage.emotion = emotionMirror['fearful'];
                                break;
                            case 'HAPPY':
                                botMessage.emotion = emotionMirror['happy'];
                                break;
                            case 'NEUTRAL':
                                botMessage.emotion = emotionMirror['neutral'];
                                break;
                            case 'SAD':
                                botMessage.emotion = emotionMirror['sad'];
                                break;
                            case 'AMAZED':
                                botMessage.emotion = emotionMirror['surprised'];
                                break;
                            case 'NO_EMOTION':
                                botMessage.emotion = null;
                                break;
                        }

                        //raw data
                        //botMessage.rawData = item.rawData;
                        if (item.rawData !== undefined && item.rawData.highchart !== undefined) {
                            botMessage.mediaType = 'highchart';
                            botMessage.media = item.rawData.highchart;
                        }

                        if (item.rawData !== undefined && item.rawData.chart !== undefined) {
                            botMessage.mediaType = 'highchart';
                            botMessage.media = self.parseOldChart(item.rawData.chart);
                            console.dir(botMessage.media);
                        }

                        if (item.rawData !== undefined && item.rawData.payment !== undefined) {
                            botMessage.mediaType = 'paypal';
                            botMessage.media = item.rawData.payment;
                            console.dir(botMessage.media);
                        }

                        if (item.rawData !== undefined && item.rawData.printer !== undefined) {
                            if (item.rawData.printer.base64 !== undefined && item.rawData.printer.base64 != null) {
                                botMessage.mediaType = 'printer';
                                botMessage.media = item.rawData.printer;
                            }
                        }

                        //payload browser automation
                        if (item.payload !== undefined) {
                            botMessage.payload = item.payload;
                        }

                        //Suggestion (NextQuestion)
                        // fix engagement che dovrebbe sparire
                        if (item.options === undefined)
                            botMessage.suggestions = self.extractNextQuestionEngagement(item.nextQuestions);

                        //Suggestion (Options)
                        if ((botMessage.suggestions || []).length === 0)
                            botMessage.suggestions = self.extractNextQuestionOptions(item.options);

                        //Form
                        botMessage.isForm = (item.field !== undefined);

                        //Form data
                        if (botMessage.isForm) {
                            botMessage.fieldType = item.field.fieldType.type;
                            botMessage.fieldName = item.field.name;
                            botMessage.fieldId = item.field.externalWSfield;

                            botMessage.maxValuesToSelect = (item.field.maxValuesToSelect !== undefined && item.field.maxValuesToSelect > 1 ? item.field.maxValuesToSelect : 1);
                            botMessage.multiple = botMessage.maxValuesToSelect > 1;
                            botMessage.scrollable = item.field.customPicker;
                            botMessage.fullscreen = item.field.showInFullscreen;

                            botMessage.options = self.extractAdmissibleOptions(item.options);
                            botMessage.suggestedOptions = self.extractSuggestedOptions(item.options);
                            botMessage.skipOption = self.extractSkipOption(item.options);
                            botMessage.abortOption = self.extractAbortOptions(item.options);
                            botMessage.admissibleType = self.extractAdmissibleType(item.options);
                            if ((item.avNextUrl || '') !== '') {
                                const url = item.avNextUrl
                                    .replace('<DATE_YEAR>-<DATE_MONTH>-<DATE_DAY>', '{date}')
                                    .replace('<DATE_END_YEAR>-<DATE_END_MONTH>-<DATE_END_DAY>', '{date_end}')
                                const uri = new URL(url);
                                botMessage.admissibleUrl = self.baseUrl + uri.pathname.replace('/generic_availabilities', '/booking/generic_availabilities') + uri.search;
                            }

                            botMessage.hasOptions = botMessage.options.length > 0;

                            //used on FieldCard
                            botMessage.value = null;
                            botMessage.values = self.preselectAdmissibleOptions(botMessage.options);
                        }

                        //console.log(botMessage);

                        ret.push(botMessage);

                    });


                } else {
                    //SLIDESHOW RESPONSE
                    const botMessage = {
                        conversationId: json.conversationId,
                        content: self.$translate(self.language, 'slideshow.title'),
                        userQuestionText: value.userQuestionText, //ci sarà in futuro
                        questionId: value.questionId || null,
                        questionType: value.questionType,

                        mediaType: 'slideshow',

                        myself: false,
                        participantId: 1,
                        timestamp: DateTime.fromISO(json.serverTime.replace(' ', 'T')),

                        uploaded: true,
                        enabled: true,
                        hidden: false,
                        viewed: false,

                        showTime: false,
                        first: false,
                        last: false,
                        multi: false,
                        history: false,
                        noAudio: muted,

                        slides: value.answerList
                    }
                    botMessage.isForm = false;

                    if (value.answerList.length > 0) {
                        //Suggestion (NextQuestions)
                        const nextQuestions = value.answerList[value.answerList.length - 1].nextQuestions;
                        botMessage.suggestions = self.extractNextQuestionSlideshow(nextQuestions);
                    }

                    ret.push(botMessage);

                }

                if (ret.length === 0) {
                    if (value.frontEndMessage === 'LIVE_CHAT') {
                        const livechatMessage = {
                            frontEndMessage: value.frontEndMessage,
                            content: self.$translate(self.language, 'livechat.message'),
                            myself: false,
                            participantId: 1,
                            timestamp: DateTime.local(),
                            uploaded: true,
                            enabled: false,
                            hidden: false,
                            viewed: true,
                            showTime: false,
                            first: false,
                            last: false,
                            multi: false,
                            history: true,
                            noAudio: muted,
                        }
                        ret.push(livechatMessage);
                    }
                }

            } else {
                let content = self.$translate(self.language, 'bot.error');

                if (json.messageCode === 'UNAUTHORIZED')
                    content = self.$translate(self.language, 'bot.unauthorized');

                const errorMessage = {
                    content: content,
                    myself: false,
                    participantId: 1,
                    timestamp: DateTime.local(),
                    uploaded: true,
                    enabled: false,
                    hidden: false,
                    viewed: true,
                    showTime: false,
                    first: false,
                    last: false,
                    multi: false,
                    history: true,
                    noAudio: muted,
                }
                ret.push(errorMessage);
            }

            if (ret.length > 0) {
                ret[0].showTime = true;
                ret[0].first = true;
                ret[ret.length - 1].last = true;

                if (ret.length > 1) {
                    for (let i in ret) {
                        ret[i].multi = true;
                    }

                    //apply only frontEndMessage and command on last message
                    for (let i = 0; i < ret.length - 1; i++) {
                        ret[i].frontEndMessage = null;
                        ret[i].command = null;
                    }

                }
            }

            return ret;

        },

        extractNextQuestionSlideshow(nextQuestions) {
            return (nextQuestions || []).map(function (nextQuestion) {
                /*
                destId: "121228"
                id: 81067
                label: "catalogo"
                nextQuestionType: "QA"
                order: 1
                qaId: "121228"
                questionText: "catalogo"
                sourceId: "121183"
                sourceType: "Q"
                 */
                return {
                    nextQuestionType: nextQuestion.nextQuestionType,
                    optionId: nextQuestion.qaId,
                    optionText: nextQuestion.label,
                    optionType: "NextQuestion"
                };
            });
        },

        extractNextQuestionEngagement(nextQuestions) {
            return (nextQuestions || []).map(function (nextQuestion) {
                /*
                destId: "121228"
                id: 81067
                label: "catalogo"
                nextQuestionType: "QA"
                order: 1
                qaId: "121228"
                questionText: "catalogo"
                sourceId: "121183"
                sourceType: "Q"
                 */
                return {
                    nextQuestionType: nextQuestion.nextQuestionType,
                    optionId: nextQuestion.qaId,
                    optionText: nextQuestion.topic ? nextQuestion.topic : nextQuestion.label,
                    optionType: "NextQuestion"
                };
            });
        },

        extractNextQuestionOptions(options) {
            return (options || []).filter(function (option) {
                return (option.optionType === 'NextQuestion' || option.optionType === 'Downvote');
            });
        },

        extractSuggestedOptions(options) {
            return (options || []).filter(function (option) {
                return ((option.optionType === 'AdmissibleValue' || option.optionType === 'SuggestedValue') && option.suggestionType === 'SuggestedValue');
            });
        },

        extractAdmissibleOptions(options) {
            return (options || []).filter(function (option) {
                option.quantity = option.previousQuantity || 0;
                return (option.optionType === 'AdmissibleValue');
            });
        },

        preselectAdmissibleOptions(options) {
            return options.filter((option) => {
                return option.previousQuantity !== undefined;
            })
        },

        extractSkipOption(options) {
            return (options || []).find(function (option) {
                return (option.optionType === 'SkipValue');
            });
        },

        extractAbortOptions(options) {
            return (options || []).find(function (option) {
                return (option.optionType === 'EndValue');
            });
        },

        extractAdmissibleType(options) {
            let ret = 'title';

            (options || []).map(function (option) {
                if (option.description !== undefined && option.description.length > 0)
                    ret = 'description';
            });
            (options || []).map(function (option) {
                if (option.media !== undefined && option.media.length > 0)
                    ret = 'media';
            });
            (options || []).map(function (option) {
                if (option.currency !== undefined && option.currency.length > 0)
                    ret = 'price';
            });

            return ret;
        },

        parseOldChart(jChart) {

            const getDataConfiguration = {
                allOthersField: 'all_others',
                allOthersFieldDesc: 'OTHER...'
            };

            //console.log(JSON.stringify(jChart));

            if (jChart !== null && jChart !== undefined && jChart !== '') {

                // Ricavo il tipo
                var chartType = '';
                var polar = false;
                if (jChart.chartType !== undefined && jChart.chartType !== null && jChart.chartType !== '') {
                    chartType = jChart.chartType;

                    if (chartType === 'bars') {
                        chartType = 'bar';  // Patch
                    } else if (chartType === 'radar') {
                        chartType = 'line'; // Patch
                        polar = true;
                    }
                }

                // Ricavo le measures
                const measures = [];
                let m;

                if (jChart.values !== undefined && jChart.values !== null && jChart.values !== '') {
                    if (jChart.values.length > 0) {
                        // "values": [
                        //     {                                    //firstElem
                        //         "000000000000003220": {          //firstValueKey
                        // 			   "Quantita": 2,               //internalObjects
                        // 			   "Fatturato": 190
                        // 	       }
                        //     },
                        // ...]
                        let firstElem = jChart.values[0];

                        let firstValueKey = Object.keys(firstElem)[0];
                        let internalObjects = firstElem[firstValueKey];

                        Object.keys(internalObjects).forEach(function (key) {
                            //console.log('Key : ' + key + ', Value : ' + data[key])
                            measures.push({"id": key, "text": key});    // Struttura json per tutti i charts != pie
                            m = key;                                    // Variabile per i chart == pie (1 sola misura alla volta)
                        });
                    }
                }

                // Parser differenziati per tipo
                // Chart PIE
                if (chartType === 'pie') {

                    let datas = [];
                    let serieName = '';

                    const recursivePie = function (dataJson, key, label) {
                        let j = 0;
                        for (let keyInt in dataJson) {
                            if (j === 0) {
                                label += keyInt;
                                datas.push({
                                    value: dataJson[keyInt][m][0],
                                    y: dataJson[keyInt][m][1],
                                    name: label
                                });
                                if (serieName === '') {
                                    for (var mName in dataJson[keyInt]) {
                                        serieName = mName;
                                    }
                                }
                            } else {
                                j++;
                                dataJson = dataJson[keyInt];
                                label += keyInt + " | ";
                                key = keyInt;
                                recursivePie(dataJson, key, label);
                            }
                        }
                    }

                    if (jChart.values !== undefined) {
                        for (let i = 0; i < jChart.values.length; i++) {
                            for (let key in jChart.values[i]) {
                                const label = '';

                                if (i === (jChart.values.length - 1)) {
                                    if (jChart.values[i][getDataConfiguration.allOthersField] !== undefined) {
                                        break;
                                    } else {
                                        recursivePie(jChart.values[i], key, label);
                                    }
                                } else {
                                    recursivePie(jChart.values[i], key, label);
                                }
                            }

                            if (i === (jChart.values.length - 1) && jChart.values[i][getDataConfiguration.allOthersField] !== undefined && jChart.values[i][getDataConfiguration.allOthersField][m] != undefined) {
                                datas.push({
                                    value: jChart.values[i][getDataConfiguration.allOthersField][m][0],
                                    y: jChart.values[i][getDataConfiguration.allOthersField][m][1],
                                    name: getDataConfiguration.allOthersFieldDesc
                                });
                            }
                        }
                    }

                    // Patch del caso in cui tutti i valori del grafici a torta siano sotto-soglia (lato BI) e quindi aggregati in 'all_others'
                    if (datas.length === 0) {

                        let key = Object.keys(jChart.values[0][getDataConfiguration.allOthersField])[0];

                        datas.push({
                            value: jChart.values[0][getDataConfiguration.allOthersField][key][0],
                            y: jChart.values[0][getDataConfiguration.allOthersField][key][1],
                            name: getDataConfiguration.allOthersFieldDesc
                        });

                        serieName = key;
                        measures.push({"id": key, "text": key});
                    }

                    const datasets = [];

                    datasets.push({
                        name: serieName,
                        colorByPoint: true,
                        data: datas
                    });

                    const dataChart = {
                        chart: {
                            type: chartType
                        },
                        title: {
                            text: ''
                        },
                        xAxis: {
                            categories: labels
                        },
                        series: datasets,
                        tooltip: {
                            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                        }
                    };

                    if (jChart.chartColors !== undefined && jChart.chartColors.length > 0) {
                        dataChart.colors = jChart.chartColors;
                    }

                    return dataChart;
                }
                //Others charts
                else {

                    // Ricavo i dati
                    var labels = [];
                    var datas = {};

                    Object.keys(measures).forEach(function (key) {
                        const value = measures[key];
                        const temp = [];
                        datas[value.id] = temp;
                    });

                    const recursiveOther = function (dataJson, key, label) {
                        let j = 0;
                        for (let keyInt in dataJson) {

                            if (j === 0) {
                                label += keyInt;
                                labels.push(label);

                                Object.keys(measures).forEach(function (key) {
                                    const value = measures[key];
                                    const temp = datas[value.id];
                                    temp.push(dataJson[keyInt][value.id]);
                                    datas[value.id] = temp;
                                });
                            } else {
                                j++;
                                dataJson = dataJson[keyInt];
                                label += keyInt + " | ";
                                key = keyInt;

                                recursiveOther(dataJson, key, label);
                            }
                        }
                    }

                    if (jChart.values !== undefined) {
                        for (let i = 0; i < jChart.values.length; i++) {
                            for (let key in jChart.values[i]) {
                                let label = '';

                                if (i === (jChart.values.length - 1)) {
                                    if (jChart.values[i][getDataConfiguration.allOthersField] !== undefined) {
                                        break;
                                    } else {
                                        recursiveOther(jChart.values[i], key, label);
                                    }
                                } else {
                                    recursiveOther(jChart.values[i], key, label);
                                }
                            }

                            if (i === (jChart.values.length - 1) && jChart.values[i][getDataConfiguration.allOthersField] !== undefined) {
                                labels.push(getDataConfiguration.allOthersFieldDesc);
                                Object.keys(measures).forEach(function (key) {
                                    const value = measures[key];
                                    const temp = datas[value.id];
                                    temp.push(jChart.values[i][getDataConfiguration.allOthersField][value.id]);
                                    datas[value.id] = temp;
                                });
                            }
                        }
                    }

                    const datasets = [];

                    Object.keys(datas).forEach(function (key) {
                        const value = datas[key];
                        datasets.push({
                            name: key,
                            data: value,
                            sum: (typeof jChart.statistics !== 'undefined') ? jChart.statistics[key].sum : 0
                        });
                    });

                    const dataChart = {
                        chart: {
                            type: chartType,
                            polar: polar
                        },
                        title: {
                            text: ''
                        },
                        //labels : labels,
                        xAxis: {
                            categories: labels
                        },
                        series: datasets
                    };

                    if (jChart.chartColors !== undefined && jChart.chartColors.length > 0) {
                        dataChart.colors = jChart.chartColors;
                    }

                    return dataChart;
                }
            }
        }
    }
}

</script>

<style>

</style>
