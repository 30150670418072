<template>
    <Panel :features="features" @onClose="onClose">
        <div slot="body" class="settings-body">

            <h3>{{ $translate(api.language, 'panels.settings.settings.title') }}</h3>
            <ul class="setting-list">
                <li class="setting-item">
                    <Icon name="settings" size="18px" class=""/>
                    <span>{{ $translate(api.language, 'panels.settings.settings.sounds') }}</span>
                    <span>
                        <ToggleButton v-model="features.settings.sound" @change="onChange"/>
                    </span>
                </li>

                <li v-if="!features.kiosk && hasAudio" class="setting-item">
                    <Icon name="settings" size="18px" class=""/>
                    <span>{{ $translate(api.language, 'panels.settings.settings.rememberInput') }}</span>
                    <span>
                        <ToggleButton v-model="features.settings.input" @change="onChange"/>
                    </span>
                </li>
                <li class="setting-item">
                    <Icon name="settings" size="18px" class=""/>
                    <span>{{ $translate(api.language, 'panels.settings.settings.fontLarge') }}</span>
                    <span>
                        <ToggleButton v-model="features.settings.fontLarge" @change="onChange"/>
                    </span>
                </li>

                <li class="setting-item">
                    <Icon name="settings" size="18px" class=""/>
                    <span>{{ $translate(api.language, 'panels.settings.settings.volume') }}</span>
                    <span>
                        <input type="range" min="0" max="100" v-model="features.settings.volume" :data-volume="features.settings.volume + '%'" class="slider-range" @change="onChange">
                    </span>
                </li>
            </ul>

            <hr/>

            <h3>{{ $translate(api.language, 'panels.settings.version.title') }}</h3>
            <ul class="setting-list">
                <li class="setting-item width-adjust">
                    <Icon name="info_o" size="18px" class=""/>
                    <span>{{ $translate(api.language, 'panels.settings.version.version') }}</span>
                    <span>
                        <span @click="onClickVersion">{{ features.version.appVersion }}</span>
                    </span>
                </li>
                <li class="setting-item width-adjust">
                    <Icon name="info_o" size="18px" class=""/>
                    <span>{{ $translate(api.language, 'panels.settings.version.date') }}</span>
                    <span>
                        <span>{{ features.version.appRelease }}</span>
                    </span>
                </li>
            </ul>


            <template v-if="egg">

                <hr/>

                <h3>TOOLS</h3>
                <ul class="setting-list">
                    <li class="setting-item">
                        <Icon name="settings" size="18px" class=""/>
                        <span>Reset settings</span>
                        <span>
                            <button class="button" @click="onResetAll">Reset</button>
                        </span>
                    </li>
                    <li v-if="features.kiosk" class="setting-item">
                        <Icon name="settings" size="18px" class=""/>
                        <span>Exit Kiosk</span>
                        <span>
                            <button class="button" @click="onExit">Exit</button>
                        </span>
                    </li>
                </ul>

                <hr/>

                <h3>{{ $translate(api.language, 'panels.settings.debug.title') }}</h3>
                <ul class="setting-list">

                    <li v-if="features.has.webcam" class="setting-item">
                        <Icon name="settings" size="18px" class=""/>
                        <span>{{ $translate(api.language, 'panels.settings.debug.webcam') }}</span>
                        <span>
                            <ToggleButton v-model="features.settings.webcamDebug" @change="onChange"/>
                         </span>
                    </li>

                    <li class="setting-item">
                        <Icon name="settings" size="18px" class=""/>
                        <span>{{ $translate(api.language, 'panels.settings.developer.stats') }}</span>
                        <span>
                            <ToggleButton v-model="features.stats" @change="onChange"/>
                        </span>
                    </li>
                </ul>

                <hr/>

                <h3>{{ $translate(api.language, 'panels.settings.developer.title') }}</h3>
                <ul class="setting-list">

                    <li class="setting-item">
                        <Icon name="settings" size="18px" class=""/>
                        <span>Log</span>
                        <span>
                            <ToggleButton v-model="features.settings.log" @change="onChange"/>
                         </span>
                    </li>
                    <li class="setting-item">
                        <Icon name="settings" size="18px" class=""/>
                        <span>Inspector</span>
                        <span>
                            <ToggleButton v-model="features.settings.developer" @change="onChange"/>
                         </span>
                    </li>
                    <li class="setting-item">
                        <Icon name="settings" size="18px" class=""/>
                        <span>Editor</span>
                        <span>
                            <ToggleButton v-model="features.settings.editor" @change="onChange"/>
                         </span>
                    </li>
                </ul>

                <hr/>
                <h3>{{ $translate(api.language, 'panels.settings.developer.browser') }}</h3>
                <p class="setting-list align-left">{{useragent}}</p>

                <h3>{{ $translate(api.language, 'panels.settings.developer.device') }}</h3>
                <ul class="setting-list">
                    <template v-for="(item, index) in device">
                        <li v-if="item !== false" :key="index" class="info-item">
                            <span>{{ index }}</span>
                            <span>{{ item }}</span>
                        </li>
                    </template>
                </ul>

                <h3>{{ $translate(api.language, 'panels.settings.developer.capabilities') }}</h3>
                <ul class="setting-list">
                    <template v-for="(item, index) in capabilities">
                        <li :key="index" class="info-item">
                            <span>{{ index }}</span>
                            <span>{{ item }}</span>
                        </li>
                    </template>
                </ul>

                <h3>{{ $translate(api.language, 'panels.settings.settings.title') }}</h3>
                <ul class="setting-list">
                    <template v-for="(item, index) in deviceSettings">
                        <li :key="index" class="info-item">
                            <span>{{ index }}</span>
                            <span>{{ item }}</span>
                        </li>
                    </template>
                </ul>

                <template v-if="features.dhi">
                    <h3>{{ $translate(api.language, 'panels.settings.developer.avatar') }}</h3>
                    <p class="block-list" v-html="JSON.stringify(features.avatar.currentConfig, null, 2)"></p>
                </template>
            </template>


        </div>
    </Panel>
</template>

<script>
import Panel from "./Panel.vue";
import Icon from "../chat/Icon.vue";
import ToggleButton from "../ToggleButton.vue";

const StorageKeys = {
    SETTINGS: '_algho_settings'
};

export default {
    name: "SettingPanel",
    components: {
        Panel,
        Icon,
        ToggleButton
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            click: 0,
            egg: false,
            useragent: window.navigator.userAgent,
            device: {
                osName: this.$device.osName,
                osVersion: this.$device.osVersion,
                browserName: this.$device.browserName,
                fullBrowserVersion: this.$device.fullBrowserVersion,
                browserVersion: this.$device.browserVersion,
                mobileVendor: this.$device.mobileVendor,
                mobileModel: this.$device.mobileModel,
                engineName: this.$device.engineName,
                engineVersion: this.$device.engineVersion,
                deviceType: this.$device.deviceType,
                isBrowser: this.$device.isBrowser,
                isMobile: this.$device.isMobile,
                isTablet: this.$device.isTablet,
                isSmartTV: this.$device.isSmartTV,
                isConsole: this.$device.isConsole,
                isWearable: this.$device.isWearable,
                isMobileSafari: this.$device.isMobileSafari,
                isChromium: this.$device.isChromium,
                isMobileOnly: this.$device.isMobileOnly,
                isAndroid: this.$device.isAndroid,
                isWinPhone: this.$device.isWinPhone,
                isIOS: this.$device.isIOS,
                isChrome: this.$device.isChrome,
                isFirefox: this.$device.isFirefox,
                isSafari: this.$device.isSafari,
                isOpera: this.$device.isOpera,
                isIE: this.$device.isIE,
                isEdge: this.$device.isEdge,
                isYandex: this.$device.isYandex,
                isIOS13: this.$device.isIOS13,
                isIPad13: this.$device.isIPad13,
                isIPhone13: this.$device.isIPhone13,
                isIPod13: this.$device.isIPod13,
                isElectron: this.$device.isElectron,
                isEdgeChromium: this.$device.isEdgeChromium,
                isLegacyEdge: this.$device.isLegacyEdge,
                isWindows: this.$device.isWindows,
                isMacOs: this.$device.isMacOs,
            },
            capabilities: {
                AudioContext: 'AudioContext' in window,
                webkitAudioContext: 'webkitAudioContext' in window,
                mozAudioContext: 'mozAudioContext' in window,
                mediaDevices: 'mediaDevices' in navigator,
                SpeechRecognition: 'webkitSpeechRecognition' in window,
                Geolocation: 'geolocation' in navigator
            },
            deviceSettings: this.features.device,
        }
    },
    computed: {
        hasAudio() {
            return this.features.tts;
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onChange() {
            window.localStorage.setItem(StorageKeys.SETTINGS, JSON.stringify(this.features.settings));

            //this.$log.setMode(this.features.settings.log ? 'debug' : 'none');

            if (this.features.settings.fontLarge) {
                this.applyRootStyles(this.features.fonts.large);
            } else {
                this.applyRootStyles(this.features.fonts.normal);
            }

            this.$sound.setVolume(this.features.settings.volume);

        },
        applyRootStyles(styles) {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            const rootStyles = rootShadow.styleSheets[0].cssRules[0].style;

            Object.keys(styles).map((key) => {
                rootStyles.setProperty('--' + key, styles[key]);
            });
        },
        onClickVersion() {
            this.click++;
            if (this.click === 10) {
                this.egg = true;
            }
        },
        onResetAll() {
            this.api.resetAll();
            window.location.reload(true);
        },
        onExit() {
            this.api.resetAll();
            if (window.kiosk) {
                this.$log.info("Close");
                window.kiosk.closeApp();
            } else {
                window.location.href = window.location.href + "#closekiosk";
            }
        },
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.chat-panel-window {

    ul.setting-list {

    }

    p.setting-list {
        text-align: center;

        &.align-left {
            text-align: left;
        }
    }
    p.block-list {
        text-align: left;
        font-family: monospace;
        user-select: all;
        white-space: pre;
        overflow: auto;
    }

    li.setting-item, li.info-item {
        display: flex;
        margin-bottom: 8px;
        min-height: 24px;

        & > svg {
            margin-right: 10px;
            margin-top: 3px;
        }
        & > span {
            margin-top: 3px;
            font-size: var(--font-12);
            width: 66%;

            &:last-child {
                text-align: right;
                margin-right: 10px;
                width: 34%;
            }
        }

        &.width-adjust > span {
            width: 34%;

            &:last-child {
                width: 66%;
            }
        }

        & > button {
            right: 0;
            position: absolute;
            background-color: var(--bg-button-color);
            border: 0;
            border-radius: 20px;
            height: 24px;
            min-width: 60px;
            color: var(--fg-color);
            text-transform: uppercase;
            cursor: pointer;
        }
        & > button:hover {
            color: var(--primary-color-text);
        }
    }

    li.info-item {
        margin-bottom: 0 !important;
        min-height: unset !important;
        & > span {
            font-size: 90%;
        }
    }

    li.setting-item-center {
        display: block !important;
        text-align: center;
    }


    button.button {
        min-width: 55px;
        padding: 5px 13px;
        border-radius: var(--border-radius);
        border-width: 0;
        outline: 0;
        font-size: var(--font-11);
        line-height: calc(var(--font-11) + 3px);
        background-color: var(--bg-color-widget);
        color: var(--fg-color-widget);
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    .slider-range {
        position: relative;
        width: 55px;
        height: 24px;
        border-radius: 12px;
        background: var(--bg-button-color);
        margin: 0;
        outline: none;

        -webkit-appearance: none;
        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-tap-highlight-color: transparent; /* for removing the highlight */
    }

    .slider-range::after {
        position: absolute;
        top: 5px;
        right: 60px;
        font-size: var(--font-12);
        line-height: calc(var(--font-12) + 3px);
        color: var(--primary-color-text);
        content: attr(data-volume);
    }

    .slider-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 3px solid;
        border-color: var(--bg-button-color);
        background: var(--fg-color);
        cursor: pointer;
    }
    .slider-range::-moz-range-thumb {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 3px solid;
        border-color: var(--bg-button-color);
        background: var(--fg-color);
        cursor: pointer;
    }

}

.kiosk {
    .settings-body {
        margin-top: 32px;
    }
}

</style>
