<template>
    <div ref="stats" class="chat-stats" @click.prevent="showNextPanel"></div>
</template>

<script>
export default {
    name: "Stats",
    data() {
        return {
            mode: 0,
            container: null,
            beginTime: null,
            prevTime: null,
            frames: 0,
            msPanel: null,
            fpsPanel: null,
            memPanel: null,
            camPanel: null,
            enabled: false
        };
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        kioskData: {
            type: Object,
            required: true
        },
    },
    watch: {
        'kioskData.face': {
            handler(newVal) {
                if (this.camPanel != null)
                    this.camPanel.update(newVal);
            },
        },
    },
    mounted() {
        this.container = this.$refs.stats;

        this.beginTime = ( performance || Date ).now();
        this.prevTime = this.beginTime;
        this.frames = 0;

        this.fpsPanel = this.addPanel( this.newPanel( 'FPS', '#0ff', '#002' ) );
        this.msPanel = this.addPanel( this.newPanel( 'MS', '#0f0', '#020' ) );

        if (self.performance && self.performance.memory) {
            this.memPanel = this.addPanel( this.newPanel( 'MB', '#f08', '#201' ) );
        }

        if (this.features.kiosk)
            this.camPanel = this.addPanel( this.newCamPanel( 'CAM', '#ff0', '#220' ) );


        this.enabled = true;
        this.showPanel(0);

        const stats = this;
        requestAnimationFrame(function loop() {
            stats.update();
            if (stats.enabled)
                requestAnimationFrame(loop);
        });

    },
    beforeDestroy() {
        this.enabled = false;
    },
    methods: {
        showNextPanel() {
            this.mode = ++ this.mode % this.container.children.length;
            this.showPanel(this.mode);
        },
        showPanel( id ) {
            for (let i = 0; i < this.container.children.length; i++) {
                this.container.children[ i ].style.display = i === id ? 'block' : 'none';
            }
            this.mode = id;
        },
        addPanel(panel) {
            this.container.appendChild(panel.dom);
            return panel;
        },
        begin() {

            this.beginTime = ( performance || Date ).now();

        },

        end() {

            this.frames ++;

            const time = ( performance || Date ).now();

            this.msPanel.update( time - this.beginTime, 200 );

            if ( time >= this.prevTime + 1000 ) {

                this.fpsPanel.update( ( this.frames * 1000 ) / ( time - this.prevTime ), 100 );

                this.prevTime = time;
                this.frames = 0;

                if (this.memPanel) {
                    const memory = performance.memory;
                    this.memPanel.update( memory.usedJSHeapSize / 1048576, memory.jsHeapSizeLimit / 1048576 );
                }
            }
            return time;
        },

        update() {
            this.beginTime = this.end();
        },
        newPanel(name, fg, bg ) {

            let min = Infinity, max = 0, round = Math.round;
            let PR = round(window.devicePixelRatio || 1);

            let WIDTH = 80 * PR, HEIGHT = 48 * PR,
                TEXT_X = 3 * PR, TEXT_Y = 2 * PR,
                GRAPH_X = 3 * PR, GRAPH_Y = 15 * PR,
                GRAPH_WIDTH = 74 * PR, GRAPH_HEIGHT = 30 * PR;

            let canvas = document.createElement('canvas');
            canvas.width = WIDTH;
            canvas.height = HEIGHT;
            canvas.style.cssText = 'width:80px;height:48px';

            let context = canvas.getContext('2d');
            context.font = 'bold ' + (9 * PR) + 'px Helvetica,Arial,sans-serif';
            context.textBaseline = 'top';

            context.fillStyle = bg;
            context.fillRect(0, 0, WIDTH, HEIGHT);

            context.fillStyle = fg;
            context.fillText(name, TEXT_X, TEXT_Y);
            context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);

            context.fillStyle = bg;
            context.globalAlpha = 0.9;
            context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);

            return {

                dom: canvas,

                update: function (value, maxValue) {

                    min = Math.min(min, value);
                    max = Math.max(max, value);

                    context.fillStyle = bg;
                    context.globalAlpha = 1;
                    context.fillRect(0, 0, WIDTH, GRAPH_Y);
                    context.fillStyle = fg;
                    context.fillText(round(value) + ' ' + name + ' (' + round(min) + '-' + round(max) + ')', TEXT_X, TEXT_Y);

                    context.drawImage(canvas, GRAPH_X + PR, GRAPH_Y, GRAPH_WIDTH - PR, GRAPH_HEIGHT, GRAPH_X, GRAPH_Y, GRAPH_WIDTH - PR, GRAPH_HEIGHT);

                    context.fillRect(GRAPH_X + GRAPH_WIDTH - PR, GRAPH_Y, PR, GRAPH_HEIGHT);

                    context.fillStyle = bg;
                    context.globalAlpha = 0.9;
                    context.fillRect(GRAPH_X + GRAPH_WIDTH - PR, GRAPH_Y, PR, round((1 - (value / maxValue)) * GRAPH_HEIGHT));
                }

            };

        },
        newCamPanel(name, fg, bg ) {

            let round = Math.round;
            let PR = round(window.devicePixelRatio || 1);

            let WIDTH = 80 * PR, HEIGHT = 48 * PR,
                TEXT_X = 3 * PR, TEXT_Y = 2 * PR,
                GRAPH_X = 3 * PR, GRAPH_Y = 15 * PR,
                GRAPH_WIDTH = 74 * PR, GRAPH_HEIGHT = 30 * PR;

            let canvas = document.createElement('canvas');
            canvas.width = WIDTH;
            canvas.height = HEIGHT;
            canvas.style.cssText = 'width:80px;height:48px';

            let context = canvas.getContext('2d');
            context.font = 'bold ' + (9 * PR) + 'px Helvetica,Arial,sans-serif';
            context.textBaseline = 'top';

            context.fillStyle = bg;
            context.fillRect(0, 0, WIDTH, HEIGHT);

            context.fillStyle = fg;
            context.fillText(name, TEXT_X, TEXT_Y);
            context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);

            context.fillStyle = bg;
            context.globalAlpha = 0.9;
            context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);

            const text = 'NO CAM';
            context.fillStyle = fg;
            context.globalAlpha = 0.5;
            const TEXT_WIDTH = context.measureText(text).width;
            context.fillText(text, GRAPH_X + (GRAPH_WIDTH - TEXT_WIDTH) / 2, GRAPH_Y + 20);

            return {

                dom: canvas,

                update: function (value) {

                    let text = value > 0 ? 'FACE': 'NONE';
                    if (value === -1)
                        text = 'STANDBY';

                    context.fillStyle = bg;
                    context.globalAlpha = 1;
                    context.fillRect(0, 0, WIDTH, HEIGHT);
                    context.fillStyle = fg;
                    context.fillText(name + ' (' + round(value) + ')', TEXT_X, TEXT_Y);

                    context.fillStyle = fg;
                    context.globalAlpha = 0.1;
                    context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);

                    context.fillStyle = fg;
                    context.globalAlpha = value > 0 ? 1 : 0.5;
                    const TEXT_WIDTH = context.measureText(text).width;
                    context.fillText(text, GRAPH_X + (GRAPH_WIDTH - TEXT_WIDTH) / 2, GRAPH_Y + 20);
                }
            };

        },
    }
}
</script>

<style lang="less">

.chat-stats {
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0.9;
}
</style>
