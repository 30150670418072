<template>
    <ul v-if="multiBot" class="bot-language">
        <li @click="onToggle()">
            <img v-if="icons" :src="api.baseUrl + flags[lang()]" class="flag-icon">
            <span v-else class="flag-text">{{language()}}</span>
        </li>
        <template v-if="visible">
        <li v-for="(val, index) in bots()" :key="index" @click="onChangeBot(val)">
            <img v-if="icons" :src="api.baseUrl + flags[val.lang]" class="flag-icon">
            <span v-else class="flag-text margin">{{language(val.lang)}}</span>
        </li>
        </template>
    </ul>
</template>

<script>

export default {
    name: "BotLang",
    props: {
        features: {
            type: Object,
            required: true
        },
        icons: {
            type: Boolean,
            required: false,
            default: () => {
                return true
            }
        },
    },
    data() {
        return {
            visible: false,
            flags: {
                it: "/img/flags/it.svg",
                en: "/img/flags/en.svg",
                es: "/img/flags/es.svg",
                pt: "/img/flags/pt.svg",
                fr: "/img/flags/fr.svg",
                de: "/img/flags/de.svg",
                ro: "/img/flags/ro.svg",
                ru: "/img/flags/ru.svg",
                zh: "/img/flags/zh.svg",
            },
            languages: {
                it: "Italiano",
                en: "English",
                es: "Español",
                pt: "Português",
                fr: "Français",
                de: "Deutsch",
                ro: "Română",
                ru: "Русский",
                zh: "中国人",
            }
        };
    },
    computed: {
        multiBot() {
            return this.features.multibot;
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        bots() {
            return (this.api.bot.botGroup || {bots: []}).bots;
        },
        lang() {
            return this.api.language;
        },
        language(lang) {
            if (lang === undefined)
                return this.languages[this.api.language];
            else
                return this.languages[lang];
        },
        onToggle() {
            this.visible = !this.visible;
        },
        onChangeBot(bot) {
            this.visible = false;
            this.$emit('onChangeBot', bot);
        }
    }
}
</script>

<style lang="less">

    .dhi {
        ul.bot-language.rounded  {
            display: flex;
            flex-direction: row-reverse;
            margin: 2px 4px 4px 0px;

            li {
                .flag-icon {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: 2px solid var(--bg-button-color);
                    box-shadow: var(--box-shadow);
                    background-color: var(--bg-button-color);
                    padding: 2px;
                    margin-left: 4px;
                }
                .flag-icon:hover {
                    border-color: var(--bg-button-color-hover);
                    background-color: var(--bg-button-color-hover);
                }

            }
        }

        ul.bot-language.kiosk  {
            display: flex;
            flex-direction: row;
            margin: 0;

            li {
                width: 100%;
                font-size: var(--font-10);

                .flag-text.margin {
                    margin-left: 10px;
                }
                .flag-text:hover {
                    color: var(--fg-button-color-hover);
                }

            }
        }
    }

    ul.bot-language {
        padding: 0;
        margin: 0 5px 0 0;
        list-style-type: none;
        font-size: var(--font-10);
        cursor: pointer;
        z-index: calc(var(--z-index) + 1);

        &.rounded {
            position: relative;

            ul {
                position: relative !important;
            }

            li {
                .flag-icon {
                    width: 19px;
                    height: 19px;
                    border: 1.5px solid var(--fg-color);
                    border-radius: 16px;
                    object-fit: cover;
                    vertical-align: middle;
                }
            }

        }

        li {
            .flag-icon {
                width: 22px;
                height: 16px;
                border: 1.5px solid var(--fg-color);
                border-radius: var(--border-radius);
            }
        }
    }


</style>
