<template>
    <div class="container-command">
        <div v-show="plugin.loaded" ref="plugin">
        </div>
    </div>
</template>

<script>

import pluginComponent from "../../../classes/PluginComponent.js";

export default {
    name: "Command",
    components: {

    },
    props: {
        features: {
            type: Object,
            required: true
        },
        enabled: {
            type: Boolean,
            required: true
        },
    },
    data: function() {
        return {
            activated: false
        }
    },
    watch: {
        'plugin.loaded': {
            handler(newVal) {
                if (newVal) {
                    this.doPluginLoaded();
                }
            }
        },
        'features.command.activated': {
            handler(newVal) {
                if (this.plugin.loaded) {
                    this.plugin.enabled(newVal);
                }
            },
            deep: true,
            immediate: false
        },
        'enabled': {
            handler(newVal) {
                //console.log('Prop changed: ', newVal, ' | was: ', oldVal);
                if (this.plugin.loaded)
                    this.plugin.enabled(newVal);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        plugin() {
            return this.features.instance.plugins.command;
        },
    },
    beforeCreate() {
        this.app = this.$root.$children[0];
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        const config = {
            init: true,
            enabled: this.enabled,
            debug: true,
            language: this.api.bot.lang,
            name: this.api.bot.name,
            model: 'vosk-model-small-en-us-0.15'
        };

        if (this.plugin.src !== '')
            pluginComponent(this.plugin, this.$refs.plugin, config, this.features.instance);

    },
    beforeDestroy() {
        if (this.plugin.loaded)
            this.plugin.enabled(false);
    },
    destroyed() {
        this.$log.debug('Command destroyed');
    },
    methods: {
        doPluginLoaded() {
            this.$log.debug('Command plugin loaded and ready');
            this.plugin.recognize(this.app.onRecognizeCommand);
        },
    }
}
</script>

<style lang="less">

</style>
