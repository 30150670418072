<template>
    <div class="quantity-container">
        <div class="quantity-item">
            <ButtonIcon name="remove" class="quantity-button" size="16px" :disabled="option.quantity == 0" @click.stop.prevent="onDecrementQuantity"/>
            <div class="quantity-value" :disabled="option.quantity == 0">{{option.quantity}}</div>
            <ButtonIcon name="add" class="quantity-button" size="16px" @click.stop.prevent="onIncrementQuantity"/>
        </div>
       </div>
</template>

<script>

import ButtonIcon from '../../ButtonIcon';

export default {
    name: "Quantity",
    components: {
        ButtonIcon
    },
    props: {
        option: {
            type: Object,
            required: true
        },
    },
    data() {
        return {

        };
    },
    computed: {
        availability() {
            return (this.option.availability ? this.option.availability : 9999);
        },
    },
    methods: {
        onIncrementQuantity: function () {
            let quantity = this.option.quantity;
            quantity++;
            if (this.availability !== -1) {
                if (quantity > this.availability)
                    quantity = this.availability;
            }
            this.option.quantity = quantity;
            this.$emit("onChangeQuantity", this.option);
        },
        onDecrementQuantity: function () {
            let quantity = this.option.quantity;
            quantity--;
            if (quantity < 0)
                quantity = 0;
            this.option.quantity = quantity;
            this.$emit("onChangeQuantity",this.option);
        },
    }
}

</script>

<style lang="less">

.quantity-container {
    width: 100%;
    text-align: center;

    .quantity-item {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }

    .quantity-value {
        width: 40px;
        border: 1px solid var(--border-other-color);
        border-radius: var(--border-radius-small);
        font-size: var(--font-12);
        color: var(--primary-color-text);
        box-shadow: inset 0px 3px 6px #0000002d;
        align-self: center;
        flex: 1;

        &[disabled] {
            color: var(--border-other-color);
        }
    }

    .quantity-button {
        flex: 1;

        svg {
            color: var(--primary-color-text);
        }

        &[disabled] {
            cursor: default;
            svg {
                color: var(--border-other-color);
            }
        }
    }

}

</style>
