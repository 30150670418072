<template>
    <Panel :features="features">
        <template slot="powered">
            <template v-if="!features.hide.settings">
                <div v-if="!panels.privacy && !panels.settings" class="chat-player-toolbar">
                    <ButtonIcon v-if="!features.kiosk" slot="close" name="settings-circle" size="20px" :aria-label="$translate(api.language, 'tooltip.settings')" :content="$translate(api.language, 'tooltip.settings')" v-tippy="{ placement : 'top',  appendTo: container }" @click="onShowSetting" />
                </div>
            </template>

            <div v-if="features.widget" class="chat-player-header-close">
                <ButtonIcon name="minify" :aria-label="$translate(api.language, 'tooltip.close')" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'top',  appendTo: container }" @click="onClose"/>
            </div>
        </template>

        <template slot="body">
            <template v-if="panels.privacy" >
                <div ref="html" class="privacy-content scrollable scrollable-y" v-html="html"></div>
            </template>

            <template v-else>
                <div class="chat-player-start">
                    <div class="chat-player-permission">
                        <p class="chat-player-permission-text">{{$translate(api.language, 'bot.start')}}</p>
                        <IconPlay @onClick="onPlayStart"/>
                        <div v-if="!features.kiosk" class="chat-player-privacy" ref="privacyMessage" v-html="privacyMessage" @click.prevent="onClick($event.target)"></div>
                    </div>
                </div>
            </template>
        </template>

        <ButtonIcon slot="close" v-if="panels.privacy" name="close" class="circle" :aria-label="$translate(api.language, 'tooltip.close')" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'top',  appendTo: container }" @click="onClosePrivacy"/>
        <div slot="close" v-else class="chat-player-powered" v-html="poweredByMessage"></div>
    </Panel>

</template>

<script>

import axios from 'axios';
import ButtonIcon from "./chat/ButtonIcon.vue";
import IconPlay from "./icons/IconPlay.vue";
import Panel from "./panels/Panel.vue";

export default {
    name: "PlayerStart",
    components: {
        ButtonIcon,
        IconPlay,
        Panel
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            html: null,
            panels: {
                privacy: false,
                setting: false,
            },
        }
    },
    watch: {
        'html': {
            handler() {
                const self = this;
                self.$nextTick(() => {
                    if (self.features.kiosk) {
                        const links = self.$refs.html.querySelectorAll("a");
                        links.forEach(function (link) {
                            link.style.pointerEvents = 'none';
                        });
                    }
                });
            },
        },
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        },
        privacyMessage() {
            return this.api.getPrivacyMessage();
        },
        poweredByMessage() {
            return this.api.bot.poweredBy || null;
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onPlayStart() {
            this.$emit('onPlayStart');
        },
        getPrivacyContent(url) {

            const self = this;
            axios.get(url).then(response => {
                const html = document.createElement('html');
                html.innerHTML = response.data;
                const div = document.createElement('div');
                Array.from(html.getElementsByClassName("SECTION")).forEach(function(item) {
                    div.append(item);
                });

                self.html = div.innerHTML;
                self.panels.privacy = true;
            })
        },
        onClick(e) {
            if (e.tagName === 'A') {
                this.getPrivacyContent(e.href);
            }
        },
        onShowSetting() {
            this.$emit('onShowSetting');
        },
        onClosePrivacy() {
            this.panels.privacy = false;
        },
        onClose: function(){
            this.$emit('onClose');
        },

    },

}
</script>

<style lang="less">

.chat-player-start {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    overflow: hidden;

    .chat-player-privacy {
        width: auto;
        text-align: center;
        font-size: var(--font-11);
        line-height: calc(var(--font-11) + 3px);
        color: var(--caption-color);
        margin: 20px 46px 20px 46px;

        a {
            color: var(--primary-color-text);
        }

        p {
            margin: 0;
        }
    }

    .chat-player-permission {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translate(0,-50%);
        text-align: center;

        .chat-player-permission-text {
            margin: 20px 70px 20px 70px;
            font-size: var(--font-12);
            line-height: calc(var(--font-12) + 3px);
            color: var(--fg-color);
        }
    }

}
.chat-player-header-close {
    padding: 10px;
}

.chat-player-toolbar {
    padding: 10px;
    display: flex;
    position: absolute;
    top: 5px;
    left: 5px;
}

.chat-player-powered {
    width: 100%;
    margin-top: 22px;
    text-align: center;
    font-size: var(--font-10);
    color: var(--fg-color);

    a {
        color: var(--primary-color-text);
    }
}

.privacy-content {
    color: var(--fg-color);
    text-align: left;
    font-size: var(--font-11);
    line-height: calc(var(--font-11) + 3px);

    a {
        color: var(--primary-color-text);
    }

    h1, h2, h3, p {
        margin-top: 0;
    }

    h2 {
        font-size: var(--font-14);
        line-height: calc(var(--font-14) + 3px);
    }
}

</style>
