<template>
    <div class="header-container">

        <div v-if="!features.showLastMessage && (features.widget || features.multibot)" class="header-chat">
            <div v-if="!features.kiosk" class="header-title">
                <BotLang :features="features" :aria-label="$translate(api.language, 'tooltip.language')" :content="$translate(api.language, 'tooltip.language')" v-tippy="{ placement : 'top',  appendTo: container }" @onChangeBot="onChangeBot" />
                <span v-if="features.extras.botName" class="header-title-text" v-html="chatTitle">
                </span>
            </div>

            <div v-if="features.widget" class="header-close">
                <ButtonIcon name="minify" :aria-label="$translate(api.language, 'tooltip.close')" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'top',  appendTo: container }" @click="onClose"/>
            </div>
            <div v-else class="header-close" :class="{'header-flex-0': features.extras.botName}">
                &nbsp;
            </div>

            <div v-if="features.livechat.extended" class="header-livechat">
                <ButtonIcon v-if="features.livechat.activated" name="livechat_off_circle" size="18px" :content="$translate(api.language, 'tooltip.livechat.close')" v-tippy="{ placement : 'top', appendTo: container }" class="active" @click="onCloseLiveChat"/>
                <ButtonIcon v-else name="livechat-on-circle" size="18px" :content="$translate(api.language, 'tooltip.livechat.open')" v-tippy="{ placement : 'top',  appendTo: container }" @click="onOpenLiveChat"/>
            </div>
        </div>

        <div v-if="!features.status.start && features.dhi && features.showLastMessage" class="header-dhi">
            <div class="header-controls">
                <BotLang v-if="!features.kiosk" :features="features" class="rounded" :aria-label="$translate(api.language, 'tooltip.language')" :content="$translate(api.language, 'tooltip.language')" v-tippy="{ placement : 'left',  appendTo: container }" @onChangeBot="onChangeBot" />

                <div class="bot-livechat" v-if="features.livechat.extended">
                    <ButtonIcon v-if="features.livechat.activated" name="livechat_off_o" :content="$translate(api.language, 'tooltip.livechat.close')" v-tippy="{ placement : 'left', appendTo: container }" class="rounded active" @click="onCloseLiveChat"/>
                    <ButtonIcon v-else name="livechat_o" :content="$translate(api.language, 'tooltip.livechat.open')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded" @click="onOpenLiveChat"/>
                </div>

                <div class="header-buttons">
                    <template v-if="features.allowFullscreen && !features.fullscreen">
                        <ButtonIcon v-if="features.status.fullscreen" name="fullscreen_exit" class="rounded" :class="{'rounded-top': features.widget}" :aria-label="$translate(api.language, 'tooltip.minimize')" :content="$translate(api.language, 'tooltip.minimize')" v-tippy="{ placement : 'left',  appendTo: container }" @click="onToggleFullScreen"/>
                        <ButtonIcon v-else name="fullscreen" class="rounded" :class="{'rounded-top': features.widget}" :aria-label="$translate(api.language, 'tooltip.maximize')" :content="$translate(api.language, 'tooltip.maximize')" v-tippy="{ placement : 'left',  appendTo: container }" @click="onToggleFullScreen"/>

                        <ButtonIcon v-if="features.widget" name="minify" class="rounded" :class="{'rounded-bottom': features.widget}" :aria-label="$translate(api.language, 'tooltip.close')" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'left',  appendTo: container }" @click="onClose"/>
                    </template>
                    <template v-else>
                        <ButtonIcon v-if="features.widget" name="minify" class="rounded" :aria-label="$translate(api.language, 'tooltip.close')" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'left',  appendTo: container }" @click="onClose"/>
                    </template>
                </div>
            </div>
        </div>


    </div>
</template>

<script>

    import ButtonIcon from "./ButtonIcon.vue";
    import BotLang from './BotLang.vue';

    export default {
        name: 'Header',
        components: {
            ButtonIcon,
            BotLang
        },
        props: {
            features: {
                type: Object,
                required: true
            },
        },
        computed: {
            container() {
                const rootShadow = this.$root.$options.customElement.shadowRoot;
                return ()=> rootShadow.querySelector('div');
            },
            participants() {
                return this.$store.state.participants;
            },
            myself() {
                return this.$store.state.myself;
            },
            chatTitle() {
                return this.$store.state.chatTitle;
            },
        },
        beforeCreate() {
            this.api = this.$root.$children[0].$refs.api;
        },
        methods:{
            onToggleFullScreen() {
              this.$emit('onToggleFullScreen');
            },
            onClose: function(){
                this.$emit('onClose');
            },
            onChangeBot(bot) {
                this.$emit('onChangeBot', bot);
            },
            onOpenLiveChat: function () {
                this.$emit("onEnableLiveChat");
            },
            onCloseLiveChat: function () {
                this.$emit("onDisableLiveChat");
            },
        }
    }
</script>

<style lang="less">
    .chat-container .header-container {
        box-sizing: border-box;
        width: 100%;

        .header-livechat {
            position: absolute;
            display: flex;
            flex: 0;
            right: 13px;
            top: 14px;
            margin: 0;
            padding: 0;
            font-size: var(--font-10);
        }

        .header-title {
            position: absolute;
            display: flex;
            flex: 0;
            left: 13px;
            top: 14px;
            margin: 0;
            padding: 0;
            font-size: var(--font-10);
        }

        .header-title-text {

            color: var(--fg-color);
            border: 1.5px solid var(--fg-color);
            padding: 0px 6px;
            border-radius: var(--border-radius);
            height: 16px;
        }

        .header-chat {
            display: flex;
            flex-flow: row;
            padding: 10px;
            border-radius: var(--border-radius-frame) var(--border-radius-frame) 0 0;
            //box-shadow: 0px 0px 6px var(--bg-color-gradient);

            .header-close {
                flex: 1;
            }
        }

        .header-dhi {
            display: flex;
            align-items: flex-start;
            //margin-bottom: 20px; //aaaa

            .bot-livechat {
                display: flex;
                margin: 2px 2px 4px 0px;
            }

            .header-controls {
                display: flex;
                flex-flow: column;
                flex: 1;
                align-items: flex-end;

                .header-buttons {
                    width: 40px;
                    margin: 2px 2px 0 0;
                    border-radius: 40px !important;
                    box-shadow: var(--box-shadow);
                    backdrop-filter: var(--backdrop-filter);

                    .rounded {
                        svg {
                            box-shadow: none;
                        }
                    }
                }


            }
        }

    }
</style>
