<template>
    <div v-if="message.enabled" class="message-form">

        <DatePicker ref="date"
            :locale="$locale(this)"
            class="calendar"
            v-model="value"
            :available-dates="dates"
            :select-attribute="selectDragAttribute"
            :drag-attribute="selectDragAttribute"
            is-range
            @drag="dragValue = $event"
            @dayclick="onDayClick"
        >
            <template v-slot:day-popover="{ format }">
                <div>
                    {{ format(dragValue ? dragValue.start : range.start, 'MMM D') }}
                    -
                    {{ format(dragValue ? dragValue.end : range.end, 'MMM D') }}
                </div>
            </template>
        </DatePicker>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns'
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import DatePicker from "@/components/calendar/components/DatePicker";

export default {
    name: "FieldDateRange",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        DatePicker
    },
    data() {
        return {
            dragValue: null,
            value: {
                start: null,
                end: null,
            },
            show: true,
        };
    },
    computed: {
        disabled() {
            return (this.value.start === null || this.value.end === null);
        },
        selectDragAttribute() {
            return {
                popover: {
                    visibility: 'hover',
                    isInteractive: false, // Defaults to true when using slot
                },
            };
        },
        dates() {
            const self = this;
            return this.options.map(function (item) {
                const split = item.optionValue.split(' - ');
                return {
                    start: new Date(self.fixDate(split[0])),
                    end: new Date(self.fixDate(split[1]))
                };
            })
        },
    },
    methods: {
        fixDate(date) {
            if (date.indexOf('/') !== -1) {
                const split = date.split('/');
                return split[2] + '-' + split[1] + '-' + split[0];
            } else {
                return date;
            }
        },
        onDayClick() {
            if (this.$refs.date.isDragging)
                this.value.end = null;
        },
        onInputClicked: function () {
            const value = {
                inputMode: InputMode.CLICK,
                //algho lo vuole in questo formato, diverso dagli altri
                //optionValue: format(this.value.start, "yyyy-MM-dd") + " - " + format(this.value.end, "yyyy-MM-dd"),
                optionValue: format(this.value.start, "dd/MM/yyyy") + " - " + format(this.value.end, "dd/MM/yyyy"),
                optionText: format(this.value.start, "dd/MM/yyyy") + " - " + format(this.value.end, "dd/MM/yyyy"),
            };
            this.$emit("onSendCommand", value);
        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand", value);
        },
    }
}
</script>

<style>

</style>
