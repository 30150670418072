<template>
    <div v-if="message.enabled" class="message-form">

        <div class="form-field2">
            <div class="camera">
                <canvas ref="canvas" :class="{'flash': captured}" width="1024" height="768" @click="captured = !captured"></canvas>
            </div>
        </div>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";

import Vue from "vue";

export default {
    name: "FieldWebcam",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
    },
    data() {
        return {
            value: null,
            video: null,
            canvas: null,
            ctx: null,
            cameraStream: null,
            captured: false
        };
    },
    computed: {
        disabled() {
            return (this.value === null);
        },
    },
    mounted() {
        this.startStreaming();
    },
    methods: {
        startStreaming(){
            const self = this;

            const mediaSupport = 'mediaDevices' in navigator;

            if (mediaSupport) {
                if (this.cameraStream == null) {

                    const options = {facingMode: "environment", video: {width: 640, height: 480}}

                    navigator.mediaDevices.getUserMedia(options)
                        .then(function (mediaStream) {

                            self.cameraStream = mediaStream;

                            if (self.video == null) {

                                const settings = self.cameraStream.getTracks()[0].getSettings();
                                self.$log.info('Webcam settings', settings);

                                self.video = document.createElement('video');
                                self.video.width = settings.width;
                                self.video.height = settings.height;
                                self.video.srcObject = self.cameraStream;
                                self.video.setAttribute('autoplay', '');
                                self.video.setAttribute('muted', '');
                                self.video.setAttribute('playsinline', '');

                                self.canvas = self.$refs.canvas;
                                self.canvas.width = settings.width;
                                self.canvas.height = settings.height;
                                self.ctx = self.canvas.getContext('2d');

                                self.video.addEventListener( 'loadedmetadata', Vue.$common.scrollToBottom, false );
                                self.video.addEventListener( 'loadeddata', self.detectImage, false );
                            }
                        })
                        .catch(function (err) {
                            self.$log.error("Unable to access camera", err);
                            self.stopStreaming();
                        });
                }
            } else {
                self.$log.error('Your browser does not support media devices.');
            }
        },
        stopStreaming() {
            if(this.cameraStream != null) {

                const track = this.cameraStream.getTracks()[0];
                track.stop();

                this.video.pause();
                this.video.srcObject = null;
                this.video.load();

                this.cameraStream = null;
            }
        },
        getCanvasFile(filename) {

            const dataURI = this.canvas.toDataURL('image/jpeg');

             // convert base64/URLEncoded data component to raw binary data held in a string
             let byteString;
             if (dataURI.split(',')[0].indexOf('base64') >= 0)
                 byteString = atob(dataURI.split(',')[1]);
             else
                 byteString = unescape(dataURI.split(',')[1]);
             // separate out the mime component
             const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
             // write the bytes of the string to a typed array
             const ia = new Uint8Array(byteString.length);
             for (let i = 0; i < byteString.length; i++) {
                 ia[i] = byteString.charCodeAt(i);
             }
             const blob = new Blob([ia], {type: mimeString});

            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            blob.lastModifiedDate = new Date();
            blob.name = filename;
            return blob;
        },
        detectImage() {
            const self = this;

            if (this.message.enabled) {
                if (!this.captured) {
                    this.ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
                    this.value = null;
                } else {
                    this.value = '';
                }

                setTimeout(() => {
                    requestAnimationFrame(self.detectImage);
                }, 100)
            } else {
                this.stopStreaming();
            }
        },
        onInputClicked() {
            const self = this;
            const file = this.getCanvasFile('capture.jpg');
            this.api.uploadFile(file).then((data) => {
                self.sendFile(data);
            });
        },
        sendFile(url) {
            let html = '';
            const ext = url.split('.').pop().toLowerCase();
            if (ext === 'jpeg' || ext === 'jpg' || ext === 'png')
                html = '<img src="' + url + '">';
            else
                html = '<a href="' + url + '" target="_blank">' + this.$translate(this.api.language, 'chat.file') + '</a>';

            const value = {
                inputMode: InputMode.KEYBOARD,
                optionValue: url,
                optionText: html,
            };

            this.message.enabled = false;
            this.onSendCommand(value);
        },
        onSendCommand(value) {
            this.$emit("onSendCommand", value);
            this.stopStreaming();
        },
    }
}
</script>

<style lang="less">

.camera {
    width: 100%;
    height: auto;

    canvas {
        background-color: black;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        width: 100%;
        height: 100%;
        transform: scaleX(-1);
        cursor: pointer;
    }

    /* WEBCAM */

    .flash {
        transform: scaleX(1) !important;
        animation: flashing ease-out 0.3s 1;
    }

    @keyframes flashing {
        0% {
            filter: contrast(1) brightness(1);
        }
        100% {
            filter: contrast(0) brightness(10);
        }
    }

}
</style>
