<template>
    <ul v-if="features.narration.message != null" class="message-narration-content">
        <li class="message-narration-title" v-html="title"></li>
        <li class="message-narration-items">
            <div class="message-narration-item" @click="onNarrate(true)">{{$translate(api.language, 'narration.yes')}}</div>
            <div class="message-narration-item" @click="onNarrate(false)">{{$translate(api.language, 'narration.no')}}</div>
        </li>
    </ul>
</template>

<script>

export default {
    name: "Narration",
    components: {

    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
        };
    },
    computed: {
        title() {
            let title = this.$translate(this.api.language, 'narration.title');

            const bot = this.api.bot;
            if(bot.narrationMessage !== undefined && bot.narrationMessage !== '')
                title = bot.narrationMessage;

            return title;
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onNarrate(value) {
            this.$emit('onNarrate', value);
        }
    }
}
</script>

<style lang="less">

.message-narration-content {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    padding: 0;
    margin: 10px 20px;

    color: var(--fg-other-color);
    background-color: var(--bg-other-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);
    border-radius: var(--border-radius);
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);

    .message-narration-title {
        padding: 9px 12px 8px 12px;
        border-bottom: 1px solid var(--border-other-color);

        p {
            margin: 0;
        }
    }

    .message-narration-items {
        display: flex;
        flex-direction: row;
        border-bottom: 0;

        .message-narration-item {
            color: var(--fg-other-color);
            border-right: 1px solid var(--border-other-color);
            width: 50%;
            padding: 8px 12px 9px 12px;
            cursor: pointer;

            &:last-child {
                border-right: 0;
            }

            &:hover {
                color: var(--primary-color-text);
            }
        }
    }

}

</style>
