<template>
    <div v-if="message.enabled" class="message-form">

        <div class="form-field2">
            <div class="camera">
                <canvas ref="canvas" width="640" height="480"></canvas>
            </div>
        </div>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";

import jsQR from "jsqr";
import Vue from "vue";
import Helpers from "../../../classes/Helpers";

export default {
    name: "FieldQRCode",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
    },
    data() {
        return {
            value: '',
            video: null,
            canvas: null,
            ctx: null,
            cameraStream: null
        };
    },
    computed: {
        disabled() {
            return (this.value === null);
        },
    },
    mounted() {
        this.startStreaming();
    },
    methods: {
        startStreaming(){
            const self = this;

            const mediaSupport = 'mediaDevices' in navigator;

            if (mediaSupport) {
                if (this.cameraStream == null) {

                    const options = {facingMode: "environment", video: {width: 640, height: 480}}

                    navigator.mediaDevices.getUserMedia(options)
                        .then(function (mediaStream) {

                            self.cameraStream = mediaStream;

                            if (self.video == null) {

                                const settings = self.cameraStream.getTracks()[0].getSettings();
                                self.$log.info('Webcam settings', settings);

                                self.video = document.createElement('video');

                                self.video.width = settings.width;
                                self.video.height = settings.height;
                                self.video.srcObject = self.cameraStream;
                                self.video.setAttribute('autoplay', "true");
                                self.video.setAttribute('muted', "true");
                                self.video.setAttribute('playsinline', "true");
                                self.video.play();

                                self.canvas = self.$refs.canvas;
                                self.canvas.width = settings.width;
                                self.canvas.height = settings.height;
                                self.ctx = self.canvas.getContext('2d');

                                self.video.addEventListener( 'loadedmetadata', Vue.$common.scrollToBottom, false );
                                self.video.addEventListener( 'loadeddata', self.detectQrCode, false );
                            }
                        })
                        .catch(function (err) {
                            self.$log.error("Unable to access camera", err);
                            self.stopStreaming();
                        });
                }
            } else {
                self.$log.error('Your browser does not support media devices.');
            }
        },
        stopStreaming() {
            if(this.cameraStream != null) {

                const track = this.cameraStream.getTracks()[0];
                track.stop();

                this.video.pause();
                this.video.srcObject = null;
                this.video.load();

                this.cameraStream = null;
            }
        },
         drawLine(begin, end, color) {
             this.ctx.beginPath();
             this.ctx.moveTo(begin.x, begin.y);
             this.ctx.lineTo(end.x, end.y);
             this.ctx.lineWidth = 3;
             this.ctx.strokeStyle = color != undefined ? color : '#31b3f4';
             this.ctx.stroke();
         },
        drawRectangle(left, top, width, height) {
            const length = 50;
            this.drawLine({x: left, y: top}, {x: left + length, y: top}, 'red');
            this.drawLine({x: left, y: top}, {x: left, y: top + length}, 'red');
            this.drawLine({x: left, y: top + height}, {x: left, y: top + height - length}, 'red');
            this.drawLine({x: left, y: top + height}, {x: left + length, y: top + height}, 'red');
            this.drawLine({x: left + width, y: top + height}, {x: left + width, y: top + height - length}, 'red');
            this.drawLine({x: left + width, y: top + height}, {x: left + width - length, y: top + height}, 'red');
            this.drawLine({x: left + width, y: top}, {x: left + width - length, y: top}, 'red');
            this.drawLine({x: left + width, y: top}, {x: left + width, y: top + length}, 'red');
        },
        detectQrCode() {
            if (this.message.enabled) {
                const self = this;

                let detected = false;

                this.ctx.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
                const imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);

                const space = 80;
                this.drawRectangle(((this.canvas.width - this.canvas.height) / 2) + space, space, this.canvas.height - (space * 2), this.canvas.height - (space * 2));

                const code = jsQR(imageData.data, imageData.width, imageData.height, {
                    inversionAttempts: "dontInvert",
                });

                if (code && code.data !== null && code.data !== '') {
                    this.drawLine(code.location.topLeftCorner, code.location.topRightCorner);
                    this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner);
                    this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner);
                    this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner);
                    //console.log(code.data);
                    detected = true;

                    this.value = code.data;

                    setTimeout(() => {
                        self.onInputClicked();
                    }, 1000)

                }

                if (!detected) {
                    setTimeout(() => {
                        requestAnimationFrame(self.detectQrCode);
                    }, 100)
                }
            } else {
                this.stopStreaming();
            }
        },
        onInputClicked() {
            const value = {
                inputMode: InputMode.KEYBOARD,
                optionValue: this.value,
                optionText: Helpers.icons.qrCode
            };
            this.$emit("onSendCommand", value);
            this.stopStreaming();
        },
        onSendCommand(value) {
            this.$emit("onSendCommand", value);
            this.stopStreaming();
        },
    }
}
</script>

<style lang="less">

.camera {
    width: 100%;
    height: auto;

    canvas {
        background-color: black;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        width: 100%;
        height: 100%;
        transform: scaleX(-1)
    }
}
</style>
