<template>
    <div v-if="waiting">
        <div class="loading-message-body">

            <div v-if="hasThumb && showPartecipant" class="thumb-container">
                <img class="participant-thumb" alt="" :src="getParticipantById(1).profilePicture"
                     :style="{'width': profilePictureConfig.styles.width, 'height': profilePictureConfig.styles.height, 'border-radius': profilePictureConfig.styles.borderRadius}">
            </div>

            <div class="message-content">

                <div class="message-waiting">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid">
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" opacity="0.3" >
                        </circle>
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" stroke-dasharray="10 50">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 10 10;360 10 10" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "LoadingMessage",
    props: {
        features: {
          type: Object,
          required: true
        },
        profilePictureConfig: {
            type: Object,
            required: true
        },
        showPartecipant: {
            type: Boolean,
            required: true
        },
        waiting: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'getParticipantById',
        ]),
        hasThumb() {
            return this.features.extras.botIcon && this.profilePictureConfig.others;
        },
    },
}
</script>

<style lang="less">

.container-message-display {

    .loading-message-body {
        display: flex;
        align-items: flex-start;
        padding-left: 13px;
        margin-top: 2px;
        margin-bottom: 6px;

        .message-content {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            flex-grow: 1;

            .message-waiting {
                border-radius: var(--border-radius-small) var(--border-radius) var(--border-radius) var(--border-radius);
                padding: 6px;
                background: var(--bg-color-bot);
                color: var(--fg-color-bot);
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);
            }
        }

        .participant-thumb {
            /* width: 25px;
        height: 25px;
        border-radius: 50%; */
            max-width: unset;
            margin-right: 10px;
        }
    }
}

</style>
