<template>
    <div>
        <ul v-if="(features.device.desktop || !features.device.slowMobile) && requested" v-show="features.status.visible" class="message-emotion-content margin-common-content">
            <li class="message-emotion-title">
                {{$translate(api.language, 'emotion.title')}}
            </li>
            <li v-if="notAccepted && (features.agreements.marketing || features.agreements.profiling || features.agreements.sensitive)" class="message-emotion-privacy">
                <div v-html="emotionMessage" @click.prevent="onClick($event.target)"></div>

                <div class="message-emotion-privacy-check" :class="{'col-2': features.showLastMessage}">
                    <template>
                        <label class="checkbox check-all">{{$translate(api.language, 'emotion.checkAll')}}
                            <input type="checkbox" v-model="privacy.values.all" @change="onPrivacyCheckAll">
                            <span class="checkmark"></span>
                        </label>
                        <br>
                    </template>

                    <template  v-if="features.agreements.marketing">
                        <label class="checkbox">{{$translate(api.language, 'emotion.marketing')}}
                            <input type="checkbox" v-model="privacy.values.marketing" @change="onPrivacyCheck">
                            <span class="checkmark"></span>
                        </label>
                        <ButtonIcon name="info_o" class="checkbox-info" size="16px" @click="onShowPrivacyPanel('MARKETING')" />
                        <br>
                    </template>

                    <template  v-if="features.agreements.profiling">
                        <label class="checkbox">{{$translate(api.language, 'emotion.profiling')}}
                            <input type="checkbox" v-model="privacy.values.profiling" @change="onPrivacyCheck">
                            <span class="checkmark"></span>
                        </label>
                        <ButtonIcon name="info_o" class="checkbox-info" size="16px" @click="onShowPrivacyPanel('PROFILING')" />
                        <br>
                    </template>

                    <template  v-if="features.agreements.sensitive">
                        <label class="checkbox">{{$translate(api.language, 'emotion.sensitive')}}
                            <input type="checkbox" v-model="privacy.values.sensitive" @change="onPrivacyCheck">
                            <span class="checkmark"></span>
                        </label>
                        <ButtonIcon name="info_o" class="checkbox-info" size="16px" @click="onShowPrivacyPanel('SENSITIVE')" />
                        <br>
                    </template>
                </div>
            </li>
            <li class="message-emotion-items">
                <div class="message-emotion-item" >
                    <button type="button" :disabled="!privacy.enabled" @click="onEmotion(true)">{{$translate(api.language, 'emotion.yes')}}</button>

                </div>
                <div class="message-emotion-item">
                    <button type="button" @click="onEmotion(false)">{{$translate(api.language, 'emotion.no')}}</button>
                </div>
            </li>
        </ul>
        <ul v-if="showNote" class="message-emotion-content margin-common-content">
            <li class="message-emotion-title">
                {{$translate(api.language, 'emotion.note')}}
                <Icon name="webcam_o" size="18px" class="note-icon"/>
            </li>
            <li class="message-emotion-items">
                <div class="message-emotion-item w-100" @click="showNote = false">{{$translate(api.language, 'emotion.close')}}</div>
            </li>
        </ul>
    </div>
</template>

<script>

const StorageKeys = {
    WEBCAM: '_algho_webcam'
};

import ButtonIcon from "../ButtonIcon.vue";
import Icon from "../Icon.vue";

export default {
    name: "Emotion",
    components: {
        ButtonIcon,
        Icon
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            privacy: {
                enabled: false,
                values: {
                    marketing: false,
                    profiling: false,
                    sensitive: false,
                    all: false
                }
            },
            showNote: false

        }
    },
    computed: {
        notAccepted() {
            return this.api.getPrivacyWebcam() === null;
        },
        requested() {
            return this.features.webcam.requested;
        },
        isSavedState() {
            return window.sessionStorage.getItem(StorageKeys.WEBCAM) != null;
        },
        emotionMessage() {
            const description = (this.api.bot.emotionalModuleDescription || '').trim();
            const noLink = description === ''; // || description === 'Da definire';
            return this.$translate(this.api.language, 'emotion.message') + (noLink ? '' : ' ' + this.$translate(this.api.language, 'emotion.messageLink'));
        },
    },
    watch: {
        'features.webcam.requested': {
            handler(newVal) {
                if (newVal)
                    this.$common.scrollToBottom();
            },
            deep: true,
            immediate: false
        },
        'showNote': {
            handler(newVal) {
                if (newVal) {
                    const self = this;
                    setTimeout(()=> {
                        self.showNote = false;
                    }, 5000);
                }
            },
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        if (!this.features.webcam.activated && !this.isSavedState)
            this.features.webcam.requested = this.features.has.webcam;

        this.privacy.enabled = !this.notAccepted;
        this.onPrivacyCheck();
    },
    methods: {
        onPrivacyCheck() {
            let max = 0;
            let sel = 0;

            if (this.api.getPrivacyWebcam() === null) {
                if (this.features.agreements.marketing)
                    max++;
                if (this.features.agreements.profiling)
                    max++;
                if (this.features.agreements.sensitive)
                    max++;

                if (this.privacy.values.marketing)
                    sel++;
                if (this.privacy.values.profiling)
                    sel++;
                if (this.privacy.values.sensitive)
                    sel++;

            }

            this.privacy.values.all = (sel === max);

            this.privacy.enabled = this.privacy.values.all;
        },
        onPrivacyCheckAll() {
            if (this.features.agreements.marketing)
                this.privacy.values.marketing = this.privacy.values.all;
            if (this.features.agreements.profiling)
                this.privacy.values.profiling = this.privacy.values.all;
            if (this.features.agreements.sensitive)
                this.privacy.values.sensitive = this.privacy.values.all;

            this.privacy.enabled = this.privacy.values.all;
        },
        onClick(e) {
            if (e.tagName === 'A') {
                this.$emit('onShowEmotionPanel');
            }
        },
        onShowPrivacyPanel(anchor) {
            this.$emit('onShowPrivacyPanel', anchor);
        },
        onEmotion(value) {
            this.features.webcam.requested = false;
            if (this.notAccepted) {
                if (value) {
                    this.api.setPrivacyWebcam();
                }
            }
            this.showNote = value;
            window.sessionStorage.setItem(StorageKeys.WEBCAM, value ? '1': '0');
            this.$emit('onEmotion', value);
        }
    }
}
</script>

<style lang="less">

.message-emotion-content {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;

    color: var(--fg-other-color);
    background-color: var(--bg-other-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);
    border-radius: var(--border-radius);
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);

    .message-emotion-title {
        padding: 9px 12px 8px 12px;
        border-bottom: 1px solid var(--border-other-color);

        .note-icon {
            position: relative;
            top:5px;
            color: var(--primary-color);
        }
    }
    .message-emotion-privacy {
        padding: 8px 12px 9px 12px;
        border-bottom: 1px solid var(--border-other-color);
        text-align: left;

        a {
            color: var(--primary-color-text);
        }
    }

    .message-emotion-items {
        display: flex;
        flex-direction: row;
        border-bottom: 0;

        .message-emotion-item {
            color: var(--fg-other-color);
            border-right: 1px solid var(--border-other-color);
            width: 50%;
            padding: 8px 12px 9px 12px;
            cursor: pointer;

            & > button {
                width: 100%;
                height: 100%;
                border: 0;

                background-color: transparent;
                font-size: var(--font-12);
            }

            & > button:not([disabled]) {
                color: var(--fg-color);
                cursor: pointer;

                &:hover {
                    color: var(--primary-color-text);
                }

                &:active {
                    color: var(--primary-color-text);
                }

                &:focus {
                    color: var(--primary-color-text);
                    outline: 0;
                }
            }

            &.w-100 {
                width: 100%;
                border-right: unset;
            }

            &:last-child {
                border-right: 0;
            }

            &:hover {
                color: var(--primary-color-text);
            }
        }
    }

    .message-emotion-privacy-check {
        margin-top: 10px;
        &.col-2 {
            column-count: 2;
        }
    }

    .checkbox-info {
        margin-left: 6px;
        margin-bottom: 3px;
    }

    .checkbox {
        display: inline-block;
        position: relative;
        padding-left: 24px;
        margin-bottom: 3px;
        line-height: calc(var(--font-12) + 7px);
        cursor: pointer;
        font-size: var(--font-12);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &.check-all {
            color: var(--primary-color-text);
        }

        & input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        & .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: #00000010;
            border: 1px solid var(--border-other-color);
        }

        &:hover input ~ .checkmark {
            background-color: #00000020;
        }

        & input:checked ~ .checkmark {
            //background-color: var(--bg-other-color);
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        & input:checked ~ .checkmark:after {
            display: block;
        }

        & .checkmark:after {
            left: 5px;
            top: 1px;
            width: 4px;
            height: 8px;
            border: solid var(--primary-color);
            box-shadow: 1px 1px 1px #00000010;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

}

</style>
