<template>
    <div class="notch" @click="onClicks">
        <div class="notch-text">
            <template v-if="standby && webcam">{{$translate(api.language, 'kiosk.introMessage.default')}}</template>
            <template v-else-if="standby && !webcam">{{$translate(api.language, 'kiosk.introMessage.nocam')}}</template>
            <template v-else-if="features.command || features.commandOffline">{{$translate(api.language, 'kiosk.activationMessage').replace('{name}', api.bot.name)}}</template>
            <template v-else>{{$translate(api.language, 'kiosk.activationMessageNoCommand')}}</template>

        </div>
    </div>
</template>

<script>

export default {
    name: "KioskNotch",
    components: {
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        standby: {
            type: Boolean,
            required: true
        },
        webcam: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            clicks: 0,
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onShowSetting() {
            this.$emit('onShowSetting');
        },
        onClicks() {
            this.clicks++;
            if (this.clicks >= 10) {
                this.clicks = 0;
                this.onShowSetting();
            }
        },

    }
}
</script>

<style lang="less">
@corner-size: 18px;
@bg-color: #000000;
@fg-color: #FFFFFF;

.notch {
    position: fixed;
    top: 0;
    left: 72px;
    right: 72px;
    height: 28px;
    background-color: @bg-color;
    border-radius: 0 0 @corner-size @corner-size;

    .notch-text {
        color: @fg-color;
        font-size: var(--font-12);
        line-height: calc(var(--font-12) + 3px);
        padding: 6px 22px 7px 22px
    }

    &::before
    {
        content: '';
        position: absolute;
        top: 0;
        left: -@corner-size + 1px;
        width: @corner-size;
        height: @corner-size;
        background-repeat: no-repeat;
        background-image: radial-gradient(
            circle at 0 100%,
            transparent @corner-size,
            @bg-color @corner-size
        );
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -@corner-size + 1px;
        width: @corner-size;
        height: @corner-size;
        background-repeat: no-repeat;
        background-image: radial-gradient(
            circle at 100% 100%,
            transparent @corner-size,
            @bg-color @corner-size
        );
    }
}
</style>
