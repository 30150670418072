<template>
    <div v-if="false"></div>
</template>

<script>

const SAMPLE_RATE = 16000;
const SAMPLE_SIZE = 16;

const audioSettings = {
    echoCancellation: true,
    channelCount: 1,
    sampleSize: SAMPLE_SIZE
};

export default {
    name: "SttAlgho",
    props: {
        listenVoice: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            textInput: '',

            audioContext: null,
            micStream: null,

            webSocket: null,
            sourceNode: null,
            scriptNode: null,
            canSend: false

        }
    },
    watch: {
        listenVoice: {
            handler(newVal) {
                if (newVal)
                    this.startRecognizeVoice();
                else
                    this.stopRecognizeVoice();
            },
            deep: true
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox)
            delete audioSettings.channelCount;

    },
    methods: {
        startRecognizeVoice() {
            const self = this;

            this.textInput = '';

            const AudioContext = window.AudioContext // Default
                || window.webkitAudioContext // Safari and old versions of Chrome
                || false;

            // Check for non Web Audio API browsers.
            if (!AudioContext) {
                self.$log.warn("Web Audio not available on web browser.");
                return;
            }

            this.audioContext = new AudioContext();
            this.scriptNode = this.audioContext.createScriptProcessor(4096, 1, 1);
            this.scriptNode.connect(this.audioContext.destination);

            this.scriptNode.onaudioprocess = function (e) {

                if (self.webSocket != null && self.canSend) {
                    const floatSamples = e.inputBuffer.getChannelData(0);
                    // The samples are floats in range [-1, 1]. Convert to 16-bit signed integer.

                    const buffer = self.downsampleBuffer(floatSamples);

                    if (self.webSocket.readyState === self.webSocket.OPEN) {
                        self.webSocket.send(buffer);
                    }
                }
            };
            navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

            const getUserMediaSuccess = function (micStream) {
                self.$log.info('Open microphone');
                self.micStream = micStream;
                self.initWebsocket();
            };

            const getUserMediaError = function (e) {
                self.$log.error('Error open microphone', e.message);
            };

            if (navigator.mediaDevices != undefined && navigator.mediaDevices.getUserMedia !== undefined) {
                // Safari iOS flow
                navigator.mediaDevices.getUserMedia({audio: audioSettings}).then(getUserMediaSuccess).catch(getUserMediaError);
            } else {
                // Standard flow
                navigator.getUserMedia({audio: audioSettings}, getUserMediaSuccess, getUserMediaError);
            }

        },

        stopRecognizeVoice() {
            this.closeWebsocket();
        },

        initWebsocket() {
            this.$log.debug('Init Stt websocket');
            this.audioContext.onstatechange = this.toggleWebsocket;
            // initialize for the current state
            this.toggleWebsocket();
        },

        toggleWebsocket() {
            this.$log.debug('Toggle websocket', this.audioContext.state);
            if (this.audioContext.state === 'running') {
                this.newWebsocket();
            } else if (this.audioContext.state === 'suspended') {
                this.closeWebsocket();
            }
        },

        newWebsocket() {
            const self = this;

            if (this.webSocket == null) {

                this.audioContext.resume();

                this.sourceNode = this.audioContext.createMediaStreamSource(this.micStream);
                this.sourceNode.connect(this.scriptNode);

                const url = this.api.baseUrl.replace('https', 'wss').replace('http', 'ws') + '/stt/';
                this.webSocket = new WebSocket(url);
                this.$log.info("Init Stt websocket");

                // If the socket is closed for whatever reason, pause the mic
                this.webSocket.onclose = function () {
                    self.canSend = false;
                    self.micStream.getTracks().forEach(track => track.stop());
                    self.audioContext.onstatechange = null;

                    self.$log.info('Stt websocket closing');
                    self.$emit("onStopRecognizeVoice");
                };

                this.webSocket.onerror = function (e) {
                    self.$log.error('Error from Stt websocket', e);
                    self.$emit("onStopRecognizeVoice");
                };

                this.webSocket.onmessage = this.onTranscription;

                this.webSocket.onopen = function () {
                    self.$log.info('Stt websocket open');
                    self.$emit("onStartRecognizeVoice");

                    if (self.webSocket != null && self.webSocket.readyState === self.webSocket.OPEN) {
                        self.$log.info('Stt websocket send language');
                        self.webSocket.send(JSON.stringify({sampleRate: SAMPLE_RATE, languageCode: self.api.language}));
                    }

                };
            }
        },

        closeWebsocket() {
            this.$log.info('Close websocket');
            if (this.scriptNode)
                this.scriptNode.disconnect();
            if (this.sourceNode)
                this.sourceNode.disconnect();

            if (this.webSocket && this.webSocket.readyState === this.webSocket.OPEN)
                this.webSocket.close();

            this.webSocket = null;
        },

        onTranscription(e) {
            const result = JSON.parse(e.data);

            if (result.sampleRate !== undefined) {
                this.canSend = true;
                return;
            }

            let text = '';
            let confidence = 0;
            const final = result.isFinal_;

            if (result.alternatives_) {
                text = result.alternatives_[0].transcript_;
                confidence = result.alternatives_[0].confidence_;
            }
            this.textInput = text;
            this.$log.debug("Recognized", text, confidence);
            this.$emit("onRecognizeText", {text, confidence, final, send: false});
        },

        downsampleBuffer(buffer) {
            // Need the maximum value for 16-bit signed samples, to convert from float.
            const MAX_INT = Math.pow(2, 16 - 1) - 1;

            const sampleRateRatio = this.audioContext.sampleRate / SAMPLE_RATE;
            const newLength = Math.round(buffer.length / sampleRateRatio);
            const result = new Int16Array(newLength);
            let offsetResult = 0;
            let offsetBuffer = 0;
            while (offsetResult < result.length) {
                const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
                let accum = 0,
                    count = 0;
                for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
                    accum += buffer[i];
                    count++;
                }
                result[offsetResult] = (accum / count) * MAX_INT;
                offsetResult++;
                offsetBuffer = nextOffsetBuffer;
            }
            return result;
        }

    }
}

</script>

<style>

</style>
