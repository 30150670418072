<template>
    <ul class="message-vote-content">
        <li class="message-vote-title">{{$translate(api.language, 'vote.title')}}</li>
        <li class="message-vote-items">
            <div class="message-vote-item" @click="onVote(true)">{{$translate(api.language, 'vote.yes')}}</div>
            <div class="message-vote-item" @click="onVote(false)">{{$translate(api.language, 'vote.no')}}</div>
        </li>
    </ul>
</template>

<script>

export default {
    name: "Vote",
    components: {
    },
    props: {
        message: {
            type: Object,
            required: true
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onVote(success) {
            if (this.message != null) {
                this.message.voted = success ? 1 : 0;

                const value = {
                    success: success,
                    answerId: this.message.answerId,
                    questionText: this.message.userQuestionText
                }
                this.$emit('onVote', value);
            }
        }
    }
}
</script>

<style lang="less">

    .message-vote-content {
        display: flex;
        flex-direction: column;
        list-style-type: none;

        padding: 0;
        margin: 10px 20px;

        color: var(--fg-other-color);
        background-color: var(--bg-other-color);
        box-shadow: var(--box-shadow);
        backdrop-filter: var(--backdrop-filter);
        border-radius: var(--border-radius);
        font-size: var(--font-12);
        line-height: calc(var(--font-12) + 3px);

        .message-vote-title {
            padding: 9px 12px 8px 12px;
            border-bottom: 1px solid var(--border-other-color);
        }

        .message-vote-items {
            display: flex;
            flex-direction: row;
            border-bottom: 0;

            .message-vote-item {
                color: var(--fg-other-color);
                border-right: 1px solid var(--border-other-color);
                width: 50%;
                padding: 8px 12px 9px 12px;
                cursor: pointer;

                &:last-child {
                    border-right: 0;
                }

                &:hover {
                    color: var(--primary-color-text);
                }
            }
        }

    }

</style>
