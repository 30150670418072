
const messages = {
    it: {
        bot: {
            error: "Si è verificato un errore, riprovare più tardi",
            unauthorized: "Errore: non autorizzato",
            start: "Clicca qui sotto per parlare con me",
            welcome: "Ciao e benvenuto. Rivolgimi domande precise, ti aiuterò a trovare le informazioni che cerchi.",
            expired: "Assistente disabilitato"
        },
        placeholder: {
            typeHere: "Scrivi qui"
        },
        chat: {
            file: "File",
            link: "Link"
        },
        date: {
            today: "Oggi",
            yesterday: "Ieri"
        },
        vote: {
            title: "La risposta ti è stata utile?",
            yes: "Si",
            no: "No"
        },
        narration: {
            title: "Vuoi che ti descriva il prodotto?",
            yes: "Si",
            no: "No"
        },
        emotion: {
            title: "Vuoi abilitare il riconoscimento emozionale?",
            message: "Per utilizzare il modulo emozionale è necessario che tu fornisca i consensi sotto riportati.",
            messageLink: "Per saperne di più fai click <a href=\"#\">qui</a>.",
            note: "Ricorda che potrai disattivare il modulo in qualsiasi momento cliccando sull’icona della webcam dal menu in basso.",
            marketing: "Marketing",
            profiling: "Profilazione",
            sensitive: "Dati sensibili",
            checkAll: "Seleziona tutto",
            close: "Chiudi",
            yes: "Si",
            no: "No"
        },
        slideshow: {
            title: "Ecco la tua presentazione!"
        },
        form: {
            title: "Sei sicuro di voler interrompere la compilazione?",
            yes: "Si",
            no: "No",
            confirm: "Conferma",
            cancel: "Annulla",
            skip: "Salta"
        },
        suggestion: {
            title: "Mostra domande correlate",
            others: "Mostra altro"
        },
        file: {
            open: "Apri",
            download: "Scarica",
            upload: "Carica",
            attachment: "Allegato"
        },
        card: {
            detail: "Mostra dettaglio",
            select: "seleziona",
            unselect: "deseleziona",
            total: "Totale",
            search: "Cerca"
        },
        location: {
            disabled: "GPS non attivo",
            activate: "Rileva posizione GPS"
        },
        livechat: {
            wait: "Ti sto mettendo in contatto con un operatore, attendere...",
            message: "Stai per essere messo in contatto con un operatore.",
            request: "Desideri accettare la chiamata da un operatore?",
            question: "Vuoi essere messo in contatto con un operatore?",
            cancel: "Annulla",
            close: "Chiudi",
            yes: "Si",
            no: "No"
        },
        kiosk: {
            exit: "Esci",
            privacy: "Privacy policy",
            privacyMessage: "Prendi visione dell'Informativa privacy cliccando sul link in basso",
            introMessage: {
                default: "Posizionati davanti alla cam o clicca sullo schermo",
                nocam: "Clicca sullo schermo"
            },
            activationMessage: "Pronuncia «Ok {name}» per interagire con me",
            activationMessageNoCommand: "Clicca sul microfono per interagire con me",
            disengageMessage: "Clicca qui sotto se vuoi riprendere la conversazione",
            resumeMessage: "Riprendi",
            multimedia: {
                close: 'Chiudi',
                expand: 'Espandi'
            }
        },
        tooltip: {
            microphone: {
                disable: "Disattiva microfono",
                enable: "Attiva microfono"
            },
            speaker: {
                stop: "Interrompi audio",
                disable: "Disattiva audio",
                enable: "Attiva audio"
            },
            messages: {
                disable: "Disattiva messaggi",
                enable: "Attiva messaggi"
            },
            keyboard: {
                enable: "Attiva tastiera"
            },
            webcam: {
                disable: "Disattiva analisi emozionale",
                enable: "Attiva analisi emozionale"
            },
            chat: {
                hide: "Nascondi chat",
                show: "Mostra chat"
            },
            mode: {
                dhi: "Interfaccia Dhi",
                txt: "Interfaccia Testuale"
            },
            send: "Invia",
            menu: "Menù",
            settings: "Impostazioni",
            livechat: {
                close: "Chiudi livechat",
                open: "Apri livechat"
            },
            info: "Info",
            close: "Chiudi",
            minimize: "Riduci",
            maximize: "Ingrandisci",
            language: "Lingua",
            clear: "Svuota"
        },
        panels: {
            settings: {
                settings: {
                    title: "IMPOSTAZIONI",
                    sounds: "Suoni notifiche",
                    rememberInput: "Ricorda scelta input",
                    fontLarge: "Testo grande",
                    volume: "Volume audio"
                },
                debug: {
                    title: "DEBUG",
                    webcam: "Webcam"
                },
                version: {
                    title: "VERSIONE",
                    version: "Versione",
                    date: "Data"
                },
                developer: {
                    title: "SVILUPPATORE",
                    stats: "Mostra prestazioni",
                    browser: "BROWSER",
                    device: "DEVICE",
                    capabilities: "CAPABILITIES",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "CONTATTI",
                    open: "Apri"
                },
                privacy: {
                    title: "PRIVACY",
                    open: "Apri"
                }
            }
        }
    },
    en: {
        bot: {
            error: "An error has occurred, please try again later",
            unauthorized: "Error: not authorized",
            start: "Click below to talk to me",
            welcome: "Hello and welcome. Ask me specific questions, I will help you find the information you are looking for.",
            expired: "Assistant disabled"
        },
        placeholder: {
            typeHere: "Write here"
        },
        chat: {
            file: "File",
            link: "Link"
        },
        date: {
            today: "Today",
            yesterday: "Yesterday"
        },
        vote: {
            title: "Was the answer useful to you?",
            yes: "Yes",
            no: "No"
        },
        narration: {
            title: "Do you want me to describe the product to you?",
            yes: "Yes",
            no: "No"
        },
        emotion: {
            title: "Do you want to enable emotional recognition?",
            message: "To use the emotional module it is necessary that you provide the consents listed below.",
            messageLink: "To learn more, click <a href=\"#\">here</a>.",
            note: "Remember that you can deactivate the module at any time by clicking on the webcam icon from the bottom menu.",
            marketing: "Marketing",
            profiling: "Profiling",
            sensitive: "Sensitive data",
            checkAll: "Select all",
            close: "Close",
            yes: "Yes",
            no: "No"
        },
        slideshow: {
            title: "Here is your presentation!"
        },
        form: {
            title: "Are you sure you want to stop compiling?",
            yes: "Yes",
            no: "No",
            confirm: "Confirm",
            cancel: "Cancel",
            skip: "Skip"
        },
        suggestion: {
            title: "Show related questions",
            others: "Show others"
        },
        file: {
            open: "Open",
            download: "Download",
            upload: "Upload",
            attachment: "Attachment"
        },
        card: {
            detail: "Show detail",
            select: "select",
            unselect: "deselect",
            total: "Total",
            search: "Search"
        },
        location: {
            disabled: "GPS not active",
            activate: "Detect GPS position"
        },
        livechat: {
            wait: "I'm putting you in touch with an operator, wait...",
            message: "You are about to be put in contact with an operator.",
            request: "Would you like to accept the call from operator?",
            question: "Would you like to call an operator?",
            cancel: "Cancel",
            close: "Close",
            yes: "Yes",
            no: "No"
        },
        kiosk: {
            exit: "Exit",
            privacy: "Privacy policy",
            privacyMessage: "Read the privacy policy by clicking on the link below",
            introMessage: {
                default: "Stand in front of the cam or click on the screen",
                nocam: "Click on the screen"
            },
            activationMessage: "Say «Ok {name}» to interact with me",
            activationMessageNoCommand: "Click on the microphone to interact with me",
            disengageMessage: "Click below if you want to resume the conversation",
            resumeMessage: "Resume",
            multimedia: {
                close: 'Close',
                expand: 'Expand'
            }
        },
        tooltip: {
            microphone: {
                disable: "Deactivate microphone",
                enable: "Activate microphone"
            },
            speaker: {
                stop: "Stop audio",
                disable: "Deactivate audio",
                enable: "Activate audio"
            },
            messages: {
                disable: "Deactivate messages",
                enable: "Activate messages"
            },
            keyboard: {
                enable: "Activate keyboard"
            },
            webcam: {
                disable: "Disable Emotion Analysis",
                enable: "Enable Emotion Analysis"
            },
            chat: {
                hide: "Hide chat",
                show: "Show chat"
            },
            mode: {
                dhi: "Dhi Interface",
                txt: "Textual interface"
            },
            send: "Send",
            menu: "Menù",
            settings: "Settings",
            livechat: {
                close: "Close livechat",
                open: "Open livechat"
            },
            info: "Info",
            close: "Close",
            minimize: "Minimize",
            maximize: "Maximize",
            language: "Language",
            clear: "Clear"
        },
        panels: {
            settings: {
                settings: {
                    title: "SETTINGS",
                    sounds: "Sound alert",
                    rememberInput: "Remember input mode",
                    fontLarge: "Large text",
                    volume: "Audio volume"
                },
                debug: {
                    title: "DEBUG",
                    webcam: "Webcam"
                },
                version: {
                    title: "VERSION",
                    version: "Version",
                    date: "Date"
                },
                developer: {
                    title: "DEVELOPER",
                    stats: "Show stats",
                    browser: "BROWSER",
                    device: "DEVICE",
                    capabilities: "CAPABILITIES",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "CONTACTS",
                    open: "Open"
                },
                privacy: {
                    title: "PRIVACY",
                    open: "Open"
                }
            }
        }
    },
    fr: {
        bot: {
            error: "Une erreur s'est produite, veuillez réessayer plus tard",
            unauthorized: "Erreur: non autorisé",
            start: "Cliquez ci-dessous pour me parler",
            welcome: "Bonjour et bienvenue. Posez-moi des questions précises, je vous aiderai à trouver les informations que vous recherchez.",
            expired: "Assistant désactivé"
        },
        placeholder: {
            typeHere: "Écrire ici"
        },
        chat: {
            file: "Fichier",
            link: "Lien"
        },
        date: {
            today: "Aujourd'hui",
            yesterday: "Hier"
        },
        vote: {
            title: "La réponse a-t-elle été utile?",
            yes: "Oui",
            no: "Non"
        },
        narration: {
            title: "Voulez-vous que je décrive le produit?",
            yes: "Oui",
            no: "Non"
        },
        emotion: {
            title: "Vous souhaitez activer la reconnaissance émotionnelle?",
            message: "Pour utiliser le module émotionnel, il est nécessaire que vous fournissiez les consentements énumérés ci-dessous.",
            messageLink: "Pour en savoir plus, cliquez <a href=\"#\">ici</a>.",
            note: "N'oubliez pas que vous pouvez désactiver le module à tout moment en cliquant sur l'icône de la webcam dans le menu du bas.",
            marketing: "Commercialisation",
            profiling: "Profilage",
            sensitive: "Données sensibles",
            checkAll: "Tout sélectionner",
            close: "Fermer",
            yes: "Oui",
            no: "Non"
        },
        slideshow: {
            title: "Voici votre présentation!"
        },
        form: {
            title: "Êtes-vous sûr de vouloir interrompre la compilation?",
            yes: "Oui",
            no: "Non",
            confirm: "Confirmer",
            cancel: "Annuler",
            skip: "Sauter"
        },
        suggestion: {
            title: "Afficher les questions connexes",
            others: "Afficher plus"
        },
        file: {
            open: "Ouvrir",
            download: "Télécharger",
            upload: "Télécharger",
            attachment: "Pièce jointe"
        },
        card: {
            detail: "Montrer le détail",
            select: "Sélectionner",
            unselect: "Désélectionner",
            total: "Total",
            search: "Recherche"
        },
        location: {
            disabled: "GPS non actif",
            activate: "Détecter la position GPS"
        },
        livechat: {
            wait: "Je vous mets en contact avec un opérateur, veuillez patienter...",
            message: "Vous êtes sur le point d'être mis en relation avec un opérateur.",
            request: "Voulez-vous accepter l'appel d'un opérateur?",
            question: "Vous souhaitez être mis en relation avec un opérateur?",
            cancel: "Annuler",
            close: "Fermer",
            yes: "Oui",
            no: "Non"
        },
        kiosk: {
            exit: "Sortie",
            privacy: "Politique de confidentialité",
            privacyMessage: "Lisez la politique de confidentialité en cliquant sur le lien ci-dessous",
            introMessage: {
                default: "Tenez-vous devant la caméra ou cliquez sur l'écran",
                nocam: "Cliquez sur l'écran"
            },
            activationMessage: "Dites «Ok {name}» pour interagir avec moi",
            activationMessageNoCommand: "Cliquez sur le microphone pour interagir avec moi",
            disengageMessage: "Cliquez ci-dessous si vous souhaitez reprendre la conversation",
            resumeMessage: "Reprendre",
            multimedia: {
                close: 'Fermer',
                expand: 'Angrandir'
            }
        },
        tooltip: {
            microphone: {
                disable: "Désactiver le microphone",
                enable: "Activer le microphone"
            },
            speaker: {
                stop: "Arrêter le son",
                disable: "Désactiver l'audio",
                enable: "Activer l'audio"
            },
            messages: {
                disable: "Désactiver les messages",
                enable: "Activer les messages"
            },
            keyboard: {
                enable: "Activer le clavier"
            },
            webcam: {
                disable: "Désactiver l'analyse des émotions",
                enable: "Activer l'analyse des émotions"
            },
            chat: {
                hide: "Masquer le chat",
                show: "Afficher le chat"
            },
            mode: {
                dhi: "Interface Dhi",
                txt: "Interface Texte"
            },
            send: "Envoyer",
            menu: "Menu",
            settings: "Paramètres",
            livechat: {
                close: "Fermer le chat en direct",
                open: "Ouvrir le chat en direct"
            },
            info: "Info",
            close: "Fermer",
            minimize: "Réduire",
            maximize: "Agrandir",
            language: "Langue",
            clear: "Vide"
        },
        panels: {
            settings: {
                settings: {
                    title: "RÉGLAGES",
                    sounds: "Notifications sonores",
                    rememberInput: "Se souvenir du choix de l'entrée",
                    fontLarge: "Grand texte",
                    volume: "Volume sonore"
                },
                debug: {
                    title: "DÉBOGUER",
                    webcam: "Webcam"
                },
                version: {
                    title: "VERSION",
                    version: "Version",
                    date: "Date"
                },
                developer: {
                    title: "DEVELOPPEUR",
                    stats: "Afficher les performances",
                    browser: "NAVIGATEUR",
                    device: "APPAREIL",
                    capabilities: "CAPABILITÉS"
                }
            },
            info: {
                contacts: {
                    title: "CONTACTS",
                    open: "Ouvrir"
                },
                privacy: {
                    title: "PRIVACY",
                    open: "Ouvrir"
                }
            }
        }
    },
    es: {
        bot: {
            error: "Se ha producido un error, inténtelo de nuevo más tarde",
            unauthorized: "Error: no autorizado",
            start: "Haga clic abajo para hablar conmigo",
            welcome: "Hola y bienvenido. Hazme preguntas específicas, te ayudaré a encontrar la información que buscas.",
            expired: "Asistente inhabilitado"
        },
        placeholder: {
            typeHere: "Escribe aquí"
        },
        chat: {
            file: "Archivo",
            link: "Enlace"
        },
        date: {
            today: "Hoy",
            yesterday: "Ayer"
        },
        vote: {
            title: "¿Ha sido útil la respuesta?",
            yes: "Sí",
            no: "No"
        },
        narration: {
            title: "¿Quiere que le describa el producto?",
            yes: "Sí",
            no: "No"
        },
        emotion: {
            title: "¿Quiere permitir el reconocimiento emocional?",
            message: "Para utilizar el módulo emocional es necesario que proporcione los consentimientos que se detallan a continuación.",
            messageLink: "Para obtener más información, haga clic <a href=\"#\">aquí</a>.",
            note: "Recuerda que puedes desactivar el módulo en cualquier momento haciendo clic en el icono de la webcam del menú inferior.",
            marketing: "Comercialización",
            profiling: "Perfilado",
            sensitive: "Informacion delicada",
            checkAll: "Seleccionar todo",
            close: "Cerrar",
            yes: "Sí",
            no: "No"
        },
        slideshow: {
            title: "¡Aquí está su presentación!"
        },
        form: {
            title: "¿Seguro que quieres abortar la compilación?",
            yes: "Sí",
            no: "No",
            confirm: "Confirmar",
            cancel: "Cancelar",
            skip: "Saltar"
        },
        suggestion: {
            title: "Mostrar preguntas relacionadas",
            others: "Mostrar más"
        },
        file: {
            open: "Abre",
            download: "Descargar",
            upload: "Subir",
            attachment: "Adjunto"
        },
        card: {
            detail: "Mostrar detalle",
            select: "Seleccionar",
            unselect: "Deseleccionar",
            total: "Total",
            search: "Búsqueda"
        },
        location: {
            disabled: "GPS no activo",
            activate: "Detectar posición GPS"
        },
        livechat: {
            wait: "Te estoy poniendo en contacto con un operador, por favor espera...",
            message: "Está a punto de ponerse en contacto con un operador.",
            request: "¿Quieres aceptar la llamada de un operador?",
            question: "¿Quieres ponerte en contacto con un operador?",
            cancel: "Cancelar",
            close: "Cerrar",
            yes: "Sí",
            no: "No"
        },
        kiosk: {
            exit: "Salida",
            privacy: "Política de privacidad",
            privacyMessage: "Lea la política de privacidad haciendo clic en el enlace a continuación",
            introMessage: {
                default: "Párese frente a la cámara o haga clic en la pantalla",
                nocam: "Haga clic en la pantalla"
            },
            activationMessage: "Di «Ok {name}» para interactuar conmigo",
            activationMessageNoCommand: "Haz click en el micrófono para interactuar conmigo",
            disengageMessage: "Haga clic a continuación si desea reanudar la conversación",
            resumeMessage: "Resumir",
            multimedia: {
                close: 'Cerrar',
                expand: 'Expandir'
            }
        },
        tooltip: {
            microphone: {
                disable: "Desactivar el micrófono",
                enable: "Habilitar micrófono"
            },
            speaker: {
                stop: "Detener el audio",
                disable: "Desactivar el audio",
                enable: "Activar el audio"
            },
            messages: {
                disable: "Desactivar mensajes",
                enable: "Activar mensajes"
            },
            keyboard: {
                enable: "Habilitar teclado"
            },
            webcam: {
                disable: "Desactivar análisis de emociones",
                enable: "Habilitar análisis de emociones"
            },
            chat: {
                hide: "Ocultar el chat",
                show: "Mostrar chat"
            },
            mode: {
                dhi: "Interfaz Dhi",
                txt: "Interfaz de Texto"
            },
            send: "Enviar",
            menu: "Menú",
            settings: "Ajustes",
            livechat: {
                close: "Cerrar chat en vivo",
                open: "Abrir chat en vivo"
            },
            info: "Info",
            close: "Cerrar",
            minimize: "Reducir",
            maximize: "Ampliar",
            language: "Idioma",
            clear: "Vacío"
        },
        panels: {
            settings: {
                settings: {
                    title: "AJUSTES",
                    sounds: "Sonidos de notificación",
                    rememberInput: "Recordar la elección de la entrada",
                    fontLarge: "Gran texto",
                    volume: "Volumen de audio"
                },
                debug: {
                    title: "DEPURAR",
                    webcam: "Webcam"
                },
                version: {
                    title: "VERSION",
                    version: "Versión",
                    date: "Fecha"
                },
                developer: {
                    title: "DESARROLLO",
                    stats: "Mostrar rendimiento",
                    browser: "NAVEGADOR",
                    device: "DISPOSITIVO",
                    capabilities: "CAPABILIDADES",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "CONTACTOS",
                    open: "Abierto"
                },
                privacy: {
                    title: "PRIVACIDAD",
                    open: "Abierto"
                }
            }
        }
    },
    pt: {
        bot: {
            error: "Ocorreu um erro, por favor tente novamente mais tarde",
            unauthorized: "Erro: não autorizado",
            start: "Clique abaixo para falar comigo",
            welcome: "Olá e bem-vindo. Faça-me perguntas específicas, vou ajudá-lo a encontrar a informação que procura.",
            expired: "Assistente desativado"
        },
        placeholder: {
            typeHere: "Escreva aqui"
        },
        chat: {
            file: "Ficheiro",
            link: "Ligação"
        },
        date: {
            today: "Hoje",
            yesterday: "Ontem"
        },
        vote: {
            title: "A resposta foi útil?",
            yes: "Sim",
            no: "Não"
        },
        narration: {
            title: "Deseja que eu descreva o produto?",
            yes: "Sim",
            no: "Não"
        },
        emotion: {
            title: "Quer permitir o reconhecimento emocional?",
            message: "Para usar o módulo emocional é necessário que você forneça os consentimentos listados abaixo.",
            messageLink: "Para saber mais, clique <a href=\"#\">aqui</a>.",
            note: "Lembre-se que você pode desativar o módulo a qualquer momento clicando no ícone da webcam no menu inferior.",
            marketing: "Marketing",
            profiling: "Perfil",
            sensitive: "Dados sensíveis",
            checkAll: "Selecionar tudo",
            close: "Fechar",
            yes: "Sim",
            no: "Não"
        },
        slideshow: {
            title: "Aqui está a sua apresentação!"
        },
        form: {
            title: "Tem a certeza de que quer abortar a compilação?",
            yes: "Sim",
            no: "Não",
            confirm: "Confirmar",
            cancel: "Cancelar",
            skip: "Saltar"
        },
        suggestion: {
            title: "Mostrar perguntas relacionadas",
            others: "Mostrar mais"
        },
        file: {
            open: "Abrir",
            download: "Download",
            upload: "Envio",
            attachment: "Fixação"
        },
        card: {
            detail: "mostrar detalhe",
            select: "seleccionar",
            unselect: "desmarcar",
            total: "Total",
            search: "Procurar"
        },
        location: {
            disabled: "GPS não activo",
            activate: "Detectar posição GPS"
        },
        livechat: {
            wait: "Estou colocando você em contato com um operador, por favor aguarde...",
            message: "Você está prestes a ser colocado em contato com um operador.",
            request: "Deseja aceitar a chamada de um operador?",
            question: "Você quer ser colocado em contato com um operador?",
            cancel: "Cancelar",
            close: "Fechar",
            yes: "Sim",
            no: "Não"
        },
        kiosk: {
            exit: "Saída",
            privacy: "Política de Privacidade",
            privacyMessage: "Leia a política de privacidade clicando no link abaixo",
            introMessage: {
                default: "Fique na frente da câmera ou clique na tela",
                nocam: "Clique na tela"
            },
            activationMessage: "Diga «Ok {name}» para interagir comigo",
            activationMessageNoCommand: "Clique no microfone para interagir comigoo",
            disengageMessage: "Clique abaixo se quiser retomar a conversa",
            resumeMessage: "Retomar",
            multimedia: {
                close: 'Fechar',
                expand: 'Expandir'
            }
        },
        tooltip: {
            microphone: {
                disable: "Desactivar microfone",
                enable: "Habilitar microfone"
            },
            speaker: {
                stop: "Parar áudio",
                disable: "Desactivar áudio",
                enable: "Habilitar áudio"
            },
            messages: {
                disable: "Desactivar mensagens",
                enable: "Habilitar mensagens"
            },
            keyboard: {
                enable: "Activar teclado"
            },
            webcam: {
                disable: "Desativar análise de emoções",
                enable: "Ativar análise de emoções"
            },
            chat: {
                hide: "Ocultar bate-papo",
                show: "Mostrar bate-papo"
            },
            mode: {
                dhi: "Interface Dhi",
                txt: "Interface de Texto"
            },
            send: "Enviar",
            menu: "Cardápio",
            settings: "Definições",
            livechat: {
                close: "Cerrar livechat",
                open: "Abrir livechat"
            },
            info: "Info",
            close: "Fechar",
            minimize: "Reduzir",
            maximize: "Ampliar",
            language: "Língua",
            clear: "Vazio"
        },
        panels: {
            settings: {
                settings: {
                    title: "CONFIGURAÇÕES",
                    sounds: "Sons de notificação",
                    rememberInput: "Lembre-se da escolha de entrada",
                    fontLarge: "Ótimo texto",
                    volume: "Volume de áudio"
                },
                debug: {
                    title: "DEPURAR",
                    webcam: "Webcam"
                },
                version: {
                    title: "VERSÃO",
                    version: "Versão",
                    date: "Data"
                },
                developer: {
                    title: "DESENVOLVEDOR",
                    stats: "Mostrar desempenho",
                    browser: "NAVEGADOR",
                    device: "DISPOSITIVO",
                    capabilities: "CAPACIDADES",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "CONTACTOS",
                    open: "Aberto"
                },
                privacy: {
                    title: "PRIVACIDADE",
                    open: "Aberto"
                }
            }
        }
    },
    de: {
        bot: {
            error: "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal",
            unauthorized: "Fehler: unautorisiert",
            start: "Klicken Sie unten, um mit mir zu sprechen",
            welcome: "Hallo und Willkommen. Stellen Sie mir spezifische Fragen, ich helfe Ihnen, die Informationen zu finden, die Sie suchen.",
            expired: "Assistent deaktiviert"
        },
        placeholder: {
            typeHere: "Schreiben Sie hier"
        },
        chat: {
            file: "Datei",
            link: "Link"
        },
        date: {
            today: "Heute",
            yesterday: "Gestern"
        },
        vote: {
            title: "War die Antwort hilfreich?",
            yes: "Ja",
            no: "Nein"
        },
        narration: {
            title: "Möchten Sie, dass ich das Produkt beschreibe?",
            yes: "Ja",
            no: "Nein"
        },
        emotion: {
            title: "Wollen Sie emotionale Anerkennung ermöglichen?",
            message: "Für die Nutzung des emotionalen Moduls ist es erforderlich, dass Sie die unten aufgeführten Einwilligungen erteilen.",
            messageLink: "Klicken Sie <a href=\"#\">hier</a>, um mehr zu erfahren.",
            note: "Denken Sie daran, dass Sie das Modul jederzeit deaktivieren können, indem Sie im unteren Menü auf das Webcam-Symbol klicken.",
            marketing: "Marketing",
            profiling: "Profilierung",
            sensitive: "Sensible Daten",
            checkAll: "Wählen Sie Alle",
            close: "Nah dran",
            yes: "Ja",
            no: "Nein"
        },
        slideshow: {
            title: "Hier ist Ihre Präsentation!"
        },
        form: {
            title: "Sind Sie sicher, dass Sie die Kompilierung abbrechen wollen?",
            yes: "Ja",
            no: "Nein",
            confirm: "Bestätigen",
            cancel: "Abbrechen",
            skip: "Überspringen"
        },
        suggestion: {
            title: "Verwandte Fragen anzeigen",
            others: "Mehr anzeigen"
        },
        file: {
            open: "Offen",
            download: "Herunterladen",
            upload: "Hochladen",
            attachment: "Anhang"
        },
        card: {
            detail: "Detail anzeigen",
            select: "Auswählen",
            unselect: "Abwählen",
            total: "Gesamt",
            search: "Suche"
        },
        location: {
            disabled: "GPS nicht aktiv",
            activate: "GPS-Position erkennen"
        },
        livechat: {
            wait: "Ich verbinde Sie mit einem Operator, bitte warten...",
            message: "Sie werden gleich mit einem Operator in Kontakt gebracht.",
            request: "Möchten Sie den Anruf einer Vermittlung annehmen?",
            question: "Möchten Sie mit einem Operator in Kontakt gebracht werden?",
            cancel: "Abbrechen",
            close: "Schließen",
            yes: "Ja",
            no: "Nein"
        },
        kiosk: {
            exit: "Ausgang",
            privacy: "Datenschutz-Bestimmungen",
            privacyMessage: "Lesen Sie die Datenschutzerklärung, indem Sie auf den untenstehenden Link klicken",
            introMessage: {
                default: "Stellen Sie sich vor die Kamera oder klicken Sie auf den Bildschirm",
                nocam: "Klicken Sie auf den Bildschirm"
            },
            activationMessage: "Sagen Sie «Ok {name}» um mit mir zu interagieren",
            activationMessageNoCommand: "Klicken Sie auf das Mikrofon, um mit mir zu interagieren",
            disengageMessage: "Klicken Sie unten, wenn Sie das Gespräch fortsetzen möchten",
            resumeMessage: "Fortsetzen",
            multimedia: {
                close: 'Schließen',
                expand: 'Erweitern'
            }
        },
        tooltip: {
            microphone: {
                disable: "Mikrofon deaktivieren",
                enable: "Mikrofon einschalten"
            },
            speaker: {
                stop: "Audio anhalten",
                disable: "Audio deaktivieren",
                enable: "Audio einschalten"
            },
            messages: {
                disable: "Nachrichten deaktivieren",
                enable: "Nachrichten einschalten"
            },
            keyboard: {
                enable: "Tastatur einschalten"
            },
            webcam: {
                disable: "Emotionsanalyse deaktivieren",
                enable: "Emotionsanalyse aktivieren"
            },
            chat: {
                hide: "Chat ausblenden",
                show: "Chat anzeigen"
            },
            mode: {
                dhi: "Dhi-Schnittstelle",
                txt: "Text-Schnittstelle"
            },
            send: "Senden",
            menu: "Menü",
            settings: "Einstellungen",
            livechat: {
                close: "Live-Chat schließen",
                open: "Live-Chat öffnen"
            },
            info: "Info",
            close: "Schließen",
            minimize: "Reduzieren",
            maximize: "Vergrößern",
            language: "Sprache",
            clear: "Leer"
        },
        panels: {
            settings: {
                settings: {
                    title: "EINSTELLUNGEN",
                    sounds: "Benachrichtigungstöne",
                    rememberInput: "Eingabeauswahl merken",
                    fontLarge: "Tolle Texte",
                    volume: "Lautstärke"
                },
                debug: {
                    title: "DEBUGGEN",
                    webcam: "Webcam"
                },
                version: {
                    title: "VERSION",
                    version: "Version",
                    date: "Datum"
                },
                developer: {
                    title: "ENTWICKLER",
                    stats: "Leistung anzeigen",
                    browser: "BROWSER",
                    device: "GERÄT",
                    capabilities: "FÄHIGKEITEN",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "KONTAKTE",
                    open: "Offen"
                },
                privacy: {
                    title: "PRIVATSPHÄRE",
                    open: "Offen"
                }
            }
        }
    },
    ro: {
        bot: {
            error: "A apărut o eroare, vă rugăm să încercați din nou mai târziu",
            unauthorized: "Eroare: neautorizat",
            start: "Faceți clic mai jos pentru a vorbi cu mine",
            welcome: "Bună și bunvenit. Pune-mi întrebări specifice, te voi ajuta să găsești informațiile pe care le cauți.",
            expired: "Asistentul este dezactivat"
        },
        placeholder: {
            typeHere: "Scrie aici"
        },
        chat: {
            file: "Fișier",
            link: "Link"
        },
        date: {
            today: "Astăzi",
            yesterday: "Ieri"
        },
        vote: {
            title: "Răspunsul a fost util?",
            yes: "Da",
            no: "Nu"
        },
        narration: {
            title: "Doriți să vă descriu produsul?",
            yes: "Da",
            no: "Nu"
        },
        emotion: {
            title: "Doriți să permiteți recunoașterea emoțională?",
            message: "Pentru a utiliza modulul emoțional este necesar să oferiți consimțământurile enumerate mai jos.",
            messageLink: "Pentru a afla mai multe, faceți clic <a href=\"#\">aici</a>.",
            note: "Amintiți-vă că puteți dezactiva modulul în orice moment făcând clic pe pictograma webcam din meniul de jos.",
            marketing: "Marketing",
            profiling: "Profilare",
            sensitive: "Date sensibile",
            checkAll: "Selectează tot",
            close: "Închide",
            yes: "Da",
            no: "Nu"
        },
        slideshow: {
            title: "Iată prezentarea ta!"
        },
        form: {
            title: "Sigur doriți să nu mai completați?",
            yes: "Da",
            no: "Nu",
            confirm: "Confirma",
            cancel: "Anulare",
            skip: "Ocolire"
        },
        suggestion: {
            title: "Afișați întrebările conexe",
            others: "Arată mai mult"
        },
        file: {
            open: "Deschis",
            download: "Descarca",
            upload: "Încărcați",
            attachment: "Atașament"
        },
        card: {
            detail: "Afișează detalii",
            select: "Selectați",
            unselect: "Deselectați",
            total: "Total",
            search: "Căutare"
        },
        location: {
            disabled: "GPS nu este activ",
            activate: "Detectează poziția GPS"
        },
        livechat: {
            wait: "Vă pun în contact cu un operator, vă rugăm să așteptați...",
            message: "Ești pe cale să fii pus în contact cu un operator.",
            request: "Doriți să acceptați apelul de la un operator?",
            question: "Vrei să fii pus în contact cu un operator?",
            cancel: "Anulare",
            close: "Închide",
            yes: "Da",
            no: "Nu"
        },
        kiosk: {
            exit: "Ieșire",
            privacy: "Politica de Confidențialitate",
            privacyMessage: "Citiți politica de confidențialitate făcând clic pe linkul de mai jos",
            introMessage: {
                default: "Stați în fața camerei sau faceți clic pe ecran",
                nocam: "Clic pe ecran"
            },
            activationMessage: "Spuneți «Ok {name}» pentru a interacționa cu mine",
            activationMessageNoCommand: "Faceți clic pe microfon pentru a interacționa cu mine",
            disengageMessage: "Faceți clic mai jos dacă doriți să reluați conversația",
            resumeMessage: "Relua",
            multimedia: {
                close: 'Închide',
                expand: 'Extinde'
            }
        },
        tooltip: {
            microphone: {
                disable: "Dezactivează microfonul",
                enable: "Activează microfonul"
            },
            speaker: {
                stop: "Opriți sunetul",
                disable: "Dezactivează sunetul",
                enable: "Activați audio"
            },
            messages: {
                disable: "Dezactivează mesajele",
                enable: "Activați mesajele"
            },
            keyboard: {
                enable: "Activează tastatura"
            },
            webcam: {
                disable: "Dezactivați analiza emoțională",
                enable: "Activați analiza emoțională"
            },
            chat: {
                hide: "Ascunde chat",
                show: "Afișează chat"
            },
            mode: {
                dhi: "Interfață Dhi",
                txt: "Interfață Text"
            },
            send: "Trimite",
            menu: "Meniu",
            settings: "Setări",
            livechat: {
                close: "Închide chatul live",
                open: "Deschide chatul live"
            },
            info: "Info",
            close: "Închide",
            minimize: "Minimizați",
            maximize: "Mărește",
            language: "Limba",
            clear: "Gol"
        },
        panels: {
            settings: {
                settings: {
                    title: "SETĂRI",
                    sounds: "Sunete de notificare",
                    rememberInput: "Amintiți-vă alegerea de intrare",
                    fontLarge: "Excelent text",
                    volume: "Volumul audio"
                },
                debug: {
                    title: "DEBUG",
                    webcam: "Camera web"
                },
                version: {
                    title: "VERSIUNE",
                    version: "Versiune",
                    date: "Data"
                },
                developer: {
                    title: "DEZVOLTATOR",
                    stats: "Afișează performanța",
                    browser: "BROWSER",
                    device: "DISPOZITIV",
                    capabilities: "CAPABILITĂȚI",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "CONTACTE",
                    open: "Deschis"
                },
                privacy: {
                    title: "CONFIDENTIALITATE",
                    open: "Deschis"
                }
            }
        }
    },
    ru: {
        bot: {
            error: "Произошла ошибка, повторите попытку позже",
            unauthorized: "Ошибка: несанкционированный",
            start: "Нажмите ниже, чтобы поговорить со мной",
            welcome: "Привет и добро пожаловать. Задавайте мне конкретные вопросы, я помогу вам найти информацию, которую вы ищете.",
            expired: "Ассистент отключен"
        },
        placeholder: {
            typeHere: "Напишите здесь"
        },
        chat: {
            file: "Файл",
            link: "Ссылка на сайт"
        },
        date: {
            today: "Сегодня",
            yesterday: "Вчера"
        },
        vote: {
            title: "Был ли ответ полезен?",
            yes: "Да",
            no: "Нет"
        },
        narration: {
            title: "Хотите, чтобы я описал продукт?",
            yes: "Да",
            no: "Нет"
        },
        emotion: {
            title: "Хотите ли вы сделать возможным эмоциональное распознавание?",
            message: "Для использования эмоционального модуля необходимо предоставить перечисленные ниже согласия.",
            messageLink: "Чтобы узнать больше, нажмите <a href=\"#\">здесь</a>.",
            note: "Помните, что вы можете деактивировать модуль в любой момент, нажав на иконку веб-камеры в нижнем меню.",
            marketing: "Маркетинг",
            profiling: "Профилирование",
            sensitive: "Конфиденциальные данные",
            checkAll: "Выбрать все",
            close: "Закрывать",
            yes: "Да",
            no: "Нет"
        },
        slideshow: {
            title: "Вот ваша презентация!"
        },
        form: {
            title: "Вы уверены, что хотите прервать компиляцию?",
            yes: "Да",
            no: "Нет",
            confirm: "Подтвердить",
            cancel: "Отмена",
            skip: "Пропускать"
        },
        suggestion: {
            title: "Показать связанные вопросы",
            others: "Показать еще"
        },
        file: {
            open: "Открытым",
            download: "Загрузка",
            upload: "Загрузить",
            attachment: "Вложение"
        },
        card: {
            detail: "Показать детали",
            select: "Выбирать",
            unselect: "Отменить выбор",
            total: "Всего",
            search: "Поиск"
        },
        location: {
            disabled: "GPS не активен",
            activate: "Определить положение GPS"
        },
        livechat: {
            wait: "Я связываю вас с оператором, пожалуйста, подождите...",
            message: "Вас скоро свяжут с оператором.",
            request: "Хотите принять звонок от оператора?",
            question: "Хотите связаться с оператором?",
            cancel: "Отмена",
            close: "Закрывать",
            yes: "Да",
            no: "Нет"
        },
        kiosk: {
            exit: "Выход",
            privacy: "Политика конфиденциальности",
            privacyMessage: "Ознакомьтесь с политикой конфиденциальности, перейдя по ссылке ниже",
            introMessage: {
                default: "Встаньте перед камерой или нажмите на экран",
                nocam: "Нажмите на экран"
            },
            activationMessage: "Скажите «Ok {name}», чтобы общаться со мной",
            activationMessageNoCommand: "Нажмите на микрофон, чтобы пообщаться со мной",
            disengageMessage: "Нажмите ниже, если хотите возобновить разговор",
            resumeMessage: "Продолжить",
            multimedia: {
                close: 'Закрывать',
                expand: 'Расширять'
            }
        },
        tooltip: {
            microphone: {
                disable: "Отключить микрофон",
                enable: "Включить микрофон"
            },
            speaker: {
                stop: "Остановить звук",
                disable: "Отключить звук",
                enable: "Включить звук"
            },
            messages: {
                disable: "Отключить сообщения",
                enable: "Включить сообщения"
            },
            keyboard: {
                enable: "Включить клавиатуру"
            },
            webcam: {
                disable: "Отключить эмоциональный анализ",
                enable: "Активировать эмоциональный анализ"
            },
            chat: {
                hide: "Скрыть чат",
                show: "Показать чат"
            },
            mode: {
                dhi: "интерфейс Dhi",
                txt: "Текстовый интерфейс"
            },
            send: "Отправлять",
            menu: "Меню",
            settings: "Настройки",
            livechat: {
                close: "Закрыть чат",
                open: "Открыть чат"
            },
            info: "Информация",
            close: "Закрывать",
            minimize: "Минимизировать",
            maximize: "Увеличить",
            language: "Язык",
            clear: "Пустой"
        },
        panels: {
            settings: {
                settings: {
                    title: "НАСТРОЙКИ",
                    sounds: "Звуки уведомлений",
                    rememberInput: "Запомнить выбор ввода",
                    fontLarge: "Отличный текст",
                    volume: "Громкость звука"
                },
                debug: {
                    title: "ОТЛАЖИВАТЬ",
                    webcam: "ВЭБ-камера"
                },
                version: {
                    title: "ВЕРСИЯ",
                    version: "Версия",
                    date: "Дата"
                },
                developer: {
                    title: "РАЗРАБОТЧИК",
                    stats: "Показать производительность",
                    browser: "Браузер",
                    device: "Устройство",
                    capabilities: "Устройство",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "Устройство",
                    open: "Открытым"
                },
                privacy: {
                    title: "КОНФИДЕНЦИАЛЬНОСТЬ",
                    open: "Открытым"
                }
            }
        }
    },
    cn: {
        bot: {
            error: "发生了一个错误。请稍后重试",
            unauthorized: "错误：未授权",
            start: "点击下面与我交谈",
            welcome: "你好，欢迎光临。 问我具体的问题，我会帮你找到你正在寻找的信息。",
            expired: "助理已停用"
        },
        placeholder: {
            typeHere: "写在这里"
        },
        chat: {
            file: "文件",
            link: "链接"
        },
        date: {
            today: "今天",
            yesterday: "昨天"
        },
        vote: {
            title: "答案有帮助吗？",
            yes: "是",
            no: "不"
        },
        narration: {
            title: "你想让我描述一下产品吗？",
            yes: "是",
            no: "不"
        },
        emotion: {
            title: "你想启用情感识别吗？",
            message: "要使用情感模块，您必须提供下面列出的同意。 要了解更多信息。",
            messageLink: "请单击<a href=\"#\">此处</a>。",
            note: "请记住，您可以随时通过单击底部菜单中的网络摄像头图标来停用该模块。",
            marketing: "营销",
            profiling: "分析",
            sensitive: "敏感数据",
            checkAll: "全选",
            close: "关闭",
            yes: "是",
            no: "不"
        },
        slideshow: {
            title: "这是你的介绍！"
        },
        form: {
            title: "你确定要中止编译吗？",
            yes: "是",
            no: "不",
            confirm: "确认",
            cancel: "取消",
            skip: "跳过"
        },
        suggestion: {
            title: "显示相关问题",
            others: "显示更多"
        },
        file: {
            open: "打开",
            download: "下载",
            upload: "上传",
            attachment: "附件"
        },
        card: {
            detail: "显示细节",
            select: "选择",
            unselect: "取消选择",
            total: "总数",
            search: "搜索"
        },
        location: {
            disabled: "GPS 未激活",
            activate: "检测GPS位置"
        },
        livechat: {
            wait: "我正在让您联系接线员，请稍候...",
            message: "您即将与接线员联系",
            request: "您想接听接线员的电话吗？",
            question: "您想与运营商联系吗？",
            cancel: "取消",
            close: "关闭",
            yes: "是",
            no: "不"
        },
        kiosk: {
            exit: "出口",
            privacy: "隐私政策",
            privacyMessage: "单击下面的链接阅读隐私政策",
            introMessage: {
                default: "站在凸轮前面或在屏幕上单击",
                nocam: "点击屏幕"
            },
            activationMessage: "说 «Ok {name}» 与我互动",
            activationMessageNoCommand: "点击麦克风与我互动",
            disengageMessage: "如果要恢复对话，请单击下面的",
            resumeMessage: "恢复",
            multimedia: {
                close: '关',
                expand: '扩张'
            }
        },
        tooltip: {
            microphone: {
                disable: "禁用麦克风禁用麦克风",
                enable: "启用麦克风"
            },
            speaker: {
                stop: "停止音频",
                disable: "禁用音频",
                enable: "启用音频"
            },
            messages: {
                disable: "禁用消息",
                enable: "启用消息"
            },
            keyboard: {
                enable: "启用键盘"
            },
            webcam: {
                disable: "禁用情绪分析",
                enable: "激活情绪分析"
            },
            chat: {
                hide: "隐藏聊天",
                show: "显示聊天"
            },
            mode: {
                dhi: "Dhi接口",
                txt: "文本界面"
            },
            send: "发送",
            menu: "菜单",
            settings: "设置",
            livechat: {
                close: "关闭实时聊天",
                open: "打开实时聊天"
            },
            info: "信息",
            close: "关闭",
            minimize: "最小化",
            maximize: "放大",
            language: "语言",
            clear: "空的"
        },
        panels: {
            settings: {
                settings: {
                    title: "设置",
                    sounds: "通知的声音",
                    rememberInput: "记住输入选择",
                    fontLarge: "很棒的文字",
                    volume: "音频音量"
                },
                debug: {
                    title: "调试",
                    webcam: "摄像头"
                },
                version: {
                    title: "版本",
                    version: "版本",
                    date: "日期"
                },
                developer: {
                    title: "发展者",
                    stats: "显示性能",
                    browser: "浏览器",
                    device: "设备",
                    capabilities: "能力",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "联系人",
                    open: "打开"
                },
                privacy: {
                    title: "隐私",
                    open: "打开"
                }
            }
        }
    },
    la: {
        bot: {
            error: "Quasi per errorem inciderunt. Conatus sodes iterum postmodum",
            unauthorized: "Error: non auctoritate",
            start: "Press infra loqui ad me",
            welcome: "Salve et salve. Interroga me specificas interrogationes, auxiliabor ut invenias indicium quod quaeris.",
            expired: "Suffragium debilitatum"
        },
        placeholder: {
            typeHere: "Scribe hic"
        },
        chat: {
            file: "Documentum",
            link: "Nexus"
        },
        date: {
            today: "Hodie",
            yesterday: "Heri"
        },
        vote: {
            title: "Responsum est tibi utile?",
            yes: "Sic",
            no: "Non"
        },
        narration: {
            title: "Visne me describere factum tibi?",
            yes: "Sic",
            no: "Non"
        },
        emotion: {
            title: "Visne recognitionem motus efficere?",
            message: "Ad moduli cinematographici uti, necesse est ut consensus infra scriptos praebeas.",
            messageLink: "Si vis plura invenire, preme <a href=\"#\">hic</a>.",
            note: "Memento te modulum quempiam debilitare te posse strepitando in icone webcam ab imo menu.",
            marketing: "Marketing",
            profiling: "Profiling",
            sensitive: "Sensitiva notitia",
            checkAll: "Desumo omnes",
            close: "Claudere",
            yes: "Sic",
            no: "Non"
        },
        slideshow: {
            title: "Haec est propositio tua!"
        },
        form: {
            title: "Certusne esne vis scribendis desinere?",
            yes: "Sic",
            no: "Non",
            confirm: "Confirmatio",
            cancel: "Relinquere",
            skip: "Deinde"
        },
        suggestion: {
            others: "Plus ostendere"
        },
        file: {
            open: "Apertum",
            download: "Hoc portat",
            upload: "Mitte",
            attachment: "Affectio"
        },
        card: {
            detail: "Monstra singula",
            select: "eligere",
            unselect: "non eligere",
            total: "Totalis",
            search: "Quaerere"
        },
        location: {
            disabled: "Locum non inveni",
            activate: "Quaerere locum"
        },
        livechat: {
            wait: "Contactum cum operante constituo te, sis exspecta...",
            message: "Contactus es cum operante.",
            request: "Visne vocationem accipere ab operante?",
            question: "Visne communicari cum operante?",
            cancel: "Relinquere",
            close: "Claudere",
            yes: "Sic",
            no: "Non"
        },
        kiosk: {
            exit: "Exitus",
            privacy: "Secretum praecepta",
            privacyMessage: "Vide secretum consilium strepitando in nexum infra",
            introMessage: {
                default: "Sta in fronte basim aut press digito tuo",
                nocam: "Press digito tuo"
            },
            activationMessage: "Dic «Ok {name}» ut correspondeat me",
            activationMessageNoCommand: "Torcular ad tortor ligula, facilisis ut penitus mecum",
            disengageMessage: "Preme si vis repetere conversationem",
            resumeMessage: "Proin",
            multimedia: {
                close: 'Claudere',
                expand: 'Dilata'
            }
        },
        tooltip: {
            microphone: {
                disable: "Tortor ligula muta",
                enable: "Admitte tortor ligula"
            },
            speaker: {
                stop: "Subsisto sonus",
                disable: "Averte sonus",
                enable: "Admitte sonus"
            },
            messages: {
                disable: "Averte mandata",
                enable: "Admitte mandata"
            },
            keyboard: {
                enable: "Admitte scripto"
            },
            webcam: {
                disable: "Celare motus",
                enable: "Videre motus"
            },
            chat: {
                hide: "Celare scripta",
                show: "Ostende scripta"
            },
            mode: {
                dhi: "Modus humanus",
                txt: "Modus scriptum"
            },
            send: "Mitte",
            menu: "Menu",
            settings: "Occasus",
            livechat: {
                close: "Nolite conversationem",
                open: "Satus conversationemt"
            },
            info: "Informationes",
            close: "Claudere",
            minimize: "Reducere",
            maximize: "Dilata",
            language: "Lingua",
            clear: "Inanis"
        },
        panels: {
            settings: {
                settings: {
                    title: "OCCASUS",
                    sounds: "Sonus notificationes",
                    rememberInput: "Memento scribo electionis",
                    fontLarge: "Magna text",
                    volume: "Sonus volubilis"
                },
                debug: {
                    title: "DEBUG",
                    webcam: "Webcam"
                },
                version: {
                    title: "VERSIO",
                    version: "Versio",
                    date: "Dies"
                },
                developer: {
                    title: "SVILUPPATORE",
                    stats: "Ostende perficientur",
                    browser: "BROWSER",
                    device: "DEVICE",
                    capabilities: "CAPABILITIES",
                    avatar: "AVATAR"
                }
            },
            info: {
                contacts: {
                    title: "CONTACTUS",
                    open: "Aperis"
                },
                privacy: {
                    title: "DECLARATIO",
                    open: "Aperis"
                }
            }
        }
    },
};

export default {

    install(Vue) {

        const flatten = (obj, prefix = '') =>
            Object.keys(obj).reduce((acc, k) => {
                const pre = prefix.length ? prefix + '.' : '';
                if (typeof obj[k] === 'object') Object.assign(acc, flatten(obj[k], pre + k));
                else acc[pre + k] = obj[k];
                return acc;
            }, {});

        const cache = flatten(messages);

        function translate(key, locale) {
            let ret = cache[locale + '.' + key];
            if (ret === undefined)
                ret = cache['en.' + key];

            return ret || key;
        }

        Vue.prototype.$locale = (self) => {
            return self.$root.$children[0].locale;
        }

        Vue.$translate = Vue.prototype.$translate = (locale, key) => {
            return translate(key, locale);
        }
    }
}
