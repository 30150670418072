<template>
    <div v-if="message.enabled" class="message-form">

        <div v-if="!features.kiosk" class="form-field">
            <input ref="input" :type="inputType" v-model="value" :placeholder="placeholder" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" @keyup="onKeyboardSend">
        </div>

        <div v-if="features.kiosk" class="keyboard-message-manager">
            <KioskKeyboard :features="features" @onChange="onKeyboardChange" @onKeyPress="onKeyboardKeyPress" :input="value" :password="message.fieldType === 'PasswordFieldType'"/>
        </div>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import KioskKeyboard from "../../KioskKeyboard";

export default {
    name: "FieldText",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        KioskKeyboard
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            value: ''
        };
    },
    computed: {
        disabled() {
            return (this.value === '');
        },
        inputType() {
            let type;
            switch (this.message.fieldType) {
                case 'PasswordFieldType':
                    type = 'password';
                    break;
                case 'TelFieldType':
                case 'AreaCodeFieldType':
                    type = 'tel';
                    break;
                case 'EmailFieldType':
                    type = 'email';
                    break;
                case 'NumFieldType':
                    type = 'number';
                    break;
                case 'UrlFieldType':
                    type = 'url';
                    break;
                case 'TextFieldType':
                case 'FullNameFieldType':
                case 'NameFieldType':
                case 'SurnameFieldType':
                case 'BusinessFieldType':
                case 'OrderFieldType':
                case 'MeasureFieldType':
                case 'CurrencyFieldType':
                case 'PostalCodeFieldType':
                case 'QuantityFieldType':
                case 'CFFieldType':
                case 'VatFieldType':
                    type = 'text';
                    break;
                default:
                    type = 'text';
                    break;
            }
            return type;
        },
        placeholder() {
            return '';
        }

    },
    mounted() {
        this.$nextTick(() => {
            if (this.$refs.input !== undefined)
                this.$refs.input.focus();
        });
    },
    methods: {
        onInputClicked() {
            const value = {
                inputMode: InputMode.KEYBOARD,
                optionValue: this.value,
                optionText: (this.message.fieldType === 'PasswordFieldType') ? '*'.repeat(this.value.length) : this.value,
            };
            this.$emit("onSendCommand", value);
        },
        onSendCommand(value) {
            this.$emit("onSendCommand", value);
        },
        onKeyboardSend(event) {
            //fix iOS
            //https://github.com/vuejs/vuejs.org/issues/394
            if (event.key === 'Enter') {
                if (this.value.length > 0) {
                    this.onInputClicked();
                }
            }
        },
        onInputChange(input) {
            this.value = input.target.value;
        },
        onKeyboardChange(input) {
            this.value = input;
        },
        onKeyboardKeyPress(button) {
            this.$log.debug("button", button);

            if (button === "{ent}") {
                if (this.value.length > 0) {
                    this.onInputClicked();
                }
            }

        },
    }
}
</script>

<style lang="less">

.message-form {
    .form-field {
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        width: 100%;

        & > input:not([type=file]) {
            & {
                color: var(--primary-color-text);
                width: 100%;
                height: 24px;
                padding: 5px 10px;
                border-radius: var(--border-radius);
                border: 1px solid var(--border-other-color);
                background-color: var(--bg-color-card);
                font-size: var(--font-12);
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);
            }

            &:focus {
                outline: 0;
            }

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type=number] {
                -moz-appearance: textfield;
            }
        }

    }
}
</style>
