<template>
    <div v-if="show" class="message-multimedia margin-common-content-multimedia">

        <Modal class="modal-slideshow" :fullscreen="!features.kiosk && fullscreen" @close="fullscreen = false">
            <vueper-slides v-if="!features.kiosk" slot="body" class="slideshow slideshow-radius no-shadow"
                           :fixed-height="true"
                           :bullets-outside="true"
                           slide-content-outside="bottom"
                           @ready="onChangeSlide($event)"
                           @slide="onChangeSlide($event)">

                <vueper-slide v-for="(item, index) in parsedSlides"
                              :key="index"
                              :title="index.toString()"
                              :image="item.image"
                              :video="item.video"
                              :content="item.content">

                    <template v-slot:loader>
                        <i class="icon icon-loader spinning" aria-hidden="true"></i>
                        <span>Loading...</span>
                    </template>

                    <template v-slot:content>
                        <div class="vueperslide__content-wrapper" style="flex-direction: row">
                            <div class="slideshow-content" v-html="item.content"></div>
                        </div>
                    </template>

                </vueper-slide>

                <template v-slot:bullets="{ bulletIndexes, goToSlide, currentSlide }">
                      <span
                          v-for="(slideIndex, i) in bulletIndexes" :key="i"
                          class="slideshow-bullet"
                          :class="{ 'active': currentSlide === slideIndex }"
                          @click="goToSlide(slideIndex)">
                          {{slideIndex + 1}}
                      </span>
                </template>

            </vueper-slides>

            <KioskMedia v-if="features.kiosk && !fullscreen" slot="body" @onToggle="toggle" @onClose="close">
                <vueper-slides slot="body" class="slideshow"
                               :fixed-height="true"
                               :bullets-outside="true"
                               slide-content-outside="bottom"
                               @ready="onChangeSlide($event)"
                               @slide="onChangeSlide($event)">

                    <vueper-slide v-for="(item, index) in parsedSlides"
                                  :key="index"
                                  :title="index.toString()"
                                  :image="item.image"
                                  :video="item.video"
                                  :content="item.content">

                        <template v-slot:loader>
                            <i class="icon icon-loader spinning" aria-hidden="true"></i>
                            <span>Loading...</span>
                        </template>

                        <template v-slot:content>
                            <div class="vueperslide__content-wrapper" style="flex-direction: row">
                                <div class="slideshow-content" v-html="item.content"></div>
                            </div>
                        </template>

                    </vueper-slide>

                    <template v-slot:bullets="{ bulletIndexes, goToSlide, currentSlide }">
                      <span
                          v-for="(slideIndex, i) in bulletIndexes" :key="i"
                          class="slideshow-bullet"
                          :class="{ 'active': currentSlide === slideIndex }"
                          @click="goToSlide(slideIndex)">
                          {{slideIndex + 1}}
                      </span>
                    </template>

                </vueper-slides>
            </KioskMedia>

        </Modal>

        <div v-if="!features.kiosk" class="media-action">
            <ButtonIcon name="fullscreen" size="16px" class="action-small" @click="toggle"/>
        </div>

        <Panel :features="features" :border="false" v-if="features.kiosk && fullscreen" @onClose="fullscreen = false">
            <div slot="body" class="modal-body not-scrollable">
                <vueper-slides slot="body" class="slideshow no-shadow"
                               :fixed-height="true"
                               :bullets-outside="true"
                               slide-content-outside="bottom"
                               @ready="onChangeSlide($event)"
                               @slide="onChangeSlide($event)">

                    <vueper-slide v-for="(item, index) in parsedSlides"
                                  :key="index"
                                  :title="index.toString()"
                                  :image="item.image"
                                  :video="item.video"
                                  :content="item.content">

                        <template v-slot:loader>
                            <i class="icon icon-loader spinning" aria-hidden="true"></i>
                            <span>Loading...</span>
                        </template>

                        <template v-slot:content>
                            <div class="vueperslide__content-wrapper" style="flex-direction: row">
                                <div class="slideshow-content" v-html="item.content"></div>
                            </div>
                        </template>

                    </vueper-slide>

                    <template v-slot:bullets="{ bulletIndexes, goToSlide, currentSlide }">
                      <span
                          v-for="(slideIndex, i) in bulletIndexes" :key="i"
                          class="slideshow-bullet"
                          :class="{ 'active': currentSlide === slideIndex }"
                          @click="goToSlide(slideIndex)">
                          {{slideIndex + 1}}
                      </span>
                    </template>

                </vueper-slides>
            </div>
            <div slot="powered"></div>
        </Panel>

    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from '../../vueperslides';
import ButtonIcon from '../ButtonIcon';
import VideoLink from "../../../classes/VideoLink";
import Modal from '../../Modal';
import Panel from "../../panels/Panel";
import KioskMedia from "./KioskMedia";

export default {
    name: "MediaSlideshow",
    components: {
        VueperSlides,
        VueperSlide,
        ButtonIcon,
        Modal,
        Panel,
        KioskMedia
    },
    data: () => ({
        show: true,
        fullscreen: false,
        readed: []
    }),
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    computed: {
        parsedSlides: function () {
            const results = [];

            if (this.message.slides === undefined)
                return results;

            this.message.slides.forEach((item) => {
                const newItem = {
                    content: item.answerText,
                    image: null,
                    video: null,
                };

                if (item.mediaType === 'img') {
                    newItem.image = item.media;
                } else if (item.mediaType === 'video') {

                    const videoLink = VideoLink(item.media, false, false, true, false);
                    newItem.video = {
                        url: videoLink,
                        props: {
                            allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                            sandbox: 'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation',
                            title: 'Video'
                        }
                    }

                } else {
                    newItem.image = item.media;
                    newItem.video = item.media;
                }

                results.push(newItem);
            });
            return results;
        }
    },
    watch: {
        fullscreen: {
            handler(newVal) {
                if (!newVal)
                    this.stopReadMessage();
                else
                    this.readed = [];
            },
            deep: true
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        readMessage(content) {
            this.$emit('onPlayMessage', {
                content: content,
                speakOnly: true
            });
        },
        stopReadMessage() {
            this.$emit('onPlayMessage', {
                content: null,
                speakOnly: true
            });
        },
        onChangeSlide(params) {
            if (!this.message.history && this.fullscreen) {
                const slide = params.currentSlide;
                if (!this.readed.includes(slide.index)) {
                    this.readed.push(slide.index);
                    this.readMessage(slide.content);
                } else {
                    this.stopReadMessage();
                }
            }
        },
        toggle() {
            this.fullscreen = !this.fullscreen
        },
        close: function () {
            this.show = false;
        },
    }
}
</script>

<style lang="less">

.message-multimedia {
    .slideshow {

        overflow: hidden;

        &.vueperslides--fixed-height {
            margin-bottom: 0 !important;
            height: 100%; //250px;

            iframe {
                height: 100%;
            }
        }

        .vueperslides__inner {
            height: 260px !important;

            .vueperslide {
                opacity: 0.9;
            }
        }

        &.vueperslides--fixed-height.vueperslides--bullets-outside {
            //margin-bottom: 140px !important;
        }

        .vueperslides__arrow {
            color: var(--primary-color);
            opacity: 0.5;

            svg {
                width: 3em;
            }
        }

        .vueperslide__content-wrapper {
            //height: 100%;
        }
    }

    .slideshow-radius {
        border-radius: var(--border-radius);

        .vueperslides__parallax-wrapper {
            border-radius: var(--border-radius);
            box-shadow: var(--box-shadow);
        }

        .vueperslides__inner {
            height: 260px !important;
            box-shadow: var(--box-shadow);
            border-radius: var(--border-radius);

            .vueperslide {
                opacity: 0.9;
            }
        }
    }

    .modal-body {
        .slideshow {
            border-radius: 0 !important;

            .vueperslides__parallax-wrapper {
                border-radius: unset;
                box-shadow: unset;
                max-height: 83%;
            }

            &.vueperslides--fixed-height {

            }

            .vueperslides__arrow {
                svg {
                    width: 3.5em;
                }
            }

            .slideshow-content {
                display: block;
                position: absolute;
                bottom: 50px;
                color: #333;
                font-size: var(--font-14);
                width: unset;
            }

            .vueperslides__inner {
                box-shadow: unset;
                border-radius: unset;
                background-color: white;
                width: calc(100vw - 160px) !important;
                height: calc(100vh - 120px) !important;
                padding: 50px 80px 70px 80px;

                .vueperslide {
                    opacity: 1;
                    background-size: contain !important;
                    background-repeat: no-repeat !important;
                    background-position: top center !important;
                }
            }

            .vueperslides__bullets--outside {
                position: absolute;
                bottom: 16px;
            }

            .slideshow-bullet {
                font-size: 18px;
                line-height: 32px;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                margin: 4px;
                box-shadow: var(--box-shadow);
            }
        }
    }

    .slideshow {
        .vueperslides__bullets--outside {
            margin-top: -30px;
        }

        .slideshow-content {
            display: none;
            width: 100%;
            padding: 15px;
            margin: 10px;
            overflow-y: auto;
            overflow-x: hidden;
            color: var(--fg-color);
            font-size: var(--font-12);
            text-shadow: var(--text-shadow);

            p {
                margin: 0;
            }
        }

        .slideshow-bullet {
            background-color: white;
            display: block;
            text-align: center;
            color: black;
            font-size: var(--font-10);
            line-height: 20px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin: 2px;
            cursor: pointer;
            transition: 0.3s;

            &.active {
                background-color: var(--primary-color);
                color: var(--fg-color-widget);
            }
        }
    }

    .modal-slideshow {

    }
}
</style>
