<template>
    <div class="cards-panel-container" :class="{'cards-panel-full-screen': message.fullscreen}">
        <Panel :features="features" v-if="!card" :border="false" class="cards-panel-main" @onClose="onClose">
            <div slot="powered"></div>
            <div slot="body" class="cards-panel-body">
                <FieldCard :message="message" :features="features" :limit="false" @onSendCommand="onSendCommand" @onShowCard="onShowCard" @onShowCards="onShowCards"></FieldCard>
            </div>
        </Panel>

        <CardPanel v-if="card" :option="card" :features="features" @onClose="onCloseCard" />
    </div>
</template>

<script>
import Panel from "./Panel.vue";
import FieldCard from "../chat/fields/FieldCard.vue";
import CardPanel from "../panels/CardPanel.vue";


export default {
    name: "CardsPanel",
    components: {
        Panel,
        FieldCard,
        CardPanel
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            card: null
        }
    },
    computed: {

    },
    methods: {
        onCloseCard() {
            this.card = null;
        },
        onSendCommand(value) {
            this.$emit("onClose");
            this.$emit('onSendCommand', value);
        },
        onShowCard(option) {
            this.card = option;
        },
        onShowCards(message) {
            this.$emit("onShowCards", message);
        },
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.cards-panel-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.cards-panel-full-screen {
        position: fixed;
        margin: var(--box-margin);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: unset;
        height: unset;

        .chat-panel {
            box-shadow: var(--box-shadow);
            border: 2px solid var(--border-color);
            border-radius: var(--border-radius-frame);
            background: transparent linear-gradient(153deg, var(--bg-color) 0%, var(--bg-color-gradient) 100%) 0% 0% no-repeat padding-box;

            .chat-panel-container {
                background-color: var(--bg-color-overlay);
            }

            &.cards-panel-main {
                .chat-panel-close {
                    display: none;
                }

                .chat-panel-window {
                    bottom: 0;
                }
            }

        }

    }

    .cards-panel-body {
        height: 100%;
        box-sizing: border-box;
        padding: 13px;

        .message-form {
            height: 100%;
        }
    }

}

.kiosk {
    .cards-panel-body {
        padding-bottom: 54px;
    }
}


</style>
