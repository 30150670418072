<template>
    <div class="container-message-manager-component">

        <div class="kiosk-container-message-manager">

            <div class="toolbar-message-manager">

                <div class="toolbar-item-kiosk">
                    <ButtonIcon v-if="!features.hideLastMessage" name="baloon_on" size="14px" :content="$translate(api.language, 'tooltip.messages.disable')" v-tippy="{ placement : 'left', appendTo: container }" class="rounded rounded-small" @click="onToggleBaloon"/>
                    <ButtonIcon v-else name="baloon_off" size="14px" :content="$translate(api.language, 'tooltip.messages.enable')" v-tippy="{ placement : 'left', appendTo: container }"  class="rounded rounded-small" @click="onToggleBaloon"/>
                </div>

                <template v-if="!showKeyboard">
                    <div class="toolbar-item-kiosk">
                        <template v-if="!features.status.speaking">
                            <ButtonIcon v-if="recognizeVoice" name="mic" :content="$translate(api.language, 'tooltip.microphone.disable')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded focus rounded-big running bordered" @click="onDisableMic"/>
                            <ButtonIcon v-else name="mic_off" :content="$translate(api.language, 'tooltip.microphone.enable')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded focus rounded-big bordered" @click="onEnableMic"/>
                        </template>
                        <ButtonIcon v-else name="speaker" :content="$translate(api.language, 'tooltip.speaker.disable')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded focus rounded-big running" @click="onStopSpeaking"/>
                    </div>

                    <div class="toolbar-item-kiosk">
                        <ButtonIcon name="keyboard" size="14px" :content="$translate(api.language, 'tooltip.keyboard.enable')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded focus rounded-small" @click="onEnableKeyboard"/>
                    </div>
                </template>

                <template v-else>

                    <div class="toolbar-item-kiosk">
                        <ButtonIcon name="mic_off" size="14px" :content="$translate(api.language, 'tooltip.microphone.enable')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded focus rounded-small" @click="onDisableKeyboard"/>
                    </div>

                    <div class="toolbar-item-kiosk">
                        <ButtonIcon v-if="!features.status.speaking" name="keyboard" :content="$translate(api.language, 'tooltip.keyboard.enable')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded focus rounded-big bordered" :class="{'bordered-active': toggleKeyboard}" @click="onToggleKeyboard"/>
                        <ButtonIcon v-else name="speaker" :content="$translate(api.language, 'tooltip.speaker.disable')" v-tippy="{ placement : 'left',  appendTo: container }" class="rounded focus rounded-big running" @click="onStopSpeaking"/>
                    </div>

                </template>

            </div>

            <div v-if="showKeyboard && (lastMessage == null || lastMessage.answerType !== 'FORM')" v-show="toggleKeyboard" class="keyboard-message-manager">
                <KioskKeyboard :features="features" :send="true" @onChange="onKeyboardChange" @onKeyPress="onKeyboardKeyPress" @onKeyboardSend="keyboardSend" :input="textInput"/>
            </div>
        </div>

    </div>
</template>

<script>

    const StorageKeys = {
        INPUT: '_algho_input'
    };

    import {mapMutations} from 'vuex'
    import InputMode from "@/classes/InputMode";
    import ButtonIcon from './ButtonIcon';
    import KioskKeyboard from "../KioskKeyboard";

    export default {
        name: 'KioskMessageManager',
        components: {
            ButtonIcon,
            KioskKeyboard
        },
        props: {
            features: {
                type: Object,
                required: true
            },
            audioVoice: {
                type: Boolean,
                required: true
            },
            recognizeVoice: {
                type: Boolean,
                required: true,
            },
            recognized: {
                type: Object,
                required: true
            },
            messages: {
                type: Array,
                required: true
            },
            playVoice: {
                type: Boolean,
                required: true,
            },
            playCount: {
                type: Number,
                required: true,
            },
            waiting: {
                type: Boolean,
                required: true
            },
            commandVoice: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                textInput: '',
                prevInput: '',
                showKeyboard: false,
                toggleKeyboard: true,
            }
        },
        computed: {
            myself() {
                return this.$store.state.myself;
            },
            container() {
                return ()=> this.$el;
            },
            hasAudio() {
                return this.features.tts;
            },
            lastMessage() {
                const filtered = this.messages.filter((message) => {return !message.myself});
                return filtered.length > 0 ? filtered[filtered.length-1] : null;
            },
        },
        watch: {
            'showKeyboard': {
                handler(newVal) {
                    if (this.hasAudio)
                        window.localStorage.setItem(StorageKeys.INPUT, (newVal ? 'keyboard': 'microphone'));
                },
                deep: true
            },
            'waiting': {
                handler(newVal) {
                    if (newVal) {
                        this.toggleKeyboard = false;
                    }
                },
                deep: true
            },
            'recognized': {
                handler(newVal) {
                    this.$emit("onPreviewContent", newVal);

                    if (newVal.text.length > 0 &&  newVal.final) {
                        this.textInput = newVal.text;
                        this.prevInput = this.textInput;

                        const self = this;
                        setTimeout(() => {
                            self.sendMessage(InputMode.VOICE);
                        }, 1000);
                        /*
                        if (newVal.confidence > 0.7) {
                            this.prevInput = this.textInput;
                            const self = this;
                            setTimeout(() => {
                                self.sendMessage(InputMode.VOICE);
                            }, 1000);
                        } else {
                            this.showKeyboard = true;
                            this.toggleKeyboard = true;
                        }*/
                    }
                },
                deep: true
            },
            'playCount': {
                handler(newVal) {
                    if (newVal === 0 && this.features.autoMicrophone) {
                        if (!this.showKeyboard && (this.lastMessage == null || this.lastMessage.answerType !== 'FORM')) {
                            if (this.lastMessage == null || (this.lastMessage.mediaType || null) == null) {
                                const self = this;
                                setTimeout(() => {
                                    //check panel is closed to reactivate mic
                                    if (!self.features.status.speaking && self.$root.$el.getElementsByClassName('chat-panel').length === 0) {
                                        self.$log.debug("Auto reactivate mic");
                                        self.onEnableMic();
                                    }
                                }, 1000);
                            }

                        }
                    }
                },
                deep: true
            },
            'commandVoice': {
                handler(newVal) {
                    if (newVal) {
                        this.showKeyboard = false;
                    }
                },
                deep: true
            },
        },
        beforeCreate() {
            this.api = this.$root.$children[0].$refs.api;
        },
        mounted() {

        },
        methods: {
            ...mapMutations([
                'newMessage'
            ]),
            onKeyboardChange(input) {
                this.textInput = input;
            },
            onKeyboardKeyPress(button) {
                this.$log.debug("button", button);

                if (button === "{ent}") {
                    this.keyboardSend();
                }

            },
            toText(input) {
                return input.replace(/<[^>]+?>/g,'');
            },
            sendMessage(inputMode) {

                this.textInput = this.toText(this.textInput);

                // match characters that are different of spaces, tabs, line breaks...
                const matchNotEmpty = /[^\s]+/i
                // match characters that are between line spaces, tabs, line breaks...
                const contentMatch = this.textInput.match(/^\s*((.|\n)+?)\s*$/i)
                if (this.textInput && matchNotEmpty.test(this.textInput) && contentMatch) {
                    this.prevInput = this.textInput;
                    this.textInput = '';
                    this.toggleKeyboard = false;
                    const text = contentMatch[1];
                    this.$emit("onSendText", { text: text, inputMode});
                }
            },
            keyboardEnter(event) {
                //fix iOS
                //https://github.com/vuejs/vuejs.org/issues/394
                if (event.key === 'Enter') {
                    this.keyboardSend();
                }
            },
            keyboardSend() {
                this.sendMessage(InputMode.KEYBOARD);
            },
            keyboardUp() {
                this.textInput = this.prevInput;
            },
            onEnableKeyboard() {
                this.showKeyboard = true;
                this.toggleKeyboard = true;
                this.$nextTick(() => {
                    if (this.$refs.userInput !== undefined)
                        this.$refs.userInput.focus();
                });
                this.$emit("onDisableMic");
            },
            onDisableKeyboard() {
                this.showKeyboard = false;
                this.toggleKeyboard = false;
                this.$emit("onEnableMic");
            },
            onToggleKeyboard() {
                if (this.lastMessage == null || this.lastMessage.answerType !== 'FORM')
                    this.toggleKeyboard = !this.toggleKeyboard;
            },
            onEnableMic() {
                this.$emit("onEnableMic");
            },
            onDisableMic() {
                this.$emit("onDisableMic");
            },
            onEnableAudio() {
                this.$emit("onEnableAudio");
            },
            onDisableAudio() {
                this.$emit("onDisableAudio");
            },
            onStopSpeaking() {
                this.$emit("onStopSpeaking");
            },
            onToggleBaloon() {
                this.features.hideLastMessage = !this.features.hideLastMessage;
            },
        }
    }
</script>

<style lang="less">
    .chat-body {
        .container-message-manager-component {
            width: 100%;
        }

        .kiosk-container-message-manager {
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            padding: 0 10px;
            margin-bottom: 10px;

            .toolbar-message-manager {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                width: 100%;
                margin-bottom: 12px;
            }
        }

        .toolbar-item-kiosk {
            width: 54px;
            margin-bottom: 10px;
        }

    }

</style>
