<template>
    <Panel :features="features" @onClose="onClose">
        <template v-if="!panels.privacy">
            <div slot="body">
            <template v-if="features.cta">
                <h3>{{$translate(api.language, 'panels.info.contacts.title')}}</h3>
                <ul class="contact-list">
                    <li v-if="info.email" class="contact-item">
                        <Icon name="email" size="18px" class=""/>
                        <span>{{info.email}}</span>
                        <button type="button" @click="openUrl('mailto:' + info.email)">{{$translate(api.language, 'panels.info.contacts.open')}}</button>
                    </li>
                    <li v-if="info.telephone" class="contact-item">
                        <Icon name="phone" size="18px" class=""/>
                        <span>{{info.telephone}}</span>
                        <button type="button" @click="openUrl('tel:' + info.telephone)">{{$translate(api.language, 'panels.info.contacts.open')}}</button>
                    </li>
                    <li v-if="info.website" class="contact-item">
                        <Icon name="link" size="18px" class=""/>
                        <span>{{webSiteName}}</span>
                        <button type="button" @click="openUrl(info.website)">{{$translate(api.language, 'panels.info.contacts.open')}}</button>
                    </li>
                    <li v-if="info.location" class="contact-item">
                        <Icon name="location" size="18px" class=""/>
                        <span>{{info.location}}</span>
                        <button type="button" @click="openUrl('https://www.google.com/maps/search/' + encodeURIComponent(info.location))">{{$translate(api.language, 'panels.info.contacts.open')}}</button>
                    </li>
                </ul>
            </template>

            <template v-if="features.privacy && features.cta">
                <hr/>
            </template>

            <template v-if="features.privacy">
                <h3>{{$translate(api.language, 'panels.info.privacy.title')}}</h3>
                <ul class="contact-list">
                    <li class="contact-item">
                        <Icon name="link" size="18px" class=""/>
                        <span>Privacy policy</span>
                        <button type="button" @click="openPrivacy(api.bot.privacyUrl)">{{$translate(api.language, 'panels.info.privacy.open')}}</button>
                    </li>
                </ul>
            </template>
        </div>
        </template>

        <template v-if="panels.privacy" >
            <div slot="body" class="privacy-content scrollable scrollable-y" v-html="html"></div>
        </template>


    </Panel>
</template>

<script>
import Panel from "./Panel.vue";
import Icon from "../chat/Icon.vue";
import axios from "axios";

export default {
    name: "InfoPanel",
    components: {
        Panel,
        Icon
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            html: null,
            panels: {
                privacy: false,
            },
        }
    },
    computed: {
        info() {
            return this.api.bot.callToAction || {};
        },
        webSiteName() {
            const url = this.api.bot.callToAction.website || '';
            if (url !== '') {
                const domain = new URL(url);
                return domain.hostname;
            } else {
                return '';
            }
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        getPrivacyContent(url) {

            const self = this;
            axios.get(url).then(response => {
                const html = document.createElement('html');
                html.innerHTML = response.data;
                const div = document.createElement('div');
                Array.from(html.getElementsByClassName("SECTION")).forEach(function(item) {
                    div.append(item);
                });

                self.html = div.innerHTML;
                self.panels.privacy = true;
            })
        },

        openUrl(url) {
          window.open(url);
        },
        openPrivacy(url) {
            this.getPrivacyContent(url);
        },
        onClose() {
            if (this.panels.privacy)
                this.panels.privacy = false;
            else
                this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.chat-panel-window {

    ul.contact-list {

    }

    li.contact-item {
        display: flex;
        margin-bottom: 8px;
        min-height: 24px;

        svg {
            margin-right: 10px;
            margin-top: 3px;
        }
        span {
            margin-top: 3px;
            font-size: var(--font-12);
            width: calc(100% - 60px - 36px);
        }
        button {
            right: 0;
            position: absolute;
            background-color: var(--bg-button-color);
            border: 0;
            border-radius: 20px;
            height: 24px;
            width: 60px;
            color: var(--fg-color);
            text-transform: uppercase;
            cursor: pointer;

            user-select: none;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-tap-highlight-color: transparent; /* for removing the highlight */
        }
        button:hover {
            color: var(--primary-color-text);
        }
    }

    .privacy-content {
        color: var(--fg-color);
        text-align: left;
        font-size: var(--font-11);
        line-height: calc(var(--font-11) + 3px);

        a {
            color: var(--primary-color-text);
        }

        h1, h2, h3, p {
            margin-top: 0;
        }

        h2 {
            font-size: var(--font-14);
            line-height: calc(var(--font-14) + 3px);
        }
    }
}

</style>
