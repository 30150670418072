<template>
    <div v-if="false"></div>
</template>

<script>

export default {
    name: "SttChromeCommand",
    props: {
        enabled: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            textInput: '',
            recognition: null,
            languageMap: {
                'it': 'it-IT',
                'en': 'en-US',
                'es': 'es-ES',
                'pt':'pt-PT',
                'fr': 'fr-FR',
                'de': 'de-DE',
                'ro': 'ro-RO',
                'zh': 'zh-CN',
                'ru': 'ru-RU',
            },
            activationCommands: {
                it: ["ok", "ehi" , "hey", "ei", "ciao"],
                en: ["ok", "hey", "hello", "hi"],
                fr: ["ok", "hé", "bonjour"],
                es: ["ok", "oye", "hola"],
                pt: ["ok", "ei", "ola"],
                de: ["ok", "hey", "hallo"],
                ro: ["ok"],
                zh: ["ok"],
                ru: ["ok", "priviet"],
            }
        }
    },
    watch: {
        'enabled': {
            handler(newVal) {
                //console.log('Prop changed: ', newVal, ' | was: ', oldVal);
                if (newVal)
                    this.startRecognizeCommand();
                else
                    this.stopRecognizeCommand();
            },
            deep: true,
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        if (this.enabled)
            this.startRecognizeCommand();
    },
    destroyed() {
        this.stopRecognizeCommand();
    },
    methods: {
        startRecognizeCommand() {
            if ('webkitSpeechRecognition' in window) {

                if (!self.listen && !self.talking) {
                    const self = this;

                    if (this.recognition == null) {
                        const language = this.api.language;
                        const name = this.api.bot.name;
                        const cmds = this.activationCommands[language];

                        this.recognition = new window.webkitSpeechRecognition();
                        this.recognition.continuous = false;
                        this.recognition.interimResults = true;
                        //todo: passare la lingua
                        this.recognition.lang = this.languageMap[language];
                        this.recognition.start();
                        //this.$log.debug('Command recognition start');

                        this.recognition.onstart = function () {
                            self.$log.debug('Command recognition started');
                            self.inputText = '';
                        };

                        this.recognition.onerror = function (event) {
                            if (event.error !== 'aborted')
                                self.$log.error('Command recognition error', event.error);
                        };

                        this.recognition.onresult = (event) => {
                            let text = '';
                            let final = false;

                            for (let i = event.resultIndex; i < event.results.length; ++i) {
                                text += event.results[i][0].transcript;
                                if (event.results[i].isFinal) {
                                    final = true;
                                    break;
                                }
                            }

                            if (final) {
                                var test = text.trim().toLowerCase();
                                let cmdFound = null;

                                for (let i in cmds) {
                                    const cmd = cmds[i].toLowerCase();
                                    if (test.indexOf(cmd + " ") == 0) {
                                        self.$log.info("Activation Command Found", cmd);
                                        cmdFound = cmd;
                                        break;
                                    }
                                }

                                if (cmdFound != null) {
                                    var activation = cmdFound + " " + name;
                                    var distance = self.levenshtein(test, activation);
                                    self.$log.debug("Levenshtein distance", distance);
                                    if (distance <= 2) {
                                        self.textInput = text;
                                        self.$log.info('Command recognized', text);
                                        self.$emit('onRecognizeCommand');
                                    }
                                }
                            }
                        }
                        this.recognition.onend = () => {
                            self.$log.debug('Command recognition ended');
                            if (!self.listen && !self.talking) {
                                if (self.recognition != null)
                                    self.recognition.start();
                            } else {
                                self.recognition = null;
                            }
                        }
                    }
                }
            }
        },
        stopRecognizeCommand() {
            if ('webkitSpeechRecognition' in window) {
                if (this.recognition != null) {
                    this.recognition.abort();
                    this.recognition.stop();
                    this.recognition = null;
                }
            }
        },
        levenshtein(a, b) {

            function prepareLevenshtein(s) {
                return s.toLowerCase()
                    .replaceAll(" ", "")
                    .replaceAll("à", "a")
                    .replaceAll("è", "e")
                    .replaceAll("é", "e")
                    .replaceAll("ì", "i")
                    .replaceAll("ò", "o")
                    .replaceAll("ù", "u");
            }

            if (a.length == 0) return b.length;
            if (b.length == 0) return a.length;

            a = prepareLevenshtein(a);
            b = prepareLevenshtein(b);

            var matrix = [];

            // increment along the first column of each row
            var i;
            for (i = 0; i <= b.length; i++) {
                matrix[i] = [i];
            }

            // increment each column in the first row
            var j;
            for (j = 0; j <= a.length; j++) {
                matrix[0][j] = j;
            }

            // Fill in the rest of the matrix
            for (i = 1; i <= b.length; i++) {
                for (j = 1; j <= a.length; j++) {
                    if (b.charAt(i - 1) == a.charAt(j - 1)) {
                        matrix[i][j] = matrix[i - 1][j - 1];
                    } else {
                        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                            Math.min(matrix[i][j - 1] + 1, // insertion
                                matrix[i - 1][j] + 1)); // deletion
                    }
                }
            }

            return matrix[b.length][a.length];
        }
    }
}
</script>

<style>

</style>
