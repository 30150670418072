<template>

    <div class="message-form-actions">
        <ul v-show="!showInterrupt" class="message-form-option-content margin-option-content">
            <li class="message-form-option-items">
                <div v-if="abortable" class="message-form-option-item">
                    <button type="button" @click="onAbortCheck()">{{message.abortOption.optionText}}</button>
                </div>
                <div v-if="confirmable && visible" class="message-form-option-item">
                    <button type="button" :disabled="disable" @click="onInputClicked()">{{$translate(api.language, 'form.confirm')}}</button>
                </div>
                <div v-if="skipable" class="message-form-option-item">
                    <button type="button" @click="onSkipClicked(message)">{{message.skipOption.optionText}}</button>
                </div>
            </li>
        </ul>
        <Interrupt v-if="showInterrupt" :message="message" @onInterrupt="onInterrupt" @onAbortClicked="onAbortClicked(message)"></Interrupt>
    </div>


</template>

<script>

import InputMode from "@/classes/InputMode";
import Interrupt from "../../others/Interrupt";
import Vue from "vue";

export default {
    name: "CommandMixin",
    components: {
        Interrupt
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        disable: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            showInterrupt: false,
        };
    },
    computed: {
        options() {
            return this.message.options;
        },
        suggestions() {
            return this.message.suggestions;
        },
        suggested() {
            return  this.message.suggestedOptions;
        },
        multiple() {
            return this.message.multiple;
        },
        max() {
            return Math.min(this.message.maxValuesToSelect, this.message.options.length);
        },
        skipable() {
            return (this.message.skipOption !== undefined);
        },
        abortable() {
            return (this.message.abortOption !== undefined);
        },
        confirmable() {
          return !(this.max == 1 &&
              this.message.fieldType !== 'BookingDateFieldType' &&
              this.message.fieldType !== 'BirthDateFieldType' &&
              this.message.fieldType !== 'DateFieldType' &&
              this.message.fieldType !== 'DateRangeFieldType' &&
              this.message.fieldType !== 'HourFieldType' &&
              this.message.fieldType !== 'HourRangeFieldType' &&
              this.message.fieldType !== 'DateHourFieldType' &&
              this.message.fieldType !== 'DateHourRangeFieldType' &&
              this.message.fieldType !== 'CatalogFieldType');
        },
        visible() {
            return (this.message.fieldType !== 'QRCodeFieldType' &&
                this.message.fieldType !== 'GreenPassFieldType' &&
                this.message.fieldType !== 'SuperGreenPassFieldType' &&
                this.message.fieldType !== 'BoosterGreenPassFieldType');
        },
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onInputClicked() {
            if (!this.disable) {
                this.$emit("onInputClicked");
                if (this.message.fieldType !== 'FileFieldType')
                    this.message.enabled = false;
            }
        },
        onInterrupt() {
            this.showInterrupt = false;
        },
        onAbortCheck() {
            this.showInterrupt = true;
            Vue.$common.scrollToBottom();

        },
        onAbortClicked(message) {
            const value = Object.assign({ inputMode: InputMode.CLICK }, message.abortOption)
            this.$emit("onSendCommand", value);
            this.message.enabled = false;
        },
        onSkipClicked(message) {
            const value = Object.assign({ inputMode: InputMode.CLICK }, message.skipOption)
            this.$emit("onSendCommand", value);
            this.message.enabled = false;
        },
    }
}

</script>

<style lang="less">

.message-form-actions {
   width: 100%;
    margin-top: 10px;
}

.message-form-option-content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;

    color: var(--fg-color);
    background-color: var(--bg-other-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);
    border-radius: var(--border-radius);
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);

    .message-form-option-items {
        display: flex;
        flex-direction: row;
        border-bottom: 0;

        .message-form-option-item {

            border-right: 1px solid var(--border-other-color);
            width: 100%;
            padding: 8px 12px 8px 12px;

            & > button {
                width: 100%;
                height: 100%;
                border: 0;

                background-color: transparent;
                font-size: var(--font-12);
            }

            & > button:not([disabled]) {
                color: var(--fg-color);
                cursor: pointer;

                &:hover {
                    color: var(--primary-color-text);
                }

                &:active {
                    color: var(--primary-color-text);
                }

                &:focus {
                    color: var(--primary-color-text);
                    outline: 0;
                }
            }

            &:last-child {
                border-right: 0;
            }

            &:hover {
                color: var(--primary-color-text);
            }
        }
    }

}

.message-form {

    .form-action {
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
    }
}

</style>


