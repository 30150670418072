<template>
    <ul v-if="bannerList().length > 0" class="banners" :style="bannerStyle()">
        <template v-for="(item, index) in bannerList()">
            <transition :key="index" appear name="fade-banner">
                <li v-if="index===current" class="banner" :style="listItemStyle(index)" />
            </transition>
        </template>
    </ul>
</template>

<script>
export default {
    name: "KioskBanner",
    props: {

    },
    data() {
        return {
            current: 0,
            height: ''
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        this.changeBanner();
    },
    methods: {
        bannerList() {
            return this.api.bot.bannerList;
        },
        bannerStyle() {
            return {
                height: this.height
            };
        },
        listItemStyle(index) {
            const bot = this.api.bot;
            return {
                backgroundImage:  'url(\'' + bot.bannerList[index].imageUrl + '\')',
                backgroundColor: bot.bannerList[index].bgColor
            }
        },
        randomBanner(index, max) {
            var randomIndex = Math.floor(Math.random() * max);
            if (randomIndex == index)
                randomIndex = randomIndex + 1;

            return randomIndex % max;
        },
        changeBanner() {
            const bot = this.api.bot;

            this.height = (bot.bannerHeight / 2) + 'px';

            if (bot.bannerList.length > 0) {

                if (bot.bannerRandom) {
                    this.current = this.randomBanner(this.current, bot.bannerList.length);
                } else {
                    this.current = this.current % bot.bannerList.length;
                }

                if (bot.bannerList.length > 1) {
                    if (!bot.bannerRandom)
                        this.current ++;

                    this.current = this.current % bot.bannerList.length;

                    setTimeout(this.changeBanner, bot.bannerTransitionTime * 1000);
                }
            }
        },
    }
}
</script>

<style lang="less">
    .banners {
        position: fixed;
        width: 100%;
        height: 0;
        left: 0;
        right: 0;
        bottom: 0; //1px fix cef chrome cutoff
        padding: 0;
        margin: 0;
        background-color: black;

        .banner {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat no-repeat;
        }
    }

    /* FADE */

    .fade-banner-enter-active, .fade-banner-leave-active {
        transition: opacity 1.0s;
    }
    .fade-banner-enter, .fade-banner-leave-to {
        opacity: 0;
    }
</style>
