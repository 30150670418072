<template>
    <div v-if="show" class="message-multimedia margin-common-content-multimedia">

        <Modal class="modal-chart" :fullscreen="!features.kiosk && fullscreen" @close="fullscreen = false">
            <highcharts v-if="!features.kiosk" slot="body" :options="chartData" class="chart chart-radius shadow-common"></highcharts>

            <KioskMedia v-if="features.kiosk && !fullscreen" slot="body" @onToggle="toggle" @onClose="close">
                <highcharts slot="body" :options="chartData" class="chart"></highcharts>
            </KioskMedia>
        </Modal>

        <div v-if="!features.kiosk" class="media-action">
            <ButtonIcon name="fullscreen" size="16px" class="action-small" @click="toggle"/>
        </div>

        <Panel :features="features" :border="false" v-if="features.kiosk && fullscreen" @onClose="fullscreen = false">
            <div slot="body" class="modal-body">
                <highcharts :options="chartData" class="chart shadow-common"></highcharts>
            </div>
            <div slot="powered"></div>
        </Panel>

    </div>
</template>

<script>
import {Chart} from 'highcharts-vue';
import ButtonIcon from '../ButtonIcon';
import Modal from '../../Modal';
import Panel from "../../panels/Panel";
import KioskMedia from "./KioskMedia";

export default {
    name: "MediaChart",
    components: {
        highcharts: Chart,
        ButtonIcon,
        Modal,
        Panel,
        KioskMedia
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            show: true,
            fullscreen: false,
            chart: {
                width: '100%',
                time: {
                    timezone: 'Europe/Rome'
                },
                chart : {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    style: {
                    }
                },
                //colors: [],
                legend: {
                    itemStyle: {
                        //fontSize: '2em'
                    },
                },
                xAxis: {
                    title: {
                        style: {
                            //fontSize: '2em',
                        }
                    },
                    labels: {
                        style: {
                            //fontSize: '2em',
                            color: '#636363'
                        }
                    }
                },
                yAxis: {
                    title: {
                        style: {
                            //fontSize: '2em',
                        }
                    },
                    labels: {
                        style: {
                            //fontSize: '2em',
                            color: '#9b9b9b'
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-weight: 600">{point.key}</span><br/>',
                    style: {
                        //fontSize: '2em',
                    }
                },
                plotOptions: {
                    pie: {
                        cursor: 'pointer',
                        dataLabels: {
                            style: {
                                //fontSize: '2em',
                                color: 'black'
                            }
                        }
                    },
                    column: {
                        cursor: 'pointer',
                        dataLabels: {
                            style: {
                                //fontSize: '2em',
                                color: 'black'
                            }
                        }
                    },
                    wordcloud: {
                        cursor: 'pointer',
                    }
                },
                lang: {
                    thousandsSep: '.',
                    decimalPoint: ',',
                    loading: "Loading",
                    //months: moment.months().map(function(el) {return toProperCase(el)}),
                    //weekdays: moment.weekdays().map(function(el) {return toProperCase(el)}),
                    //shortMonths: moment.monthsShort(function(el) {return toProperCase(el)}),
                    noData: "No data",
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: null,
                    align: "center",
                    style: {
                        fontSize: '3em',
                    }
                },
                subtitle: {
                    text: null,
                    align: "center",
                    style: {
                        fontSize: '2em',
                    }
                },
                noData: {
                    style: {
                        fontSize: '2em',
                        color: '#666666'
                    }
                }
            }
        };
    },
    computed: {
        chartData() {
            return Object.assign(this.chart, this.message.media);
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        toggle () {
            this.fullscreen = !this.fullscreen;
        },
        close: function () {
            this.show = false;
        },
    }
}
</script>

<style lang="less">

.message-multimedia {
    .chart {
        width: 100%;
        height: 300px;
    }

    .chart-radius {
        border-radius: var(--border-radius);
    }

    .modal-body .chart {
        border: unset !important;
        border-radius: unset !important;
        box-shadow: unset !important;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .modal-chart {

    }
}

</style>
