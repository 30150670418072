<template>
    <div class="menu-container toolbar-item">

        <transition name="fade">
            <div v-if="visible" class="menu" @mouseleave="hover = false" @mouseenter="hover = true">

                <template v-if="showSwitch">
                    <ButtonIcon v-if="!features.dhi" name="switch_3d" :size=size :content="$translate(api.language, 'tooltip.mode.dhi')" v-tippy="{ placement : 'right', appendTo: container }" @click="onToggleMode('dhi')"/>
                    <ButtonIcon v-else name="switch_txt" :size=size :content="$translate(api.language, 'tooltip.mode.txt')" v-tippy="{ placement : 'right', appendTo: container }" @click="onToggleMode('txt')"/>
                    <div class="menu-separator"/>
                </template>

                <template v-if="showLiveChat">
                    <ButtonIcon v-if="features.livechat.activated" name="livechat_off_o" :size=size :content="$translate(api.language, 'tooltip.livechat.close')" v-tippy="{ placement : 'right', appendTo: container }" class="active" @click="onCloseLiveChat"/>
                    <ButtonIcon v-else name="livechat_o" :size=size :content="$translate(api.language, 'tooltip.livechat.open')" v-tippy="{ placement : 'right', appendTo: container }" @click="onOpenLiveChat"/>
                </template>

                <template v-if="showWebcam">
                    <ButtonIcon v-if="features.webcam.activated" name="videocam_o" :size=size :content="$translate(api.language, 'tooltip.webcam.disable')" v-tippy="{ placement : 'right', appendTo: container }" @click="onToggleWebcam"/>
                    <ButtonIcon v-else name="videocam_off_o" :size=size :content="$translate(api.language, 'tooltip.webcam.enable')" v-tippy="{ placement : 'right', appendTo: container }" class="deactive" @click="onToggleWebcam"/>
                </template>

                <template v-if="showBaloon">
                    <ButtonIcon v-if="!features.hideLastMessage" name="baloon_on" :size=size :content="$translate(api.language, 'tooltip.messages.disable')" v-tippy="{ placement : 'right', appendTo: container }" @click="onToggleBaloon"/>
                    <ButtonIcon v-else name="baloon_off" :size=size :content="$translate(api.language, 'tooltip.messages.enable')" v-tippy="{ placement : 'right', appendTo: container }" class="deactive" @click="onToggleBaloon"/>
                </template>

                <template v-if="showAudio">
                    <ButtonIcon v-if="audioVoice" name="volume_on_o" :size=size :content="$translate(api.language, 'tooltip.speaker.disable')" v-tippy="{ placement : 'right', appendTo: container }" @click="onDisableAudio"/>
                    <ButtonIcon v-else name="volume_off_o" :size=size :content="$translate(api.language, 'tooltip.speaker.enable')" v-tippy="{ placement : 'right',  appendTo: container }" class="deactive" @click="onEnableAudio"/>
                </template>

                <template v-if="showChat">
                    <ButtonIcon v-if="!features.showLastMessage" name="chat_off" :size=size :content="$translate(api.language, 'tooltip.chat.hide')" v-tippy="{ placement : 'right',  appendTo: container }" class="active" @click="onToggleFullChat"/>
                    <ButtonIcon v-else name="chat_on" :size=size :content="$translate(api.language, 'tooltip.chat.show')" v-tippy="{ placement : 'right', appendTo: container }" @click="onToggleFullChat"/>
                </template>

                <template v-if="showSeparator">
                    <div class="menu-separator"/>
                </template>

                <template v-if="showSettings">
                    <ButtonIcon name="settings_o" :size=size :content="$translate(api.language, 'tooltip.settings')" v-tippy="{ placement : 'right', appendTo: container }" class="" @click="onShowSetting"/>
                </template>

                <template v-if="showInfo">
                    <ButtonIcon v-if="features.cta || features.privacy" name="info_o" :size=size :content="$translate(api.language, 'tooltip.info')" v-tippy="{ placement : 'right', appendTo: container }" class="" @click="onShowInfo"/>
                </template>
                <div class="menu-separator"/>
                <ButtonIcon name="close" :size=size :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'right', appendTo: container }" @click="onToggleMenu"/>

            </div>
        </transition>

        <ButtonIcon v-visible="!visible" name="menu" :content="$translate(api.language, 'tooltip.menu')" v-tippy="{ placement : 'top', appendTo: container }" class="rounded focus" @click="onToggleMenu"/>

    </div>
</template>

<script>
import ButtonIcon from './ButtonIcon';

export default {
    name: "Menu",
    components: {
        ButtonIcon,
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        audioVoice: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            delay: 3000,
            visible: false,
            size: '20px',
            timer: null,
            hover: false,
        }
    },
    watch: {
        visible: {
            handler(newVal) {
                if (newVal) {
                    this.sleepClose();
                    this.$emit("onShowMenu");
                } else {
                    this.$emit("onHideMenu");
                }
            }
        },
        hover: {
            handler() {
                this.sleepClose();
            }
        },
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        },
        showInfo() {
            return !this.features.hide.infos;
        },
        showSettings() {
            return !this.features.hide.settings;
        },
        showChat() {
            return this.features.dhi;
        },
        showAudio() {
            return this.features.tts;
        },
        showWebcam() {
            return (this.features.device.desktop || !this.features.device.slowMobile) && this.features.has.webcam;
        },
        showLiveChat() {
            return this.features.livechat.show && !this.features.livechat.extended;
        },
        showSwitch() {
            return this.features.has.switch;
        },
        showBaloon() {
            return this.features.showLastMessage // && this.features.has.hideMessage;
        },
        showSeparator() {
            return this.showSettings && this.showInfo && (this.showChat || this.showAudio || this.showWebcam || this.showLiveChat);
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    destroyed() {
        document.removeEventListener("click", this.close, {once : true});
    },
    methods: {
        onToggleMenu(event) {
            event.stopPropagation();
            this.visible = !this.visible;

            if (this.visible)
                document.addEventListener("click", this.close, {once : true});
            else
                document.removeEventListener("click", this.close);

        },
        onToggleFullChat() {
            this.features.showLastMessage = !this.features.showLastMessage;
            this.close();
        },
        onToggleBaloon() {
            this.features.hideLastMessage = !this.features.hideLastMessage;
            this.close();
        },
        onToggleWebcam() {
            if (this.api.getPrivacyWebcam() == null) {
                this.features.webcam.requested = true;
            } else {
                this.features.webcam.activated = !this.features.webcam.activated;
            }
            this.close();
        },
        sleepClose: function () {
            if (this.timer != null) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            if (this.visible && !this.hover) {
                const self = this;
                this.timer = setTimeout(() => {
                    self.visible = false;
                }, this.delay);
            }
        },
        close() {
            if (this.timer != null) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.visible = false;
        },
        onShowSetting() {
            this.$emit("onShowSetting");
            this.close();
        },
        onShowInfo() {
            this.$emit("onShowInfo");
            this.close();
        },
        onEnableAudio: function () {
            this.$emit("onEnableAudio");
            this.close();
        },
        onDisableAudio: function () {
            this.$emit("onDisableAudio");
            this.close();
        },
        onOpenLiveChat: function () {
            this.$emit("onEnableLiveChat");
            this.close();
        },
        onCloseLiveChat: function () {
            this.$emit("onDisableLiveChat");
            this.close();
        },
        onToggleMode(mode) {
            this.$emit("onToggleMode", mode);
            this.close();
        },
    }
}
</script>

<style lang="less">

.menu-container {
    position: relative;
    display: inline-flex;

    .menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 40px;
        border-radius: 28px;
        bottom: 0;
        margin: 0;
        background-color: var(--bg-button-color);
        box-shadow: var(--box-shadow);
        backdrop-filter: var(--backdrop-filter);

        button {
            padding: 5px 2px 5px 2px;

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }

        }

        div.menu-separator {
            height: 1px;
            width: 24px;
            margin: 4px 8px 0px 8px;
            border-top: 1px solid var(--fg-button-color);
            opacity: 0.3;
        }

    }
}

</style>
