<template>
    <div v-if="message.enabled && options.length > 0 && (!message.fullscreen || !limit)" class="message-form">

        <div class="card-container">

            <div v-if="!limit && !features.kiosk" class="card-filter">
                <input type="text" v-model="search" :placeholder="$translate(api.language, 'card.search')" class="card-input-filter" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
            </div>

            <template v-if="!limit && features.kiosk">
                <div class="card-filter">
                    <input type="text" v-model="search" :placeholder="$translate(api.language, 'card.search')" class="card-input-filter" readonly @focus="showKeyboard = true">
                    <div class="box-keyboard">
                        <ButtonIcon name="keyboard" class="icon-keyboard" :class="{'active': showKeyboard}" size="20px" :content="$translate(api.language, 'tooltip.keyboard.enable')" v-tippy="{ placement : 'left',  appendTo: container }" @click="showKeyboard = !showKeyboard" />
                    </div>
                </div>
                <div v-show="showKeyboard" class="keyboard-message-manager">
                    <KioskKeyboard :features="features" @onChange="onKeyboardChange" :input="search"/>
                </div>
            </template>

            <div class="card-categories scrollable" v-if="isCatalog && !limit">
                <div class="field-category" v-for="(category, index) in categories" v-bind:key="index" :class="{'category-filtered': isFiltered(category)}" @click="onFilterCategory(category)">{{ category }}</div>
            </div>

            <div ref="cards" class="cards scrollable" :class="{'cards-horizontal': message.scrollable && limit, 'cards-vertical': !message.scrollable || !limit, 'no-limit': !limit, 'cards-multimedia': hasMultimedia, 'cards-description': hasDescription }">
                <div v-show="!limit || index < maxLength" v-for="(option, index) in filteredOptions" v-bind:key="index" class="col" :class="{ 'col-xs-12 col-sm-6 col-md-4 col-lg-3' : message.fullscreen }">
                    <div :class="{'card-item': true, 'card-item-multimedia': hasMultimedia, 'card-item-description': hasDescription, 'card-item-title': hasTitle, 'card-suggested': isSuggested(option), 'card-selected' : isSelected(option) }" :style="{'height': maxHeight}" @click="onItemClicked(option, false)">

                        <div v-if="isImageOrVideo(option)" class="card-box-multimedia" @click="onShowCardMobile(option)">
                            <img v-if="isImage(option)" class="card-multimedia" :src="parseLink(option)" alt="Image" onerror="this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='">
                            <iframe v-if="isVideo(option)" class="card-multimedia" :src="parseLink(option)"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                    sandbox="allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                                    title="Video" style="pointer-events: none" />

                            <div v-if="!features.kiosk && features.device.desktop" class="card-info">
                                <ButtonIcon name="info_o" size="20px" class="default" :content="$translate(api.language, 'card.detail')" v-tippy="{ placement : 'top',  appendTo: container }" @click="onShowCard(option)"/>
                            </div>
                        </div>

                        <div class="card-content">
                            <div class="card-title">{{ option.optionText }}</div>
                            <div v-if="option.description" class="card-description">{{ option.description }}</div>

                            <table v-if="hasMultimedia" style="width: 100%" aria-hidden="true">
                                <tr>
                                    <td>
                                        <div v-if="!features.kiosk && option.externalUrl" class="card-external-link">
                                            <ButtonIcon name="link" size="16px" @click.stop.prevent="onLinkClicked(option)"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="option.price" class="card-price">{{ option.price }} {{ option.currency }}</div>
                                    </td>
                                </tr>
                            </table>

                        </div>

                        <div v-if="isCatalog" class="card-quantity">
                            <Quantity :option="option" @onChangeQuantity="onChangeQuantity" />
                        </div>

                        <div v-if="hasMultimedia && !isCatalog" class="card-selection" @click="onItemClicked(option, true)">
                            <template v-if="isSelected(option)">{{ $translate(api.language, 'card.unselect') }}</template>
                            <template v-else>{{ $translate(api.language, 'card.select') }}</template>
                        </div>

                        <input type="radio" v-if="!multiple" v-show="false" v-model="message.value" :value="option.optionValue">
                        <input type="checkbox" v-else-if="multiple" v-show="false" v-model="message.value" :value="option.optionValue">
                    </div>
                </div>
                <template v-if=" isLimited">
                    <div class="col" :class="{'col-xs-12 col-sm-6 col-md-4 col-lg-3' : message.fullscreen }">
                        <div class="card-other card-item" :class="{'card-item-multimedia': hasMultimedia, 'card-item-description': hasDescription, 'card-item-title': hasTitle, }" @click="onShowCards">
                            <div v-if="!hasMultimedia" class="card-content">
                                <Icon name="showall" size="18px" style="transform: translate(1px, 3px);" />
                            </div>
                            <div v-else class="card-other-container">
                                <Icon name="showall" class="card-other-button" size="18px" />
                                <div class="card-other-text">Mostra tutto</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="isCatalog" class="card-total" :class="{'no-limit': !limit }">
                {{ $translate(api.language, 'card.total') }} {{sum}} {{currency}}
            </div>

            <div class="form-action" :class="{'no-limit': !limit}">
                <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
            </div>
        </div>

        <!--<Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>-->

    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import InputMode from "@/classes/InputMode";
import Quantity from "@/components/chat/fields/mixins/Quantity";
import ButtonIcon from '../ButtonIcon';
import Icon from '../Icon';
import VideoLink, {PreviewLink} from "../../../classes/VideoLink";
import KioskKeyboard from "../../KioskKeyboard";

//import Suggested from "@/components/chat/fields/mixins/Suggested";

export default {
    name: "FieldCard",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Quantity,
        ButtonIcon,
        Icon,
        KioskKeyboard
        //Suggested

    },
    props: {
        limit: {
            type: Boolean,
            required: false,
            default: true
        },
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            filter: [],
            search: '',
            maxLength: 5,
            maxHeight: '',
            showKeyboard: false
        };
    },
    computed: {
        isLimited() {
          return this.limit && this.options.length > this.maxLength;
        },
        isObject() {
            return (this.message.fieldType === 'CatalogFieldType' || (this.message.fieldType !== 'CatalogFieldType' && this.multiple && this.max > 1));
        },
        isCatalog() {
            return (this.message.fieldType === 'CatalogFieldType');
        },
        hasMultimedia() {
          return (this.message.admissibleType === 'media' || this.message.admissibleType === 'price');
        },
        hasDescription() {
            return this.message.admissibleType === 'description';
        },
        hasTitle() {
            return this.message.admissibleType === 'title';
        },
        filteredOptions() {

            return this.options.filter((option) => {
                let found = true;

                if (this.search.length > 0) {
                    found = (option.optionText.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
                        || ((option.description || '').toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
                }

                if (found && this.filter.length > 0) {
                    found = this.filter.indexOf(option.category || '') !== -1;
                }

                return found;
            });
        },
        disabled() {
            if (this.isObject) {
                return (this.message.values.length == 0);
            } else {
                return (this.message.value === null);
            }
        },
        sum() {
            let sum = 0;
            this.message.values.map((option) => {
                if (option.quantity)
                    sum += option.quantity * option.price;
            });

            return sum.toFixed(3).slice(0,-1);
        },
        currency() {
          return this.options[0].currency;
        },
        categories() {
            let categories = [];
            this.message.options.map((option) => {
                if (option.category !== undefined && option.category.length > 0 && categories.indexOf(option.category) === -1)
                    categories.push(option.category);
            });

            return categories;
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },

    mounted() {
        if (this.message.enabled && this.message.fullscreen && this.options.length > 0) {
            this.$emit("onShowCards", this.message);
        }

        if (this.message.fullscreen && this.hasDescription) {
            this.calculateHeight();
            /*
            const self = this;
            this.$nextTick(() => {
                this.calculateHeight();
            });*/
        }
    },
    methods: {
        calculateHeight() {
            if (this.$refs.cards !== undefined) {
                let maxHeight = 0;

                const cards = this.$refs.cards.getElementsByClassName('card-item');
                for (const element of cards) {
                    if (element.clientHeight > maxHeight)
                        maxHeight = element.clientHeight;
                }

                this.maxHeight = maxHeight + 'px';
            }
        },
        parseLink: function(option) {
            let videoLink = option.media;

            if (option.mediaType === 'video') {
                const previewLink = PreviewLink(option.media);
                if (previewLink == null)
                    videoLink = VideoLink(option.media, false, false, true, false);
                else
                    videoLink = previewLink;
            }
            return videoLink;
        },
        isSelected: function (item) {
            let selected = false;

            if (this.isObject) {
                selected = this.message.values.includes(item);
            } else {
                selected = (this.message.value === item);
            }
            return selected;
        },
        isSuggested: function (item) {
            return this.message.suggestedOptions.findIndex(value => { return value.optionValue === item.optionValue }) !== -1;
        },
        isFiltered: function (category) {
            return this.filter.indexOf(category) !== -1;
        },
        isImageOrVideo(item) {
            return (item.mediaType || '' !== '');
        },
        isImage(item) {
            return (item.mediaType === 'img' || (item.mediaType === 'video' && PreviewLink(item.media) != null));
        },
        isVideo(item) {
            return (item.mediaType === 'video' && PreviewLink(item.media) == null);
        },
        onItemClicked: function (item, isMultimedia) {

            if (isMultimedia != this.hasMultimedia) {
                return;
            }

            if (!this.isObject) {
                if (this.max > 1 && this.options.length > 1) {

                    //solo per attivare scelta multipla con 1 elemento
                    if (this.message.value === item) {
                        this.message.value = null;
                    } else {
                        this.message.value = item;
                    }
                } else {
                    this.message.value = item;
                    this.onInputClicked();
                }
            } else {
                if (!this.isCatalog) {
                    if (this.message.values.includes(item)) {
                        const index = this.message.values.indexOf(item);
                        if (index !== -1) {
                            this.message.values.splice(index, 1);
                        }
                    } else {
                        if (this.message.values.length < this.max)
                            this.message.values.push(item);
                    }
                }
            }

        },
        onChangeQuantity: function (option) {
            const index = this.message.values.indexOf(option);

            if (option.quantity === 0) {
                if (index !== -1)
                    this.message.values.splice(index, 1);
            } else {
                if (index === -1)
                    this.message.values.push(option);
            }
            this.message.values = [...this.message.values];
        },

        onFilterCategory: function(category) {
            const index = this.filter.indexOf(category);
            if (index !== -1)
                this.filter.splice(index, 1);
            else
                this.filter.push(category);
        },
        onLinkClicked: function (option) {
            window.open(option.externalUrl, '_blank')
        },
        onShowCard(option) {
            this.$emit("onShowCard", option);
        },
        onShowCardMobile(option) {
            if (this.features.kiosk || this.features.device.mobile)
                this.$emit("onShowCard", option);
        },
        onShowCards() {
            this.$emit("onShowCards", this.message);
        },
        onInputClicked: function () {
            const self = this;
            let value = null;
            if (this.isObject) {
                value = {
                    inputMode: InputMode.CLICK,
                    optionObject: {
                        type: this.message.fieldType,
                        values: this.message.values
                    },
                    optionValue: this.message.values.map((option) => {
                        if (self.isCatalog)
                            return option.optionValue + ':' + option.quantity
                        else
                            return option.optionValue
                    }).join(', '),
                    optionText: this.message.values.map((option) => {
                        if (self.isCatalog)
                            return option.optionValue + ':' + option.quantity
                        else
                            return option.optionValue

                    }).join(', '),
                };

            } else {
                value = {
                    inputMode: InputMode.CLICK,
                    optionObject: {
                        type: this.message.fieldType,
                        values: [this.message.value]
                    },
                    optionValue: this.message.value.optionValue,
                    optionText: this.message.value.optionValue,
                };
            }
            this.$emit("onSendCommand", value);
        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand", value);
        },
        onKeyboardChange(input) {
            this.search = input;
        },

    }
}
</script>

<style lang="less">

.message-form {


    .card-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        //overflow: hidden; //rimosso per problema box-shadow elementi interni

        .cards {
            width: 100%;
            height: 100%;
            text-align: center;

            box-sizing: border-box;

            &.no-limit {
                max-height: unset;

                .card-item {
                    padding: 0 5px 0 5px;
                    &.card-item-multimedia {
                        width: 131px !important;
                        padding: 0 !important;
                    }

                    &.card-item-description {
                        width: calc(100% - 10px);
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
            }

            .card-item {
                position: relative;
                background-color: var(--bg-color-card);

                margin-top: 5px;
                margin-bottom: 5px;

                display: inline-block;
                vertical-align: top;

                border-radius: var(--border-radius);
                border: 3px solid transparent;
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);
                box-sizing: border-box;

                &.card-item-title {
                    min-width: 72px;
                    text-align: center;
                    cursor: pointer;

                    .card-title {
                        margin: 4px;
                        padding: 0px 8px 0 8px;
                        font-size: var(--font-12);
                        color: var(--fg-color);
                    }
                }

                &.card-item-description {
                    width: calc(100% - 10px);
                    margin-left: 5px;
                    margin-right: 5px;
                    padding: 8px;
                    cursor: pointer;

                    .card-title {
                        font-weight: 600;
                        font-size: var(--font-12);
                        line-height: calc(var(--font-12) + 3px);
                        color: var(--fg-color);
                    }

                    .card-description {
                        margin-top: 4px;
                        color: var(--fg-color);
                        font-size: var(--font-10);
                        line-height: calc(var(--font-10) + 3px);
                    }
                }

                &.card-item-multimedia {
                    width: 122px;
                    height: 210px;

                    .card-box-multimedia {
                        position: relative;

                        .card-info {
                            display: none;
                            position: absolute;
                            bottom: 6px;
                            right: 1px;
                        }

                        &:hover {
                            .card-info {
                                display: block;
                            }
                        }
                    }

                    .card-multimedia {
                        object-fit: cover;
                        align-content: center;
                        border-radius: calc(var(--border-radius) - 2px) calc(var(--border-radius) - 2px) 0 0;
                        border: 0;
                        width: 100%;
                        height: 74px !important;
                        background-color: #ffffff;
                    }

                    .card-content {
                        padding: 0 10px;
                    }

                    .card-title {
                        height: 14px;
                        white-space: nowrap;
                        color: var(--fg-color);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: left;
                        font-weight: 600;
                        font-size: var(--font-11);
                        line-height: calc(var(--font-11) + 3px);
                        padding: 0;
                        margin-bottom: 5px;
                        margin-left: 0;
                    }

                    .card-description {
                        width: 100%;
                        height: 36px;
                        margin: 0 0 5px 0;

                        color: var(--fg-color);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: left;
                        font-size: var(--font-10);
                        line-height: calc(var(--font-10) + 3px);
                        white-space: break-spaces;
                    }

                    .card-price {
                        font-size: 11px;
                        color: var(--fg-color);
                        text-align: right;
                    }

                    .card-external-link {
                        text-align: left;
                    }

                    .card-quantity {
                        position: absolute;
                        width: 100%;
                        bottom: 10px;
                    }

                    .card-selection {
                        display: block;
                        position: absolute;
                        color: var(--primary-color-text);
                        font-size: var(--font-12);
                        background-color: transparent;
                        border-top: 1px solid var(--border-other-color);
                        left: -3px;
                        right: -3px;
                        bottom: 0;
                        padding: 10px;
                        text-align: center;
                        cursor: pointer;
                    }
                }

                &.card-selected {
                    border: 3px solid var(--primary-color) !important;

                    .card-selection {
                        left: 0;
                        right: 0;
                    }
                }

                &.card-suggested {
                    border: 3px solid var(--suggested-color);
                }

            }

            .card-other {
                cursor: pointer;

                .card-other-container {
                    transform: translate(-50%, -50%);
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    .card-other-text {
                        color: var(--fg-color);
                        font-size: var(--font-12);
                        white-space: nowrap;
                    }
                }

                &:hover {
                    .card-other-container > .chat-icon, .card-content > .chat-icon {
                        color: var(--primary-color-text);
                    }
                }

            }

        }

        .cards-vertical {
            overflow-y: auto;
            overflow-x: hidden;

            &.cards-multimedia {
                .col {
                    width: unset;
                }
            }


            &.cards-description {
                .col {
                    display: block;
                }
            }

            .card-item {
                margin-right: 5px;
                margin-left: 5px;
            }
        }

        .cards-horizontal {
            white-space: nowrap;
            padding-bottom: 15px;
            overflow-x: auto;
            overflow-y: hidden;

            .col {
                width: unset;
            }

            .card-item {
                margin-right: 10px;
                margin-bottom: 0;
            }

            .col:last-child {
                .card-item {
                    margin-right: 0 !important;
                }
            }

        }

        .card-total {
            margin-top: 10px;
            color: var(--primary-color-text);
            font-size: var(--font-12);
            text-align: center;
            text-shadow: var(--text-shadow);
        }

        .card-categories {
            overflow-y: hidden;
            overflow-x: auto;
            width: 100%;
            text-align: center;
            padding-bottom: 15px;
            margin-top: 4px;
            white-space: nowrap;

            .field-category {
                display: inline-block;
                min-width: 80px;
                padding: 3px 10px;
                margin-right: 10px;
                //background-color: var(--bg-color-card);
                border-radius: var(--border-radius);
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);
                //border: 0;
                //color: var(--fg-color-text);
                font-size: var(--font-12);
                text-align: center;
                cursor: pointer;

                background-color: var(--bg-color);
                border: 2px solid var(--primary-color);
                color: var(--primary-color);
            }

            .category-filtered {
                background-color: var(--primary-color);
                color: var(--fg-color-text);
            }
        }

    }

    .card-filter {
        position: relative;
        margin-bottom: 8px;
        display: flex;
        width: 100%;
        height: 32px;

        .card-input-filter {
            & {
                color: var(--fg-color-input);
                width: 100%;
                height: 24px;
                padding: 3px 10px;
                border-radius: var(--border-radius);
                border: 1px solid var(--border-color-input);
                background: transparent;
                font-size: var(--font-12);
            }
            &:focus-within {
                //outline: 0;
                //box-shadow: none;
                //outline: 2px solid var(--focus-color-input);
            }

            &::placeholder {
                color: var(--border-color-input);
                //opacity: 0.3;
            }
        }

    }

    // Columns
    .col {
        display: inline-block;
    }

    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        float: left;
    }
    .col-xs-12 {
        width: 100%;
    }
    .col-xs-11 {
        width: 91.66666667%;
    }
    .col-xs-10 {
        width: 83.33333333%;
    }
    .col-xs-9 {
        width: 75%;
    }
    .col-xs-8 {
        width: 66.66666667%;
    }
    .col-xs-7 {
        width: 58.33333333%;
    }
    .col-xs-6 {
        width: 50%;
    }
    .col-xs-5 {
        width: 41.66666667%;
    }
    .col-xs-4 {
        width: 33.33333333%;
    }
    .col-xs-3 {
        width: 25%;
    }
    .col-xs-2 {
        width: 16.66666667%;
    }
    .col-xs-1 {
        width: 8.33333333%;
    }

    @media (min-width: 640px) { //768
        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: left;
        }
        .col-sm-12 {
            width: 100%;
        }
        .col-sm-11 {
            width: 91.66666667%;
        }
        .col-sm-10 {
            width: 83.33333333%;
        }
        .col-sm-9 {
            width: 75%;
        }
        .col-sm-8 {
            width: 66.66666667%;
        }
        .col-sm-7 {
            width: 58.33333333%;
        }
        .col-sm-6 {
            width: 50%;
        }
        .col-sm-5 {
            width: 41.66666667%;
        }
        .col-sm-4 {
            width: 33.33333333%;
        }
        .col-sm-3 {
            width: 25%;
        }
        .col-sm-2 {
            width: 16.66666667%;
        }
        .col-sm-1 {
            width: 8.33333333%;
        }
    }
    @media (min-width: 992px) {
        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-10,
        .col-md-11,
        .col-md-12 {
            float: left;
        }
        .col-md-12 {
            width: 100%;
        }
        .col-md-11 {
            width: 91.66666667%;
        }
        .col-md-10 {
            width: 83.33333333%;
        }
        .col-md-9 {
            width: 75%;
        }
        .col-md-8 {
            width: 66.66666667%;
        }
        .col-md-7 {
            width: 58.33333333%;
        }
        .col-md-6 {
            width: 50%;
        }
        .col-md-5 {
            width: 41.66666667%;
        }
        .col-md-4 {
            width: 33.33333333%;
        }
        .col-md-3 {
            width: 25%;
        }
        .col-md-2 {
            width: 16.66666667%;
        }
        .col-md-1 {
            width: 8.33333333%;
        }
    }

    @media (min-width: 1200px) {
        .col-lg-1,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12 {
            float: left;
        }
        .col-lg-12 {
            width: 100%;
        }
        .col-lg-11 {
            width: 91.66666667%;
        }
        .col-lg-10 {
            width: 83.33333333%;
        }
        .col-lg-9 {
            width: 75%;
        }
        .col-lg-8 {
            width: 66.66666667%;
        }
        .col-lg-7 {
            width: 58.33333333%;
        }
        .col-lg-6 {
            width: 50%;
        }
        .col-lg-5 {
            width: 41.66666667%;
        }
        .col-lg-4 {
            width: 33.33333333%;
        }
        .col-lg-3 {
            width: 25%;
        }
        .col-lg-2 {
            width: 16.66666667%;
        }
        .col-lg-1 {
            width: 8.33333333%;
        }
    }

    .box-keyboard {
        position: absolute;
        right: 8px;
        top: 6px;

        .icon-keyboard {
            color: var(--fg-button-color);
            cursor: pointer;

            &:hover {
                color: var(--primary-color);
            }
        }
    }
}

.kiosk {
    .card-filter {
        margin-top: 10px;
        width: calc(100% - 62px);
    }

}

</style>
