<template>
    <div v-if="message.enabled" class="message-form">
        <DatePicker
            :locale="$locale(this)"
            class="calendar"
            v-model="value"
            :min-date='minDate'
            :attributes="attr"
            :available-dates="dates"
            mode="date"
            @update:from-page="onPageChange"
        >
            <template v-slot:footer>
                <div v-if="loading" class="loader-calendar">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid">
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" opacity="0.3" >
                        </circle>
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" stroke-dasharray="10 50">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 10 10;360 10 10" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                </div>
            </template>
        </DatePicker>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>
</template>

<script>
import {endOfMonth, format} from 'date-fns'
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import DatePicker from "@/components/calendar/components/DatePicker";
import axios from "axios";


export default {
    name: "FieldBookingDate",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        DatePicker
    },
    data() {
        return {
            value: null,
            dates: [],
            loading: false
        };
    },
    computed: {
        disabled() {
            if (!this.multiple) {
                return (this.value === null);
            } else {
                return (this.value === null || this.value.length == 0);
            }
        },
        optionType() {
            return this.options.length > 0 ? 'AdmissibleValue' : null;
        },
        minDate() {
            return new Date();
        },
        attr() {
            const dates = [];
            this.suggested.map(function (item) {
                dates.push(new Date(item.optionValue));
            });
            return [
                {
                    dot: true,
                    dates: dates,
                    popover: {
                        label: 'Suggested',
                        visibility: 'hover'
                    }
                },

            ];
        },
        hasUrl() {
            return this.message.admissibleUrl !== undefined;
        }
    },
    mounted() {
        this.dates = this.getDates();
    },
    methods: {
        getDates() {
            return this.options.length > 0 ? this.options.map(function (item) {
                const part = item.optionText.split(' ');
                return {
                    start: new Date(part[0]),
                    end: new Date(part[0])
                };
            }) : null;
        },
        getNextUrlDates(start, stop) {

            const self = this;
            this.$log.debug("Get NextUrlDates", start, stop);

            const url = this.message.admissibleUrl
                .replace('{date}', format(start, 'yyyy-MM-dd'))
                .replace('{date_end}', format(stop, 'yyyy-MM-dd'));

            const config = {
                params: {
                    days_mode: '1'
                }
            };

            this.loading = true;

            return axios
                .get(url, config)
                .then(response => {
                    self.loading = false;
                    const json = response.data;
                    if (json.values !== undefined) {

                        self.dates = json.values.map(function (item) {
                            return {
                                start: new Date(item.name),
                                end: new Date(item.name)
                            };
                        });
                    }

                }).catch((error) => {
                    self.loading = false;
                    this.$log.error("Get NextUrlDates", error.message);
                    return null;
                });
        },
        onPageChange(view) {
            if (this.hasUrl) {
                const start = new Date(view.year, view.month - 1, 1);
                const stop = endOfMonth(start);
                this.getNextUrlDates(start, stop);
            }
        },
        onInputClicked: function () {
            const value = {
                inputMode: InputMode.CLICK,
                optionType: this.optionType,
                optionValue: format(this.value, "yyyy-MM-dd"),
                optionText: format(this.value, "dd/MM/yyyy"),
            };
            this.$emit("onSendCommand", value);
        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand", value);
        },
    }
}
</script>

<style>

</style>
