<template>
    <div v-if="!message.hidden" class="myself-message">
        <div class="myself-message-body">

            <div class="message-content">
                <div class="message-text">
                    <p ref="message-content" :class="{'message-content-partial': message.partial}" v-if="message.mediaType === 'text'" v-html="message.content"></p>
                </div>
                <div v-if="!message.partial" class="message-timestamp">
                    <template v-if="timestampConfig.relative">
                        {{message.timestamp.toRelative()}}
                    </template>
                    <template v-else>
                        {{message.timestamp.toFormat(timestampConfig.format)}}
                    </template>
                </div>
            </div>

            <div v-if="profilePictureConfig.myself" class="thumb-container">
                <img class="participant-thumb" alt="" :src="myself.profilePicture"
                     :style="{'width': profilePictureConfig.styles.width, 'height': profilePictureConfig.styles.height, 'border-radius': profilePictureConfig.styles.borderRadius}">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'MyMessage',
        components:{

        },
        props:{
            message: {
                type: Object,
                required: true
            },
            profilePictureConfig: {
                type: Object,
                required: true
            },
            timestampConfig: {
                type: Object,
                required: true
            },
            showPartecipant: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            ...mapGetters([
                'getParticipantById',
                'messages',
                'myself'
            ]),
        },
        methods: {

        }
    }
</script>

<style lang="less">
.container-message-display {

    .myself-message {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 16px;

        .myself-message-body {
            display: flex;
            position: relative;

            align-items: flex-end;
            padding-right: 10px;
            justify-content: flex-end;

            .message-content {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                flex-direction: column;
                flex-grow: 1;
            }

            .participant-thumb {
                /*
            width: 25px;
            height: 25px;
            border-radius: 50%;
           */
                margin-left: 10px;
                max-width: unset;
            }

            .message-text {
                border-radius: var(--border-radius) var(--border-radius-small) var(--border-radius) var(--border-radius);
                max-width: 75%;
                text-align: left;
                background: var(--bg-color-user);
                color: var(--fg-color-user);
                font-size: var(--font-12);
                line-height: calc(var(--font-12) + 3px);
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);

                & > p {
                    padding: 8px 42px 9px 14px;
                    margin: 0;
                }

                img {
                    padding-top: 5px;
                    max-width: 120px;
                    max-height: 120px;
                    object-fit: scale-down;
                }

                a, a:hover, a:visited {
                    color: var(--primary-color-text);
                }

                & > p.message-content-partial {
                    padding-right: 14px;
                    color: var(--primary-color-text);

                    &:after {
                        content: '...';
                    }
                }
            }

            .message-timestamp {
                position: absolute;
                width: auto;
                text-align: center;
                font-size: var(--font-9);
                color: var(--timestamp-color-user);
                right: 16px;
                top: 8px;
            }
        }
    }

    &.last-message {
        .myself-message {
            .myself-message-body {
                .message-text {
                    & > p {
                        padding-right: 14px;
                    }
                }
            }
        }
    }
}

</style>

