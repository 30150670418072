<template>
    <div v-if="message.enabled" class="message-form">

        <div class="form-field-bool-container">
            <div v-for="(option, index) in options" v-bind:key="index" class="form-field-bool" :class="{'option-suggested': isSuggested(option)}">
                <input type="radio" :id="'bool_' + index" v-model="value" :value="option.optionValue" @click="onItemClicked(option.optionValue)">
                <label :for="'bool_' + index">{{option.optionText}}</label>
            </div>
        </div>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import InputMode from "@/classes/InputMode";
export default {
    name: "FieldBool",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
    },
    data() {
        return {
            value: null,
        };
    },
    computed: {
        disabled() {
            return (this.value === null);
        },
    },
    methods: {
        onItemClicked(value) {
            this.value = value;
            this.onInputClicked();
        },
        onInputClicked() {
            const value = {
                inputMode: InputMode.CLICK,
                optionValue: this.value,
                optionText: this.value
            };
            this.$emit("onSendCommand", value);
        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand", value);
        },
        isSuggested: function (item) {
            return this.message.suggestedOptions.findIndex(value => { return value.optionValue === item.optionValue }) !== -1;
        },
    }
}
</script>

<style lang="less">

.form-field-bool-container{
    display: flex;
    text-align: center;
}

.form-field-bool {

    flex: 1;
    padding: 8px;

     input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        width: 75%;
        padding: 5px 12px 6px 12px;
        color: var(--fg-color);

        background-color: var(--bg-color-card);
        border-radius: var(--border-radius);
        border: 3px solid transparent;
        box-shadow: var(--box-shadow);
        backdrop-filter: var(--backdrop-filter);

        font-size: var(--font-12);
        line-height: calc(var(--font-12) + 3px);

        cursor: pointer;

    }

    input[type="radio"]:checked + label {
        border-color: var(--primary-color);
    }

    input[type="radio"]:focus + label {

    }

    &.option-suggested {
        input[type="radio"]:not(:checked) + label {
            border-color: var(--suggested-color);
        }
    }
}

</style>
