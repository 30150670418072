export default function VideoLink(url, controls, autoplay, loop, playlist, props) {
    const videoControls = controls ? '1': '0';
    const videoPlay = autoplay ? '1': '0';
    const videoLoop = loop ? '1': '0';

    let videoLink = url;

    if (videoLink.includes('youtu.be') || videoLink.includes('youtube.com')) {
        //https://developers.google.com/youtube/player_parameters
        let videoId = null;

        if (videoLink.includes('youtube.com')) {
            if (videoLink.includes("/embed/")) {
                const res = new URL(videoLink.trim());
                videoId = res.pathname.replace('/embed/', '');
            } else if (videoLink.includes("/shorts/")) {
                const res = new URL(videoLink.trim());
                videoId = res.pathname.replace('/shorts/', '');
            } else {
                const res = new URL(videoLink.trim());
                videoId = res.searchParams.get('v');
            }
            const videoPlaylist = playlist ? '&playlist=' + videoId : '';
            videoLink = 'https://www.youtube.com/embed/' + videoId + '?fs=0&playsinline=1&rel=1&modestbranding=1&autohide=1&controls=' + videoControls + '&autoplay=' + videoPlay + '&showinfo=0&loop=' + videoLoop + videoPlaylist;

        } else if (videoLink.includes('youtu.be')) {
            const res = new URL(videoLink.trim());
            videoId = res.pathname.replace('/', '');

            const videoPlaylist = playlist ? '&playlist=' + videoId : '';
            videoLink = 'https://www.youtube.com/embed/' + videoId + '?fs=0&playsinline=1&rel=1&modestbranding=1&autohide=1&controls=' + videoControls + '&autoplay=' + videoPlay + '&showinfo=0&loop=' + videoLoop + videoPlaylist;
        }

    } else if (videoLink.includes('vimeo.com')) {

        //https://developer.vimeo.com/player/sdk/embed
        let videoId = null;

        if (!videoLink.includes('/video/')) {
            const res = videoLink.trim().split('/');
            videoId = res[res.length - 1];
            videoLink = 'https://player.vimeo.com/video/' + videoId + '?playsinline=1&controls=' + videoControls + '&autoplay=' + videoPlay + '&loop=' + videoLoop;
        } else {
            const res = new URL(videoLink.trim());
            videoId = res.pathname.replace('/', '');
            videoLink = 'https://player.vimeo.com/video/' + videoId + '?playsinline=1&controls=' + videoControls + '&autoplay=' + videoPlay + '&loop=' + videoLoop;
        }

    } else {
        if (props !== undefined)
            props.sandbox = true;
    }
    return videoLink;
}

export function PreviewLink(url) {
    let previewLink = null;

    if (url.includes('youtu.be') || url.includes('youtube.com')) {
        let videoId = null;

        if (url.includes('youtube.com')) {
            if (url.includes("/embed/")) {
                const res = new URL(url.trim());
                videoId = res.pathname.replace('/embed/', '');
            } else if (url.includes("/shorts/")) {
                const res = new URL(url.trim());
                videoId = res.pathname.replace('/shorts/', '');
            } else {
                const res = new URL(url.trim());
                videoId = res.searchParams.get('v');
            }
            previewLink = 'https://img.youtube.com/vi/' + videoId + '/default.jpg';

        } else if (url.includes('youtu.be')) {
            const res = new URL(url.trim());
            videoId = res.pathname.replace('/', '');

            previewLink = 'https://img.youtube.com/vi/' + videoId + '/default.jpg';
        } else if (url.includes('vimeo.com')) {

        //https://developer.vimeo.com/player/sdk/embed
        let videoId = null;

        if (!url.includes('/video/')) {
            const res = url.trim().split('/');
            videoId = res[res.length - 1];
            previewLink = 'https://vumbnail.com/' + videoId + '_small.jpg';
        } else {
            const res = new URL(url.trim());
            videoId = res.pathname.replace('/', '');
            previewLink = 'https://vumbnail.com/' + videoId + '_small.jpg';
        }

    }

    }

    return previewLink;
}
