<template>
    <div class="container-webcam">
        <div v-show="plugin.loaded" ref="plugin">
        </div>
    </div>
</template>

<script>

import pluginComponent from "../../../classes/PluginComponent.js";

const StorageKeys = {
    WEBCAM: '_algho_webcam'
};

export default {
    name: "WebCam",
    components: {

    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data: function() {
        return {
            activated: false
        }
    },
    watch: {
        'plugin.loaded': {
            handler(newVal) {
                if (newVal) {
                    this.doPluginLoaded();
                }
            }
        },
        'features.webcam.activated': {
            handler(newVal) {
                if (this.plugin.loaded) {
                    if (!this.notAccepted) {
                        this.$log.debug('Webcam activate state', newVal);
                        this.plugin.enabled(newVal);
                        if (newVal)
                            this.$common.scrollToBottom();
                    } else {
                        if (newVal)
                            this.doRequestEmotionPermission();
                    }
                }

                window.sessionStorage.setItem(StorageKeys.WEBCAM, newVal ? '1': '0');
            },
            deep: true,
            immediate: false
        },
        'features.settings.webcamDebug': {
            handler(newVal) {
                if (this.plugin.loaded)
                    this.plugin.debug(newVal);

            },
            deep: true,
            immediate: false
        },
        'ready': {
            handler(newVal) {
               if (newVal) {
                   if (!this.features.device.mobile && this.isAccepted && this.isSavedStateOpen) {
                       if (this.plugin.loaded)
                           this.features.webcam.activated = true;
                       else
                            this.activated = true;

                   }
               }
            },
            immediate: true
        },
    },
    computed: {
        plugin() {
            return this.features.instance.plugins.webcam;
        },
        notAccepted() {
            return this.api.getPrivacyWebcam() == null;
        },
        isAccepted() {
            return this.api.getPrivacyWebcam() != null;
        },
        isSavedStateOpen() {
            return window.sessionStorage.getItem(StorageKeys.WEBCAM) === '1';
        },
        ready() {
            //determina se la chat è pronta per partire
            return (this.features.dhi ? this.features.status.loaded && this.features.status.loadedAvatar : this.features.status.loaded) && this.features.status.opened;
        },
    },
    beforeCreate() {
        this.app = this.$root.$children[0];
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        const config = {
            delay: 1000,
            init: this.api.getPrivacyWebcam() != null && window.sessionStorage.getItem(StorageKeys.WEBCAM) === '1',
            enabled: this.features.webcam.activated && this.features.has.webcam,
            debug: this.features.settings.webcamDebug,
            backend: this.features.webcam.backend
            //'model-emotion': 'mobileNet_16int', //old
        };
        if (this.features.kiosk) {
            config.delay = 1000;
            if (!this.api.bot.emotionalAnalysis)
                config.mode = 'kiosk';
        }

        //test webcam plugin
        if (this.plugin.src !== '')
            pluginComponent(this.plugin, this.$refs.plugin, config, this.features.instance);

    },
    beforeDestroy() {
        if (this.plugin.loaded)
            this.plugin.enabled(false);
    },
    destroyed() {
        this.$log.debug('Webcam destroyed');
    },
    methods: {
        doPluginLoaded() {
            this.$log.debug('Webcam plugin loaded and ready');
            this.plugin.recognize(this.app.onRecognizeWebcamData);

            if (this.activated) {
                //non dhi mode: fast
                this.$log.info('Automatic activate webcam');
                this.features.webcam.activated = true;
            }
        },
        doRequestEmotionPermission() {
            this.features.webcam.requested = true;
        },
    }
}
</script>

<style lang="less">

.container-webcam {
    padding: 0;
    margin: 10px 20px;
}

</style>
