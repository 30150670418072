import Vue from "vue";
export default function pluginComponent(plugin, container, options, algho) {

    if (plugin.src.toLowerCase().indexOf('http') === -1)
        plugin.src = algho.baseUrl + plugin.src + '?v=' + algho.appVer;

    const tag = document.createElement(plugin.tag);

    Object.defineProperty(tag, 'algho', {
        value: algho
    });

    Object.keys(options).map((key) => {
        tag.setAttribute(key, options[key]);
    });

    container.appendChild(tag);

    if (window.customElements.get(plugin.tag) === undefined) {
        const script = document.createElement('script');
        script.async = true;
        script.addEventListener('load', () => {
            Vue.$log.info(`Plugin ${plugin.tag} loaded ${plugin.src}`);
        });
        script.addEventListener('error', () => {
            Vue.$log.error(`Error loading ${plugin.src}`);
        });
        script.src = plugin.src;
        container.appendChild(script);
    }
}
