import * as Three from 'three';

export default {
    /*
    // Vertex Shader
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
         }

    //Fragment Shader
        uniform sampler2D tDiffuse;
        varying vec2 vUv;
        void main() {
            gl_FragColor = texture2D( tDiffuse, vUv );
         }

     */
    FxAAA: {
        uniforms: { tDiffuse: { value: null }, resolution: { value: new Three.Vector2(1 / 1024, 1 / 512) } },
        vertexShader:
            `
            void main() { 
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
             }
             `,
        fragmentShader: `
            uniform sampler2D tDiffuse;
            uniform vec2 resolution;
            
            #ifndef FXAA_REDUCE_MIN
                #define FXAA_REDUCE_MIN   (1.0/ 128.0)
            #endif
            #ifndef FXAA_REDUCE_MUL
                #define FXAA_REDUCE_MUL   (1.0 / 8.0)
            #endif
            #ifndef FXAA_SPAN_MAX
                #define FXAA_SPAN_MAX     8.0
            #endif
            
            vec4 fxaa(sampler2D tex, vec2 fragCoord, vec2 resolution,
                        vec2 v_rgbNW, vec2 v_rgbNE, 
                        vec2 v_rgbSW, vec2 v_rgbSE, 
                        vec2 v_rgbM) {
                vec4 color;
                vec3 rgbNW = texture2D(tex, v_rgbNW).xyz;
                vec3 rgbNE = texture2D(tex, v_rgbNE).xyz;
                vec3 rgbSW = texture2D(tex, v_rgbSW).xyz;
                vec3 rgbSE = texture2D(tex, v_rgbSE).xyz;
                vec4 texColor = texture2D(tex, v_rgbM);
                vec3 rgbM  = texColor.xyz;
                vec3 luma = vec3(0.299, 0.587, 0.114);
                float lumaNW = dot(rgbNW, luma);
                float lumaNE = dot(rgbNE, luma);
                float lumaSW = dot(rgbSW, luma);
                float lumaSE = dot(rgbSE, luma);
                float lumaM  = dot(rgbM,  luma);
                float lumaMin = min(lumaM, min(min(lumaNW, lumaNE), min(lumaSW, lumaSE)));
                float lumaMax = max(lumaM, max(max(lumaNW, lumaNE), max(lumaSW, lumaSE)));
                
                vec2 dir;
                dir.x = -((lumaNW + lumaNE) - (lumaSW + lumaSE));
                dir.y =  ((lumaNW + lumaSW) - (lumaNE + lumaSE));
                
                float dirReduce = max((lumaNW + lumaNE + lumaSW + lumaSE) *
                                      (0.25 * FXAA_REDUCE_MUL), FXAA_REDUCE_MIN);
                
                float rcpDirMin = 1.0 / (min(abs(dir.x), abs(dir.y)) + dirReduce);
                dir = min(vec2(FXAA_SPAN_MAX, FXAA_SPAN_MAX),
                          max(vec2(-FXAA_SPAN_MAX, -FXAA_SPAN_MAX),
                          dir * rcpDirMin)) * resolution;
                
                vec3 rgbA = 0.5 * (
                    texture2D(tex, fragCoord * resolution + dir * (1.0 / 3.0 - 0.5)).xyz +
                    texture2D(tex, fragCoord * resolution + dir * (2.0 / 3.0 - 0.5)).xyz);
                vec3 rgbB = rgbA * 0.5 + 0.25 * (
                    texture2D(tex, fragCoord * resolution + dir * -0.5).xyz +
                    texture2D(tex, fragCoord * resolution + dir * 0.5).xyz);
            
                float lumaB = dot(rgbB, luma);
                if ((lumaB < lumaMin) || (lumaB > lumaMax))
                    color = vec4(rgbA, texColor.a);
                else
                    color = vec4(rgbB, texColor.a);
                return color;
            }
                        
            void texcoords(vec2 fragCoord, vec2 resolution,
                out vec2 v_rgbNW, out vec2 v_rgbNE,
                out vec2 v_rgbSW, out vec2 v_rgbSE,
                out vec2 v_rgbM) {
                v_rgbNW = (fragCoord + vec2(-1.0, -1.0)) * resolution;
                v_rgbNE = (fragCoord + vec2(1.0, -1.0)) * resolution;
                v_rgbSW = (fragCoord + vec2(-1.0, 1.0)) * resolution;
                v_rgbSE = (fragCoord + vec2(1.0, 1.0)) * resolution;
                v_rgbM = vec2(fragCoord * resolution);
            }
            
            void main() {
                vec2 v_rgbNW;
                vec2 v_rgbNE;
                vec2 v_rgbSW;
                vec2 v_rgbSE;
                vec2 v_rgbM;
                
                //compute the texture coords
                texcoords(gl_FragCoord.xy, resolution, v_rgbNW, v_rgbNE, v_rgbSW, v_rgbSE, v_rgbM);
                
                //compute FXAA
                gl_FragColor = fxaa(tDiffuse, gl_FragCoord.xy, resolution, v_rgbNW, v_rgbNE, v_rgbSW, v_rgbSE, v_rgbM);
            }
            `
    },
    ChromaticAberration: {
        uniforms: {
            tDiffuse: {type: "t", value: null},
            fAmount: {type: "f", value: 1},
            amount: {type: "f", value: .005},
            angle: {type: "f", value: 0.0},
        },
        vertexShader: `
            varying vec2 vUv;
             void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
             }
             `,
        fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float amount;
        uniform float angle;
        varying vec2 vUv;
        void main() {
            vec2 offset = amount * ( length( vUv - 0.5 ) + 0.01 ) * vec2( cos( angle ), sin( angle ) );
            vec4 cr = texture2D( tDiffuse, vUv + offset );
            vec4 cga = texture2D( tDiffuse, vUv );
            vec4 cb = texture2D( tDiffuse, vUv - offset );
            gl_FragColor = vec4( cr.r, cga.g, cb.b, cga.a );
        }
        `
    },
    Bokeh: {
        /**
         * Depth-of-field shader with bokeh
         * ported from GLSL shader by Martins Upitis
         * http://artmartinsh.blogspot.com/2010/02/glsl-lens-blur-filter-with-bokeh.html
         */
        defines: {
            'DEPTH_PACKING': 1,
            'PERSPECTIVE_CAMERA': 1,
        },

        uniforms: {

            'tDiffuse': { value: null },
            'focus': { value: 1.0 },
            'aspect': { value: 1.0 },
            'aperture': { value: 0.025 },
            'maxblur': { value: 0.01 },
            'nearClip': { value: 1.0 },
            'farClip': { value: 1000.0 },

        },

        vertexShader: /* glsl */`

		varying vec2 vUv;

		void main() {

			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

        fragmentShader: /* glsl */`

		#include <common>

		varying vec2 vUv;

		uniform sampler2D tDiffuse;

		uniform float maxblur; // max blur amount
		uniform float aperture; // aperture - bigger values for shallower depth of field

		uniform float nearClip;
		uniform float farClip;

		uniform float focus;
		uniform float aspect;

		#include <packing>

		float getDepth( const in vec2 screenPosition ) {
			#if DEPTH_PACKING == 1
			return unpackRGBAToDepth( texture2D( tDiffuse, screenPosition ) );
			#else
			return texture2D( tDiffuse, screenPosition ).x;
			#endif
		}

		float getViewZ( const in float depth ) {
			#if PERSPECTIVE_CAMERA == 1
			return perspectiveDepthToViewZ( depth, nearClip, farClip );
			#else
			return orthographicDepthToViewZ( depth, nearClip, farClip );
			#endif
		}


		void main() {

			vec2 aspectcorrect = vec2( 1.0, aspect );

			float viewZ = getViewZ( getDepth( vUv ) );

			float factor = ( focus + viewZ ); // viewZ is <= 0, so this is a difference equation

			vec2 dofblur = vec2 ( clamp( factor * aperture, -maxblur, maxblur ) );

			vec2 dofblur9 = dofblur * 0.9;
			vec2 dofblur7 = dofblur * 0.7;
			vec2 dofblur4 = dofblur * 0.4;

			vec4 col = vec4( 0.0 );

			col += texture2D( tDiffuse, vUv.xy );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.0,   0.4  ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.15,  0.37 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.29,  0.29 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.37,  0.15 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.40,  0.0  ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.37, -0.15 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.29, -0.29 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.15, -0.37 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.0,  -0.4  ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.15,  0.37 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.29,  0.29 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.37,  0.15 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.4,   0.0  ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.37, -0.15 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.29, -0.29 ) * aspectcorrect ) * dofblur );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.15, -0.37 ) * aspectcorrect ) * dofblur );

			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.15,  0.37 ) * aspectcorrect ) * dofblur9 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.37,  0.15 ) * aspectcorrect ) * dofblur9 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.37, -0.15 ) * aspectcorrect ) * dofblur9 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.15, -0.37 ) * aspectcorrect ) * dofblur9 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.15,  0.37 ) * aspectcorrect ) * dofblur9 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.37,  0.15 ) * aspectcorrect ) * dofblur9 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.37, -0.15 ) * aspectcorrect ) * dofblur9 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.15, -0.37 ) * aspectcorrect ) * dofblur9 );

			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.29,  0.29 ) * aspectcorrect ) * dofblur7 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.40,  0.0  ) * aspectcorrect ) * dofblur7 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.29, -0.29 ) * aspectcorrect ) * dofblur7 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.0,  -0.4  ) * aspectcorrect ) * dofblur7 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.29,  0.29 ) * aspectcorrect ) * dofblur7 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.4,   0.0  ) * aspectcorrect ) * dofblur7 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.29, -0.29 ) * aspectcorrect ) * dofblur7 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.0,   0.4  ) * aspectcorrect ) * dofblur7 );

			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.29,  0.29 ) * aspectcorrect ) * dofblur4 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.4,   0.0  ) * aspectcorrect ) * dofblur4 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.29, -0.29 ) * aspectcorrect ) * dofblur4 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.0,  -0.4  ) * aspectcorrect ) * dofblur4 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.29,  0.29 ) * aspectcorrect ) * dofblur4 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.4,   0.0  ) * aspectcorrect ) * dofblur4 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2( -0.29, -0.29 ) * aspectcorrect ) * dofblur4 );
			col += texture2D( tDiffuse, vUv.xy + ( vec2(  0.0,   0.4  ) * aspectcorrect ) * dofblur4 );

			gl_FragColor = col / 41.0;
			gl_FragColor.a = texture2D(tDiffuse, vUv).a; //1.0;
			
		}`

    },
    Bleach: {
        /**
         * Bleach bypass shader [http://en.wikipedia.org/wiki/Bleach_bypass]
         * - based on Nvidia example
         * http://developer.download.nvidia.com/shaderlibrary/webpages/shader_library.html#post_bleach_bypass
         */
        uniforms: {
            tDiffuse: {type: "t", value: null},
            opacity: {type: "f", value: 1.0}
        },
        vertexShader: `
            varying vec2 vUv;
             void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
             }
             `,
        fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float opacity;
        varying vec2 vUv;
        
        void main() {
        
            vec4 base = texture2D( tDiffuse, vUv );
			vec3 lumCoeff = vec3( 0.25, 0.65, 0.1 );
			float lum = dot( lumCoeff, base.rgb );
			vec3 blend = vec3( lum );
			float L = min( 1.0, max( 0.0, 10.0 * ( lum - 0.45 ) ) );
			vec3 result1 = 2.0 * base.rgb * blend;
			vec3 result2 = 1.0 - 2.0 * ( 1.0 - blend ) * ( 1.0 - base.rgb );
			vec3 newColor = mix( result1, result2, L );
			float A2 = opacity * base.a;
			vec3 mixRGB = A2 * newColor.rgb;
			mixRGB += ( ( 1.0 - A2 ) * base.rgb );
			gl_FragColor = vec4( mixRGB, base.a );			
        }
        `,
    },
    Custom: {
        uniforms: {
            tDiffuse: {type: "t", value: null},
            resolution: {type: "v2", value: new Three.Vector2()},
            time: { value: 0.0 },
        },
        defines: {
            'VIGNETTE': 0,
            'SNOW': 1
        },
        vertexShader: `
            varying vec2 vUv;
             void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
             }
             `,
        fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform vec2 resolution;
        uniform float time;

        varying vec2 vUv;
        
        #if SNOW == 1
        #define pi 3.1415926
        
        // iq's hash function from https://www.shadertoy.com/view/MslGD8
        vec2 hash( vec2 p ) { p=vec2(dot(p,vec2(127.1,311.7)),dot(p,vec2(269.5,183.3))); return fract(sin(p)*18.5453); }
        
        float simplegridnoise(vec2 v)
        {
            float s = 1. / 256.;
            vec2 fl = floor(v), fr = fract(v);
            float mindist = 1e9;
            for(int y = -1; y <= 1; y++)
                for(int x = -1; x <= 1; x++)
                {
                    vec2 offset = vec2(x, y);
                    vec2 pos = .5 + .5 * cos(2. * pi * (time *.1 + hash(fl+offset)) + vec2(0,1.6));
                    mindist = min(mindist, length(pos+offset -fr));
                }
            
            return mindist;
        }
        
        float blobnoise(vec2 v, float s)
        {
            return pow(.5 + .5 * cos(pi * clamp(simplegridnoise(v)*2., 0., 1.)), s);
        }
        
        float fractalblobnoise(vec2 v, float s)
        {
            float val = 0.;
            const float n = 4.;
            for(float i = 0.; i < n; i++)
                val += pow(0.5, i+1.) * blobnoise(exp2(i) * v + vec2(0, time), s);
        
            return val;
        }
       #endif 
        
        void main() {
            vec2 uv = gl_FragCoord.xy / resolution.xy;
            gl_FragColor = texture2D(tDiffuse, vUv);
                    
            #if VIGNETTE == 1
			uv *=  1.0 - uv.yx;
            float vig = uv.x*uv.y * 15.0; // multiply with sth for intensity
            vig = pow(vig, 0.25); // change pow for modifying the extend of the  vignette
            gl_FragColor = vec4(gl_FragColor * vig);
			#endif    
			
            #if SNOW == 1
            vec2 r = vec2(1.0, resolution.y / resolution.x);
            float val = fractalblobnoise(r * uv * 20.0, 5.0);
            if (gl_FragColor.a > 0.0)
                val = 0.0;
            
            gl_FragColor = gl_FragColor + val;  //mix(gl_FragColor, vec4(1.0), vec4(val));   
            #endif  
        }
        `,
    }

};
