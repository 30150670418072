<template>
    <div v-if="message.enabled" class="message-form">

        <div class="form-field">
            <label class="file-upload">
                <div class="box-file">
                    <Icon name="select-file" class="icon-file" size="20px" :content="$translate(api.language, 'file.upload')" v-tippy="{ placement : 'left',  appendTo: container }" />
                </div>
                <input type="file" ref="file" accept="image/jpeg, image/png, application/pdf" @change="handleFileUpload"/>
                {{ name }}
            </label>
        </div>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>

</template>

<script>
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import Icon from "../Icon";

export default {
    name: "FieldFile",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        Icon
    },
    data() {
        return {
            value: null,
            name: ''
        };
    },
    computed: {
        disabled() {
            return (this.value === null);
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        handleFileUpload() {
            if (this.$refs.file.files.length > 0) {
                this.name =  this.$refs.file.files[0].name;
                this.value = '';
            } else {
                this.name = '';
                this.value = null;
            }
        },
        onInputClicked() {
            const self = this;
            const file = this.$refs.file.files[0];
            this.api.uploadFile(file).then((data) => {
                self.sendFile(data);
            });
        },
        sendFile(url) {
            let html = '';
            const ext = url.split('.').pop().toLowerCase();
            if (ext === 'jpeg' || ext === 'jpg' || ext === 'png')
                html = '<img src="' + url + '">';
            else
                html = '<a href="' + url + '" target="_blank">' + this.name + '</a>';

            const value = {
                inputMode: InputMode.KEYBOARD,
                optionValue: url,
                optionText: html,
            };

            this.message.enabled = false;
            this.onSendCommand(value);
        },
        onSendCommand(value) {
            this.$emit('onSendCommand', value);
        },
    }
}
</script>

<style lang="less">

.message-form {
    .form-field {
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        width: 100%;

        & > .file-upload {
            position: relative;
            color: var(--primary-color);
            width: 100%;
            height: 36px;
            line-height: 36px;
            padding: 0 50px 0 10px;
            border-radius: var(--border-radius);
            border: 1px solid var(--border-other-color);
            background-color: var(--bg-color-card);
            font-size: var(--font-12);
            box-shadow: var(--box-shadow);
            backdrop-filter: var(--backdrop-filter);
            text-align: left;
            cursor: pointer;

            input[type=file] {
                display: none;
            }
        }

        .box-file {
            position: absolute;
            right: 10px;
            top: 6px;

            .icon-file {
                color: var(--fg-button-color);
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }

    }
}

</style>
