<template>
    <Panel :features="features" @onClose="onClose">
        <div slot="body">
            <div class="emotion-content" v-html="emotionMessage"></div>
        </div>
    </Panel>
</template>

<script>
import Panel from "./Panel.vue";

export default {
    name: "EmotionPanel",
    components: {
        Panel
    },
    props: {
        features: {
            type: Object,
            required: true
        },
    },
    data() {
        return {

        }
    },
    computed: {
        emotionMessage() {
            return this.api.bot.emotionalModuleDescription || '';
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.emotion-content {
    color: var(--fg-color);
    text-align: left;
    font-size: var(--font-11);
    line-height: calc(var(--font-11) + 3px);

    ul {
        list-style-type: disc !important;
        padding-left: 12px !important;
    }

    li {
        display: list-item !important;
    }

    p {
        * {
            background-color: unset !important;
            color: unset !important;
            font-size: unset !important;
        }

        a {
            color: var(--primary-color-text);
        }

        h1, h2, h3, p {
            margin-top: 0;
        }
    }

}

</style>
