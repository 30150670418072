<template>
    <Panel :features="features" @onClose="onClose">
        <div slot="body" class="privacy-body">
            <div ref="html" class="privacy-content" v-html="html"></div>
        </div>
    </Panel>
</template>

<script>
import Panel from "./Panel.vue";
import axios from "axios";

export default {
    name: "PrivacyPanel",
    components: {
        Panel
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        anchor: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            html: null
        }
    },
    computed: {

    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        this.getPrivacyContent();
    },
    watch: {
        'html': {
            handler() {
                const self = this;
                self.$nextTick(() => {
                    const element = self.$refs.html.querySelector('[role="' + self.anchor + '"]');
                    if (element != null)
                        self.$refs.html.closest('.scrollable').scrollTop = element.offsetTop;

                    if (self.features.kiosk) {
                        const links = self.$refs.html.querySelectorAll("a");
                        links.forEach(function (link) {
                            link.style.pointerEvents = 'none';
                        });
                    }
                });
            },
        },
    },
    methods: {
        getPrivacyContent() {
            const url = this.api.bot.privacyUrl;
            const self = this;
            axios.get(url).then(response => {
                const html = document.createElement('html');
                html.innerHTML = response.data;
                const div = document.createElement('div');
                Array.from(html.getElementsByClassName("SECTION")).forEach(function(item) {
                    div.append(item);
                });

                self.html = div.innerHTML;

            })
        },
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.privacy-content {
    color: var(--fg-color);
    text-align: left;
    font-size: var(--font-11);
    line-height: calc(var(--font-11) + 3px);

    a {
        color: var(--primary-color-text);
    }

    h1, h2, h3, p {
        margin-top: 0;
    }

    h2 {
        font-size: var(--font-14);
        line-height: calc(var(--font-14) + 3px);
    }
}

.kiosk {
    .privacy-body {
        margin-top: 32px;
    }
}

</style>
