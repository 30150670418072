import { render, staticRenderFns } from "./KioskWelcomeVideo.vue?vue&type=template&id=0a6db8b7&"
import script from "./KioskWelcomeVideo.vue?vue&type=script&lang=js&"
export * from "./KioskWelcomeVideo.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./KioskWelcomeVideo.vue?vue&type=style&index=0&lang=less&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports