<template>
    <div v-if="message.enabled" class="message-form">
        <DatePicker
            :locale="$locale(this)"
            class="calendar"
            v-model="value"
            :available-dates="dates"
            :mode="mode"
            @update:from-page="onPageChange"
            is24hr
        >
            <template v-slot:footer>
                <div v-if="loading" class="loader-calendar">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="20px" height="20px" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid">
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" opacity="0.3" >
                        </circle>
                        <circle cx="10" cy="10" fill="none" stroke="currentColor" stroke-width="2" r="9" stroke-dasharray="10 50">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 10 10;360 10 10" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                </div>
                <div v-if="!loading && hours && hours.length > 0" class="picker-time">
                    <ul class="time-list scrollable scrollable-primary scrollable-y">
                        <li v-for="(item, index) in hours" :key="index" class="time-item" :class="{'selected': time===item}" @click="onTimeClicked(item)">
                            <span class="time-text">{{ pad(item.hours) }}:{{ pad(item.minutes) }}</span>
                        </li>
                    </ul>
                </div>
            </template>
        </DatePicker>

        <Suggested :message="message" @onSendCommand="onSendCommand"></Suggested>

        <div class="form-action">
            <CommandMixin :message="message" :disable="disabled" @onInputClicked="onInputClicked" @onSendCommand="onSendCommand"/>
        </div>
    </div>
</template>

<script>
import {endOfMonth, format} from 'date-fns';
import CommandMixin from "@/components/chat/fields/mixins/Option";
import Suggested from "@/components/chat/fields/mixins/Suggested";
import InputMode from "@/classes/InputMode";
import DatePicker from "@/components/calendar/components/DatePicker";
import axios from "axios";

export default {
    name: "FieldDateTime",
    mixins: [CommandMixin],
    components: {
        CommandMixin,
        Suggested,
        DatePicker
    },
    data() {
        return {
            value: null,
            time: null,
            hours: null,
            dates: [],
            loading: false
        };
    },
    watch: {
        value: {
            handler(newVal) {

                if (newVal) {
                    if (this.hasUrl) {
                        this.time = null;
                        this.getNextUrlDates(newVal, newVal, false);
                    } else {
                        if (this.options.length > 0) {
                            this.time = null;
                            const date = format(newVal, "yyyy-MM-dd");
                            this.hours = this.options.filter(function (item) {
                                return (item.optionText.indexOf(date) === 0);
                            }).map(function (item) {
                                const time = new Date(item.optionText.replace(' ', 'T'));
                                return {
                                    hours: time.getHours(),
                                    minutes: time.getMinutes()
                                }
                            });
                            this.$common.scrollToBottom();
                        }
                    }
                } else {
                    this.hours = null;
                }
            }
        },
    },
    computed: {
        disabled() {
            if (this.dates != null) {
                return (this.value === null || this.time === null);
            } else {
                if (!this.multiple) {
                    return (this.value === null);
                } else {
                    return (this.value === null || this.value.length === 0);
                }
            }
        },
        optionType() {
            return this.options.length > 0 ? 'AdmissibleValue' : null;
        },
        mode() {
            return (this.dates != null) ? 'date' : 'dateTime';
        },
        hasUrl() {
            return this.message.admissibleUrl !== undefined;
        }
    },
    mounted() {
        this.dates = this.getDates();
    },
    methods: {
        pad: function (n) {
            return (n < 10) ? ('0' + n) : n;
        },
        onTimeClicked: function(time) {
          this.time = time;
        },
        onInputClicked: function () {

            const newValue = new Date(this.value);
            if (this.dates != null && this.time != null) {
                newValue.setHours(this.time.hours, this.time.minutes,0);
            }
            const value = {
                inputMode: InputMode.CLICK,
                optionType: this.optionType,
                optionValue: format(newValue, "yyyy-MM-dd HH:mm"),
                optionText: format(newValue, "dd/MM/yyyy HH:mm"),
            };

            this.$emit("onSendCommand", value);
        },
        onSendCommand: function (value) {
            this.$emit("onSendCommand",value);
        },
        onPageChange(view) {
            if (this.hasUrl) {
                const start = new Date(view.year, view.month - 1, 1);
                const stop = endOfMonth(start);
                this.getNextUrlDates(start, stop, true);
            }
        },
        getDates() {
            return this.options.length > 0 ? this.options.map(function (item) {
                const part = item.optionText.split(' ');
                return {
                    start: new Date(part[0]),
                    end: new Date(part[0])
                };
            }) : null;
        },
        getNextUrlDates(start, stop, days) {

            const self = this;
            this.$log.debug("Get NextUrlDates", start, stop);

            const url = this.message.admissibleUrl
                .replace('{date}', format(start, 'yyyy-MM-dd'))
                .replace('{date_end}', format(stop, 'yyyy-MM-dd'));

            const config = {
                params: {
                    days_mode: days ? '1' : '0'
                }
            };

            this.hours = null;
            this.loading = true;

            return axios
                .get(url, config)
                .then(response => {
                    self.loading = false;
                    const json = response.data;
                    if (json.values !== undefined) {

                        if (days) {
                            self.dates = json.values.map(function (item) {
                                return {
                                    start: new Date(item.name),
                                    end: new Date(item.name)
                                };
                            });
                        } else {
                            self.hours = json.values.map(function (item) {
                                const time = new Date(format(start, 'yyyy/MM/dd') + ' ' + item.name + ':00');
                                return {
                                    hours: time.getHours(),
                                    minutes: time.getMinutes()
                                }
                            });
                        }
                    }

                }).catch((error) => {
                    self.loading = false;
                    this.$log.error("Get NextUrlDates", error.message);
                    return null;
                });
        },
    }
}
</script>

<style>

</style>
