export default {

    install(Vue) {
        let trackEventFn  = null;

        Vue.$track = Vue.prototype.$track = {
            event(name) {
                if (typeof trackEventFn === 'function') {
                    Vue.$log.log("Track event", name);
                    trackEventFn(name);
                }
                },
            init(callback) {
                if (typeof callback === 'function')
                    trackEventFn = callback;
            }
        }
    }
}
