<template>
    <Panel :features="features" :border="false" @onClose="onClose">
        <div slot="powered"></div>
        <div slot="body" class="card-panel-body">

            <img v-if="option.mediaType === 'img'" class="card-multimedia" alt="Image" :src="parseLink(option)" onerror="this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='">
            <iframe v-if="option.mediaType === 'video'" class="card-multimedia" :src="parseLink(option)"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    sandbox="allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                    title="Video"/>

            <div class="card-content scrollable scrollable-y">
                <div class="card-title">{{ option.optionText }}</div>
                <div v-if="option.description" class="card-description scrollable scrollable-y">{{ option.description }}</div>

                <div class="card-flex">
                    <div>
                        <div v-if="option.externalUrl" class="card-external-link">
                            <ButtonIcon name="link" class="margin-right" @click.stop.prevent="onLinkClicked(option)"/>
                        </div>
                    </div>
                    <div>
                        <div v-if="option.price" class="card-price">{{ option.price }} {{ option.currency }}</div>
                    </div>
                </div>
            </div>



        </div>
    </Panel>
</template>

<script>
import Panel from "./Panel.vue";
import ButtonIcon from "../chat/ButtonIcon.vue";
import VideoLink from "../../classes/VideoLink";

export default {
    name: "CardPanel",
    components: {
        Panel,
        ButtonIcon
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        option: {
            type: Object,
            required: true
        },
    },
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        parseLink: function(option) {
            let videoLink = option.media;

            if (option.mediaType === 'video') {
                videoLink = VideoLink(option.media, true, false, true, false);
            }
            return videoLink;
        },
        onLinkClicked: function (option) {
            window.open(option.externalUrl, '_blank')
        },
        onClose() {
            this.$emit("onClose");
        },
    }
}
</script>

<style lang="less">

.chat-panel-window {

    .card-panel-body {
        height: 100%;
        display: flex;
        flex-flow: column;


        .card-multimedia {
            object-fit: cover;
            border: 0;
            width: 100%;
            height: 200px;
            background-color: white;
            border-radius: var(--border-radius-frame) var(--border-radius-frame) 0 0;
            margin-bottom: 10px;
        }

        .card-content {
            padding: 0 20px;
            height: 100%;
            display: flex;
            flex-flow: column;


            .card-title {
                margin-bottom: 20px;
                text-align: left;
                font-weight: 600;
                color: var(--fg-color);
                font-size: 15px;
                white-space: break-spaces;
                padding: 0;
            }

            .card-description {
                width: 100%;
                max-height: 100%;
                margin-bottom: 20px;

                color: var(--fg-color);
                font-size: 12px;
                white-space: break-spaces;
            }

            .card-price {
                color: var(--fg-color);
                text-align: right;
                font-size: 18px;
            }

            .card-external-link {
                text-align: left;
            }

            .card-quantity {
                display: flex;
            }

            .card-flex {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                & > div {
                    width: 50%;
                }
            }

        }

    }

}

.kiosk {

    .card-panel-body {
        .card-multimedia {
            height: 300px;
            border-radius: unset;
        }

        .card-content {
            padding-bottom: 54px;
        }
    }
}

</style>
