import * as Three from 'three'

export default class CustomBackgroundPlane extends Three.Mesh{
    constructor(options){
        super(
            new Three.PlaneBufferGeometry(2,2,1,1),
            new Three.ShaderMaterial({
                defines: {
                    USE_LUT: 0,
                },
                uniforms:{
                    uvTransform: { value: options.texture.matrix },
                    diffuse: { value: options.texture },
                    gamma: { value: options.gamma },
                    enabled: { value: options.enabled },

                    lut3d: { value: null },
                    lutSize: { value: 0.0 },
                    lutIntensity: { value: 1.0 }
                },
                vertexShader: `
                    varying vec2 vUv;
                    uniform mat3 uvTransform;

                    void main(){
                        vUv = ( uvTransform * vec3( uv, 1 ) ).xy;
                        gl_Position = vec4( position.xy, 1.0, 1.0 ); 
                        //gl_Position = projectionMatrix * viewMatrix * modelMatrix  * gl_Position; //test
                    }
                `,

                fragmentShader:`
                    varying vec2 vUv;
                    uniform sampler2D diffuse;
                    uniform bool enabled;
                    uniform float gamma;
                    
                    #if USE_LUT
                    uniform float lutIntensity;
                    uniform float lutSize;
                    precision highp sampler3D;
                    uniform sampler3D lut3d;
                    #endif
				
				    vec4 CustomLinearToGamma( in vec4 value, in float gammaFactor ) {
                        return vec4( pow( value.xyz, vec3( 1.0 / gammaFactor ) ), value.w );
                    }
                    
                    void main(){
                        gl_FragColor = texture2D( diffuse, vUv );
                        
                        //gl_FragColor = LinearTosRGB( gl_FragColor );
                        //gl_FragColor = normalize( gl_FragColor );
                        
                        if (enabled)
                            gl_FragColor = CustomLinearToGamma( gl_FragColor, gamma );
                            
                        #if USE_LUT    
                        float pixelWidth = 1.0 / lutSize;
                        float halfPixelWidth = 0.5 / lutSize;
                        vec3 uvw = vec3( halfPixelWidth ) + gl_FragColor.rgb * ( 1.0 - pixelWidth );
                        vec4 lutVal = vec4( texture( lut3d, uvw ).rgb, gl_FragColor.a );
                        gl_FragColor = vec4( mix( gl_FragColor, lutVal, lutIntensity ) );
                        #endif
                    }
                `
            })
        )

        this.texture = options.texture;
        this.frustumCulled = false;

        this.resize(options.width, options.height);

        if (options.lut != null) {
            this.material.uniforms.lut3d.value = options.lut.texture3D;
            this.material.uniforms.lutSize.value = options.lut.size;
            this.material.uniforms.lutIntensity.value =  options.lutIntensity;
            this.material.defines.USE_LUT = 1;
        }
    }

    resize(width, height) {
        //this.material.uniforms.uvTransform.value.copy(this.texture.matrix);
        this.texture.matrixAutoUpdate = false;

        const aspect = width / height;
        const imageAspect = this.texture.image.width / this.texture.image.height;

        if ( aspect < imageAspect ) {
            this.texture.matrix.setUvTransform( 0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5 );
        } else {
            this.texture.matrix.setUvTransform( 0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5 );
        }
    }

}
