export default {

    install(Vue) {

        Vue.$smartphone = Vue.prototype.$smartphone = {

            iPhoneModel(check) {
                //https://28b.co.uk/ios-device-dimensions-reference-table/

                /*
Model Logical resolution	Physical resolution	Aspect ratio	Render scale	Screen size	Pixel density
iPhone 13 Mini (2021)	    780 × 360	2340 × 1080	19.5:9	3×	5.42″	476 PPI
iPhone 13 (2021)	        844 × 390	2532 × 1170	19.5:9	3×	6.06″	460 PPI
iPhone 13 Pro (2021)	    844 × 390	2532 × 1170	19.5:9	3×	6.06″	460 PPI
iPhone 13 Pro Max (2021)	926 × 428	2778 × 1284	19.5:9	3×	6.68″	458 PPI
iPhone 12 pro max (2020)	926 × 428	2778 × 1284	19.5:9	3×	6.7″	458 PPI
iPhone 12 pro (2020)	    844 × 390	2532 × 1170	19.5:9	3×	6.1″	466 PPI
iPhone 12 (2020)	        844 × 390	2532 × 1170	19.5:9	3×	6.1″	460 PPI
iPhone 12 mini (2020)	    780 × 360	2340 × 1080	19.5:9	3×	5.4″	476 PPI
iPhone 11 Pro Max (2019)	896 × 414	2688 × 1242	19.5:9	3×	6.5″	458 PPI
iPhone 11 Pro (2019)	    812 × 375	2436 × 1125	19.5:9	3×	5.8″	458 PPI
iPhone 11 (2019)	        812 × 375	1792 × 828	19.5:9	3×	6.1″	458 PPI
iPhone XR (2018)	        896 × 414	1792 × 828	19.5:9	3×	6.1″	326 PPI
iPhone XS Max (2018)	    896 × 414	2688 × 1242	19.5:9	3×	6.5″	458 PPI
iPhone XS (2018)	        812 × 375	2436 × 1125	19.5:9	3×	5.8″	458 PPI
------------------------------------------------------------------------------- current
iPhone X (2017)	            812 × 375	2436 × 1125	13:6	3×	5.8″	458 PPI
iPhone 8+ (2017)	        736 × 414	1920 × 1080	16:9	3×	5.5″	401 PPI
iPhone 8 (2017)	            667 × 375	1334 × 750	16:9	2×	4.7″	326 PPI
iPhone 7+ (2016)	        736 × 414	1920 × 1080	16:9	3×	5.5″	401 PPI
iPhone 7 (2016)	            667 × 375	1334 × 750	16:9	2×	4.7″	326 PPI
iPhone SE (2016)	        568 × 320	1136 × 640	16:9	2×	4″	    326 PPI
iPhone 6S+ (2015)	        736 × 414	1920 × 1080	16:9	3×	5.5″	401 PPI
iPhone 6S (2015)	        667 × 375	1334 × 750	16:9	2×	4.7″	326 PPI
iPhone 6+ (2014)	        667 × 375	1334 × 750	16:9	2×	4.7″	326 PPI
iPhone 6 (2014)	            667 × 375	1334 × 750	16:9	2×	4.7″	326 PPI
iPhone 5S (2013)	        568 × 320	1136 × 640	16:9	2×	4″	    326 PPI
iPhone 5C (2013)	        568 × 320	1136 × 640	16:9	2×	4″	    326 PPI
iPhone 5 (2012)	            568 × 320	1136 × 640	16:9	2×	4″	    326 PPI
iPhone 4S (2011)	        480 × 320	960 × 640	4:3	    2×	3.5″	326 PPI
iPhone 4 (2010)	            480 × 320	960 × 640	4:3	    2×	3.5″	326 PPI
iPhone 3GS (2009)	        480 × 320	480 × 320	4:3	    1×	3.5″	163 PPI
iPhone 3G (2008)	        480 × 320	480 × 320	4:3	    1×	3.5″	163 PPI
iPhone 2G (2007)	        480 × 320	480 × 320	4:3	    1×	3.5″	163 PPI

                 */
                const aspect = window.screen.height / window.screen.width;
                const pixel = window.devicePixelRatio;

                let models = 'None';
                if (pixel === 3) {
                    if (aspect === 926 / 428) {
                        //2,163551401869159
                        models = "iPhone 12 Pro Max, iPhone 13 Pro Max ";
                    }
                    else if (aspect === 844 / 390) {
                        //2,164102564102564
                        models = "iPhone 12, iPhone 12 Pro, iPhone 13 Pro, iPhone 13";
                    }
                    else if (aspect === 780 / 360) {
                        //2,166666666666667
                        models = "iPhone 12 Mini, iPhone 13 Mini";
                    }
                    else if (aspect === 896 / 414) {
                        //2,164251207729469
                        models = "iPhone XS Max, iPhone XR, iPhone 11 Pro Max";
                    }
                    else if (aspect === 812 / 375) {
                        //2,165333333333333
                        models = "iPhone X, iPhone XS, iPhone 11, iPhone 11 Pro";
                    }
                    else if (aspect === 736 / 414) {
                        //1,777777777777778
                        models = "iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8 Plus";
                    }
                    else if (aspect === 667 / 375) {
                        //1,778666666666667
                        models = "iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8 Plus (display zoom)";
                    }
                } else if (pixel === 2) {
                    if (aspect === 667 / 375) {
                        //1,778666666666667
                        models = "iPhone 6, iPhone 6s, iPhone 7, iPhone 8";
                    }
                    else if (aspect === 1.775) {
                        models = "iPhone 5, iPhone 5C, iPhone 5S, iPhone SE, iPhone 6, iPhone 6s, iPhone 7, iPhone 8 (display zoom)";
                    }
                    else if (aspect === 1.5) {
                        models = "iPhone 4, iPhone 4s";
                    }
                } else if (pixel === 1) {
                    if (aspect === 1.5) {
                        models = "iPhone 1, iPhone 3G, iPhone 3GS";
                    }
                }
                return Vue.$device.mobileModel=== 'iPhone' && models.indexOf(check) !== -1;
            },

            iPadModel(check) {
                const aspect = window.screen.height / window.screen.width;
                const pixel = window.devicePixelRatio;

                let models = 'None';
                //https://51degrees.com/blog/device-detection-for-apple-iphone-and-ipad
                //https://28b.co.uk/ios-device-dimensions-reference-table/
                /*
Model Logical resolution	Physical resolution	Aspect ratio	Render scale	Screen size	Pixel density
iPad 10.2″ (2021)	        1080 × 810	    2160 × 1620	    4:3	    2×	    10.2″	    264 PPI
iPad Mini 6 (2021)	        1133 × 744	    2266 × 1488	    3:2	    2×	    8.3″	    326 PPI
iPad Pro 12.9″ (2021)	    1366 × 1024	    2732 × 2048	    4:3	    2×	    12.9″	    264 PPI
iPad Pro 11″ (2021)	        1194 × 834	    2388 × 1668	    4:3	    2×	    11″	        264 PPI
iPad Air 4 (2020)	        1180 × 820	    2360 × 1640	    23:16	2×	    10.9″	    264 PPI
iPad 10.2″ (2020)	        1080 × 810	    2160 × 1620	    4:3	    2×	    10.2″	    264 PPI
iPad Pro 11″ (2018-2020)	1194 × 834	    2388 × 1668	    10:7	2×	    11″	        264 PPI
iPad Pro 12.9″ (2018-2020)	1366 × 1024	    2732 × 2048	    4:3	    2×	    12.9″	    264 PPI
iPad Air 3 (2019)	        1112 × 834	    2224 × 1668	    4:3	    2×	    10.5″	    264 PPI
iPad 7 (2019)	            1024 × 768	    2160 × 1620	    4:3	    2×	    10.2″	    264 PPI
iPad Mini 5 (2019)	        1024 × 768	    2048 × 1536	    4:3	    2×	    7.9″	    326 PPI
iPad 6 (2018)	            1024 × 768	    2048 × 1536	    4:3	    2×	    9.7″	    264 PPI
iPad 5 (2017)	            1024 × 768	    2048 × 1536	    4:3	    2×	    9.7″	    264 PPI
iPad Pro 10.5″ (2017)	    1112 × 834	    2224 × 1668	    4:3	    2×	    10.5″	    264 PPI
iPad Pro 12.9″ (2017)	    1366 × 1024	    2732 × 2048	    4:3	    2×	    12.9″	    264 PPI
iPad Pro 9.7″ (2016)	    1024 × 768	    2048 × 1536	    4:3	    2×	    9.7″	    264 PPI
iPad Mini 4 (2015)	        1024 × 768	    2048 × 1536	    4:3	    2×	    7.9″	    326 PPI
----------------------------------------------------------------------------------------------- current
iPad Pro 12.9″ (2015)	    1366 × 1024	    2732 × 2048	    4:3	    2×	    12.9″	    264 PPI
iPad Air 2 (2014)	        1024 × 768	    2048 × 1536	    4:3	    2×	    9.7″	    264 PPI
iPad Mini 3 (2014)	        1024 × 768	    2048 × 1536	    4:3	    2×	    7.9″	    326 PPI
iPad Air (2013)	            1024 × 768	    2048 × 1536	    4:3	    2×	    9.7″	    264 PPI
iPad Mini 2 (2013)	        1024 × 768	    2048 × 1536	    4:3	    2×	    7.9″	    326 PPI
iPad 4 (2012)	            1024 × 768	    2048 × 1536	    4:3	    2×	    9.7″	    264 PPI
iPad 3 (2012)	            1024 × 768	    2048 × 1536	    4:3	    2×	    9.7″	    264 PPI
iPad Mini (2012)	        1024 × 768	    2048 × 1536	    4:3	    2×	    7.9″	    326 PPI
iPad 2 (2011)	            1024 × 768	    1024 × 768	    4:3	    1×	    9.7″	    132 PPI
iPad (2010)	                1024 × 768	    1024 × 768	    4:3	    1×	    9.7″	    132 PPI
                 */
                if (pixel === 1) {
                    if (aspect === 1024 / 768) {
                        models = 'iPad 1 (2010), iPad 2 (2011)';
                    }
                } else if (pixel === 2) {
                    if (aspect === 1024 / 768) {
                        models = 'iPad Mini 1 (2012), iPad 3 (2012), iPad 4 (2012), iPad Air 1 (2013), iPad Air 2 (2014), iPad Mini 2 (2013), iPad Mini 3 (2014), iPad Mini 4 (2015), iPad Pro 9.7 (2016), iPad 5 (2017), iPad 6 (2018), iPad Mini 5 (2019), iPad 7 (2019)';
                    } else if (aspect === 1112 / 834) {
                        models = 'iPad Pro 10.5 (2017), iPad Air 3 (2019)';
                    }
                    else if (aspect === 1366 / 1024) {
                        models = 'iPad Pro 12.9 (2015), iPad Pro 12.9 (2017), iPad Pro 12.9 (2018-2020), iPad Pro 12.9 (2021)';
                    }
                    else if (aspect === 1194 / 834) {
                        models = 'iPad Pro 11 (2018-2020), iPad Pro 11 (2021)';
                    }
                    else if (aspect === 1080 / 810) {
                        models = 'iPad 10.2 (2020), iPad 10.2 (2021)';
                    }
                    else if (aspect === 1180 / 820) {
                        models = 'iPad Air 4 (2020)';
                    } else if (aspect === 1133 / 744) {
                        models = 'iPad Mini 6 (2021)';
                    }
                }

                return Vue.$device.mobileModel === 'iPad' && models.indexOf(check) !== -1;
            },

            notSupportNormalMap() {
                const ua = navigator.userAgent;
                return Vue.$device.isMobile && Vue.$device.isAndroid && (
                    //Mozilla/5.0 (Linux; Android 10; ONEPLUS A5000) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.66 Mobile Safari/537.36
                    ua.indexOf('ONEPLUS A5000') > 0 ||
                    //Mozilla/5.0 (Linux; Android 10; CPH1877) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.66 Mobile Safari/537.36
                    ua.indexOf('CPH1877') > 0 ||
                    //Mozilla/5.0 (Linux; Android 10; CPH1907) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.66 Mobile Safari/537.36
                    ua.indexOf('CPH1907') > 0
                );
            },
            isAppleMobile() {
                return Vue.$device.isIOS;
            },

            isIPad() {
                return Vue.$device.mobileModel === 'iPad';
            },

            isIPhone() {
                return Vue.$device.mobileModel === 'iPhone';
            },

            isSlowAppleDevice() {
                //https://it.wikipedia.org/wiki/Versioni_di_iOS

                let ret = false;
                if (Vue.$device.isIOS && Vue.$device.isMobile) {
                    //https://en.wikipedia.org/wiki/IOS_version_history

                    if (Vue.$device.osVersion !== 'none') {
                        if (Vue.$device.osVersion === 'Mac OS') {
                            const version = parseInt(Vue.$device.browserVersion);
                            //fix ipad safari
                            if (
                                version < 15 ||
                                (this.isIPad() && !(this.iPadModel('iPad Pro') || this.iPadModel('iPad Pro 1'))) ||
                                (this.isIPhone() && (this.iPhoneModel('iPhone 6') || this.iPhoneModel('iPhone 7') || this.iPhoneModel('iPhone 8')))
                            )
                                ret = true;
                        } else {
                            const version = parseInt(Vue.$device.osVersion);
                            if (
                                version < 15 ||
                                (this.isIPad() && !(this.iPadModel('iPad Pro') || this.iPadModel('iPad Pro 1'))) ||
                                (this.isIPhone() && (this.iPhoneModel('iPhone 6') || this.iPhoneModel('iPhone 7') || this.iPhoneModel('iPhone 8')))
                             )
                                ret = true;
                        }
                    }
                }
                return ret;
            },

            isAndriodSlowDevice() {
                let ret = false;
                if (Vue.$device.isAndroid) {
                    //https://it.wikipedia.org/wiki/Versioni_di_Android
                    if (Vue.$device.osVersion !== 'none') {
                        const version = parseInt(Vue.$device.osVersion);
                        if (version < 10)
                            ret = true;
                    }
                }
                return ret;
            },

            slowDevices() {
                return this.isAndriodSlowDevice() || this.isSlowAppleDevice();
            },
            iosDevices() {
                return Vue.$device.isIOS && Vue.$device.isMobileSafari;
            },

        }
    }
}

