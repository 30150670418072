<template>
    <div class="avatar">

        <div class="wall">
            <img class="wall-background" ref="imageBackground" style="display: none" alt="">
            <video v-show="false" class="wall-background" ref="videoBackground" autoplay playsinline muted loop>
            </video>
        </div>

        <img v-if="features.extras.botLogo !== ''" v-show="features.status.fullscreen" class="logo" :src="features.extras.botLogo" alt="Logo">

        <div ref="container" class="scene" :class="{'scene-preview': features.preview}" :style="{pointerEvents: features.settings.editor || features.avatar.editor || features.preview ? 'all' : 'none'}" @dblclick="onDoubleClick" >
        </div>

        <div ref="loading" class="loading">
            <div class="loader">
                <span v-if="percent" class="loader-percent">{{percent}}%</span>
            </div>
        </div>

        <div ref="pane" class="avatar-pane"></div>

    </div>

</template>

<script>

import * as Three from 'three';
import {AnimationClip, MathUtils} from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { LUTCubeLoader } from 'three/examples/jsm/loaders/LUTCubeLoader.js';

import { KTX2Loader } from 'three/examples/jsm//loaders/KTX2Loader';
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module";

import { ZipLoader } from "@/threejs/ZipLoader";

import {TWEEN} from "three/examples/jsm/libs/tween.module.min.js"

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
//import { LUTPass } from 'three/examples/jsm/postprocessing/LUTPass.js';

import { SavePass } from 'three/examples/jsm/postprocessing/SavePass.js';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
import { BlendShader } from 'three/examples/jsm/shaders/BlendShader.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
//import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';


import CustomShader from "../threejs/CustomShader";
import "../threejs/CustomToneMapping";

import CustomBackgroundPlane from "../threejs/CustomBackgroundPlane";
import { PeppersGhostEffect } from '../threejs/PeppersGhostEffect.js';
import Helpers from "../classes/Helpers";

import {Pane} from '../threejs/TweakPane.js';

const toneMappingOptions = {
    None: Three.NoToneMapping,
    Linear: Three.LinearToneMapping,
    Reinhard: Three.ReinhardToneMapping,
    Cineon: Three.CineonToneMapping,
    ACESFilmic: Three.ACESFilmicToneMapping,
    Custom: Three.CustomToneMapping
};

export default {
    name: 'Avatar',
    components: {
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        playVoice: {
            type: Boolean,
            required: true,
        },
        playCount: {
            type: Number,
            required: true,
        },
        listenVoice: {
            type: Boolean,
            required: true,
        },
        audioVoice: {
            type: Boolean,
            required: true,
        },
        recognizeVoice: {
            type: Boolean,
            required: true,
        },
        recognized: {
            type: Object,
            required: true
        },
        receiveData: {
            type: Boolean,
            required: true
        },
        messageRead: {
            type: Object,
            required: true
        },
        empathy: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            loaderDelay: 0,
            percent: null,
            postprocessing: null,
            loadingManager: null,
            camera: null,
            scene: null,
            avatar: null,
            world: null,
            renderer: null,
            effect: null,
            controls: null,
            clock: null,
            mixer: null,
            width: null,
            height: null,
            pixelRatio: null,
            background: null,
            lut: null,
            actions: null,
            segues: null,
            animations: null,
            animationVersion: null,
            threejsVersion: null,
            configMode: 'desktop', //todo: comanda sempre desktop per le luci dei vecchi avatar
            pane: null,
            panesInput: {
                body: null,
                face: null,
                expression: null,
                lip: null,
                target: null,
                camera: null,
                fov: null,
                json: null
            },
            panes: {
                json: '',
                list: {
                    body: {},
                    face: {},
                    expression: {},
                    lip: {}
                },
                body: '',
                face: '',
                expression: 'neutral',
                lip: 'lip_sil',
                config: {
                    target: {
                        x: 0,
                        y: 0,
                        z: 0
                    },
                    camera: {
                        fov: 0,
                        x: 0,
                        y: 0,
                        z: 0,
                    }
                }
            },
            defaultConfig: null,
            config: null,
            mode: 'embed',
            currentAnimation: '',
            currentAdditiveAnimation: '',
            currentAdditiveViseme: '',
            currentAdditiveExpression: '',
            currentAdditiveExpressionWeight: 0,
            currentViseme: '',
            currentVisemeName: '',
            loadedHdr: null,
            hasMultimedia: null,
            empatyList: [],
            empatyCount: 4,
            //test
            command: false,

            visemeAnimations: [],
            visemeMap: {
                'sil': { name: 'lip_sil', weight: 1.0 },
                'p': { name: 'lip_p', weight: 0.8 },
                't': { name: 'lip_t', weight: 0.8, blendTime: 0.2 },
                'S': { name: 'lip_S', weight: 0.8 },
                'T': { name: 'lip_T', weight: 0.8 },
                'f': { name: 'lip_f', weight: 0.8 },
                'k': { name: 'lip_k', weight: 0.8 },
                'i': { name: 'lip_i', weight: 0.8 },
                'J': { name: 'lip_J', weight: 0.8 },
                'r': { name: 'lip_r', weight: 0.8 },
                's': { name: 'lip_s', weight: 0.8, blendTime: 0.25 },
                'u': { name: 'lip_u', weight: 0.8 },
                '@': { name: 'lip_A', weight: 0.6 },
                'a': { name: 'lip_a', weight: 0.6 },
                'e': { name: 'lip_e', weight: 0.8, blendTime: 0.2 },
                'E': { name: 'lip_E', weight: 0.8 },
                'o': { name: 'lip_o', weight: 0.8 },
                'O': { name: 'lip_O', weight: 0.8 },
            },
            visemeMapMissing: {
                'T': { name: 'lip_t', weight: 0.8 },
            },
            lipsMap: {
                cmu_sphinx: {
                    'SIL': { name: 'sil'},
                    'a': { name: 'a'},
                    'b': { name: 'p'},
                    'a1': { name: '@'},
                    'd': { name: 't'},
                    'dz': { name: 's'},
                    'dZZ': { name: 'S'},
                    'e': { name: 'e'},
                    'e1': { name: 'e'},
                    'EE': { name: 'E'},
                    'f': { name: 'f'},
                    'g': { name: 'k'},
                    'i': { name: 'r'},
                    'i1': { name: 'i'},
                    'j': { name: 'i'},
                    'JJ': { name: 'J'},
                    'k': { name: 'k'},
                    'l': { name: 't'},
                    'LL': { name: 'J'},
                    'm': { name: 'p'},
                    'n': { name: 't'},
                    'nf': { name: 'f'},
                    'ng': { name: 't'},
                    'o': { name: 'o'},
                    'o1': { name: 'O'},
                    'OO': { name: 'O'},
                    'p': { name: 'p'},
                    'r': { name: 'r'},
                    's': { name: 's'},
                    'SS': { name: 'S'},
                    't': { name: 't'},
                    'ts': { name: 's'},
                    'tSS': { name: 'S'},
                    'u': { name: 'u'},
                    'u1': { name: 'u'},
                    'v': { name: 'f'},
                    'w': { name: 'u'},
                    'z': { name: 's'},
                },
                amazon: {
                    'sil': { name: 'sil' },
                    'p': { name: 'p' },
                    't': { name: 't' },
                    'S': { name: 'S' },
                    'T': { name: 'T' },
                    'f': { name: 'f' },
                    'k': { name: 'k' },
                    'i': { name: 'i' },
                    'J': { name: 'J' },
                    'r': { name: 'r' },
                    's': { name: 's' },
                    'u': { name: 'u' },
                    '@': { name: '@' },
                    'a': { name: 'a' },
                    'e': { name: 'e' },
                    'E': { name: 'E' },
                    'o': { name: 'o' },
                    'O': { name: 'O' },
                },
                fake: {
                    '-': {name: 'sil'},
                    '_': {name: 'sil'},

                    'A': {name: 'a'},
                    'E': {name: 'e'},
                    'I': {name: 'r'},
                    'O': {name: 'o'},
                    'U': {name: 'u'},

                    'B': {name: 'p'},
                    'C': {name: 'k'},
                    'D': {name: 't'},
                    'F': {name: 'f'},
                    'G': {name: 'k'},
                    'H': {name: 'k'},
                    'J': {name: 'S'},
                    'K': {name: 'k'},
                    'L': {name: 't'},
                    'M': {name: 'p'},
                    'N': {name: 't'},
                    'P': {name: 'p'},
                    'Q': {name: 'k'},
                    'R': {name: 'r'},
                    'S': {name: 's'},
                    'T': {name: 't'},
                    'V': {name: 'f'},
                    'W': {name: 'u'},
                    'X': {name: 'i'},
                    'Y': {name: 'i'},
                    'Z': {name: 's'},

                    'CI': {name: 'r'},
                    'GLI': {name: 'J'},
                    'GN': {name: 'J'},
                    'HO': {name: 'k'},
                    'ZI': {name: 's'},

                    'BB': {name: 'p'},
                    'CC': {name: 'k'},
                    'DD': {name: 't'},
                    'FF': {name: 'f'},
                    'GG': {name: 'k'},
                    'LL': {name: 't'},
                    'MM': {name: 'p'},
                    'NN': {name: 't'},
                    'PP': {name: 'p'},
                    'QQ': {name: 'k'},
                    'RR': {name: 'r'},
                    'SS': {name: 's'},
                    'TT': {name: 't'},
                    'VV': {name: 'f'},
                    'ZZ': {name: 's'},

                    'À': {name: '@'},
                    'È': {name: 'E'},
                    'É': {name: 'e'},
                    'Ò': {name: 'O'},
                    'Ù': {name: 'u'},
                }
            },
            expressionMirror: {
                fearful: 'fearful',
                happy: 'happy',
                sad: 'sad',
                angry: 'sad',
                disgusted: 'sad',
                surprised: 'surprised',
                neutral: 'neutral'
            }
        }
    },
    computed: {
        isFemale() {
            return this.features.avatar.gender === 'f';
        },
        supportLipSync() {
            return this.existAction('lip_sil');
        },
        supportEmpathy() {
            return this.existAction('expr_neutral');
        },
    },
    watch: {
        playVoice: {
            handler(newVal) {
                const self = this;
                if (newVal) {

                    let animation = this.hasMultimedia ? 'showing' : 'talking';
                    if (this.messageRead.payload !== undefined && this.messageRead.payload !== null && this.messageRead.payload.indexOf('dhi:') === 0) {
                        animation = this.messageRead.payload.replace('dhi:', '');
                    }
                    if (!this.currentAnimationType('showing') && !this.currentAnimationType('talking')) {

                        const expression = this.expressionMirror[this.messageRead.emotion || 'neutral'];
                        this.fadeAdditiveExpression(expression, expression === 'neutral' ? 0 : 0.75);

                        if (!this.supportLipSync || this.messageRead.duration > 4.0)
                            this.fadeActionRandom(animation);
                    }

                    this.playLipSync(this.messageRead);

                } else {
                    setTimeout(() => {
                        self.fadeAdditiveExpression('neutral', 0);
                    }, 2.0 * 1000);

                    if (this.playCount === 0) {
                        if (!this.currentAnimationType('waiting'))
                            this.fadeActionRandom('waiting');
                    }

                    this.stopLipSync(this.messageRead);
                }
            },
            deep: true
        },
        listenVoice: {
            handler(newVal) {
                if (newVal)
                    this.fadeActionRandom('listening');
            },
            deep: true
        },
        receiveData: {
            handler(newVal) {
                if (this.animationVersion === 'old') {
                    if (newVal) {
                        this.fadeActionRandom('waiting');
                    } else {
                        if (Math.random() > 0.5)
                            this.fadeActionRandom('typing');
                        else
                            this.fadeActionRandom('listening');
                    }
                }
            },
            deep: true
        },
        messageRead: {
            handler(newVal) {
                if (newVal.mediaType !== undefined && newVal.mediaType != null) {
                    this.hasMultimedia = true;
                } else {
                    this.hasMultimedia = false;
                }
            },
            deep: true
        },
        'features.status.fullscreen': {
            handler(newVal) {
                this.setOpacity(0);

                if (newVal) {
                    this.setZoom('desktop');
                    this.loadBackground();
                } else {
                    if (!this.features.showLastMessage)
                        this.features.showLastMessage = true;
                    this.setZoom('embed');
                    this.clearBackground();
                }

                setTimeout(() => {
                    this.onResize();
                }, 10);
            },
            deep: true,
            immediate: false
        },
        'features.showLastMessage': {
          handler(newVal) {
              if (!this.features.kiosk && this.features.status.fullscreen && !this.features.device.mobile) {

                  if (this.features.status.loadedAvatar) {
                      // calculate distance from comaera to avatar
                      const distance = this.camera.position.distanceTo(this.avatar.position);
                      // convert vertical fov to radians
                      const vFov = Three.MathUtils.degToRad(this.camera.fov);
                      // visible height
                      const height = 2 * Math.tan(vFov / 2) * distance;
                      // visible width
                      const width = height * this.camera.aspect;
                      //console.log(width, height);

                      //calculate avatar movement offset (work only if avatar is centered in camera)
                      let offset = width / 6 * (newVal ? -1 : 1);

                      this.updateTarget({
                          x: this.controls.target.x + offset,
                          y: this.controls.target.y,
                          z: this.controls.target.z,
                      });
                  }
              }
          },
          deep: true
        },
        empathy: {
            handler(newVal) {
                if (newVal != null)
                    this.playEmpathy(newVal);
            },
            deep: true
        },
    },
    methods: {
        init() {
            const self = this;
            const container = this.$refs.container;
            this.clock = new Three.Clock();

            this.width = container.clientWidth;
            this.height = container.clientHeight;

            const near = 0.1;
            const far = 1000;

            this.camera = new Three.PerspectiveCamera(this.config.camera.fov, this.width / this.height, near, far);
            this.camera.position.set(this.config.camera.x, this.config.camera.y, this.config.camera.z);
            this.scene = new Three.Scene();
            this.scene.dispose = ()=> {};

            const antialias = !this.features.device.slowMobile;
            this.renderer = new Three.WebGLRenderer({antialias: antialias, alpha: true});

            this.renderer.setSize(this.width, this.height);

            if (this.width === 0 || this.height === 0)
                this.$log.warn('Avatar container is zero size', this.width, this.height);

            if (this.features.device.slowMobile) {
                this.$log.info("Slow/old device detected, set pixelRatio = 1");
                this.pixelRatio = Math.min(this.features.pixelRatio, 1);
            } else {
                this.pixelRatio = this.features.pixelRatio;
            }

            this.renderer.setPixelRatio(this.pixelRatio);

            if (this.config.gamma.enabled) {
                this.renderer.outputEncoding = Three.LinearEncoding; //.GammaEncoding;
                this.renderer.gammaFactor = this.config.gamma.gamma;
            } else {
                this.renderer.outputEncoding = Three.sRGBEncoding; //.sRGBEncoding;
            }

            if (this.configMode !== 'embedded') {
                this.renderer.shadowMap.type = Three.PCFSoftShadowMap;
                this.renderer.shadowMap.enabled = true;
            }

            this.initPostprocessing();

            container.appendChild(this.renderer.domElement);

            this.renderer.toneMapping = toneMappingOptions[this.config.tonemapping.type];
            this.renderer.toneMappingExposure = this.config.tonemapping.exposure;

            // Orbit control
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.target = new Three.Vector3(this.config.target.x, this.config.target.y, this.config.target.z);

            if (this.features.settings.editor || this.features.avatar.editor ) {
                this.controls.addEventListener('change', function () {
                    self.refreshPane('target');
                    self.refreshPane('camera');
                });
            }

            if (this.features.settings.editor || this.features.avatar.editor || this.features.preview) {
                this.controls.screenSpacePanning = true;
                this.controls.enableKeys = true;
                this.controls.enableRotate = true;
                this.controls.enablePan = true;
                this.controls.enableZoom = true;
                if (this.features.preview) {
                    this.controls.enableDamping = true;
                    this.controls.dampingFactor = 0.05;
                    this.controls.minDistance = 5;
                    this.controls.maxDistance = 50;
                    this.controls.maxPolarAngle = Math.PI / 2;
                }
            } else {
                this.controls.screenSpacePanning = false;
                this.controls.enableKeys = false;
                this.controls.enableRotate = false;
                this.controls.enablePan = false;
                this.controls.enableZoom = false;
            }

            if (this.features.kiosk) {
                this.setZoom('kiosk');
            } else if (this.features.status.fullscreen && !this.features.device.mobile) {
                this.setZoom('desktop');
            } else {
                this.setZoom('embed');
            }

            //old frontend light compatibility
            if (this.config.hdr === undefined) {
                if (this.config.light_base_hemisphere !== undefined) {
                    // Light (general illumination)
                    const light = new Three.HemisphereLight(
                        this.config.light_base_hemisphere.skyColor,
                        this.config.light_base_hemisphere.groudColor,
                        this.config.light_base_hemisphere.intensity
                    );
                    this.scene.add(light);
                }
            }

            if (this.configMode === 'desktop') {
                if (this.config.light_2_point !== undefined) {
                    // Light2 (from left)
                    const light2 = new Three.PointLight(
                        this.config.light_2_point.color,
                        this.config.light_2_point.intensity,
                        this.config.light_2_point.distance,
                        this.config.light_2_point.decay
                    );

                    light2.position.set(
                        this.config.light_2_point.position.x,
                        this.config.light_2_point.position.y,
                        this.config.light_2_point.position.z
                    );

                    light2.shadow.mapSize.width = 2048;
                    light2.shadow.mapSize.height = 2048;
                    light2.shadow.camera.near = 0.5;
                    light2.shadow.camera.far = 500;
                    light2.castShadow = this.config.light_2_point.cast_shadow;
                    light2.shadow.radius = 6;
                    this.scene.add(light2);
                }

                if (this.config.light_3_point !== undefined) {
                    // Light3 (from right)
                    const light3 = new Three.PointLight(
                        this.config.light_3_point.color,
                        this.config.light_3_point.intensity,
                        this.config.light_3_point.distance,
                        this.config.light_3_point.decay
                    );

                    light3.position.set(
                        this.config.light_3_point.position.x,
                        this.config.light_3_point.position.y,
                        this.config.light_3_point.position.z
                    );

                    light3.shadow.mapSize.width = 2048;
                    light3.shadow.mapSize.height = 2048;
                    light3.shadow.camera.near = 0.5;
                    light3.shadow.camera.far = 500;
                    light3.castShadow = this.config.light_3_point.cast_shadow;
                    light3.shadow.radius = 6;
                    this.scene.add(light3);
                }

                if (this.config.light_4_point !== undefined) {
                    // Light4 (help light)
                    const light4 = new Three.PointLight(
                        this.config.light_4_point.color,
                        this.config.light_4_point.intensity,
                        this.config.light_4_point.distance,
                        this.config.light_4_point.decay
                    );

                    light4.position.set(
                        this.config.light_4_point.position.x,
                        this.config.light_4_point.position.y,
                        this.config.light_4_point.position.z
                    );

                    light4.shadow.mapSize.width = 2048;
                    light4.shadow.mapSize.height = 2048;
                    light4.shadow.camera.near = 0.5;
                    light4.shadow.camera.far = 500;
                    light4.castShadow = this.config.light_4_point.cast_shadow;
                    light4.shadow.radius = 6;
                    this.scene.add(light4);
                }

            } else if (this.configMode === 'mobile') {
                if (this.config.light_2_point !== undefined) {
                    // Light2 (from left)
                    const light2 = new Three.SpotLight(
                        this.config.light_2_point.color
                    );
                    light2.position.set(
                        this.config.light_2_point.position.x,
                        this.config.light_2_point.position.y,
                        this.config.light_2_point.position.z
                    );
                    light2.shadow.mapSize.width = 512;
                    light2.shadow.mapSize.height = 512;
                    light2.shadow.camera.near = 0.5;
                    light2.shadow.camera.far = 500;
                    light2.castShadow = this.config.light_2_point.cast_shadow;
                    light2.shadow.radius = 6;
                    this.scene.add(light2);
                }

            } else if (this.configMode === 'embedded') {
                if (this.config.light_2_point !== undefined) {
                    // Light2 (from left)
                    const light2 = new Three.PointLight(
                        this.config.light_2_point.params.color,
                        this.config.light_2_point.intensity,
                        this.config.light_2_point.distance,
                        (this.config.light_2_point.decay !== undefined ? this.config.light_2_point.decay : 1));

                    light2.position.set(
                        this.config.light_2_point.position.x,
                        this.config.light_2_point.position.y,
                        this.config.light_2_point.position.z
                    );
                    this.scene.add(light2);
                }
            }

            window.addEventListener("resize", this.onResize, false);

            this.initRenderer();

            this.initLutCube((lut)=> {
                self.lut = lut;

                self.loadBackground();

                self.loadHdr(self.config.scene.hdr, self.config.scene.hdrLite);

                if (self.config.scene.world !== undefined)
                    self.loadWorld(self.config.scene.world);

                if (self.features.device.slowMobile || self.features.device.iosMobile) {
                    self.loadAvatar(self.config.scene.avatarLite || self.config.scene.avatar);
                } else {
                    if (self.features.avatar.lite) {
                        self.loadAvatar(self.config.scene.avatarLite || self.config.scene.avatar);
                    } else {
                        self.loadAvatar(self.config.scene.avatar);
                    }
                }
            });

        },

        initRenderer() {
            if (this.features.renderer === 'PeppersGhost') {
                this.renderer.toneMappingExposure = 0.85;
                this.updateCamera({fov: 12, x: 0, y: 0, z: 0});
                this.updateTarget({x: 0, y: 0, z: 0});
                this.effect = new PeppersGhostEffect(this.renderer);
                this.effect.setSize(this.width, this.height);
                this.effect.reflectFromAbove = true;
                this.effect.cameraDistance = 0.8;
                this.effect.cameraYOffset = 1.5;
            }
        },
        initPostprocessing() {

            //const lut = this.config.lut !== undefined && this.config.lut.enabled;
            const effect = this.features.postprocessing.toLowerCase();

            if (effect === 'bokeh' || effect === 'chromatic' || effect === 'bleach' || effect === 'motionblur' || effect === 'custom' || effect === 'test') {

                //fix strange artifact
                this.camera.near = 2;

                this.postprocessing = {};

                const renderPass = new RenderPass(this.scene, this.camera);

                const composer = new EffectComposer(this.renderer);
                const gammaPass = new ShaderPass(GammaCorrectionShader);

                //const fxaaPass = new ShaderPass(FXAAShader);
                const fxaaPass = new ShaderPass(CustomShader.FxAAA);
                fxaaPass.uniforms.resolution.value.x =  1 / (this.width * this.pixelRatio);
                fxaaPass.uniforms.resolution.value.y = 1 / (this.height * this.pixelRatio);

                composer.addPass(renderPass);
                composer.addPass(gammaPass);
                composer.addPass(fxaaPass);

                this.postprocessing.fxaa = fxaaPass;
                this.postprocessing.composer = composer;

                if (effect === 'bokeh') {
                    const custom = new ShaderPass(CustomShader.Bokeh);
                    custom.material.uniforms.focus.value = 0;
                    custom.material.uniforms.aperture.value = 2.0 * 0.00001;
                    custom.material.uniforms.maxblur.value = 0.006;
                    custom.enabled = true;

                    composer.addPass(custom);
                    this.postprocessing.custom = custom;
                }

                if (effect === 'chromatic') {
                    const custom = new ShaderPass(CustomShader.ChromaticAberration);
                    custom.material.uniforms.fAmount.value = 0.05;
                    custom.material.uniforms.amount.value = 0.003;
                    custom.material.uniforms.angle.value = 0.0;
                    custom.enabled = true;

                    composer.addPass(custom);
                    this.postprocessing.custom = custom;
                }

                if (effect === 'bleach') {
                    const custom = new ShaderPass(CustomShader.Bleach);
                    custom.material.uniforms.opacity.value = 1.0;
                    custom.enabled = true;

                    composer.addPass(custom);
                    this.postprocessing.custom = custom;

                }

                if (effect === 'motionblur') {

                    // save pass
                    const savePass = new SavePass(
                        new Three.WebGLRenderTarget(this.width, this.height, {
                                minFilter: Three.LinearFilter,
                                magFilter: Three.LinearFilter,
                                stencilBuffer: false
                            }
                        )
                    );
                    this.postprocessing.save = savePass;

                    // blend pass
                    const blendPass = new ShaderPass(BlendShader, "tDiffuse1");
                    blendPass.uniforms["tDiffuse2"].value = savePass.renderTarget.texture;
                    blendPass.uniforms["mixRatio"].value = 0.5;
                    blendPass.enabled = true;

                    // output pass
                    const outputPass = new ShaderPass(CopyShader);
                    outputPass.renderToScreen = true;

                    composer.addPass(blendPass);
                    composer.addPass(savePass);
                    composer.addPass(outputPass);
                }

                if (effect === 'custom') {
                    const custom = new ShaderPass(CustomShader.Custom);
                    custom.material.uniforms.resolution.value.x =  Math.floor(this.width * this.pixelRatio);
                    custom.material.uniforms.resolution.value.y = Math.floor(this.height * this.pixelRatio);
                    custom.enabled = true;

                    composer.addPass(custom);
                    this.postprocessing.custom = custom;

                }

                /*
                if (lut) {
                    const self = this;
                    const custom = new LUTPass();
                    custom.intensity = this.config.lut.intensity || 1.0;
                    custom.enabled = true;

                    new LUTCubeLoader().load(this.config.lut.cube, function (lut) {
                        custom.lut = lut.texture3D; //texture (use2DLut)
                    }, self.onProgress, self.onErrorLoad);

                    composer.addPass(custom);
                }
                 */
            }
        },
        initLutShader() {
            const self = this;
            this.$log.info("Init lut shader");

            this.avatar.traverse(function (child) {
                if (child.isMesh) {
                    child.material.onBeforeCompile = function (shader) {
                        //console.log(shader.fragmentShader);
                        //console.log(shader.vertexShader);

                        shader.uniforms.lut3d = {value: self.lut.texture3D};
                        shader.uniforms.lutSize = {value: self.lut.texture3D.image.width};
                        shader.uniforms.lutIntensity = {value: self.config.lut.intensity || 1.0};

                        shader.fragmentShader = [
                            'uniform float lutIntensity;',
                            'uniform float lutSize;',
                            'precision highp sampler3D;',
                            'uniform sampler3D lut3d;',
                        ].join('\n') + '\n' + shader.fragmentShader.replace('#include <dithering_fragment>',
                            [
                                '#include <dithering_fragment>',
                                'float pixelWidth = 1.0 / lutSize;',
                                'float halfPixelWidth = 0.5 / lutSize;',
                                'vec3 uvw = vec3( halfPixelWidth ) + gl_FragColor.rgb * ( 1.0 - pixelWidth );',
                                'vec4 lutVal = vec4( texture( lut3d, uvw ).rgb, gl_FragColor.a );',
                                'gl_FragColor = vec4( mix( gl_FragColor, lutVal, lutIntensity ) );',
                            ].join('\n')
                        );
                    };
                }
            });
        },

        initEditor() {
            const self = this;
            if (this.pane != null) {
                this.pane.dispose();
                this.pane = null;
            }
            this.pane = new Pane({
                container: this.$refs.pane,
            });

            //Animations
            const fanimation = this.pane.addFolder({
                title: 'Animations',
                expanded: false
            });

            this.panesInput.body = fanimation.addInput(this.panes, 'body', {
                label: 'Body',
                options: this.panes.list.body
            }).on('change', function (event) {
                self.fadeAction(event.value);
            });

            this.panesInput.face = fanimation.addInput(this.panes, 'face', {
                label: 'Face',
                options: this.panes.list.face
            }).on('change', function (event) {
                self.fadeAdditive(event.value);
            });

            this.panesInput.expression = fanimation.addInput(this.panes, 'expression', {
                label: 'Expr',
                options: this.panes.list.expression
            }).on('change', function (event) {
                self.fadeAdditiveExpression(event.value);
            });

            if (this.supportLipSync) {
                this.panesInput.lip = fanimation.addInput(this.panes, 'lip', {
                    label: 'Lip',
                    options: this.panes.list.lip
                }).on('change', function (event) {
                    self.testFadeLips(event.value);
                });

                //Animations
                const fviseme = this.pane.addFolder({
                    title: 'Visemes',
                    expanded: false
                });

                fviseme.addMonitor(this, 'currentVisemeName', {
                    label: 'Viseme',
                    multiline: false
                });

                fviseme.addMonitor(this, 'currentViseme', {
                    label: 'Animation',
                    multiline: false
                });

                const tab = fviseme.addTab({
                    pages: [
                        {title: 'Weight'},
                        {title: 'Map'},
                        {title: 'Amazon'},
                        {title: 'Sphinx'},
                    ],
                });

                for (let index in this.visemeMap) {
                    tab.pages[0].addInput(this.visemeMap[index], 'weight', {
                        label: index,
                        min: 0,
                        max: 1,
                        step: 0.05
                    }).on('change', function () {
                        self.testFadeViseme(index);
                    });
                }

                for (let index in this.visemeMap) {
                    tab.pages[1].addInput(this.visemeMap[index], 'name', {
                        label: index,
                        options: this.panes.list.lip
                    });
                }

                const visemes = {}
                Object.keys(this.visemeMap).map(function (key) { visemes[key] = key; })

                for (let index in this.lipsMap.amazon) {
                    tab.pages[2].addInput(this.lipsMap.amazon[index], 'name', {
                        label: index,
                        options: visemes
                    });
                }

                for (let index in this.lipsMap.cmu_sphinx) {
                    tab.pages[3].addInput(this.lipsMap.cmu_sphinx[index], 'name', {
                        label: index,
                        options: visemes
                    });
                }
            }

            //Positions
            const fposition = this.pane.addFolder({
                title: 'Positions',
                expanded: false
            });

            this.panesInput.target = fposition.addInput(this.panes.config, 'target', {
                label: 'Avatar',
                min: -5.0,
                max: 5.0,
                step: .001
            }).on('change', function (event) {
                self.controls.target.x = event.value.x;
                self.controls.target.y = event.value.y;
                self.controls.target.z = event.value.z;
                self.controls.update();
            });

            this.panesInput.camera = fposition.addInput(this.panes.config, 'camera', {
                label: 'Camera',
                min: -100.0,
                max: 100.0,
                step: .001
            }).on('change', function (event) {
                self.camera.position.x = event.value.x;
                self.camera.position.y = event.value.y;
                self.camera.position.z = event.value.z;
                self.controls.update();
            });

            this.panesInput.fov = fposition.addInput(this.panes.config.camera, 'fov', {
                label: 'Fov',
                min: 1,
                max: 100,
                step: 1
            }).on('change', function (event) {
                self.camera.fov = event.value;
                self.controls.update();
                self.camera.updateProjectionMatrix();
            });

            //Tone Mapping
            const ftonemapping = this.pane.addFolder({
                title: 'Tone Mapping',
                expanded: false
            });

            ftonemapping.addInput(this.renderer, 'toneMapping', {
                label: 'Type',
                options: toneMappingOptions,
            });

            ftonemapping.addInput(this.renderer, 'toneMappingExposure', {
                label: 'Exposure',
                min: 0,
                max: 10,
                step: 0.01
            });

            if (this.config.scene !== undefined && this.config.scene.hdr !== undefined) {
                //HDR
                const fhdr = this.pane.addFolder({
                    title: 'Hdr',
                    expanded: false
                });

                fhdr.addInput(this.config.scene, 'hdr', {
                    label: 'File Hdr',
                    options: {
                        "Default": this.features.instance.baseUrl + "/hdr/default.hdr",
                        "Default New": this.features.instance.baseUrl + "/hdr/default2.hdr",
                    },
                }).on('change', function (event) {
                    self.loadHdr(event.value);
                });
            }

            //Extra
            const fexport = this.pane.addFolder({
                title: 'Config',
                expanded: false
            });

            this.panesInput.json = fexport.addMonitor(this.panes, 'json', {
                label: this.mode,
                multiline: true,
                lineCount: 5,
            });

            fexport.addButton({
                title: 'Reset'
            }).on('click', function () {
                self.setZoom(self.mode);
            });

        },
        refreshPane(pane) {

            if (pane === 'target') {
                this.panes.config.target.x = parseFloat(this.controls.target.x.toFixed(2));
                this.panes.config.target.y = parseFloat(this.controls.target.y.toFixed(2));
                this.panes.config.target.z = parseFloat(this.controls.target.z.toFixed(2));
                if (this.panesInput.target != null)
                    this.panesInput.target.refresh();
            } else if (pane === 'camera') {
                this.panes.config.camera.x = parseFloat(this.camera.position.x.toFixed(2));
                this.panes.config.camera.y = parseFloat(this.camera.position.y.toFixed(2));
                this.panes.config.camera.z = parseFloat(this.camera.position.z.toFixed(2));
                if (this.panesInput.camera != null)
                    this.panesInput.camera.refresh();
            } else if (pane === 'fov') {
                this.panes.config.camera.fov = this.camera.fov;
                if (this.panesInput.fov != null)
                    this.panesInput.fov.refresh();
            }  else if (pane === 'animation') {
                this.panes.body = this.currentAnimation;
                if (this.panesInput.body != null)
                    this.panesInput.body.refresh();
            } else if (pane === 'additive') {
                this.panes.additive = this.currentAdditiveAnimation;
                if (this.panesInput.face != null)
                    this.panesInput.face.refresh();
            } else if (pane === 'expression') {
                this.panes.expression = this.currentAdditiveExpression;
                if (this.panesInput.expression != null)
                    this.panesInput.expression.refresh();
            } else if (pane === 'lip') {
                this.panes.lip = this.currentAdditiveViseme;
                if (this.panesInput.lip != null)
                    this.panesInput.lip.refresh();
            }

            if (this.panesInput.json != null) {
                this.panes.json = JSON.stringify(this.panes.config, null, 2);
                this.panesInput.json.refresh();
            }

                /*if (pane === undefined)
                    pane = this.pane;

                for (let i in pane.children) {
                    if (pane.children[i].refresh !== undefined)
                        pane.children[i].refresh();
                    if (pane.children[i].children !== undefined)
                        this.refreshPane(pane.children[i])
                }*/

        },
        onNoProgress() {

        },
        onProgress(progress) {
            if (progress.lengthComputable && !this.features.preview) {
                let percent = parseInt(progress.loaded / progress.total * 100);
                if (percent > 100)
                    percent = 100;

                if (percent < 100) {
                    const blurring = parseInt((100 - percent) / 10) + 1;
                    this.$refs.imageBackground.style['filter'] = 'blur(' + blurring + 'px)';
                    this.$refs.imageBackground.style['-webkit-filter'] = 'blur(' + blurring + 'px)';
                } else {
                    this.$refs.imageBackground.style['filter'] = 'unset';
                    this.$refs.imageBackground.style['-webkit-filter'] = 'unset';
                }

                this.percent = percent;
            }
        },
        onErrorLoad(error) {
            this.$log.error('Error loading: ' + error.message);
            this.$log.error(error.stack);
        },
        onErrorLoadGltfAvatar(error) {
            this.loadAvatar(this.features.instance.baseUrl + '/models/error.gltf');
            this.onEndLoading();

            this.$log.error('Error loading avatar: ' + error.message);
            this.$log.error(error.stack);
        },
        onEndLoading() {
            const self = this;

            if (this.width === 0 || this.height === 0) {
                //fix container size 0 in some case
                this.onResize();
            }

            if (this.features.preview)
                this.fitCameraToAvatar(this.avatar, 1.1);

            if (this.features.renderer !== 'PeppersGhost' && !this.features.device.slowMobile) {
                this.$log.info('Load full hdr');
                this.loadHdr(this.config.scene.hdr);
            }

            this.$log.info('Ready');
            this.$log.info('Start first animation');

            let animation = '';
            if (this.features.preview)
                animation = this.getRandomAction('preview');
            else
                animation = this.getRandomAction('intro');

            if (!this.existAction(animation))
                animation = this.getRandomAction('waiting');

            this.currentAnimation = animation;
            this.playAction(this.currentAnimation);

            if (this.existAction('face_waiting'))
                this.fadeAdditive('face_waiting');

            this.renderer.setAnimationLoop(this.animate);

            const loading = this.$refs.loading;
            if (loading !== undefined) {
                loading.classList.add('fade-out');

                loading.addEventListener('transitionend', function (event) {
                    const element = event.target;
                    element.remove();
                    if (self.loaderDelay > 0) {
                        setTimeout(()=> {
                            self.features.status.loadedAvatar = true;
                        }, self.loaderDelay);
                    } else {
                        self.features.status.loadedAvatar = true;
                    }
                });
            }

        },

        setOpacity(opacity) {
            const container = this.$refs.container;
            if (container != null)
                container.style.opacity = opacity;
        },

        animate() {
            if (this.mixer != null) {
                if (this.features.status.opened || this.features.preview) {
                    const delta = this.clock.getDelta();

                    this.mixer.update(delta);
                    this.controls.update(delta);
                    TWEEN.update();

                    if (this.effect != null) {
                        this.effect.render(this.scene, this.camera);
                    } else {

                        if (this.postprocessing == null) {
                            this.renderer.render(this.scene, this.camera);
                        } else {
                            if (this.postprocessing.custom !== undefined && this.postprocessing.custom.material.uniforms.time !== undefined) {
                                this.postprocessing.custom.material.uniforms.time.value += delta;
                            }
                            this.postprocessing.composer.render(delta);
                        }
                    }
                }
            } else {
                this.renderer.render(this.scene, this.camera);
            }
        },
        isNotEmpty(prop) {
          return (prop || '' !== '');
        },
        loadConfigOld(config) {
            this.config = {
                scene: {
                    avatar: config.avatar_model,
                    avatarLite: null, //todo: sarebbe la config mobile ma non c'è
                    animations: config.avatar_animations,
                    hdr: config.hdr,
                    hdrLite: config.hdrLite,
                    backgroundImage: config.background_image,
                    world: config.foreground_model
                },
                renderer: {
                    antialias: true,
                    alpha: true
                },
                gamma: {
                    enabled: false,
                    gammaFactor: 2.2
                },
                tonemapping: {
                    type: 'None',
                    exposure: 1
                },
                camera: {
                    fov: config.camera_perspective,
                    x: config.camera_position[0],
                    y: config.camera_position[1],
                    z: config.camera_position[2]
                },
                target: {
                    x: config.controls_target[0],
                    y: config.controls_target[1],
                    z: config.controls_target[2]
                },
                modes: {
                  //manca mobile, embed
                },
                animations: config.animations,
                animations_segues: config.animations_segues
            };

            if (config.light_base_hemisphere !== undefined) {
                this.config.light_base_hemisphere = {
                    skyColor: config.light_base_hemisphere.params[0].replace("0x", "#"),
                    groudColor: config.light_base_hemisphere.params[1].replace("0x", "#"),
                    intensity: config.light_base_hemisphere.params[2],
                };
            }

            if (this.configMode === 'desktop') {
                if (config.light_2_point !== undefined) {
                    this.config.light_2_point = {
                        position: {
                            x: config.light_2_point.position[0],
                            y: config.light_2_point.position[1],
                            z: config.light_2_point.position[2]
                        },
                        color: config.light_2_point.params[0].replace("0x", "#"),
                        intensity: config.light_2_point.params[1],
                        distance: config.light_2_point.params[2],
                        decay: config.light_2_point.params[3],
                        cast_shadow: config.light_2_point.cast_shadow
                    };
                }

                if (config.light_3_point !== undefined) {
                    this.config.light_3_point = {
                        position: {
                            x: config.light_3_point.position[0],
                            y: config.light_3_point.position[1],
                            z: config.light_3_point.position[2]
                        },
                        color: config.light_3_point.params[0].replace("0x", "#"),
                        intensity: config.light_3_point.params[1],
                        distance: config.light_3_point.params[2],
                        decay: config.light_3_point.params[3],
                        cast_shadow: config.light_3_point.cast_shadow
                    };
                }

                if (config.light_4_point !== undefined) {
                    this.config.light_4_point = {
                        position: {
                            x: config.light_4_point.position[0],
                            y: config.light_4_point.position[1],
                            z: config.light_4_point.position[2]
                        },
                        color: config.light_4_point.params[0].replace("0x", "#"),
                        intensity: config.light_4_point.params[1],
                        distance: config.light_4_point.params[2],
                        decay: config.light_4_point.params[3],
                        cast_shadow: config.light_4_point.cast_shadow
                    };
                }
            } else if (this.configMode == "mobile") {

                if (config.light_2_point !== undefined) {
                    this.config.light_2_point = {
                        position: {
                            x: config.light_2_point.position[0],
                            y: config.light_2_point.position[1],
                            z: config.light_2_point.position[2]
                        },
                        color: config.light_2_point.params[0].replace("0x", "#"),
                        cast_shadow: config.light_2_point.cast_shadow
                    };
                }

            } else if (this.configMode == "embedded") {

                if (config.light_2_point !== undefined) {
                    this.config.light_2_point = {
                        position: {
                            x: config.light_2_point.position[0],
                            y: config.light_2_point.position[1],
                            z: config.light_2_point.position[2]
                        },
                        color: config.light_2_point.params[0].replace("0x", "#"),
                        intensity: config.light_2_point.params[1],
                        distance: config.light_2_point.params[2],
                        decay: config.light_2_point.params[3]
                    };
                }
            }

            // compatibilità hdr vecchi avatar
            if (config.tonemapping !== undefined) {
                this.config.tonemapping = {
                    type: config.tonemapping.type,
                    exposure: config.tonemapping.exposure
                }
            }

            //Load random avatar (Like Montemagno)
            if (this.isNotEmpty(this.config.scene.avatar) && this.config.scene.avatar.indexOf('||') !== -1) {
                const splitted = this.config.scene.avatar.split('||');
                const index = Math.round(Math.random() * (splitted.length - 1));
                this.config.scene.avatar = splitted[index];
            }

            //Fix releative path and retro compatibility path

            //avatar
            if (this.isNotEmpty(this.config.scene.avatar) && this.config.scene.avatar.indexOf('/modelsGltf') === 0)
                this.config.scene.avatar = this.config.scene.avatar.replace('/modelsGltf', 'models/old');

            if (this.isNotEmpty(this.config.scene.avatar) && this.config.scene.avatar.indexOf('modelsGltf') === 0)
                this.config.scene.avatar = this.config.scene.avatar.replace('modelsGltf', 'models/old');

            if (this.isNotEmpty(this.config.scene.avatar) && this.config.scene.avatar.indexOf('http') === -1)
                this.config.scene.avatar = this.features.instance.baseUrl + '/' + this.config.scene.avatar;

            //world
            if (this.isNotEmpty(this.config.scene.world) && this.config.scene.world.indexOf('/modelsGltf') === 0)
                this.config.scene.world = this.config.scene.world.replace('/modelsGltf', 'models/old');

            if (this.isNotEmpty(this.config.scene.world) && this.config.scene.world.indexOf('modelsGltf') === 0)
                this.config.scene.world = this.config.scene.world.replace('modelsGltf', 'models/old');

            if (this.isNotEmpty(this.config.scene.world) && this.config.scene.world.indexOf('http') === -1)
                this.config.scene.world = this.features.instance.baseUrl + '/' + this.config.scene.world;

            //hdr
            if (this.isNotEmpty(this.config.scene.hdr) && this.config.scene.hdr.indexOf('/3d/hdr') === 0)
                this.config.scene.hdr = this.config.scene.hdr.replace('/3d/hdr', 'hdr/old');

            if (this.isNotEmpty(this.config.scene.hdr) && this.config.scene.hdr.indexOf('3d/hdr') === 0)
                this.config.scene.hdr = this.config.scene.hdr.replace('3d/hdr', 'hdr/old');

            if (this.isNotEmpty(this.config.scene.hdr) && this.config.scene.hdr.indexOf('http') === -1)
                this.config.scene.hdr = this.features.instance.baseUrl + '/' + this.config.scene.hdr;

            //background
            if (this.isNotEmpty(this.config.scene.backgroundImage) && this.config.scene.backgroundImage.indexOf('/backgrounds') === 0)
                this.config.scene.backgroundImage = this.config.scene.backgroundImage.replace('/backgrounds', 'backgrounds/old');

            if (this.isNotEmpty(this.config.scene.backgroundImage) && this.config.scene.backgroundImage.indexOf('backgrounds') === 0)
                this.config.scene.backgroundImage = this.config.scene.backgroundImage.replace('backgrounds', 'backgrounds/old');

            if (this.isNotEmpty(this.config.scene.backgroundImage) && this.config.scene.backgroundImage.indexOf('http') === -1)
                this.config.scene.backgroundImage = this.features.instance.baseUrl + '/' + this.config.scene.backgroundImage;

        },
        initLutCube(callback) {
            if (this.config.lut !== undefined && this.config.lut.enabled) {
                const self = this;
                this.$log.info('Load lut cube', this.config.lut.cube);

                const loader = new LUTCubeLoader();
                loader.load(this.config.lut.cube, function (lut) {
                    callback(lut);
                }, null, function (error) {
                    self.$log.error('Error loading lut cube', error);
                    callback(null);
                });
            } else {
                callback(null);
            }
        },
        loadBackground() {
            const self = this;

            if (this.config.background !== undefined &&
                this.config.background.image !== undefined &&
                this.config.background.enabled !== undefined &&
                this.config.background.gamma !== undefined) {

                const loader = new Three.TextureLoader();
                const file = this.config.background.image;
                if (file != null && file !== '') {
                    loader.load(file, function (texture) {

                        self.background = new CustomBackgroundPlane({
                            texture: texture,
                            width: self.width,
                            height: self.height,
                            gamma: self.config.background.gamma,
                            enabled: self.config.background.enabled,

                            lut: self.lut,
                            lutIntensity: self.features.avatar.lutIntensity
                        });

                        if (!self.features.transparent || self.features.status.fullscreen) {
                            self.background.visible = true;
                        } else {
                            self.background.visible = false;
                        }

                        self.scene.add(self.background);
                        self.background.position.z = -1;

                    },self.onProgress, self.onErrorLoad);
                }
            } else {
                const container = this.$refs.container;
                const videoBackground = this.$refs.videoBackground;
                const imageBackground = this.$refs.imageBackground;

                if (this.features.renderer === 'PeppersGhost') {
                    container.style.background = 'black';
                } else {
                    if (!this.features.transparent || this.features.status.fullscreen) {
                        if (this.config.scene.backgroundImage !== undefined && this.config.scene.backgroundImage !== '') {
                            imageBackground.setAttribute('src', this.config.scene.backgroundImage);
                            imageBackground.style.display = 'block';
                        }
                        if (this.config.scene.backgroundVideo !== undefined && this.config.scene.backgroundVideo !== '') {
                            videoBackground.setAttribute('src', this.config.scene.backgroundVideo);
                            videoBackground.style.display = 'block';
                        }

                        if (this.background != null)
                            this.background.visible = true;
                    } else {

                        imageBackground.style.display = 'none';

                        if (this.background != null)
                            this.background.visible = false;
                    }
                }
            }
        },

        clearBackground() {
            const videoBackground = this.$refs.videoBackground;
            const imageBackground = this.$refs.imageBackground;

            if (this.features.transparent) {

                if (this.config.scene.backgroundImage !== '') {
                    imageBackground.removeAttribute('src');
                    imageBackground.style.display = 'none';
                }
                if (this.config.scene.backgroundVideo !== '') {
                    videoBackground.removeAttribute('src');
                    videoBackground.style.display = 'none';
                }

                if (this.background != null)
                    this.background.visible = false;
            }
        },
        loadHdr(file, file_light) {
            const self = this;

            if (file_light !== undefined && file_light != null)
                file = file_light;

            if (file !== undefined && file != null && file != self.loadedHdr) {
                const loader = new RGBELoader();
                loader.load(file, function (texture) {

                    const pmremGenerator = new Three.PMREMGenerator(self.renderer);

                    let envMap = null;
                    if (texture.image.height <= 256) {
                        pmremGenerator.compileCubemapShader();
                        envMap = pmremGenerator.fromCubemap(texture).texture;
                    } else {
                        pmremGenerator.compileEquirectangularShader();
                        envMap = pmremGenerator.fromEquirectangular(texture).texture;

                    }

                    self.scene.environment = envMap;

                    if (self.config.scene.backgroundImage === undefined)
                        self.scene.background = envMap;

                    texture.dispose();
                    pmremGenerator.dispose();

                    self.loadedHdr = file;
                }, self.onNoProgress, self.onErrorLoad);
            }
        },
        loadAvatar(file) {
            const self = this;
            this.loadingManager = new Three.LoadingManager();

            new Promise(function(resolve) {

                if (file.match(/\.zip$/)) {
                    self.loaderDelay = 1000;
                    new ZipLoader().load(file, self.onProgress, self.onErrorLoad).then(function(zip) {
                        if (zip !== undefined) {
                            self.loadingManager.setURLModifier(zip.urlResolver);
                            resolve(zip.find(/\.(gltf|glb)$/i)[0]);
                        } else {
                            self.onErrorLoadGltfAvatar('Zip file not found');
                        }
                    });

                } else {
                    resolve(file);
                }
            }).then(function (file) {
                if (file.indexOf('.gl') !== -1)
                    self.loadGltfAvatar(file);
                else if (file.indexOf('.fbx') !== -1)
                    self.loadFbxAvatar(file);
            });
        },
        loadWorld(file) {
            const self = this;
            this.loadingManager = new Three.LoadingManager();

            new Promise(function(resolve) {

                if (file.match(/\.zip$/)) {
                    self.loaderDelay = 1000;
                    new ZipLoader().load(file, self.onNoProgress, self.onErrorLoad).then(function(zip) {
                        if (zip !== undefined) {
                            self.loadingManager.setURLModifier(zip.urlResolver);
                            resolve(zip.find(/\.(gltf|glb)$/i)[0]);
                        } else {
                            self.onErrorLoadGltfAvatar('Zip file not found');
                        }
                    });

                } else {
                    resolve(file);
                }
            }).then(function (file) {
                self.loadGltfWorld(file);
            });
        },
        loadAvatarAnimations(file) {
            const self = this;
            this.loadingManager = new Three.LoadingManager();

            new Promise(function(resolve) {

                if (file.match(/\.zip$/)) {

                    new ZipLoader().load(file, self.onProgress, self.onErrorLoad).then(function(zip) {
                        self.loadingManager.setURLModifier(zip.urlResolver);
                        resolve(zip.find(/\.(gltf|glb)$/i)[0]);
                    });

                } else {
                    resolve(file);
                }
            }).then(function (file) {
                self.loadGltfAdditional(file);
            });
        },
        loadAsset(gltf) {
            this.$log.info('Gltf asset', gltf.asset);

            //read extras custom info from asset
            if (gltf.asset !== undefined) {
                //read asset from gltf
                if (gltf.asset.threejs !== undefined) {
                    this.threejsVersion = gltf.asset.threejs;
                }
                //read extras asset from glb
                if (gltf.asset.extras !== undefined && gltf.asset.extras.threejs !== undefined) {
                    this.threejsVersion = gltf.asset.extras.threejs;
                }

                if (gltf.asset.animation !== undefined) {
                    this.animationVersion = gltf.asset.animation;
                }
                //read extras asset from glb
                if (gltf.asset.extras !== undefined && gltf.asset.extras.animation !== undefined) {
                    this.animationVersion = gltf.asset.extras.animation;
                }
            }
        },
        scaleAvatar() {
            let box = new Three.Box3().setFromObject(this.avatar);
            const size = box.getSize(new Three.Vector3()).y;
            //riferimenti avatar margherita e alessandro
            const scalar = this.isFemale ? 1.7465958240480002 : 1.7772767147558668;

            this.$log.debug('Avatar y size:', size);
            this.$log.info('Scale avatar to ' + scalar.toFixed(3) + 'm');
            this.$log.debug('Height: ' + size.toFixed(3) + 'm Factor: ' + (scalar / size).toFixed(3));

            this.avatar.scale.set(scalar / size, scalar / size, scalar / size);
        },
        loadFbxAvatar(file) {
            const self = this;

            const loader = new FBXLoader(this.loadingManager);

            loader.load(
                // resource URL
                file,
                // called when the resource is loaded
                function (fbx) {

                    self.avatar = fbx;

                    fbx.traverse((child) => {
                        if (child.isMesh) {
                            if (child.material.type === 'MeshPhongMaterial') {
                                const prevMaterial = child.material;
                                child.material = new Three.MeshStandardMaterial({
                                    color: prevMaterial.color,
                                    map: prevMaterial.map,
                                });
                                prevMaterial.dispose();
                            }
                        }
                    });

                    self.scaleAvatar();
                    self.scene.add(fbx);

                    if (self.lut != null)
                        self.initLutShader();

                    self.loadAnimations(fbx);

                    self.onEndLoading();
                },
                self.onProgress, self.onErrorLoadGltfAvatar
            );
        },
        loadGltfAvatar(file) {
            const self = this;

            const loader = new GLTFLoader(this.loadingManager);
            const ktx2Loader = new KTX2Loader()
                .setTranscoderPath(this.features.instance.baseUrl + '/js/libs/basis/')
                .detectSupport(this.renderer);
            loader.setKTX2Loader(ktx2Loader);
            loader.setMeshoptDecoder(MeshoptDecoder);

            loader.load(
                // resource URL
                file,
                // called when the resource is loaded
                function (gltf) {

                    self.avatar = gltf.scene;

                    self.loadAsset(gltf);

                    /*
                    gltf.scene.updateMatrixWorld();
                    let box = new Three.Box3().setFromObject(gltf.scene);
                    const size = box.getSize(new Three.Vector3()).length();
                    alert(size);
                     */

                    //fix old models made for three.js 102
                    if (self.threejsVersion === 102) {
                        gltf.scene.traverse(function (node) {
                            if (node.isMesh) {
                                if (node.material.alphaTest === 0 && node.material.transparent === true) {
                                    node.material.depthWrite = true;
                                }
                            }
                        });
                    }

                    //Inserire questa funzione per renderizzare correttamente le doppie textures https://discourse.threejs.org/t/mesh-disapear-when-camera-close/2914/3
                    gltf.scene.traverse(function (node) {
                        node.frustumCulled = false;
                    });

                    //remove normal map for unupported devices
                    if (self.$smartphone.notSupportNormalMap()) {
                        self.$log.info('Remove Normal Map for unsupported devices');
                        gltf.scene.traverse(function (node) {
                            if (node.isMesh) {
                                node.material.normalMap = null;
                            }
                        });
                    }

                    //test bump map
                    /*
                    self.$log.info('Test bump map');
                    gltf.scene.traverse(function (object) {
                        if (object.isMesh && object.material.normalMap != null) {
                            self.$log.info(object.name);
                            if (object.name === 'mesh_0_1') {
                                object.material.bumpMap = object.material.normalMap;
                                object.material.bumpScale = 0.7;
                                object.material.normalMap = null;
                            }
                        }
                    });
                    */

                    if (self.config.scene.world === undefined)
                        self.scaleAvatar();

                    self.scene.add(gltf.scene);

                    if (self.lut != null)
                        self.initLutShader();

                    self.loadAnimations(gltf);

                    self.onEndLoading();
                },
                self.onProgress, self.onErrorLoadGltfAvatar
            );
        },
        loadGltfWorld(file) {
            const self = this;

            const loader = new GLTFLoader(this.loadingManager);
            const ktx2Loader = new KTX2Loader()
                .setTranscoderPath(this.features.instance.baseUrl + '/js/libs/basis/')
                .detectSupport(this.renderer);
            loader.setKTX2Loader(ktx2Loader);
            loader.setMeshoptDecoder(MeshoptDecoder);

            loader.load(
                // resource URL
                file,
                // called when the resource is loaded
                function (gltf) {

                    self.world = gltf.scene;

                    self.scene.add(gltf.scene);

                    if (self.lut != null)
                        self.initLutShader();
                },
                self.onNoProgress, self.onErrorLoad
            );
        },
        loadGltfAdditional(file) {
            const self = this;

            const loader = new GLTFLoader(this.loadingManager);
            const ktx2Loader = new KTX2Loader()
              .setTranscoderPath(this.features.instance.baseUrl + '/js/libs/basis/')
              .detectSupport( this.renderer );
            loader.setKTX2Loader( ktx2Loader );
            loader.setMeshoptDecoder(MeshoptDecoder);

            loader.load(
              // resource URL
              file,
              // called when the resource is loaded
              function (gltf) {
                  self.loadAnimations(gltf);
              },
                self.onProgress, self.onErrorLoad
            );
        },
        loadAnimations(gltf) {

            const self = this;
            const firstRun = this.animations == null;

            if (firstRun) {
                // Animations mixer
                this.mixer = new Three.AnimationMixer(gltf.scene || gltf);

                this.mixer.addEventListener('loop', function (e) {
                    self.$log.debug('Animation loop', e.action.name);
                });

                this.mixer.addEventListener('finished', function (e) {
                    const action = e.action;
                    if (action.name.indexOf('_') === -1)
                        self.$log.debug('Animation finished', action.name);

                    let playNext = false;

                    if (self.currentAnimationType(action.name)) {
                        if (self.animationType(action.name, 'intro') ||
                            self.animationType(action.name, 'waiting') ||
                            self.animationType(action.name, 'showing') ||
                            self.animationType(action.name, 'talking') ||
                            self.animationType(action.name, 'preview'))
                            playNext = true;
                    }

                    if (playNext) {
                        const nextAction = self.segues[action.name];
                        if (nextAction !== undefined) {
                            self.$log.debug('Segues', nextAction);
                            if (self.existAction(nextAction)) {
                                self.fadeAction(nextAction);
                            } else {
                                self.$log.debug('No segues found', nextAction);
                                const randomName = self.getRandomAction(nextAction);
                                self.fadeAction(randomName);
                            }
                        } else {
                            self.$log.debug('No segues for', action.name);
                            let animation = action.name;
                            //check no segues rules
                            if (self.animationType(animation,'intro'))
                                animation = 'waiting';
                            else if (self.animationType(animation,'showing'))
                                animation = 'talking';
                            else if (self.animationType(animation,'talking'))
                                animation = 'talking';

                            const randomName = self.getRandomAction(animation);
                            self.fadeAction(randomName);
                        }
                    }

                    //Lip Sync
                    if (action.tweens !== undefined) {
                        action.tweens.map(function (tween) {
                            tween.stop();
                        });
                        delete(action.tweens);
                    }

                    if (self.visemeAnimations != null  && self.currentViseme === action.name) {
                        var v = self.visemeAnimations.shift();
                        if (v !== undefined) {
                            self.fadeViseme(v, action);
                        } else {
                            action.fadeOut(0.15);
                        }
                    }
                });

                this.$log.info("Load animations");
                this.animations = {};
                this.segues = {};
                this.actions = {};
            } else {
                this.$log.info("Load additional animations");
            }

            //print gltf animations
            this.$log.info("Gltf Animations", gltf.animations.map(function (item) {
                return item.name
            }));

            if (firstRun) {

                if (this.config.animations !== undefined && this.config.animations != null) {
                    //populate animations config
                    this.$log.info('Populate animation config...');
                    this.config.animations.map(function (item) {
                        self.animations[item.animation_name] = {
                            loopOnce: item.animation_loop_once,
                            enabled: item.animation_enabled
                        }
                        self.$log.debug('Animation config ', item.animation_name, item.animation_loop_once, item.animation_enabled);
                    });
                } else {
                    this.$log.warn('No animation config.');
                }

                if (this.config.animations_segues !== undefined && this.config.animations_segues != null) {
                    //populate animations segues
                    this.$log.info('Populate animation segues...');
                    this.config.animations_segues.map(function (item) {
                        self.segues[item.previous] = item.next;
                        self.$log.debug('Animation segue', item.previous, item.next);
                    });
                } else {
                    this.$log.warn('No animation segues.');
                }
            }

            this.$log.info('Load animations...');
            gltf.animations.map(function (item) {

                let clip = item;

                let name = item.name;
                let additive = false;

                //fbx mixamo test
                if (name.indexOf('mixamo.com') === 0)
                    name = 'waiting01';
                if (name.indexOf('Take 001') === 0)
                    name = 'pose';


                //fix animations name for retro compatibility
                if (name.indexOf('body_') === 0)
                    name = name.replace('body_', '');

                if (name.indexOf('face_') === 0) {
                    additive = true;
                }

                if (name.indexOf('lip_') === 0) {
                    clip = Three.AnimationUtils.subclip(clip, name, 1,4, 30);
                    additive = true;
                }

                if (name.indexOf('espr_') === 0) {

                    switch (name) {
                        case 'espr_neutra':
                            name = 'expr_neutral';
                            break;
                        case 'espr_rabbia':
                            name = 'expr_angry';
                            break;
                        case 'espr_disgusto':
                            name = 'expr_disgusted';
                            break;
                        case 'espr_paura':
                            name = 'expr_fearful';
                            break;
                        case 'espr_gioia':
                            name = 'expr_happy';
                            break;
                        case 'espr_tristezza':
                            name = 'expr_sad';
                            break;
                        case 'espr_sorpresa':
                            name = 'expr_surprised';
                            break;
                        case 'espr_aspettativa':
                            name = 'expr_expectation';
                            break;
                        case 'espr_fiducia':
                            name = 'expr_confident';
                            break;
                    }

                    clip = Three.AnimationUtils.subclip(clip, name, 1,4, 30);
                    additive = true;
                }

                if (!additive) {
                    self.actions[name] = self.mixer.clipAction(clip);
                    self.panes.list.body[name] = name;
                } else {
                    clip = Three.AnimationUtils.makeClipAdditive(clip);
                    self.actions[name] = self.mixer.clipAction(clip);

                    if (name.indexOf('face_') === 0)
                        self.panes.list.face[name] = name;
                    else if (name.indexOf('lip_') === 0)
                        self.panes.list.lip[name] = name;
                    else if (name.indexOf('expr_') === 0)
                        self.panes.list.expression[name] = name.replace('expr_', '');
                }

                //populate mixer
                self.actions[name].name = name;
                self.actions[name].timeScale = 1;

                // Set dynamic weights
                self.actions[name].setEffectiveWeight(1);

                //compatibility old models animation
                if (self.animationVersion !== 'new') {
                    // For animation of type 'talking' and 'showing' we accelerate the animation speed
                    // For animation of type 'talking' and 'showing' we accelerate the animation speed
                    if (name.indexOf('talking') === 0)
                        self.actions[name].timeScale = 1.3;
                    if (name.indexOf('showing') === 0)
                        self.actions[name].timeScale = 1.1;
                }

                const animationConfig = self.animations[name];
                if (animationConfig !== undefined) {
                    // Set the dynamic loop-once mode
                    if (animationConfig.loopOnce === true) {
                        self.actions[name].setLoop(Three.LoopOnce, 0);
                        self.actions[name].clampWhenFinished = true;
                    }
                    // Set the dynamic animation enabled or not
                    self.actions[name].enabled = animationConfig.enabled;

                } else {
                    //set others
                    if (name.indexOf('intro') === 0 ||
                      name.indexOf('showing') === 0 ||
                      name.indexOf('waiting') === 0 ||
                      name.indexOf('pose') === 0 ||
                      name.indexOf('listening') === 0 ||
                      name.indexOf('talking') === 0 ||
                      name.indexOf('typing') === 0 ||
                      name.indexOf('preview') === 0) {
                        self.actions[name].setLoop(Three.LoopOnce, 0);
                        self.actions[name].clampWhenFinished = true;
                    }
                    if (name.indexOf('face_') === 0) {
                        self.actions[name].setLoop(Three.LoopRepeat, 0);
                        self.actions[name].clampWhenFinished = true;
                    }

                    if (name.indexOf('expr_') === 0) {
                        self.actions[name].setLoop(Three.LoopOnce, 0);
                        self.actions[name].clampWhenFinished = true;
                    }

                    if (name.indexOf('lip_') === 0) {
                        self.actions[name].setLoop(Three.LoopOnce, 0);
                        self.actions[name].clampWhenFinished = true;
                    }

                    // Set the dynamic animation enabled by default
                    self.actions[name].enabled = true;
                }

            });

            if (firstRun) {
                if (this.supportLipSync)
                    this.features.lipsync = true;

                if (this.config.scene.animations !== undefined && this.config.scene.animations != null)
                    for (const i in this.config.scene.animations) {
                        this.loadAvatarAnimations(this.config.scene.animations[i]);
                    }
            }

            if (this.features.settings.editor || this.features.avatar.editor)
                this.initEditor();

        },
        getEmotionTimeScale() {
            let timeScale = 1;

            if (!this.currentAnimationType('waiting')) {
                switch (this.currentAdditiveExpression) {
                    case 'angry':
                    case 'fearful':
                    case 'happy':
                        timeScale = 1.1;
                        break;
                    case 'sad':
                    case 'disgusted':
                    case 'surprised':
                        timeScale = 0.9;
                        break;
                    case 'neutral':
                        timeScale = 1.0;
                        break;
                }
            }

            return timeScale;
        },

        fadeAdditiveExpression(expression, weight) {

            if (this.supportEmpathy) {
                if (weight === undefined)
                    weight = 1.0;

                if (expression !== this.currentAdditiveExpression || weight !== this.currentAdditiveExpressionWeight) {
                    this.$log.info('Play additive avatar expression', expression, weight);

                    const action = this.actions["expr_" + expression];

                    action.setEffectiveWeight(weight);
                    action.setDuration(1.5);
                    action.reset().play();

                    if (this.currentAdditiveExpression !== '') {
                        const prevAction = this.actions["expr_" + this.currentAdditiveExpression];
                        prevAction.crossFadeTo(action, 1.5);
                    } else {
                        action.fadeIn(1.5);
                    }

                    this.currentAdditiveExpression = expression;
                    this.currentAdditiveExpressionWeight = weight;
                    this.refreshPane('expression');
                }
            }

        },
        playEmpathy(empathy) {

            const self = this;

            function getMaxExpression(empathy) {
                const sorted = Object.keys(empathy).map(function(key) {
                    return {
                        expression: key,
                        probability: empathy[key]
                    }
                }).sort((e0, e1) => e1.probability - e0.probability);

                return sorted[0].expression;
            }

            function getMaxExpressionFromList(empathyList) {
                const map = {};
                for (let empathy of empathyList) {
                    const expr = self.expressionMirror[getMaxExpression(empathy)];
                    if (map[expr] === undefined) {
                        map[expr] = 1;
                    } else {
                        map[expr] ++;
                    }
                }

                self.$log.debug('Calculate empathy', map);

                const sorted = Object.keys(map).map(function(key) {
                    return {
                        expression: key,
                        count: map[key]
                    }
                }).sort((e0, e1) => e1.count - e0.count);

                return sorted[0].expression;
            }

            //calculate expression
            if (this.supportEmpathy && !this.playVoice) {
                this.empatyList.push(empathy);

                const expression = getMaxExpressionFromList(this.empatyList);
                this.$log.info('Detect empathy', expression);
                this.empatyList = [];
                this.fadeAdditiveExpression(expression, 1.0);

                if (this.currentAnimation !== 'waiting00')
                    this.fadeAction('waiting00');
                /*
                if (this.empatyList.length === this.empatyCount) {

                    const expression = getMaxExpressionFromList(this.empatyList);
                    this.$log.info('Detect empathy', expression);

                    if (expression !== 'neutral') {
                        this.empatyList = [];
                    } else {
                        this.empatyList = this.empatyList.slice(0, 1);
                    }

                    this.fadeAdditiveExpression(expression, 1.0);

                    if (this.currentAnimation !== 'waiting00')
                        this.fadeAction('waiting00');
                }
                */
            }

        },
        playAction(name) {

            if (name != null && name !== '') {

                if (this.actions[name] !== undefined) {
                    this.actions[name].reset().play();
                    this.$log.debug('Play animation', name);

                } else {
                    this.$log.warn('Play animation not found', name);
                }
            }
        },
        testFadeViseme(viseme) {

            let map = this.visemeMap[viseme];

            //bug lip_T missing in avatar
            if (this.actions[map.name] === undefined) {
                map = this.visemeMapMissing[viseme];
            }

            if (this.actions[map.name] !== undefined) {

                let weight = 1.0;
                if (map.weight !== undefined)
                    weight = map.weight;

                const to = this.actions[map.name];
                to.setEffectiveWeight(weight);
                to.setDuration(0);

                this.$log.debug('Play viseme animation', map.name);

                if (this.currentAdditiveViseme !== '' &&
                    this.currentAdditiveViseme !== map.name &&
                    this.actions[this.currentAdditiveViseme] !== undefined) {

                    const from = this.actions[this.currentAdditiveViseme];
                    to.reset().play();
                    from.crossFadeTo(to, 0.5);
                } else {
                    to.reset().play();
                }

                this.currentAdditiveViseme = map.name;
                this.refreshPane('lip');

            }
        },
        testFadeLips(name) {

            if (this.actions[name] != undefined) {

                const to = this.actions[name];
                to.setEffectiveWeight(1.0);
                to.setDuration(0);

                this.$log.debug('Play lips animation', name);

                if (this.currentAdditiveViseme !== '' &&
                    this.currentAdditiveViseme !== name &&
                    this.actions[this.currentAdditiveViseme] !== undefined) {

                    const from = this.actions[this.currentAdditiveViseme];
                    to.reset().play();
                    from.crossFadeTo(to, 0.5);
                } else {
                    to.reset().play();
                }

                this.currentAdditiveViseme = name;
                this.refreshPane('lips');

            }
        },
        fadeAdditive(name) {
            if (this.actions[name] != undefined) {

                const to = this.actions[name];
                this.$log.debug('Play additive animation', name);

                if (this.currentAdditiveAnimation !== '' &&
                    this.currentAdditiveAnimation !== name &&
                    this.actions[this.currentAdditiveAnimation] !== undefined) {

                    const from = this.actions[this.currentAdditiveAnimation];
                    to.reset().play();
                    from.crossFadeTo(to, 0.5);
                } else {
                    to.reset().play();
                }

                this.currentAdditiveAnimation = name;
                this.refreshPane('additive');

            }
        },
        fadeViseme(viseme, prevAction) {

            let map = this.visemeMap[viseme.name];

            //bug lip_T missing in avatar
            if (map !== undefined && this.actions[map.name] === undefined) {
                map = this.visemeMapMissing[viseme.name];
            }

            if (map !== undefined && this.actions[map.name] !== undefined) {

                const action = this.actions[map.name];

                const current = { x : 0};
                const update = function () {
                    action.setEffectiveWeight(Math.min(current.x, 1.0));
                };

                let weight = 1;
                let maxWeight = 1;
                let duration = viseme.duration;

                let blendTime = 0.10;

                if (map.blendTime !== undefined)
                    blendTime = map.blendTime;

                if (map.overrideWeight !== undefined)
                    weight = map.overrideWeight;

                if (map.weight !== undefined)
                    maxWeight = map.weight;

                if (duration < blendTime) {
                    const lerpFactor = blendTime > 0 ? duration / blendTime : 1;
                    weight = map.overrideWeight !== undefined ? map.overrideWeight : MathUtils.lerp(0, maxWeight, lerpFactor);
                }

                const blendInTime = Math.min(duration, blendTime);
                // Find the amount and time viseme will be held for
                const holdTime = duration - blendTime;

                const tweenIn = new TWEEN.Tween(current)
                    .to({ x : weight}, blendInTime * 1000)
                    .easing(TWEEN.Easing.Back.Out)
                    .onUpdate(update);

                action.tweens = [tweenIn];

                if (holdTime > 0) {
                    const decayRate = {amount: 0.5, seconds: 0.5};
                    const lerpFactor = decayRate.seconds > 0 ? holdTime / decayRate.seconds : 1;
                    // Perform in -> hold -> out animation
                    const decayWeight = MathUtils.lerp(
                        weight,
                        weight * decayRate.amount,
                        Math.min(1, lerpFactor)
                    );

                    const tweenDecade = new TWEEN.Tween(current)
                        .to({x: decayWeight}, holdTime * 1000)
                        .easing(TWEEN.Easing.Back.In)
                        .onUpdate(update);

                    tweenIn.chain(tweenDecade);
                    action.tweens.push(tweenDecade);
                }

                action.reset();
                action.setLoop(Three.LoopOnce, 0);
                action.clampWhenFinished = true;
                action.setDuration(duration);
                action.setEffectiveWeight(0);
                action.play();

                if (prevAction !== undefined) {
                    if (viseme.name === 'sil')
                        action.crossFadeFrom(prevAction, 0.067);
                    else
                        action.crossFadeFrom(prevAction, duration * 1.5);
                }

                tweenIn.start();

                this.currentViseme = map.name;
                this.currentVisemeName = viseme.name;
            } else {
                this.$log.error('Animation missing on avatar', viseme.name);
            }

        },
        stopAllVisemes() {
            this.$log.debug('Stop all lipsync animations');
            const self = this;
            //stop all lips animations
            Object.keys(this.visemeMap).map(function (name) {
                const action = self.actions[self.visemeMap[name]];
                if (action !== undefined && action.isScheduled()) {
                    action.fadeOut();
                    action.stop();
                }
            });
        },
        playLipSync(message) {
            if (this.supportLipSync) {
                this.visemeAnimations = this.parseLipSync(message);

                this.$log.info('Play lipsync', this.visemeAnimations.map((item) => item.name));
                this.stopAllVisemes();

                if (this.visemeAnimations != null) {
                    var v = this.visemeAnimations.shift();
                    if (v !== undefined) {
                        this.fadeViseme(v);
                    }

                }
            }
        },
        stopLipSync(message) {
            if (this.supportLipSync) {
                this.$log.info('Stop lipsync');
                this.visemeAnimations = [{
                    name: 'sil',
                    key: 'SIL',
                    duration: 0.015
                }];
            }

            //nasconde ultimo messaggio se non form o multimedia
            if (this.features.showLastMessage) {
                if (!message.isForm && !message.mediaType) {
                    if (this.features.avatar.autoHideMessage > 0) {
                        setTimeout(() => {
                            message.history = true;
                        }, this.features.avatar.autoHideMessage * 1000);
                    }
                }
            }
        },
        parseLipSync(message) {
            let data = [];

            const lipsyncData = message.lipsync;
            const audioDuration = message.duration;

            if (lipsyncData !== undefined && lipsyncData.timestamps !== undefined) {

                let offset = 0;
                let prev = null;

                let prevEnd = 0;
                for (let timestamp of lipsyncData.timestamps) {
                    const start = timestamp.start;
                    const end = timestamp.end;
                    let char = timestamp.value;
                    let duration = (end - start) + (start - prevEnd);

                    let name = null;
                    let mapped = null;

                    switch (lipsyncData.model_id) {
                        case 'cmu_sphinx':
                            mapped = this.lipsMap.cmu_sphinx[char];
                            break;
                        case 'amazon':
                            mapped = this.lipsMap.amazon[char];
                            break;
                    }

                    if (mapped !== undefined) {
                        name = mapped.name;
                    }

                    if (name != null && prev !== char) {

                        data.push({
                            name: name,
                            key: char,
                            duration: duration + offset
                        });
                        offset = 0;

                    } else {
                        offset += duration;
                    }

                    prev = char;
                    prevEnd = end;
                }
            }

            if (data.length === 0) {
                data = this.generateFakeViseme(audioDuration);
            }

            return data;
        },
        generateFakeViseme(totalDuration) {
            const data = [];
            const endPause = 0.40;
            let text = 'Lorem ipsum dolor sit amet consectetur adipiscing elit Phasellus ac viverra neque vitae posuere turpis Donec fringilla elit sed vehicula faucibus leo magna ornare risus at imperdiet enim libero vel libero Mauris congue tristique scelerisque Donec tincidunt orci est sit amet facilisis justo mattis vel Morbi scelerisque pulvinar sem vitae rutrum velit faucibus et Fusce eleifend aliquet leo at feugiat lectus Mauris leo odio laoreet sit amet arcu vel lobortis suscipit dui Nunc et nisl eget tellus pretium luctus auctor ut lectus Morbi ut ante tortor Sed nec eleifend metus Vivamus sem nisl sollicitudin eget dolor quis ultrices lacinia felis Donec bibendum luctus tortor vitae laoreet In nec sollicitudin nisi Nulla sagittis in dui eget hendrerit Duis diam mi pretium vel enim ut hendrerit iaculis nisi Phasellus dignissim egestas semper';
            let lung = Math.trunc((totalDuration - endPause) / 0.067);

            let str = text.substr(0, lung);

            str = str.replaceAll(/\s+/g, '-');
            str = "-" + str.replaceAll(/[.?,!]/g, '_');

            const letterDuration = (totalDuration - endPause) / (str.length);

            var total = 0;

            if (str.length > 0) {

                let offset = 0;
                for (let l=0; l < str.length - 1; l++) {

                    if (total < totalDuration) {
                        const char = str[l].toUpperCase();

                        let name = null;

                        let mapped = this.lipsMap.fake[char];
                        if (mapped !== undefined) {
                            name = mapped.name;
                        }

                        if (name != null) {

                            data.push({
                                name: name,
                                key: char,
                                duration: letterDuration + offset
                            });
                            total += letterDuration + offset;
                            offset = 0;

                        } else {
                            //console.error(char, letterDuration);
                            offset += letterDuration;
                        }
                    }

                }

                data.push({
                    name: this.lipsMap.fake['_'].name,
                    duration: endPause
                });

                this.$log.debug('Generate fake lipsync', totalDuration, letterDuration, str);
            }

            return data;
        },
        fadeAction(name, crossFadeTime) {
            if (this.mixer != null) {
                if (this.actions[this.currentAnimation] !== undefined && this.actions[name] !== undefined) {

                    if (this.currentAnimation !== name) {
                        this.$log.debug('Play animation', name);

                        const from = this.actions[this.currentAnimation].play();
                        const to = this.actions[name].play();

                        from.enabled = true;
                        to.enabled = true;

                        to.timeScale = this.getEmotionTimeScale();

                        if (to.loop === Three.LoopOnce) {
                            to.reset();
                            if (this.animationVersion === 'new') {
                                //se passo a waiting inizio l'animazione da un punto random
                                if (!this.currentAnimationType('waiting')
                                    && this.animationType(name, 'waiting')) {
                                    const duration = this.actions[name].getClip().duration;
                                    const time = this.randomFromInterval(0, duration / 2.0);
                                    to.time = time;
                                    this.$log.debug('Start time', time, duration);
                                }
                            }
                        }

                        if (crossFadeTime === undefined || crossFadeTime === null) {
                            crossFadeTime = 0.6;

                            if (this.animationVersion === 'new')
                                crossFadeTime = 0.8;
                        }

                        from.crossFadeTo(to, crossFadeTime);
                    } else {
                        this.$log.debug('Animation replay', name);

                        const to = this.actions[name].play();
                        if (to.loop === Three.LoopOnce) {
                            to.reset();
                        }
                    }

                    this.currentAnimation = name;

                    this.refreshPane('animation');
                }


                let additive = '';
                if (this.playVoice) {
                    additive = 'face_talking';
                } else {
                    additive = 'face_' + name.replace(/\d+$/, '');
                }
                if (this.supportLipSync && additive === 'face_talking')
                    additive = 'face_forlip';

                if (this.existAction(additive))
                    this.fadeAdditive(additive);
            }
        },
        fadeActionRandom(name, crossFadeTime) {
            this.fadeAction(this.getRandomAction(name), crossFadeTime);
        },
        existAction(name) {
            return this.actions != null && this.actions[name] !== undefined;
        },
        currentAnimationType(type) {
            const key = type.replace(/\d+$/, '');
            return this.currentAnimation.indexOf(key) === 0;
        },
        animationType(name, type) {
            const key = type.replace(/\d+$/, '');
            return name.indexOf(key) === 0;
        },
        getRandomAction(name) {
            const self = this;

            let result = '';
            const key = name.replace(/\d+$/, '');
            const reg = new RegExp('^' + key + '[0-9][1-9]$', 'g');

            let temp = Object.keys(this.actions || {}).filter(function (item) {
                return reg.test(item);
            });

            if (temp.length > 1) {
                //se ci sono più animazioni dello stesso tipo non viene considerata l'ultima riprodotta
                temp = Object.keys(this.actions).filter(function (item) {
                    return reg.test(item) && item !== self.currentAnimation;
                });
            }

            if (temp.length > 0) {
                const random = Math.round(Math.random() * (temp.length - 1));
                result = temp[random];
            }

            this.$log.debug('Random animation', key, result);
            return result;
        },
        randomFromInterval(min, max) {
            return Math.random() * (max - min + 1) + min;
        },
        onResize() {
            const container = this.$refs.container;
            this.width = container.clientWidth;
            this.height = container.clientHeight;

            this.$log.info('Resize', this.width, this.height);

            this.camera.aspect = this.width / this.height;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.width, this.height);

            this.resizeBackground();

            if (this.effect != null) {
                this.effect.setSize(this.width, this.height);
            }

            if (this.postprocessing != null) {
                this.postprocessing.composer.setSize(this.width, this.height);

                if (this.postprocessing.fxaa !== undefined && this.postprocessing.fxaa.uniforms.resolution !== undefined) {
                    //resize fxaa shader
                    this.postprocessing.fxaa.uniforms.resolution.value.x =  1 / (this.width * this.pixelRatio);
                    this.postprocessing.fxaa.uniforms.resolution.value.y = 1 / (this.height * this.pixelRatio);
                }

                if (this.postprocessing.save !== undefined) {
                    //resize save pass
                    this.postprocessing.save.renderTarget.setSize(this.width, this.height);
                }

                if (this.postprocessing.custom !== undefined && this.postprocessing.custom.material.uniforms.resolution !== undefined) {
                    //resize custom shader
                    this.postprocessing.custom.material.uniforms.resolution.value.x =  Math.floor(this.width * this.pixelRatio);
                    this.postprocessing.custom.material.uniforms.resolution.value.y = Math.floor(this.height * this.pixelRatio);
                }
            }

            if (this.effect != null)
                this.effect.setSize(this.width, this.height);

            this.setOpacity(1);

        },
        resizeBackground() {
            if (this.background != null) {
                this.background.resize(this.width, this.height);
            }
        },
        onSendCommand(value){
            this.$emit('onSendCommand', value);
        },
        updateCamera(cam) {
            this.camera.fov = cam.fov;
            this.camera.position.set(cam.x, cam.y, cam.z);
            this.controls.update();
            this.camera.updateProjectionMatrix();
            this.refreshPane('camera');
            this.refreshPane('fov');
        },
        updateTarget(target) {
            this.controls.target.x = target.x;
            this.controls.target.y = target.y;
            this.controls.target.z = target.z;
            this.controls.update();
            this.refreshPane('target');
        },
        setZoom(mode) {

            //se dispositivo mobile lo zoom è sempre mobile
            if (mode === 'desktop' && this.features.device.mobile)
                mode = 'mobile';

            if (mode === 'embed' && this.features.device.mobile)
                mode = 'mobile';

            if (this.config.modes !== undefined && this.config.modes[mode] !== undefined) {
                this.mode = mode;
                const modeConfig = this.config.modes[mode];
                this.updateCamera(modeConfig.camera);
                this.updateTarget(modeConfig.target);
            }
        },
        onDoubleClick() {
          if (this.features.preview) {
              this.fitCameraToAvatar(this.avatar, 1.1);
          }
        },
        fitCameraToAvatar(object, offset ) {
            const boundingBox = new Three.Box3();
            boundingBox.setFromObject( object );

            const size = new Three.Vector3();
            boundingBox.getSize(size);
            const y = (boundingBox.max.y - boundingBox.min.y) / 2;
            const cameraY = (boundingBox.max.y - boundingBox.min.y);

            // https://wejn.org/2020/12/cracking-the-threejs-object-fitting-nut/
            // figure out how to fit the box in the view:
            // 1. figure out horizontal FOV (on non-1.0 aspects)
            // 2. figure out distance from the object in X and Y planes
            // 3. select the max distance (to fit both sides in)
            //
            // The reason is as follows:
            //
            // Imagine a bounding box (BB) is centered at (0,0,0).
            // Camera has vertical FOV (camera.fov) and horizontal FOV
            // (camera.fov scaled by aspect, see fovh below)
            //
            // Therefore if you want to put the entire object into the field of view,
            // you have to compute the distance as: z/2 (half of Z size of the BB
            // protruding towards us) plus for both X and Y size of BB you have to
            // figure out the distance created by the appropriate FOV.
            //
            // The FOV is always a triangle:
            //
            //  (size/2)
            // +--------+
            // |       /
            // |      /
            // |     /
            // | F° /
            // |   /
            // |  /
            // | /
            // |/
            //
            // F° is half of respective FOV, so to compute the distance (the length
            // of the straight line) one has to: `size/2 / Math.tan(F)`.
            //
            // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
            // the camera.fov is the vertical FOV.

            const fov = this.camera.fov * ( Math.PI / 180 );
            const fovh = 2*Math.atan(Math.tan(fov/2) * this.camera.aspect);
            let dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
            let dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
            let cameraZ = Math.max(dx, dy);

            // offset the camera, if desired (to avoid filling the whole canvas)
            if( offset !== undefined && offset !== 0 ) cameraZ *= offset;

            this.camera.position.set( 0, cameraY, cameraZ );

            // set the far plane of the camera so that it easily encompasses the whole object
            const minZ = boundingBox.min.z;
            const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

            this.camera.far = cameraToFarEdge * 3;
            this.camera.updateProjectionMatrix();

            if (this.controls !== undefined) {
                // set camera to rotate around the center
                this.controls.target = new Three.Vector3(0, y, 0);
                // prevent camera from zooming out far enough to create far plane cutoff
                this.controls.maxDistance = cameraToFarEdge * 2;
            }

            this.refreshPane('target');
            this.refreshPane('camera');
        },
        dispose(object) {
            const self = this;

            if (!object || typeof object !== 'object') {
                return;
            }

            if (object.children) {
                // Dispose all children first
                object.children.forEach(child => self.dispose(child));
            }

            if (object.parent) {
                // Remove from parent to prevent rerender
                object.parent.remove(object);
            }

            if (object.geometry) {
                // Dispose geometry

                object.geometry.dispose();
            }

            if (object.material) {
                // Dispose material and all possible textures

                Object.keys(object.material).forEach(key => {
                    if (object.material[key] && typeof object.material[key].dispose === 'function') {
                        object.material[key].dispose();
                    }
                });

                object.material.dispose();
            }

            if (typeof object.dispose === 'function') {
                // Dispose object itself
                object.dispose();
            }
        },
        testPose(file, additive) {
            if (file === undefined)
                file = 'test';

            if (additive === undefined)
                additive = false;

            const self = this;
            const loader = new Three.FileLoader();
            loader.load( this.features.instance.baseUrl + '/poses/' + file + '.json', function ( result ) {

                const name = 'test';
                let clip = AnimationClip.parse( JSON.parse(result));
                if (additive)
                    clip = Three.AnimationUtils.makeClipAdditive(clip);

                self.actions[name] = self.mixer.clipAction(clip);

                //populate mixer
                self.actions[name].name = name;
                self.actions[name].timeScale = 1;
                // Set dynamic weights
                self.actions[name].setEffectiveWeight(1);
                self.actions[name].enabled = true;

                if (additive)
                    self.fadeAdditive("test");
                else
                    self.fadeAction("test");

            } );

        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {

        this.defaultConfig = {
            scene: {
                avatar: this.features.instance.baseUrl + "/models/" + (this.isFemale ? "default_female" : "default_male") + "/base.glb",
                avatarLite: this.features.instance.baseUrl + "/models/" + (this.isFemale ? "default_female" : "default_male") + "/lite.glb",
                animations: [
                    this.features.instance.baseUrl + "/models/" + (this.isFemale ? "default_female" : "default_male") + "/expression.glb",
                    this.features.instance.baseUrl + "/models/" + (this.isFemale ? "default_female" : "default_male") + "/additional.glb"
                ],
                hdr: this.features.instance.baseUrl + "/hdr/default2.hdr",
                hdrLite: this.features.instance.baseUrl + "/hdr/default2_lite.hdr",
                backgroundImage: this.features.instance.baseUrl + "/backgrounds/default.jpg",
                backgroundVideo: "",
                world: undefined
            },
            /*
            background: {
                enabled: false,
                gamma: 1.0,
                image: this.features.instance.baseUrl + "/backgrounds/default.jpg",
            },
            */
            renderer: {
                antialias: true,
                alpha: true
            },
            camera: null,
            target: null,
            modes: {
                embed: {
                    camera: {
                        fov: 14,
                        x: 0,
                        y: this.isFemale ? 1.8 : 1.7,
                        z: this.isFemale ? 5.4 : 5.5,
                    },
                    target: {
                        x: 0,
                        y: this.isFemale ? 1.16 : 1.16,
                        z: 0
                    },
                },
                mobile: {
                    camera: {
                        fov: 20,
                        x: 0,
                        y: this.isFemale ? 1.59 : 1.58,
                        z: this.isFemale ? 3.5 : 3.6,
                    },
                    target: {
                        x: 0,
                        y: this.isFemale ? 1.22 : 1.22,
                        z: 0
                    },
                },
                desktop: {
                    camera: {
                        fov: 10,
                        x: 0,
                        y: this.isFemale ? 1.61 : 1.56,
                        z: this.isFemale ? 3.58 : 3.6
                    },
                    target: {
                        x: 0,
                        y: this.isFemale ? 1.49 : 1.51,
                        z: 0
                    }
                },
                kiosk: {
                    camera: {
                        fov: 10,
                        x: 0,
                        y: this.isFemale ? 1.75 : 1.65,
                        z: this.isFemale ? 6.4 : 6.6,
                    },
                    target: {
                        x: 0,
                        y: this.isFemale ? 1.22 : 1.23, //1.27, 1.28
                        z: 0
                    }
                },
            },
            gamma: {
                enabled: false,
                gamma: 2.0
            },
            tonemapping: {
                exposure: 0.5,
                type: "Cineon"
            },
            lut: {
                enabled: false,
                cube: '',
                intensity: 1.0
            }
        };

        this.$log.debug('DHI Gender', (this.isFemale ? 'Female' : 'Male'));
        const config = (this.api.bot != null) ? this.api.bot.config3d : this.defaultConfig;
        this.$log.debug('Dhi Config', this.features.dhiConfig);

        if (this.features.dhiConfig && config !== undefined && config.scenario3d !== undefined) {
            if (config.scenario3d.avatar_model !== undefined) {
                this.loadConfigOld(config.scenario3d);
            } else {
                this.config = Helpers.mergeDeep(this.defaultConfig, config.scenario3d);
                const mode = !this.features.device.mobile ? this.config.modes.embed : this.config.modes.mobile;
                this.config.camera = mode.camera;
                this.config.target = mode.target;
            }
        } else {
            this.config = this.defaultConfig;
            const mode = !this.features.device.mobile ? this.config.modes.embed : this.config.modes.mobile;
            this.config.camera = mode.camera;
            this.config.target = mode.target;
        }

        // set personalized model3d, image or video background

        if (this.features.avatar.config3d !== '') {
            this.config = Helpers.mergeDeep(this.config, JSON.parse(this.features.avatar.config3d));
        }

        if (this.features.avatar.model3d !== '') {
            if (this.features.avatar.model3d.indexOf('||') !== -1) {
                const splitted = this.features.avatar.model3d.split('||');
                const index = Math.round(Math.random() * (splitted.length - 1));
                this.config.scene.avatar = splitted[index];
                this.config.scene.avatarLite = splitted[index];
            } else {
                this.config.scene.avatar = this.features.avatar.model3d;
                this.config.scene.avatarLite = this.features.avatar.model3d;
            }
            this.config.scene.animations = [];
        }

        if (this.features.avatar.bgImage !== '') {
            this.config.scene.backgroundImage = this.features.avatar.bgImage;
        }

        if (this.features.avatar.bgVideo !== '') {
            this.config.scene.backgroundVideo = this.features.avatar.bgVideo;
        }

        if (this.features.avatar.hdr !== '') {
            this.config.scene.hdr = this.features.avatar.hdr;
            this.config.scene.hdrLite = null;
        }

        if (this.features.avatar.lutCube !== '') {
            if (this.config.lut === undefined)
                this.config.lut = {};

            this.config.lut.enabled = true;
            this.config.lut.cube = this.features.avatar.lutCube;
            this.config.lut.intensity = this.features.avatar.lutIntensity;
        }

        this.$log.info('Config 3D loaded', this.config);
        this.features.avatar.currentConfig = this.config;

        if (this.features.pixelRatio > 0) {
            this.init();
        } else {
            this.$refs.loading.remove();
            this.features.status.loadedAvatar = true;
        }

        /*if (this.features.settings.developer)
            this.features.instance.inspector.avatar = this;*/

    },
    destroyed() {
        /*if (this.features.settings.developer)
            delete this.features.instance.inspector.avatar;*/

        window.removeEventListener('resize', this.onResize, { passive: true });
        this.renderer.dispose();
        this.controls.dispose();
        this.dispose(this.scene);
    }
}
</script>

<style lang="less">

.avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent linear-gradient(153deg, var(--bg-color) 0%, var(--bg-color-gradient) 100%) 0% 0% no-repeat padding-box;

    .wall, .scene {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .scene-preview {
        cursor: grab;
    }

    .wall {
        background-size: cover;
        background-position: bottom center;
        pointer-events: none;

        video.wall-background {
            position: absolute;
            left: 0;
            top: 0;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            pointer-events: none;
            background-color: white;
        }

        img.wall-background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            background-color: white;
        }
    }

    .logo {
        position: absolute;
        left: 20px;
        top: 20px;
        max-height: 120px;
    }

    .avatar-pane {
        position: fixed;
        left: 10px;
        top: 10px;
        z-index: calc(var(--z-index) + 1);
    }

}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: 1s opacity;
}

.loader-percent {
    color: var(--primary-color);
    font-size: 16px;
    opacity: 0.75;
}

.loading.fade-out {
    opacity: 0;
}

.loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 96px;
    height: 96px;
    margin: -48px 0 0 -48px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--primary-color); //rgba(255,255,255, 1);
    opacity: 1;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    filter: drop-shadow(0px 0px 6px var(--primary-color));
    -webkit-filter: drop-shadow(0px 0px 6px var(--primary-color));
    //filter: drop-shadow(0px 0px 10px #0000004D);
    //-webkit-filter: drop-shadow(0px 0px 10px #0000004D);
}

.loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--primary-color); //rgba(255,255,255, 0.6);
    opacity: 0.6;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
    filter: drop-shadow(0px 0px 6px var(--primary-color));
    -webkit-filter: drop-shadow(0px 0px 6px var(--primary-color));
    //filter: drop-shadow(0px 0px 10px #0000004D);
    //-webkit-filter: drop-shadow(0px 0px 10px #0000004D);
}
.loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--primary-color); //rgba(255,255,255, 0.3);
    opacity: 0.3;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
    filter: drop-shadow(0px 0px 6px var(--primary-color));
    -webkit-filter: drop-shadow(0px 0px 6px var(--primary-color));
    //filter: drop-shadow(0px 0px 10px #0000004D);
    //-webkit-filter: drop-shadow(0px 0px 10px #0000004D);
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.chat-player {

    position: absolute;
    height: 100%;
    width: 100%;

    .chat-container {

        display: flex;
        width: 320px;
        height: 100%;
        margin: auto;
        left: 0;
        right: 0;

        flex-direction: column;
        align-items: stretch;
        overflow: hidden;

    }
}

.transparent {
    .avatar {
        background: transparent !important;
    }
    .loading {
        background-color: transparent !important;
    }
}

/*
.floating {
    position: fixed;
    right: 0;
    bottom:0;
    width: 320px;
    height: 640px;
}
*/

.standby {
    .scene {
        opacity: 0.5;
    }
}

</style>
