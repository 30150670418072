<template>
    <div v-if="!message.hidden" class="other-message">
        <div v-if="message.content !== ''" v-show="showMessageBaloon" class="other-message-body padding-left-common-content">

            <div v-if="hasThumb && showPartecipant" class="thumb-container">
                <img v-if="hasThumb" class="participant-thumb" alt="" :src="getParticipantById(message.participantId).profilePicture"
                     :style="{'width': profilePictureConfig.styles.width, 'height': profilePictureConfig.styles.height, 'border-radius': profilePictureConfig.styles.borderRadius}">
            </div>

            <div class="message-content" :class="{'has-thumb': hasThumb}">
                <div class="message-text" :class="{'first': message.first && showGrouped, 'last': message.last && showGrouped, 'multi': message.multi && showGrouped}">

                    <p :class="{'padding-close': showLastMessageClose}" v-start-with-html="message.content" @click.prevent="onClick($event.target)">
                        <ButtonIcon v-if="showLastMessageClose" size="12px" name="close" class="message-close" @click="onMessageClose" />
                    </p>

                </div>
                <div v-if="message.showTime === true"  class="message-timestamp" :class="{'has-vote': api.voting && canVote}">
                    <template v-if="timestampConfig.relative">
                        {{ message.timestamp.toRelative() }}
                    </template>
                    <template v-else>
                        {{ message.timestamp.toFormat(timestampConfig.format) }}
                    </template>
                </div>

                <template v-if="this.api.voting && canVote">
                    <div class="message-voting" :class="{'voting-off': !showVote, 'voting-on': showVote}" @click="onToggleVote()">
                        <Icon v-if="!showVote" name="icon-vote-off" size="16px" class="chat-icon" />
                        <Icon v-else name="icon-vote-on" size="16px" class="chat-icon" />
                    </div>
                </template>

            </div>
        </div>

        <div class="other-message-multimedia">
            <MediaImage v-if="message.mediaType === 'img'" :message="message" :features="features" />
            <MediaVideo v-if="message.mediaType === 'video'" :message="message" :features="features"/>
            <MediaLink v-if="message.mediaType === 'link'" :message="message" :features="features" @onOpenUrl="onOpenUrl"/>
            <MediaFile v-if="message.mediaType === 'file'" :message="message" :features="features"/>
            <MediaSlideshow v-if="message.mediaType === 'slideshow'" :message="message" :features="features" @onPlayMessage="onPlayMessage"/>
            <MediaChart v-if="message.mediaType === 'highchart'" :message="message" :features="features" />
            <MediaQrCode v-if="message.mediaType === 'qrcode'" :message="message" :features="features"/>
            <MediaIframe v-if="message.mediaType === 'iframe'" :message="message" :features="features"/>
            <MediaPayPal v-if="message.mediaType === 'paypal'" :message="message" :features="features" />
            <MediaPrinter v-if="message.mediaType === 'printer'" :message="message" :features="features" />
        </div>

        <template v-if="message.enabled" v-show="message.enabled">
            <div v-if="message.isForm" class="other-message-form margin-common-content">

                <FieldBookingDate v-if="message.fieldType === 'BookingDateFieldType'" :message="message" @onSendCommand="onSendCommand"/>
                <FieldBirthDate v-else-if="message.fieldType === 'BirthDateFieldType'" :message="message" @onSendCommand="onSendCommand"/>

                <FieldDate v-else-if="message.fieldType === 'DateFieldType'" :message="message" @onSendCommand="onSendCommand"/>
                <FieldDateRange v-else-if="message.fieldType === 'DateRangeFieldType'" :message="message" @onSendCommand="onSendCommand"/>

                <FieldTime v-else-if="message.fieldType === 'HourFieldType'" :message="message" @onSendCommand="onSendCommand"/>
                <FieldTimeRange v-else-if="message.fieldType === 'HourRangeFieldType'" :message="message" @onSendCommand="onSendCommand"/>

                <FieldDateTime v-else-if="message.fieldType === 'DateHourFieldType'" :message="message" @onSendCommand="onSendCommand"/>
                <FieldDateTimeRange v-else-if="message.fieldType === 'DateHourRangeFieldType'" :message="message" @onSendCommand="onSendCommand"/>

                <FieldBool v-else-if="message.fieldType === 'BoolFieldType'" :message="message" @onSendCommand="onSendCommand"/>
                <FieldFile v-else-if="!features.kiosk && message.fieldType === 'FileFieldType'" :message="message" @onSendCommand="onSendCommand"/>
                <FieldWebcam v-else-if="features.kiosk && message.fieldType === 'FileFieldType'" :message="message" @onSendCommand="onSendCommand"/>

                <FieldQRCode v-else-if="message.fieldType === 'QRCodeFieldType' || message.fieldType === 'GreenPassFieldType' || message.fieldType === 'SuperGreenPassFieldType' || message.fieldType === 'BoosterGreenPassFieldType'" :message="message" @onSendCommand="onSendCommand"/>

                <FieldCard v-else-if="message.fieldType === 'CatalogFieldType'" :message="message" :features="features" @onSendCommand="onSendCommand" @onShowCard="onShowCard" @onShowCards="onShowCards"/>

                <template v-else-if="message.hasOptions">
                    <FieldCard :message="message" :features="features" @onSendCommand="onSendCommand" @onShowCard="onShowCard" @onShowCards="onShowCards"/>
                </template>

                <FieldLocation v-else-if="message.fieldType === 'LocationFieldType'" :message="message" :features="features" @onSendCommand="onSendCommand"/>

                <FieldText v-else :message="message" :features="features" @onSendCommand="onSendCommand"/>

            </div>

            <div v-if="canShowSuggestion">
                <Suggestion :message="message" :features="features" @onSendCommand="onSendCommand" @onScrollBottom="onScrollBottom"/>
            </div>
        </template>

    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import Suggestion from "./others/Suggestion";

import MediaImage from "./medias/MediaImage";
import MediaVideo from "./medias/MediaVideo";
import MediaLink from "./medias/MediaLink";
import MediaFile from "./medias/MediaFile";
import MediaSlideshow from "./medias/MediaSlideshow";
import MediaChart from "./medias/MediaChart";
import MediaQrCode from "./medias/MediaQrCode";
import MediaIframe from "./medias/MediaIframe";
import MediaPayPal from "./medias/MediaPayPal";
import MediaPrinter from "./medias/MediaPrinter";

import FieldText from "./fields/FieldText";
import FieldLocation from "./fields/FieldLocation";
import FieldDate from "./fields/FieldDate";
import FieldBookingDate from "./fields/FieldBookingDate";
import FieldBirthDate from "./fields/FieldBirthDate";
import FieldDateRange from "./fields/FieldDateRange";
import FieldTime from "./fields/FieldTime";
import FieldTimeRange from "./fields/FieldTimeRange";
import FieldDateTime from "./fields/FieldDateTime";
import FieldDateTimeRange from "./fields/FieldDateTimeRange";
import FieldCard from "./fields/FieldCard";
import FieldBool from "./fields/FieldBool";
import FieldFile from "./fields/FieldFile";
import FieldWebcam from "./fields/FieldWebcam";
import FieldQRCode from "./fields/FieldQRCode";

import Icon from "./Icon";
import ButtonIcon from "./ButtonIcon";

export default {
    name: 'OtherMessage',
    components: {
        Suggestion,
        MediaImage,
        MediaVideo,
        MediaLink,
        MediaFile,
        MediaSlideshow,
        MediaChart,
        MediaQrCode,
        MediaIframe,
        MediaPayPal,
        MediaPrinter,
        FieldText,
        FieldLocation,
        FieldDate,
        FieldBookingDate,
        FieldBirthDate,
        FieldDateRange,
        FieldTime,
        FieldTimeRange,
        FieldDateTime,
        FieldDateTimeRange,
        FieldCard,
        FieldBool,
        FieldFile,
        FieldWebcam,
        FieldQRCode,
        Icon,
        ButtonIcon
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
        profilePictureConfig: {
            type: Object,
            required: true
        },
        timestampConfig: {
            type: Object,
            required: true
        },
        showPartecipant: {
            type: Boolean,
            required: true
        },
        showVote: {
            type: Boolean,
            required: true
        },
        groupClose: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        }
    },
    data() {
        return {

        }
    },
    directives: {
        startWithHtml: {
            inserted(el, binding) {
                el.insertAdjacentHTML('beforeend', binding.value);
            }
        }
    },
    computed: {
        ...mapGetters([
            'getParticipantById',
            'messages',
            'myself',
        ]),
        firstMessage() {
            return this.showPartecipant;
        },
        hasThumb() {
            if (!this.features.showLastMessage)
                return this.features.extras.botIcon && this.profilePictureConfig.others;
            else
                return false;
        },
        canVote() {
            const lastMessage = this.messages[this.messages.length - 1];
            return this.message.answerType === 'QA' && this.message.questionId != null &&
                this.message.frontEndMessage !== 'FORM_END' &&
                this.message.key === lastMessage.key &&
                this.firstMessage &&
                lastMessage.enabled &&
                lastMessage.voted == null;
        },
        canShowSuggestion() {
            return !this.message.isForm && this.message.voted !== 0 && !this.showVote && this.isLastMessage;
        },
        isLastMessage() {
            const lastMessage = this.messages[this.messages.length - 1];
            return this.message.key === lastMessage.key;
        },
        showLastMessageClose() {
            return this.features !== undefined && !this.features.kiosk && this.features.showLastMessage && this.message.groupType !== 'FORM' &&
                ((this.groupClose && this.message.first) || (!this.groupClose));
        },
        showGrouped() {
            if (!this.features.showLastMessage)
                return true;
            else
                return this.features.showLastMessage && this.features.groupLastMessage;
        },
        showMessageBaloon() {
            if (!this.features.showLastMessage)
                return true;
            else
                return !this.features.hideLastMessage || (this.message.answerType || 'FORM') === 'FORM';
        }
    },
    watch: {
        'message.enabled': {
            handler() {
                this.onScrollBottom();
            }
        },
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onClick(e) {
            const a = e.closest('a');
            if (a != null) {
                const href = a.getAttribute('href');
                if (href != null) {
                    let url = new URL(href);

                    this.$log.debug("Intercept click link", url.href);

                    if (url.host === window.location.host && !this.features.fullscreen)
                        this.$emit('onOpenUrl', url.href);
                    else
                        window.open(url.href, '_blank');
                }

            }
        },
        onSendCommand(value) {
            this.$emit('onSendCommand', value);
        },
        onScrollBottom() {
            this.$emit('onScrollBottom');
        },
        onToggleVote() {
            this.$emit('onToggleVote');
        },
        onShowCard(option) {
            this.$emit("onShowCard", option);
        },
        onShowCards(message) {
            this.$emit("onShowCards", message);
        },
        onOpenUrl(url) {
            this.$emit("onOpenUrl", url);
        },
        onPlayMessage(message) {
            this.$emit("onPlayMessage", message);
        },
        onMessageClose() {
            if (this.groupClose)
                this.$emit("onGroupClose", this.message);
            else
                this.$emit("onMessageClose", this.message);
        }
    }
}
</script>

<style lang="less">
.container-message-display {

    .other-message {
        width: 100%;
        margin-bottom: 8px;

        .other-message-body {
            display: flex;
            position: relative;

            align-items: flex-end;

            .message-content {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                flex-grow: 1;
            }

            .thumb-container {
                margin-right: 10px;
                width: 30px;
                position: absolute;
                top: 1px;

                .participant-thumb {
                    border-radius: 50%;
                    max-width: unset;
                }
            }

            .has-thumb {
                margin-left: 40px;
            }

            .message-text {
                width: auto;
                max-width: calc(100% - 46px);
                margin-bottom: -6px;
                text-align: left;
                word-break: break-word;
                background: var(--bg-color-bot);
                color: var(--fg-color-bot);
                font-size: var(--font-12);
                line-height: calc(var(--font-12) + 3px);
                box-shadow: var(--box-shadow);
                backdrop-filter: var(--backdrop-filter);

                a, a:hover, a:visited {
                    color: var(--primary-color-text);
                }

                & > p {
                    position: relative;
                    padding: 8px 12px 9px 12px;
                    margin: 0;

                    &.padding-close {
                        padding-right: 32px;
                    }
                }

                & > p > p {
                    margin: 0;
                }

                & > p > ol {
                    padding: 0px 0 0 20px;
                    margin: 0;
                }

                & > p > ul {
                    padding: 0px 0 0 20px;
                    margin: 0;
                }


            }

            .message-text.first {
                border-top-left-radius: 4px !important;
                border-top-right-radius: 16px !important;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                margin-bottom: -6px;
            }

            .message-text.last {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 16px !important;
                border-bottom-left-radius: 16px !important;
                margin-bottom: 0;
            }

            .message-text.multi {
                min-width: calc(100% - 46px);
            }

            .message-timestamp {
                position: absolute;
                width: auto;
                text-align: center;
                font-size: var(--font-9);
                color: var(--timestamp-color-bot);
                right: 16px;
                top: 8px;

                &.has-vote {
                    top: 2px;
                }
            }

            .message-close {
                position: absolute;
                right: 6px;
                top: 6px;

                svg {
                    padding: 4px;
                    border-radius: 50%;
                    background-color: var(--bg-button-color-hover);
                    color: var(--fg-color-bot);
                    opacity: 0;
                    animation: showDelay 3s forwards;
                }
                svg:hover {
                    color: var(--fg-button-color-hover);
                    opacity: 1 !important;
                }

                @keyframes showDelay {
                    0%   {opacity: 0;}
                    90% {opacity: 0;}
                    100% {opacity: 1;}
                }
            }

            .message-voting {
                position: absolute;
                right: 18px;
                top: 15px;
                cursor: pointer;

                &.voting-off {
                    svg {
                        color: var(--timestamp-color-bot);
                    }
                }
                &.voting-on {
                    svg {
                        filter: drop-shadow(0px 0px 8px var(--fg-color));
                    }
                }
            }
        }

        .other-message-multimedia {

            display: flex;
            align-items: flex-end;

            .message-multimedia {
                position: relative;
                width: 100%;
                border: 0;
                border-radius: var(--border-radius);
                border-bottom-left-radius: 0px;

                text-align: left;


                & .media-action {
                    display: none;
                    justify-content: flex-end;
                    position: absolute;
                    top: 6px;
                    right: 6px;
                }

                &:hover {
                    & .media-action {
                        display: flex;
                    }
                }

            }

        }

        .other-message-form {
            width: auto;

            .message-form {
                //overflow: hidden;
                width: 100%;
                display: block;
                border: 0;
            }

        }
    }

}

.kiosk {
    .other-message-form {

        .message-form {
            overflow: hidden;
        }

    }
}

</style>
