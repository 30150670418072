<template>
    <div v-if="!features.kiosk" class="message-multimedia margin-common-content-multimedia">

        <Modal class="modal-pdf" :fullscreen="!features.kiosk && fullscreen" @close="fullscreen = false">
            <iframe slot="body"
                    class="pdf shadow-common"
                    :src="message.media.base64 + '#toolbar=0'"
                    title="Pdf"
                    allow="clipboard-write; fullscreen"
            />
        </Modal>

        <div class="media-action">
            <ButtonIcon name="fullscreen" size="16px" class="action-small" @click="toggle"/>
        </div>
    </div>
</template>

<script>

import ButtonIcon from '../ButtonIcon';
import Modal from '../../Modal';

export default {
    name: "MediaPrinter",
    components: {
        ButtonIcon,
        Modal,
    },
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            fullscreen: false,
        };
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        if (this.features.kiosk && !this.message.history) {
            this.printPdf();
        }
    },
    methods: {
        dataURLtoFile(dataurl, filename) {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type:mime});
        },
        printPdf() {
            const filename = this.message.media.filename || "print.pdf";
            const file = this.dataURLtoFile(this.message.media.base64, filename);
            if (file.type === "application/pdf") {
                this.api.printPdf(file).then(() => {
                    //
                });
            }
        },
        toggle() {
            this.fullscreen = !this.fullscreen
        },
    }

}
</script>

<style lang="less">

.pdf {
    border: 0;
    border-radius: var(--border-radius);
    width: 100%;
    height: 200px;
    background-color: #ffffff;
}

.modal-body .pdf {
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.modal-pdf {

}

</style>
