<template>
    <div class="message-multimedia">
        <ul class="message-link-content margin-common-content-multimedia">
            <li class="message-link-items">

                <div class="message-link-item">
                    <svg v-if="!loaded" class="iframe-icon-image" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48" xml:space="preserve">
                      <circle fill="#fff" stroke="none" cx="16" cy="24" r="3">
                        <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.1"/>
                      </circle>
                        <circle fill="#fff" stroke="none" cx="24" cy="24" r="3">
                        <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.2"/>
                      </circle>
                        <circle fill="#fff" stroke="none" cx="32" cy="24" r="3">
                        <animate
                            attributeName="opacity"
                            dur="1s"
                            values="0;1;0"
                            repeatCount="indefinite"
                            begin="0.3"/>
                      </circle>
                    </svg>

                    <template v-else>
                        <img v-if="image !== ''" class="link-image" :src="image" alt="">

                        <svg v-else class="link-icon-image" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path v-if="type === 'image'" fill="currentColor" d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z"/>
                            <path v-else-if="type === 'video'" fill="currentColor" d="M4 6.47L5.76 10H20v8H4V6.47M22 4h-4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4z"/>
                            <path v-else-if="type === 'pdf'" fill="currentColor" d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm12 6V9c0-.55-.45-1-1-1h-2v5h2c.55 0 1-.45 1-1zm-2-3h1v3h-1V9zm4 2h1v-1h-1V9h1V8h-2v5h1zm-8 0h1c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1H9v5h1v-2zm0-2h1v1h-1V9z"/>
                            <path v-else fill="currentColor" d="M17 7h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-6 8H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-2zm-3-4h8v2H8z"/>
                        </svg>

                    </template>
                    <div class="link-title" v-html="title"></div>
                    <div v-if="description !== ''" class="link-description" v-html="description"></div>
                </div>
            </li>
            <li class="message-link-anchor" :content="clearLink" v-tippy="{ placement : 'top',  appendTo: container }" @click="onLinkClicked()">{{hostname}}</li>
        </ul>
    </div>
</template>

<script>

const StorageKeys = {
    LINK: '_algho_link'
};

export default {
    name: "MediaLink",
    components: {

    },
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            title: '',
            description: '',
            image: '',
            type: '',
            loaded: false
        };
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        },
        hostname() {
          const parser = document.createElement('a');
          parser.href = this.message.media;
          return parser.hostname;
        },
        clearLink() {
            return this.message.media.replace('#ba=false', '');
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    created() {
        const self = this;
        const url = this.clearLink;

        const links = JSON.parse(window.sessionStorage.getItem(StorageKeys.LINK) || '{}');
        const preview = links[url];

        if (preview != null) {
            this.showPreview(preview);
        } else {
            this.api.loadPreview(url).then((result) => {
                if (result != null) {
                    links[url] = result;
                    window.sessionStorage.setItem(StorageKeys.LINK, JSON.stringify(links));
                    self.showPreview(result);
                } else {
                    self.showPreview({
                        title: self.$translate(self.api.language, 'chat.link'),
                        description: '',
                        image: '',
                        type: '',
                        loaded: true
                    });
                }
            });
        }
    },
    mounted() {
        /*if (this.features.kiosk)
            this.onLinkClicked();*/
    },
    methods: {
        showPreview(data) {
            this.title = data.title;
            this.description = data.description;
            this.image = data.image;
            this.type = data.type;
            this.loaded = data.loaded;
        },
        onLinkClicked() {
            const url = new URL(this.message.media);
            if (url.host === window.location.host && !this.features.fullscreen)
                this.$emit('onOpenUrl', this.message.media);
            else
                window.open(this.message.media, '_blank')
        },
    }
}
</script>

<style lang="less">

.message-link-content {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;

    background-color: var(--bg-color-card);
    box-shadow: var(--box-shadow);
    backdrop-filter: var(--backdrop-filter);
    border-radius: var(--border-radius);
    font-size: var(--font-12);
    line-height: calc(var(--font-12) + 3px);

    .message-link-anchor {
        text-align: center;
        padding: 8px 12px 9px 12px;
        color: var(--primary-color-text);
        cursor: pointer;
    }

    .message-link-items {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid var(--border-other-color);

        .message-link-item {
            color: var(--fg-color);
            padding: 10px;

            &:last-child {
                border-right: 0;
            }
            &:first-child {
                padding-right: 0;
            }
        }

    }

    .link-image {
        width: 30%;
        height: auto;
        object-fit: cover;
        padding: 0 6px 0 0;
        float: left;
    }

    .link-icon-image {
        color: var(--fg-color);
        padding: 0 6px 0 0;
        float: left;
    }


    .link-title {
        display: inline;
        color: var(--fg-color);
        font-weight: 600;
        font-size: var(--font-10);
    }

    .link-description {
        margin-top: 6px;

        color: var(--fg-color);
        font-size: var(--font-9);
        white-space: break-spaces;
    }

}


</style>
