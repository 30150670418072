<template>
    <div v-if="show" class="message-multimedia margin-common-content-multimedia">

        <Modal class="modal-video" :fullscreen="!features.kiosk && fullscreen" @close="fullscreen = false">

            <iframe v-if="!features.kiosk && !hasDirectLink" slot="body" class="video video-radius shadow-common" :src="parseLink"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                    :sandbox="disableSandbox ? '' :
                    (features.kiosk ?
                    'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation' :
                    'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-popups')"
                    allowfullscreen
                    title="Video"
                    />

            <video v-if="!features.kiosk && hasDirectLink" slot="body" controls="controls" class="video video-radius shadow-common" title="Video" :src="parseLink"></video>

            <KioskMedia v-if="features.kiosk && !fullscreen" slot="body" @onToggle="toggle" @onClose="close">
                <iframe v-if="!hasDirectLink" slot="body" class="video" :src="parseLink"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                        :sandbox="disableSandbox ? '' :
                    (features.kiosk ?
                    'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation' :
                    'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-popups')"
                        allowfullscreen
                        title="Video"
                />
                <video v-if="hasDirectLink" slot="body" controls="controls" disablepictureinpicture controlsList="nofullscreen nodownload noremoteplayback noplaybackrate" class="video" title="Video" :src="parseLink" @click="onPlayback"></video>

            </KioskMedia>
        </Modal>

        <Panel :features="features" :border="false" v-if="features.kiosk && fullscreen" @onClose="fullscreen = false">
            <div slot="body" class="modal-body">
                <iframe v-if="!hasDirectLink" class="video shadow-common" :src="parseLink"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                        :sandbox="disableSandbox ? '' :
                    (features.kiosk ?
                    'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation' :
                    'allow-presentation allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-popups')"
                        allowfullscreen
                        title="Video"
                />

                <video v-if="hasDirectLink" slot="body" controls="controls" disablepictureinpicture controlsList="nofullscreen nodownload noremoteplayback noplaybackrate" class="video shadow-common" title="Video" :src="parseLink" @click="onPlayback"></video>

            </div>
            <div slot="powered"></div>
        </Panel>

        <div v-if="!features.kiosk" class="media-action">
            <ButtonIcon name="fullscreen" size="16px" class="action-small" @click="toggle"/>
        </div>

    </div>
</template>

<script>

import ButtonIcon from '../ButtonIcon';
import VideoLink from "../../../classes/VideoLink";
import Modal from '../../Modal';
import Panel from "../../panels/Panel";
import KioskMedia from "./KioskMedia";

export default {
    name: "MediaVideo",
    components: {
        ButtonIcon,
        Modal,
        Panel,
        KioskMedia
    },
    data: () => ({
        show: true,
        fullscreen: false,
        iframe: {
            sandbox: undefined
        }
    }),
    props: {
        features: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
    },
    computed: {
        parseLink() {
            return VideoLink(this.message.media, true, false, false, false, this.iframe);
        },
        hasDirectLink() {
            return (this.message.media || '').toLowerCase().indexOf(".mp4") !== -1;
        },
        disableSandbox() {
            return this.iframe.sandbox;
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    methods: {
        onPlayback(event) {
            const video = event.target;
            if (video.paused)
                video.play();
            else
                video.pause();
        },
        toggle() {
            this.fullscreen = !this.fullscreen
        },
        close: function () {
            this.show = false;
        },
    }
}
</script>

<style lang="less">
.message-multimedia {
    .video {
        border: 0;
        width: 100%;
        height: 200px;
        background-color: #000000;
    }

    .video-radius {
        border-radius: var(--border-radius);
    }

    .modal-body .video {
        border: unset !important;
        border-radius: unset !important;
        box-shadow: unset !important;
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        background-color: transparent !important;
    }

    .modal-video {

    }
}

.kiosk {
    video::-webkit-media-controls-fullscreen-button {
        display: none;
    }

    video::-webkit-media-controls-volume-slider {
        display: none;
    }

    video::-webkit-media-controls-mute-button {
        display: none;
    }

}
</style>
