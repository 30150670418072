<template>
    <div>
        <slot v-if="!hidden" name="body"></slot>

        <transition v-if="fullscreen" name="modal" appear>
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">

                        <div class="modal-header">
                            <ButtonIcon name="close" size="16px" class="modal-close" :content="$translate(api.language, 'tooltip.close')" v-tippy="{ placement : 'top', appendTo: container }" @click="onClose" />
                        </div>

                        <div class="modal-body scrollable scrollable-y">
                            <slot name="body"></slot>
                        </div>

                        <div class="modal-footer">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import ButtonIcon from './chat/ButtonIcon';

export default {
    name: "Modal",
    components: {
        ButtonIcon
    },
    props: {
        fullscreen: {
            type: Boolean,
            required: true
        },
        hidden: {
            type: Boolean,
            required: false,
            default: () => {
                return false
            }
        },
    },
    computed: {
        container() {
            const rootShadow = this.$root.$options.customElement.shadowRoot;
            return ()=> rootShadow.querySelector('div');
        }
    },
    beforeCreate() {
        this.api = this.$root.$children[0].$refs.api;
    },
    mounted() {
        document.addEventListener("keydown", this.onKeyPress);
    },
    destroyed() {
        document.removeEventListener('keydown', this.onKeyPress);
    },
    methods: {
        onKeyPress(e) {
            if (this.fullscreen && e.keyCode == 27) {
                this.$emit('close');
            }
        },
        onClose() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="less">

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    z-index: calc(var(--z-index) + 1);
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {

    position: relative;
    height: 100%;

    opacity: 1;
    transition: all 0.3s ease;
    box-sizing: border-box;
    background-color: black;
    //background: transparent linear-gradient(153deg, var(--bg-color) 0%, var(--bg-color-gradient) 100%) 0% 0% no-repeat padding-box;

}

.modal-header {
    text-align: left;

    .modal-close {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: calc(var(--z-index) + 1);

        svg {
            padding: 8px;
            border-radius: 50%;
            background-color: var(--bg-button-color-hover);
            color: var(--fg-color-bot);

            &:hover {
                color: var(--fg-button-color-hover);
            }
        }
    }
}

.modal-header h3 {
    margin-top: 0;
    color: var(--primary-color);
    & p {
        margin: 0 !important;
    }
}

.modal-body {
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    //padding: 64px;
    box-sizing: border-box;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
